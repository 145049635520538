import type { WoosmapAutoCompleteArguments, WoosmapLocalityDetailArguments } from "~/types/woosmap/locality.type";

/* Interface for woosmap service */
export type TWoosmapService = {
  fetchLocalitySuggestions: (
    input: string,
    countries?: string[]
  ) => Promise<woosmap.map.localities.LocalitiesAutocompleteResponse>;

  fetchLocalityDetails: (
    publicId: string
  ) => Promise<woosmap.map.localities.LocalitiesDetailsResponse>;
};

/* Error related to woosmap service */
export class WoosmapError extends Error {
  public constructor(message: string) {
    super();
    this.message = message;
  }
}

/* WOOSMAP API SERVICE */
export class WoosmapService implements TWoosmapService {
  private apiKey: string;

  public baseApiUrl: string;

  /* Service setup */
  public constructor(woosmapApiKey: string, woosmapBaseApiUrl: string) {
    this.apiKey = woosmapApiKey;
    this.baseApiUrl = woosmapBaseApiUrl;
  }

  /* Locality based autocomplete suggestions */
  async fetchLocalitySuggestions(
    input: string,
    countries: string[] = ["nl"]
  ): Promise<woosmap.map.localities.LocalitiesAutocompleteResponse> {
    const woosmapComponents = countries
      .map((countryCode) => `country:${countryCode}`)
      .join("|");

    const suggestionQuery: WoosmapAutoCompleteArguments = {
      key: this.apiKey,
      input,
      types: "locality|postal_code",
      components: woosmapComponents,
    };

    return useAjaxServer(
      `${this.baseApiUrl}/localities/autocomplete/`,
      {
        query: suggestionQuery,
      }
    );
  }

  /* To get latitude, longitude details */
  async fetchLocalityDetails(
    localitiesPublicId: string
  ): Promise<woosmap.map.localities.LocalitiesDetailsResponse> {
    const detailsApiQuery: WoosmapLocalityDetailArguments = {
      key: this.apiKey,
      public_id: localitiesPublicId,
    };

    // @ts-ignore
    return useAjax(
      `${this.baseApiUrl}/localities/details/`,
      {
        query: detailsApiQuery,
        show_notifications: false,
      }
    );
  }
}