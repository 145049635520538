//---- filter branches by specified details
export async function handleNearByStockSearch(details: {
  latitude: string;
  longitude: string;
  productCode: string;
  requiredQuantity: number;
}) {
  const {latitude , longitude, productCode, requiredQuantity} = details;
  const stockStore = useStockStore();

  const nearestBranchRequestBody = {
    latitude,
    longitude,
  };

  await stockStore.getNearByStock(
    productCode,
    requiredQuantity,
    nearestBranchRequestBody
  );
}
