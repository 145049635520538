<template>
  <!--  <div :class="twMerge('flex', rootClass)">-->
  <component
      :is="as"
      v-bind="$attrs"
      :class="
        $attrs.class ||
        twMerge(
          'flex',
          appendClass,
          vertical && 'flex-col',
          horizontal && 'flex-row',
        )
      "
      :data-testid="props.dataTestid"
  >
    <slot />
  </component>
  <!--  </div>-->
</template>

<script setup lang="ts">
import { twJoin, twMerge, type ClassNameValue } from "tailwind-merge";

defineOptions({
  inheritAttrs: false,
});

const props=defineProps({
  as: {
    type: String,
    default: "div",
  },
  horizontal: Boolean,
  vertical: Boolean,
  space: Number,
  rootClass: String,
  appendClass: String as PropType<ClassNameValue>,
  dataTestid: {
    type:String,
    default:"organism-media"
  },
  containerClass:String as PropType<ClassNameValue>,
});

const mediaContainerClass =
    "container inline-block w-full text-natural-black bg-natural-soft-white rounded-lg border " +
    "border-natural-light-grey/80 dark:border-natural-dark-grey/40 shadow-md dark:bg-natural-black dark:text-natural-soft-white";
</script>

<!--  FLEX GAPS-->
<!--  gap-1 gap-2 gap-3 gap-4 gap-5 gap-6 gap-7 gap-8 gap-9 gap-10 gap-11 gap-12 gap-14 gap-16 gap-20 gap-24 gap-28 gap-32 -->
<!--  gap-36 gap-40 gap-44 gap-48 gap-52 gap-56 gap-60 gap-64 gap-72 gap-80 gap-96-->
