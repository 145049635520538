<template>
  <div :id="props.id"
       :class="twMerge('mb-8', props.appendClass)">
    <div :class="twMerge('mx-auto max-w-screen-xl px-4', props.containerClass)">
      <slot>
        <Blocks v-if="Object.entries(props.builderBlock).length" :parent="props.builderBlock.id"
          :path="`component.options.content`" :blocks="normalizedContent" />
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Blocks } from '@builder.io/sdk-vue';
import { twMerge } from "tailwind-merge";
import { computed } from "vue";
import { object } from 'zod';

type Props = {
  containerClass?: string,
  appendClass?: string,
  builderBlock?: object,
  content?: object[] | object,
  id?: string,
}

const props = withDefaults(defineProps<Props>(), {
  containerClass: '',
  appendClass: '',
  builderBlock: () => ({}),
  content: () => ({}),
});

const normalizedContent = computed(() =>
  Array.isArray(props.content) ? props.content : [props.content]
);
</script>
