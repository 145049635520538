import { useAjaxEcom } from "../composables/useAjaxEcom";

export class ProCardService {
  static async getCompaniesDataByNumber(number: string, code: string) {
    return await useAjaxEcom(`/procard/company-search?countryISOAlpha2Code=${code}&registrationNumber=${number}`, {
      method: "get",
      headers: {
        'app-type': "Toolstation Web"
      }
    });
  }

  static async getCompaniesDataByName(name: string, code: string) {
    return await useAjaxEcom(`/procard/company-search?countryISOAlpha2Code=${code}&name=${name}`, {
      method: "get",
      headers: {
        'app-type': "Toolstation Web"
      }
    });
  }


  static async getCompaniesData(name: string, number: string, code: string) {
    return await useAjaxEcom(`/procard/company-search?countryISOAlpha2Code=${code}&registrationNumber=${number}&name=${name}`, {
      method: "get",
      headers: {
        'app-type': "Toolstation Web"
      }
    });
  }

  static async postProCardInfo(data: any) {
    return await useAjaxEcom(`/procard/application`, {
      method: "post",
      params: data,
      headers: {
        'app-type': "Toolstation Web"
      }
    });
  }

  static async redirectToTweeky(data: any) {
    return await useAjaxEcom(`/procard/agreement`, {
      method: "post",
      params: data,
      headers: {
        'app-type': "Toolstation Web"
      }
    })
  }

  static async handleRedirectToThankyou(data: any) {
    return await useAjaxEcom(`/procard/application`, {
      method: "post",
      params: data,
      headers: {
        'app-type': "Toolstation Web"
      }
    })
  }

  static async saveData(data: any) {
    return await useAjaxEcom(`/procard/application/save`, {
      method: "put",
      params: JSON.stringify(data),
      headers: {
        'app-type': "Toolstation Web"
      }
    })
  }

  static async getDraftData() {
    return await useAjaxEcom(`/procard/application/draft`, {
      method: "get",
      headers: {
        'app-type': "Toolstation Web"
      }
    })
  }

  static async getBanksList(is_consumer: Boolean) {
    return await useAjaxEcom(`/procard/banks`, {
      method: "get",
      params: is_consumer,
      headers: {
        'app-type': "Toolstation Web"
      }
    })
  }

  static async getApplicationStatus() {
    return await useAjaxEcom(`/procard/application/is-active`, {
      method: "get",
      headers: {
        'app-type': "Toolstation Web"
      }
    })
  }

  static async validateUserInput(data: Object) {
    return await useAjaxEcom(`/procard/match-principle`, {
      method: "post",
      params: data,
      headers: {
        'app-type': "Toolstation Web"
      }
    });
  }
}