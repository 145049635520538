import type {
  Geoposition,
} from "~/types/ecom/branch.type";

export class LocationService {
  //--------------- Method to ask for location permission ------//
  async getCurrentLocation(): Promise<Geoposition> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coordinates: Geoposition = {
              latitude: String(position.coords.latitude),
              longitude: String(position.coords.longitude),
            };
            //------------ TODO - Log in GTM ----------------//
            resolve(coordinates);
          },
          (error) => {
            //---------------TODO Log in DataDog, Gtm------------//
            reject(error);
          }
        );
      } else {
        reject("Geolocation is not supported by this browser");
      }
    });
  }
}
