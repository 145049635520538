import type { PaymentStrategy } from "../payment.service";
import braintree from "braintree-web";

export class ApplePayStrategy {
  private braintree_instance: braintree.Client | null = null;
  private apple_pay_instance: any;
  private data_collector: braintree.DataCollector | null = null;

  async initializePayment(client, data_collector): Promise<any> {
    try {
      const checkoutStore = useCheckoutStore();
      this.braintree_instance = client;
      this.data_collector = data_collector;
      let is_apple_pay_supported = await this.checkApplePaySupport();

      let button = document.querySelector("#apple-pay");
      button?.addEventListener("click", async (event) => {
        checkoutStore.resetError();

        event.preventDefault();

        if (!is_apple_pay_supported) {
          return;
        }
        await this.processApplePay();
      });
    } catch (error) {
      console.error(error);
      useErrorHandler(error);
    }
  }

  async processApplePay($: any = window): Promise<any> {
    const checkoutStore = useCheckoutStore();
    const trolleyStore = useTrolleyStore();

    try {
      let lineItems: any = [];
      // Prepare line items from the trolley store
      for (const key in trolleyStore.trolley_line_items) {
        if (trolleyStore.trolley_line_items[key].length) {
          const products = trolleyStore.trolley_line_items[key].map(
            (product) => ({
              label: product.product.name,
              amount: product.product.prices?.raw.net,
              type: "final",
            })
          );
          lineItems = lineItems.concat(products);
        }
      }

      // If no items, abort the payment process
      if (lineItems.length === 0) {
        trolleyStore.payment_error.applepay_error = "No products in trolley";
        return;
      }

      braintree.applePay.create(
        {
          client: this.braintree_instance,
        },
        function (applePayErr, applePayInstance) {
          if (applePayErr) {
            useErrorHandler(applePayErr);
            console.log(applePayErr);
            return;
          }

          let payment_request = applePayInstance?.createPaymentRequest({
            total: {
              label: "Toolstation order",
              amount:
                trolleyStore.trolley.order_response?.totals?.inc_vat.raw.toString(),
            },
            lineItems: lineItems,
            requiredBillingContactFields: ["postalAddress"], // Optional: configure billing contact fields
          });

          let session = new $.ApplePaySession(3, payment_request);

          session.begin();

          session.onvalidatemerchant = function (event) {
            applePayInstance?.performValidation(
              {
                validationURL: event.validationURL,
                displayName: "Toolstation",
              },
              function (validationErr, validationData) {
                if (validationErr) {
                  console.error(validationErr);
                  useErrorHandler(validationErr);
                  session.abort();
                  return;
                }
                session.completeMerchantValidation(validationData);
              }
            );
          };

          session.onpaymentauthorized = function (event) {
            applePayInstance?.tokenize(
              {
                token: event.payment.token,
              },
              function (tokenizeErr, tokenizedPayload) {
                if (tokenizeErr) {
                  session.completePayment(ApplePaySession.STATUS_FAILURE);
                  return;
                }
                checkoutStore.createTransaction(
                  tokenizedPayload?.nonce,
                  this.data_collector,
                  "braintree-apple-pay"
                );
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
              }
            );
          };

          session.oncancel = function (event) {
            console.log("Apple Pay session was cancelled", event);
            checkoutStore.payment_error.applepay_error =
              "Payment was cancelled by the user.";
            session.abort(); // Optional: Abort the session if needed
          };
        }
      );
    } catch (err) {
      useErrorHandler(err);
      checkoutStore.payment_error.applepay_error = "Apple Pay failed";
    }
  }

  async checkApplePaySupport(
    $: any = window,
    version: number = 3
  ): Promise<Boolean> {
    const checkoutStore = useCheckoutStore();

    if (version < 3) {
      await useErrorHandler(
        new Error("Minnimum version of Apple Pay supported is 3"),
        "high"
      );
      checkoutStore.payment_error.applepay_error =
        "Apple Pay is not supported on this device";
      return false;
    }

    if ($.ApplePaySession && $.ApplePaySession.supportsVersion(version)) {
      checkoutStore.is_apple_pay_supported = true;
      return true;
    }

    await useErrorHandler(
      new Error("Apple Pay is not supported on this device"),
      "high"
    );
    checkoutStore.payment_error.applepay_error =
      "Apple Pay is not supported on this device";
    return false;
  }

  async processPayment(): Promise<any> {}
}
