<template>
  <ClientOnly>
    <TsModal v-model:visible="rootStore.is_ecom_failed_modal_visible"
             append-class="w-[94%] max-w-3xl p-4">
      <template #modal-content>
        <div class="flex justify-between">
          <TsTypography :variant="isMobile ? 'h3' : 'h2'" append-class="text-idle-black mt-2" variant="danger" align="left">
            Error from Ecom API
          </TsTypography>
          <TsTag align="right" append-class="h-6 mt-3" size="sm" variant="info" >Debug mode is ON</TsTag>
        </div>
        <div v-if="rootStore.ecom_error" class="text-center">

          <TsAlert append-class="mt-4" variant="info">
            <TsTag v-if="rootStore.ecom_error.method" variant="success">
              {{ rootStore.ecom_error.method.toUpperCase() }}
            </TsTag>
            <span>&nbsp;&nbsp;&nbsp;{{ rootStore.ecom_error.url }}</span>
          </TsAlert>

          <TsIcon class="text-danger mt-3" size="36" name="fluent:error-circle-24-regular" />

          <p class="text-red-500 text-2xl">
            <strong>{{ rootStore.ecom_error.code }}</strong>
          </p>
          <p class="text-red-500">
            <strong>{{ rootStore.ecom_error.message }}</strong>
          </p>

          <TsAlert  append-class="mt-4" variant="info">
            Note: You are seeing this pop-up because <strong>&nbsp; the site is NOT IN PRODUCTION</strong>.
          </TsAlert>
          <div class="text-center space-x-3 mt-4">
            <TsButton outlined @click="rootStore.is_ecom_failed_modal_visible = false">Close</TsButton>
            <TsButton variant="success" @click='reloadNuxtApp({
              path: localePath(router.path),
              ttl: 1000, // default 10000
            })'>
              Reload
            </TsButton>
          </div>
        </div>
      </template>
    </TsModal>
  </ClientOnly>

</template>

<script setup>
const { isMobile } = useDevice();
const router = useRouter();
const rootStore = useRootStore();
const localePath = useLocalePath();
</script>
