type Event =
  | "click"
  | "page_view"
  | "form_submit"
  | "Sign In"
  | "Sign Up"
  | "PageLoaded";

interface GenericEventOptions {
  eventName: string;
  eventProperties: any;
  eventAction: Event;
  eventLabel: string;
  eventInteraction: boolean;
  eventValue?: any;
}

type EventOptions = {
  options?: GenericEventOptions;
};

export const logEvent = (
  eventParameters: EventOptions,
  skipDataDog = false
): void => {
  let gtm = useGtm();

  /**
   * Event Options map differently as compared to what you might see in the GTM dash.
   * @param eventAction is "action"
   * @param eventProperties is "target"
   * @param eventInteraction is "interaction-type"
   * @param eventLabel is "event"
   * @param eventName is "target-properties"
   * @param eventValue is "value"
   * @
   */

  if (gtm === undefined) {
    gtm = useGtm();
  }

  if (gtm && eventParameters.options) {
    gtm.trackEvent({
      event: eventParameters.options.eventName ?? "click-default",
      category: eventParameters.options.eventProperties ?? {},
      action: eventParameters.options.eventAction ?? "click-default",
      label: eventParameters.options.eventLabel ?? "gtm-event",
      value: eventParameters.options.eventValue ?? "",
      noninteraction: eventParameters.options.eventInteraction ?? false,
    });
  }

  if(useNuxtApp().$ddRum){
    useNuxtApp().$ddRum.addAction(eventParameters.options.eventName,
        eventParameters.options.eventProperties)
  }


  // Similarly implement for datadog

  /**
   * Check here whether datadog object is availabale or not else do it.
   * Ideally you will check the false condition first that it is not available.
   * In case it is undefined or null, reinitialize it
   * */
  if (!true) {
    // initialize datadog client/object here
  }

  // log datadog event here.
  if (!skipDataDog) {
  }
};
