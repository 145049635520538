<template>
  <div
    :class="
      twMerge(
        'flex items-center w-fit',
        props.outlined
          ? 'border border-natural-light-grey rounded-md dark:bg-natural-black dark:border-natural-grey focus-within:border-info dark:focus-within:border-info-dark pl-3.5'
          : '',
        props.disabled && props.checked !== false
          ? '!border-natural-light-grey dark:!border-natural-grey'
          : '',
        props.appendClass
      )
    "
  >
    <input
      :data-testid="props.dataTestid"
      :id="props.id || 'radio-' + randomId"
      type="radio"
      v-model="modelValue"
      v-bind="$attrs"
      :class="
        twMerge(
          input_classes[props.variant.toLowerCase()],
          'border-2 border-natural-silver-grey dark:border-natural-light-grey',
          props.disabled && props.checked !== false
            ? 'cursor-not-allowed border-natural-light-grey dark:border-natural-grey text-natural-light-grey dark:text-natural-grey'
            : '',
          props.inputClass
        )
      "
      :disabled="props.disabled"
      :checked="props.checked"
    />

    <label
      :data-testid="props.dataTestid + '-label'"
      :for="props.id || 'radio-' + randomId"
      :class="
        twMerge(
          label_classes[props.variant.toLowerCase()],
          props.disabled &&
            'cursor-not-allowed select-none text-natural-light-grey dark:text-natural-grey',
          props.outlined && 'py-2 pl-2.5 pr-3.5',
          props.labelClass
        )
      "
    >
      <slot name="label">
        {{ props.label }}
      </slot>
    </label>
  </div>
</template>

<script setup lang="ts" generic="T">
import { twMerge } from "tailwind-merge";
import { ref, onMounted } from "vue";
import { useRandomUUID } from "../../composables/useRandomUUID";

defineOptions({
  inheritAttrs: false,
});

enum Variants {
  Primary = "Primary",
}

const modelValue = defineModel<T>();

// Props
type Props = {
  id?: string
  variant?: `${Variants}`;
  label?: string;
  outlined?: boolean;
  disabled?: boolean;
  checked?: boolean;
  appendClass?: string;
  labelClass?: string;
  inputClass?: string;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  variant: Variants.Primary,
  label: "primary",
  value: "",
  disabled: false,
  checked: false,
  outlined: false,
  name: "select",
  appendClass: "",
  inputClass: "",
  dataTestid: "atom-radio",
});

const label_classes: Record<string, string> = {
  primary:
    "w-full text-base font-medium text-idle-black dark:text-idle-white leading-[22px] pl-2",
};

const input_classes: Record<string, string> = {
  primary:
    "size-5 text-info dark:text-info-dark bg-transparent focus:ring-info-border " +
    "dark:focus:ring-info-dark-border dark:ring-offset-idle-black focus:ring-2 dark:bg-transparent",
};

// random ID for each Vue Instance
const randomId = ref<string>("");

onMounted(() => {
  randomId.value = useRandomUUID();
});
</script>
