<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import { computed } from "vue";

const Variants = [
  "primary",
  "secondary",
  "success",
  "warning",
  "info",
  "danger",
] as const;

enum Sizes {
  Xs = "xs",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}

const Types = ["button", "reset", "submit"] as const;

type Props = {
  label?: string;
  icon?: string;
  variant?: (typeof Variants)[number];
  size?: `${Sizes}`;
  class?: string;
  appendClass?: string;
  iconClass?: string;
  type?: (typeof Types)[number];
  outlined?: boolean;
  rounded?: boolean;
  block?: boolean;
  iconPosition?: "left" | "right";
  link?: string;
  isLoading?: boolean;
  dataTestid?: string;
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  variant: "primary",
  size: Sizes.Md,
  type: "button",
  outlined: false,
  outlinedSuccess: false,
  rounded: false,
  block: false,
  isLoading: false,
  icon: "",
  dataTestid: "atom-button",
  disabled: false, // Set default value for disabled prop
});

const button_classes = computed(() => {
  const baseClasses = {
    primary:
      "text-idle-white bg-primary hover:bg-primary-hover dark:hover:bg-primary-dark-hover dark:text-primary " +
      "dark:hover:text-idle-white dark:bg-idle-white focus:ring focus:ring-primary-border border border-primary dark:border-idle-white dark:hover:border-primary-dark-hover",

    secondary: "text-natural-black bg-natural-soft-white",

    success:
      "text-idle-white bg-success hover:bg-success-hover hover:text-idle-white border-success dark:border-success-dark dark:hover:border-success-dark-hover " +
      "dark:bg-success-dark dark:hover:bg-success-dark-hover dark:hover:text-idle-white focus:ring focus:ring-success-border border border-success dark:border-success-dark",

    info:
      "text-idle-white bg-info hover:bg-info-hover hover:text-idle-white border-info dark:border-info-dark dark:hover:border-info-dark-hover  " +
      "dark:bg-info-dark dark:hover:bg-info-dark-hover dark:hover:text-idle-white focus:ring focus:ring-info-border border border-info dark:border-info-dark",

    warning:
      "text-idle-white bg-warning hover:bg-warning-hover hover:text-idle-white border-warning dark:border-warning-dark dark:hover:border-warning-dark-hover " +
      "dark:bg-warning-dark dark:hover:bg-warning-dark-hover dark:hover:text-idle-white focus:ring focus:ring-warning-border border border-warning dark:border-warning-dark",

    danger:
      "text-idle-white bg-danger hover:bg-danger-hover hover:text-idle-white border-danger dark:border-danger-dark dark:hover:border-danger-dark-hover " +
      "dark:bg-danger-dark dark:hover:bg-danger-dark-hover dark:hover:text-idle-white focus:ring focus:ring-danger-border border border-danger dark:border-danger-dark",
  };

  const outlinedClasses = {
    primary:
      "bg-transparent hover:bg-primary text-primary hover:text-idle-white " +
      "dark:hover:bg-idle-white dark:text-idle-white dark:hover:text-primary border border-primary dark:border-idle-white focus:ring focus:ring-primary-border",

    success:
      "bg-transparent hover:bg-success text-success hover:text-idle-white " +
      "dark:hover:bg-success-dark dark:text-success-dark dark:hover:text-idle-white border border-success dark:border-success-dark focus:ring focus:ring-success-border",

    info:
      "bg-transparent hover:bg-info text-info hover:text-idle-white " +
      "dark:hover:bg-info-dark dark:text-info-dark dark:hover:text-idle-white border border-info dark:border-info-dark focus:ring focus:ring-info-border",

    warning:
      "bg-transparent hover:bg-warning text-warning hover:text-idle-white " +
      "dark:hover:bg-warning-dark dark:text-warning-dark dark:hover:text-idle-white border border-warning dark:border-warning-dark focus:ring focus:ring-warning-border",

    danger:
      "bg-transparent hover:bg-danger text-danger hover:text-idle-white " +
      "dark:hover:bg-danger-dark dark:text-danger-dark dark:hover:text-idle-white border border-danger dark:border-danger-dark focus:ring focus:ring-danger-border",
  };

  return props.outlined ? outlinedClasses : baseClasses;
});


const button_sizes: Record<string, string> = {
  xs: "px-[12px] py-[7px] text-[12px] rounded-[4px] leading-[18px]",
  sm: "px-[12px] py-[8px] text-[14px] rounded-[6px] leading-[20px]",
  md: "px-[20px] py-[8px] text-[16px] rounded-[8px] leading-[24px]",
  lg: "px-[20px] py-[10px] text-[16px] rounded-[8px] leading-[24px]",
  xl: "px-[20px] py-[12px] text-[16px] rounded-[8px] leading-[24px]",
};

defineOptions({
  inheritAttrs: false,
});

const size_map = {
  xs: "size-3",
  sm: "size-5",
  md: "size-[1.125rem]",
  lg: "size-6",
};
let sizes = size_map[props.size] || "";
</script>

<template>
  <NuxtLinkWithLocale
    v-if="props.link"
    :to="props.link"
    :type="props.type"
    v-bind="$attrs"
    :data-testid="props.dataTestid"
    :class="
      props.class || [
        twMerge(
          'font-semibold rounded-md text-base inline-flex leading-6 justify-center items-center gap-2 ',
          Boolean($attrs.disabled) || $attrs.disabled === ''
            ? 'text-natural-silver-grey dark:text-natural-silver-grey cursor-not-allowed bg-natural-light-grey dark:bg-natural-dark-grey'
            : button_classes[variant.toLowerCase()],
          button_sizes[size.toLowerCase()],
          props.block && 'w-full',
          props.iconPosition === 'right' && 'flex-row-reverse',
          [
            props.icon && !props.label && props.size === Sizes.Xs && 'p-[9px]',
            props.icon && !props.label && props.size === Sizes.Sm && 'p-2.5',
            props.icon && !props.label && props.size === Sizes.Md && 'p-3',
            props.icon && !props.label && props.size === Sizes.Lg && 'p-3.5',
          ],
          props.rounded && 'rounded-full',
          props.appendClass
        ),
      ]
    "
  >
    <slot>
      <ClientOnly>
      <Icon
        v-if="props.icon"
        :class="twMerge(sizes, props.iconClass)"
        :name="props.icon"
      /> </ClientOnly>
      <span v-if="props.label" v-html="props.label" />
    </slot>
  </NuxtLinkWithLocale>
  <button
    v-else
    :type="props.type"
    v-bind="$attrs"
    :disabled="props.isLoading || props.disabled"
    :data-testid="props.dataTestid"
    :class="
      props.class || [
        twMerge(
          'font-semibold rounded-md text-base inline-flex leading-6 justify-center items-center gap-2 border',
          props.disabled
            ? 'text-natural-silver-grey dark:text-natural-silver-grey cursor-not-allowed bg-natural-light-grey dark:bg-natural-dark-grey'
            : button_classes[variant.toLowerCase()],
          button_sizes[size.toLowerCase()],
          props.block && 'w-full',
          props.iconPosition === 'right' && 'flex-row-reverse',
          [
            props.icon && !props.label && props.size === Sizes.Xs && 'p-[9px]',
            props.icon && !props.label && props.size === Sizes.Sm && 'p-2.5',
            props.icon && !props.label && props.size === Sizes.Md && 'p-3',
            props.icon && !props.label && props.size === Sizes.Lg && 'p-3.5',
          ],
          props.rounded && 'rounded-full',
          props.isLoading && 'opacity-75 dark:opacity-80',
          props.appendClass
        ),
      ]
    "
  >
    <slot>
        <Icon
          v-if="props.isLoading"
          class="animate-spin"
          :class="twMerge(sizes, props.iconClass)"
          name="mingcute:loading-line"
        />
        <!-- Added loading icon -->
        <Icon
          v-else-if="props.icon"
          :class="twMerge(sizes, props.iconClass)"
          :name="props.icon"
        />
      <span v-if="props.label" v-html="props.label" />
    </slot>
  </button>
</template>
