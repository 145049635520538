<script lang="ts" setup>
import { onMounted, nextTick, onUnmounted } from "vue";
import { initFlowbite } from "flowbite";
import { EventConstants } from "./assets/constants/ecomApi";
import { ExponeaPushNotification } from '~/services/exponeaPushNotification.service';

const { locale } = useI18n();
const router = useRouter();
const rootStore = useRootStore();
rootStore.device = useDevice();
const branchStore = useBranchStore();

const authStore = useAuthStore();
// User from the store
const user = authStore.user;
const builderStore = useBuilderStore();

// if (value) {
//   rootStore.isIncVat = useCookie("vat").value?.isIncVat ?? true;
// }

function confirmTrolleyExists() {
  const trolleyStore = useTrolleyStore();
  if (!trolleyStore.total_products || trolleyStore.total_products === 0) return;
  const authStore = useAuthStore();
  const trolleyExists = trolleyStore.trolley_id && (authStore.is_authenticated || trolleyStore.trolley_session_token)
  if (!trolleyExists) trolleyStore.destroyTrolley();
}


// initialize all services
onBeforeMount(() => {
  const branchStore = useBranchStore();
  branchStore.initialize();
});

// initialize flowbite components based on data attribute selectors
onMounted(async () => {
  // await rootStore.initializeDeviceId();
  if (useCookie("vat")) {
    rootStore.isIncVat = useCookie("vat").value !== undefined ? useCookie("vat").value : true;
  }

  await rootStore.initializeSessionId();

  nextTick(() => {
    initFlowbite();
  });

  confirmTrolleyExists();

  rootStore.isAppHydrated = true;

  builderStore.init();
});


onUnmounted(() => {
  let interval_id = rootStore.access_token_interval_id;
  /* clear the set interval from plugin [refresh-access-token] */
  if (interval_id) clearInterval(interval_id);
});

useHead({
  htmlAttrs: {
    lang: locale.value,
  },
});
onMounted(async () => {
  // registering service worker
  if ('serviceWorker' in navigator) {
    const isServiceWorkerActive = await navigator.serviceWorker.getRegistration()
    if (!isServiceWorkerActive) {
      await navigator.serviceWorker.register('/service-worker.js');
    }
  };

  // initialize exponea push notification
  new ExponeaPushNotification()
})

watch(() => authStore.user?.id, (customer_id: string) => {
  useHead({
  script: [
    {
      src: '/bloomreach-exponea-push-notification.js',
      id: 'exponeaPushNotification',
      customer_id,
      defer: true
    }
  ]
})
}, { immediate: true })
// Navigation tracking setup
const navigationJourney: { from: string; to: string }[] = [];
router.afterEach((to, from) => {
  // logging the PageLoaded event

  logEvent({
    options: {
      eventAction: "PageLoaded",
      eventProperties: [
        {
          customer: authStore.is_authenticated
            ? {
                user_id: user.id,
                email: user.email,
                phone: user.mobile,
                order_count: user.order_count,
                order_value: user.order_value,
                account_type: user.account_type,
              }
            : "User is not Logged In",
          page: {
            page_url: window.location.href,
            page_path: to.fullPath,
            page_referrer: from.fullPath || document.referrer,
            page_title: document.title,
            content_group: "",
            content_group2: "",
          },
          site: {
            shop_language: "",
            shop_currency: EventConstants.currency,
            tax_toggle: rootStore.isIncVat ? "INC" : "EX",
            selected_branche: branchStore.currentSavedBranch?.id,
          },
        },
      ],

      eventInteraction: true,
      eventLabel: `Page Loaded ${to.fullPath}`,
      eventName: "trackPageLoaded",
    },
  });
  navigationJourney.push({ from: from.fullPath, to: to.fullPath });
  logEvent({
    options: {
      eventAction: "page_view",
      eventProperties: {
        navigationJourney: navigationJourney,
      },
      eventInteraction: true,
      eventLabel: `User Navigation: ${JSON.stringify(navigationJourney)}`,
      eventName: "navigationTracking",
    },
  });
});


</script>

<template>
  <div>
    <TsSeoMetaTags />
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <TsModalRelease />
      <TsModalEcomError />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
