export default defineNuxtRouteMiddleware(async (to, from) => {
  // Static redirects mapping
  const staticRedirects = {
    '/content/faq': '/faq',
    '/content/payment': '/payment-methods',
    '/content/delivery': '/delivery',
    '/content/clickandcollect': '/click-and-collect',
    '/content/returnsandexchanges': '/returns-exchange',
    '/content/aboutus': '/about-us',
    '/content/pro': '/pro-card',
    '/content/toolstation-app': '/toolstation-app',
    '/content/jobs': '/vacancies',
    '/content/terms': '/terms-conditions',
    '/content/privacy': '/privacy-policy',
    '/content/cookies': '/cookie-policy',
    '/content/reviewguidelines': '/reviews',
    '/content/weee': '/weee',
    '/account/home': '/account/profile',
    '/account/addressbook': '/account/addresses',
    '/account/order-history': '/account/orders',
    '/account/saved-lists': '/account/save-list',
    '/content/brands': '/brands',
    '/contact': '/contact-us',
    '/sitemap': '/site-map',
    '/warranty': '/guarantee',
    '/news': '/blogs-news-advices',
    '/register': '/auth/signup',
  };

  // Dynamic redirects
  const dynamicRedirectRules = [
    {
      pattern: /^\/([^/]+)\/c(\d+)$/, // Category pattern
      handler: ([, category, id]) => `/categories/${category.replace('-', '&-')}-${id}`,
    },
    {
      pattern: /^\/([^/]+)\/([^/]+)\/c(\d+)$/, // Sub-category pattern
      handler: ([, mainCategory, subCategory, id]) => `/category/${subCategory}-${id}`,
    },
    {
      pattern: /^\/([^/]+(?:-[^/]+)*)\/p(\d+)$/, // Product pattern
      handler: ([, productName, productId]) => `/product/${productName}-${productId}`,
    },
    {
      pattern: /^\/content\/([^/]+)$/, // Brands with sub-path
      handler: ([, brand]) => `/brands/${brand}`,
    },
  ];

  // Check static redirects
  if (staticRedirects[to.path]) {
    return await navigateTo(staticRedirects[to.path], { redirectCode: 301 });
  }

  // Check dynamic redirects
  for (const { pattern, handler } of dynamicRedirectRules) {
    const match = to.path.match(pattern);
    if (match) {
      const newPath = handler(match);
      return await navigateTo(newPath, { redirectCode: 301 });
    }
  }
});
