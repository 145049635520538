export const useBoldMatchingText = (text: string, keyword: string) => {
    
    // Escape special characters in the keyword for safe use in a RegExp
    const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    
    // Create a RegExp to match the keyword globally and case insensitively
    const regex = new RegExp(`(${escapedKeyword})`, 'gi');
    
    // Replace matched keyword with bold HTML
    return text.replace(regex, '<span class="font-bold">$1</span>');
}