
// @ts-nocheck


export const localeCodes =  [
  "nl-NL",
  "en-US",
  "fr-FR",
  "nl-BE"
]

export const localeLoaders = {
  "nl-NL": [{ key: "../locales/nl-NL.ts", load: () => import("../locales/nl-NL.ts?hash=21bc9bdc&locale=nl-NL" /* webpackChunkName: "locale__vercel_path0_locales_nl_NL_ts" */), cache: false }],
  "en-US": [{ key: "../locales/en-US.ts", load: () => import("../locales/en-US.ts?hash=59800465&locale=en-US" /* webpackChunkName: "locale__vercel_path0_locales_en_US_ts" */), cache: false }],
  "fr-FR": [{ key: "../locales/fr-FR.ts", load: () => import("../locales/fr-FR.ts?hash=a06553a9&locale=fr-FR" /* webpackChunkName: "locale__vercel_path0_locales_fr_FR_ts" */), cache: false }],
  "nl-BE": [{ key: "../locales/nl-BE.ts", load: () => import("../locales/nl-BE.ts?hash=9627f433&locale=nl-BE" /* webpackChunkName: "locale__vercel_path0_locales_nl_BE_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "nl-NL",
      "iso": "nl-NL",
      "name": "Dutch (Netherlands)",
      "display_name": "Dutch (NL)",
      "files": [
        "locales/nl-NL.ts"
      ]
    },
    {
      "code": "en-US",
      "iso": "en-US",
      "name": "English (United States)",
      "display_name": "English",
      "files": [
        "locales/en-US.ts"
      ]
    },
    {
      "code": "fr-FR",
      "iso": "fr-FR",
      "name": "French (France)",
      "display_name": "French",
      "files": [
        "locales/fr-FR.ts"
      ]
    },
    {
      "code": "nl-BE",
      "iso": "nl-BE",
      "name": "Dutch (Belgium)",
      "display_name": "Dutch (BE)",
      "files": [
        "locales/nl-BE.ts"
      ]
    }
  ],
  "defaultLocale": "nl-NL",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "locales",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const normalizedLocales = [
  {
    "code": "nl-NL",
    "iso": "nl-NL",
    "name": "Dutch (Netherlands)",
    "display_name": "Dutch (NL)",
    "files": [
      {
        "path": "locales/nl-NL.ts"
      }
    ]
  },
  {
    "code": "en-US",
    "iso": "en-US",
    "name": "English (United States)",
    "display_name": "English",
    "files": [
      {
        "path": "locales/en-US.ts"
      }
    ]
  },
  {
    "code": "fr-FR",
    "iso": "fr-FR",
    "name": "French (France)",
    "display_name": "French",
    "files": [
      {
        "path": "locales/fr-FR.ts"
      }
    ]
  },
  {
    "code": "nl-BE",
    "iso": "nl-BE",
    "name": "Dutch (Belgium)",
    "display_name": "Dutch (BE)",
    "files": [
      {
        "path": "locales/nl-BE.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const STRATEGIES = {
  "PREFIX": "prefix",
  "PREFIX_EXCEPT_DEFAULT": "prefix_except_default",
  "PREFIX_AND_DEFAULT": "prefix_and_default",
  "NO_PREFIX": "no_prefix"
}
export const DEFAULT_LOCALE = ""
export const DEFAULT_STRATEGY = "prefix_except_default"
export const DEFAULT_TRAILING_SLASH = false
export const DEFAULT_ROUTES_NAME_SEPARATOR = "___"
export const DEFAULT_LOCALE_ROUTE_NAME_SUFFIX = "default"
export const DEFAULT_DETECTION_DIRECTION = "ltr"
export const DEFAULT_BASE_URL = ""
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"


