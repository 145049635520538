
import { acceptHMRUpdate, defineStore } from 'pinia';
import { AlgoliaService } from '~/services/algolia.service';
import { EcomService } from '~/services/ecom.service';
import {
    type options, type ComputedSuggestions, type Filters,
    TsProvidedFacetFilters, type SearchSource, ProductSortByIndexMap
} from '~/types/algolia/algolia.type';
import type { APIState } from '~/types/api-state.type';



export const useAlgoliaStore = defineStore({
    id: 'algolia',
    state: () => ({
        algolia: null as AlgoliaService | null,
        searchHistory: <options[]>([]),
        algoliaResponse: <options[]>([]),
        response: <string[]>([]),
        filter_reviews: [],
        route: null,
        searchItem: <string>(''),
        lastTypedKeyword: <string>(''),
        lastSearchedKeyword: <string>(''),
        isDebounced: <boolean>(false),
        algoliaState: <APIState>({
            status: 'idle',
            message: ''
        }),
        review_count: {
            5: 0,
            4: 0,
            3: 0,
            2: 0,
            1: 0
        },
        delay_time: 1500,
        delay_timer: 0,
        ECOMState: <APIState>({
            status: 'success',
            message: ''
        }),
        numericFilters: [],
        productIDs: <any>([]),
        totalNumOfResults: <number>(0),
        totalNumOfFilteredResults: <number>(0),
        filters: <Filters>undefined,
        filterCategoryToUpdate: <string[]>([]),
        filterCategoryToUpdateValue: {},

        nbPages: <number>(1),
        currentPage: <number>(0),
        offset: <number>(24),
        sortBy: <string>('default'),
        selectedRating: 0,

        currentActiveIndex: <string>('TSNL_NEW_nl_products'),
        currentActiveCategoryFilter: <string>(''),

        currentFlowParams: ({
            currentActiveSearchFlow: <SearchSource>('direct-search'),
            subCategoryId: <number | undefined>(undefined),
            subCategoryName: <string | undefined>(undefined),
        }),
        currentActiveBrand: <string>'',

        currentPLPRoute: <string>'',

        filterCategories: <any>{},

        breadcrumb_data: <any>[],
        currentDropdownStatus: <boolean>(false),
        product_ids_based_on_deal_type: <string[]>([]),
    }),
    persist: {
        enabled: true,
        key: null,
        storage: import.meta.client ? localStorage : undefined,
        pick: ['searchHistory'],
    },

    actions: {
        initialize() {
            this.algolia = new AlgoliaService();
        },



        async getAlgoliaSuggestions(searchKeyword: string) {



            this.lastTypedKeyword = searchKeyword;
            /**
             * running a for loop, to update individual values in the response array.
             * Another way of doing it is by redeclaring the array as empty before the request
             * and then spreading the resp array.
             */
            // for (let index = 0; index < resp. ; index++) {
            //     algoliaResponse.value[index] = resp[index];
            // }

            if (!this.isDebounced) {
                setTimeout(() => {
                    this.isDebounced = true;
                    this.getSuggestionsFromAlgolia(this.lastTypedKeyword);
                }, 650);
            }
        },

        async getSuggestionsFromAlgolia(searchKeyword: string) {
            if (!searchKeyword.trim()) {
                useStateModifier(this.algoliaState, 'idle', 'Idle');
                this.isDebounced = false;
                return; // Early exit if searchKeyword is empty or whitespace
            }


            useStateModifier(this.algoliaState, 'loading', 'Loading..');
            if (!this.algolia) {
                this.algolia = new AlgoliaService();
            }

            await this.algolia.getSuggestions(
                searchKeyword,
                {
                    hitsPerPage: 3
                },
                this.currentActiveIndex
            )
                .then((response) => {
                    this.algoliaResponse = response;

                    if (this.algoliaResponse.length > 0) {
                        useStateModifier(this.algoliaState, 'success', 'Results Found');
                        // GTM logger for track suggestions
                        logEvent({
                            options: {
                                eventAction: "click",
                                eventProperties: {
                                    suggestions: this.algoliaResponse,
                                    keyword: searchKeyword,
                                },
                                eventInteraction: true,
                                eventLabel: "new",
                                eventName: "trackSuggestions",
                            },
                        });
                    } else {
                        useStateModifier(this.algoliaState, 'failed', 'No results found');
                    }
                })

                .catch((error: any) => {
                    useErrorHandler(error);
                    let errorMsg = "Unable to load results, try again..."
                    if (useOnline()) {
                        errorMsg = "No internet connection"
                    }

                    useStateModifier(this.algoliaState, 'failed', errorMsg);
                })
                .finally(() => {
                    this.isDebounced = false;
                });
        },

        saveSearchHistoryItem() {
            const existingIndex = this.searchHistory.findIndex(option => option.name === this.lastTypedKeyword.toLowerCase());

            if (existingIndex !== -1) {
                // Move the found item to index 0
                const [item] = this.searchHistory.splice(existingIndex, 1);
                this.searchHistory.unshift(item);
            } else if (this.lastTypedKeyword.length > 0) {
                // Add the new item if it doesn't exist
                this.searchHistory.unshift(<options>{
                    name: this.lastTypedKeyword.toLowerCase(),
                    image: null,
                    priceRange: null
                });

                // Ensure the array doesn't exceed 50 items
                if (this.searchHistory.length > 50) {
                    this.searchHistory.pop();
                }
            }
            useStateModifier(this.ECOMState, 'success', 'Results Found!');

        },


        handleClearSearchInput() {
            this.isDebounced = false;
            useStateModifier(this.algoliaState, 'idle', 'Idle');

        },


        async handleEnterClick(keyword: string, source: SearchSource,
            doNotNavigate: boolean, facets?: string[][],
            searchIndex?: string, filters?: string,
            subCategoryName?: string, categoryPageId?: number) {
            this.lastTypedKeyword = this.lastSearchedKeyword = keyword;

            let parse_int = parseInt(keyword);

            if (keyword.length === parse_int.toString().length &&
                keyword.length === 5 && this.algoliaResponse.length === 3) {

                let item: any = useRootStore().findInArrayByKey(this.algoliaResponse[2], 'productCode', keyword);

                if (item) {
                    navigateTo('/product/' + item.slug + '-' + parse_int)
                    return;
                }
            }

            this.currentPage = 0;
            const searchResultStore = useSearchResultStore();
            const stockStore = useStockStore();
            this.setCurrentFlowParams(source, subCategoryName, categoryPageId);

            if (doNotNavigate === false) {
                /**
                 * Reseting filters on new search
                */
                searchResultStore.resetFilters();
            }

            if (source === 'direct-search') {
                this.saveSearchHistoryItem();
            }

            searchIndex ? this.currentActiveIndex = searchIndex : this.currentActiveIndex;



            /**
             * This check is an important fallback.
             * Though, we have initialized Algolia before App.vue is mounted, but if due to any reason,
             * this method doesn't get an instance of Algolia, or, if the serach result page is directly
             * opened - this ensures a new instance of Algolia is initialized.
             */
            // if (!this.algolia) {
                this.algolia = new AlgoliaService();
          //  }

            if (doNotNavigate === false) {

                this.navigationDriver(
                    source,
                    keyword.length > 0 ? keyword : '',
                    subCategoryName ?? '',
                    categoryPageId ?? 0
                );
            }

            useStateModifier(
                this.computedStateToUpdate(),
                'loading',
                keyword,
                {
                    searchQuery: keyword,
                    resultFound: true,
                }
            );
           

            Promise.all([
                await this.algolia.getProductDetailsOnEnter(
                    keyword,
                    {
                        hitsPerPage: this.offset,
                        cacheable: false,
                        minWordSizefor1Typo: 3,
                        facets: TsProvidedFacetFilters,
                        facetFilters: facets,
                        filters: this.getActiveCategoryFilter(filters)
                    },
                    this.currentActiveIndex
                )
            ]).then(async ([response]) => {
                const { productIDs, filters, nbHits, nbPages, page, offset, hits } = response;
                delete filters.review;
                this.productIDs = productIDs;
                this.totalNumOfResults = this.totalNumOfFilteredResults = nbHits;
                this.filters = {};
                this.nbPages = nbPages;
                this.currentPage = page;
                // this.offset = offset ?? 24;
                this.updateFilters(filters);
                if (productIDs.length > 0 && filters) {
               
                    
                    await searchResultStore.warmSearchResults(this.productIDs, hits);
                   // searchResultStore.products = await stockStore.fetchStockProduct(productIDs, hits);
                    useStateModifier(
                        searchResultStore.searchResultState,
                        'success',
                        keyword,
                        {
                            searchQuery: keyword,
                            resultFound: true,
                        }
                    );

                    this.lastTypedKeyword = ""
                }
                else {
                    useStateModifier(
                        searchResultStore.searchResultState,
                        'failed',
                        keyword,
                        {
                            searchQuery: keyword,
                            resultFound: true,
                        }
                    );
                }
            })

        },

        async redirectOnEnterClick(keyword: string) {
            this.lastTypedKeyword = this.lastSearchedKeyword = keyword;
            this.saveSearchHistoryItem();
            let parse_int = parseInt(keyword);

            if (keyword.length === parse_int.toString().length &&
                keyword.length === 5 && this.algoliaResponse.length === 3) {

                let item: any = useRootStore().findInArrayByKey(this.algoliaResponse[2], 'productCode', keyword);

                if (item) {
                    navigateTo('/product/' + item.slug + '-' + parse_int)
                    return;
                }
            }

            const localePath = useLocalePath();
            navigateTo({
                path: localePath('/search'),
                query: {
                    q: keyword
                }
            });

        },

        async searchProducts(keyword: string, source: SearchSource,
            canUpdateFilter: boolean = true, facets?: string[][], page = 0, filters?: string,
            subCategoryName?: string, categoryPageId?: number, extendedQueries = []) {

            const searchResultStore = useSearchResultStore();
            this.setCurrentFlowParams(source, subCategoryName, categoryPageId);

            /**
             * This check is an important fallback.
             * Though, we have initialized Algolia before App.vue is mounted, but if due to any reason,
             * this method doesn't get an instance of Algolia, or, if the serach result page is directly
             * opened - this ensures a new instance of Algolia is initialized.
             */
            // if (!this.algolia) {
                this.algolia = new AlgoliaService();
          //  }

            if (canUpdateFilter || !this.filters ||
                Object.keys(this.filters).length === 0) {
                useStateModifier(
                    this.computedStateToUpdate(),
                    'loading',
                    keyword,
                    {
                        searchQuery: keyword,
                        resultFound: true,
                    }
                );
            } else {
                useStateModifier(
                    searchResultStore.productState,
                    'loading',
                    keyword,
                );
            }

            let self = this;

            let queries = [
                {
                    indexName: ProductSortByIndexMap[self.sortBy ?? 'default'],
                    query: keyword,
                    params: {
                        hitsPerPage: self.offset,
                        minWordSizefor1Typo: 3,
                        facets: TsProvidedFacetFilters,
                        facetFilters: facets,
                        page: page,
                        filters: self.getActiveCategoryFilter(filters),
                        numericFilters: self.numericFilters ?? []
                    }
                }
            ];

            if (this.delay_timer) clearTimeout(this.delay_timer);
            self.delay_timer = setTimeout(async function () {
                Promise.all([
                    await self.algolia.getProductDetails(queries.concat(extendedQueries))
                ]).then(async ([response]) => {
                    const { productIDs, filters, nbHits, nbPages, page, offset, hits } = response;
                    self.review_count = filters.reviewFilterValue;
                    delete filters.review;
                    delete filters.reviewFilterValue;
                    self.productIDs = productIDs;
      
                    self.totalNumOfResults = self.totalNumOfFilteredResults = nbHits;

                    // self.offset = offset ?? 24;
                    self.filterCategoryToUpdateValue = {};
                    if (canUpdateFilter || facets.length === 0
                        || !self.filters ||
                        Object.keys(self.filters).length === 0) {
                        self.nbPages = nbPages;
                        self.currentPage = page;
                        self.filters = {};
                    } else {
                        self.extractSelectedFiltersValues(facets);
                    }

                    if (productIDs.length > 0 && filters) {
                        self.updateFilters(filters);
                        await searchResultStore.warmSearchResults(self.productIDs, hits);

                        useStateModifier(
                            searchResultStore.searchResultState,
                            'success',
                            keyword,
                            {
                                searchQuery: keyword,
                                resultFound: true,
                            }
                        );

                        self.lastTypedKeyword = ""
                    }
                    else {
                        searchResultStore.products = []
                        if (canUpdateFilter) {
                            useStateModifier(
                                searchResultStore.searchResultState,
                                'failed',
                                keyword,
                                {
                                    searchQuery: keyword,
                                    resultFound: true,
                                }
                            );
                        } else {
                            useStateModifier(
                                searchResultStore.productState,
                                'failed',
                                `Product IDs: ${productIDs} & Filter ${filters}`,
                            );
                        }
                    }
                })
            }, self.delay_time);

        },

        getActiveCategoryFilter(filterFromArgument: string | undefined) {
            if (filterFromArgument && filterFromArgument.length <= 0) {
                if (this.currentActiveCategoryFilter.length <= 0) {
                    return ''
                }
                else {
                    return this.currentActiveCategoryFilter;
                }
            } else {
                return filterFromArgument;
            }
        },

        navigationDriver(pageSource: SearchSource, keyword?: string, subCategoryName?: string, categoryPageId?: number) {
            // If filter doesn't exist

            const localePath = useLocalePath();
            if (pageSource === 'direct-search') {

                // navigate to search page
                navigateTo({
                    path: localePath('/search'),
                    query: {
                        q: keyword,
                        ...((this.currentPage + 1 !== 1) ? { page: this.currentPage + 1 } : {}),
                        ...((() => {
                            return this.buildSearchResultQuery();
                        })())
                    }
                });
            }
            if (pageSource === 'brand-search') {
                // keyword ? this.currentActiveBrand = keyword : this.currentActiveBrand = ""
                // navigate to search page



                navigateTo({
                    path: localePath(`/brands/${this.currentActiveBrand.toLowerCase()}`),
                    query: {
                        ...((this.currentPage + 1 !== 1) ? { page: this.currentPage + 1 } : {}),
                        ...((() => {
                            const filters = this.buildSearchResultQuery();
                            return filters.length > 0 ? { filters } : {};
                        })())
                    }
                });
            }

            if (pageSource === 'category-search') {
                // navigate to category page

                navigateTo({
                    path: localePath(`/category/${subCategoryName}-${categoryPageId}`),
                    // path: `/categories/${subCategoryName}-${categoryPageId}`,
                    query: {
                        ...((this.currentPage + 1 !== 1) ? { page: this.currentPage + 1 } : {}),
                        ...((() => {
                            const filters = this.buildSearchResultQuery();
                            return filters.length > 0 ? { filters } : {};
                        })())
                        // filters: this.buildSearchResultQuery()
                    }
                });
            }
        },
        async handleBrandRedirect(keyword: string, doNotNavigate: boolean, facets?: string[][], searchIndex?: string) {
            this.lastTypedKeyword = this.lastSearchedKeyword = keyword;
            this, this.currentPage = 0;
            const searchResultStore = useSearchResultStore();

            /**
             * Reseting filters on new search
             */
            searchResultStore.resetFilters();

            this.saveSearchHistoryItem();

            searchIndex ? this.currentActiveIndex = searchIndex : this.currentActiveIndex;

            if (keyword) {

                /**
                 * This check is an important fallback.
                 * Though, we have initialized Algolia before App.vue is mounted, but if due to any reason,
                 * this method doesn't get an instance of Algolia, or, if the serach result page is directly
                 * opened - this ensures a new instance of Algolia is initialized.
                 */
                // if (!this.algolia) {
                    this.algolia = new AlgoliaService();
               // }


                useStateModifier(
                    this.computedStateToUpdate(),
                    'loading',
                    keyword,
                    {
                        searchQuery: keyword,
                        resultFound: true,
                    }
                );

                Promise.all([
                    this.algolia.getProductDetailsOnEnter(
                        keyword,
                        {
                            hitsPerPage: this.offset,
                            cacheable: false,
                            minWordSizefor1Typo: 3,
                            facets: TsProvidedFacetFilters,
                            facetFilters: facets,
                        },
                        this.currentActiveIndex
                    )
                ]).then(async ([response]) => {
                    const { productIDs, filters, nbHits, nbPages, page, offset, hits } = response;
                    delete filters.review;
                    this.productIDs = productIDs;
                    this.totalNumOfResults = this.totalNumOfFilteredResults = nbHits;
                    this.filters = {};
                    this.nbPages = nbPages;
                    this.currentPage = page;
                    this.offset = offset ?? 24;
                    this.updateFilters(filters);
                    if (productIDs.length > 0 && filters) {

                        await searchResultStore.warmSearchResults(this.productIDs, hits);

                        useStateModifier(
                            searchResultStore.searchResultState,
                            'success',
                            keyword,
                            {
                                searchQuery: keyword,
                                resultFound: true,
                            }
                        );
                        // navigateTo({
                        //     path: '/search',
                        //     query: {
                        //         q: keyword,
                        //         filters: this.buildSearchResultQuery()
                        //     }
                        // })
                    }
                    else {
                        useStateModifier(
                            searchResultStore.searchResultState,
                            'failed',
                            keyword,
                            {
                                searchQuery: keyword,
                                resultFound: true,
                            }
                        );
                        // navigateTo({
                        //     path: '/search',
                        //     query: {
                        //         q: keyword,
                        //         filters: this.buildSearchResultQuery()
                        //     }
                        // })
                    }
                })
            }
        },




        async handleFilterChange(keyword: string, doNotNavigate: boolean, facets: string[][],
            numericFilters?: string[], searchIndex?: string, page?: number) {
            this.lastSearchedKeyword = keyword;
            const searchResultStore = useSearchResultStore();
            searchIndex ? this.currentActiveIndex = searchIndex : this.currentActiveIndex


            // if (!this.algolia) {
                this.algolia = new AlgoliaService();
           // }
            useStateModifier(
                searchResultStore.productState,
                'loading',
                keyword,
            );
            this.navigationDriver(
                this.currentFlowParams.currentActiveSearchFlow,
                this.lastSearchedKeyword,
                this.currentFlowParams.subCategoryName,
                this.currentFlowParams.subCategoryId
            );

            let alogoliaRequestOptions = {
                hitsPerPage: this.offset,
                cacheable: false,
                minWordSizefor1Typo: 3,
                facets: TsProvidedFacetFilters,
                facetFilters: facets,
                page: page ?? 0,
                filters: this.buildCategoryFilter(),
                numericFilters: numericFilters ?? []
            };

            Promise.all([
                this.algolia.getProductDetailsOnEnter(
                    keyword,
                    alogoliaRequestOptions,
                    this.currentActiveIndex
                )
            ]).then(async ([response]) => {
                const { productIDs, filters, nbHits, hits } = response;
                delete filters.review;


                this.productIDs = productIDs;
                this.extractSelectedFilters(facets);

                this.totalNumOfFilteredResults = nbHits;

                if (productIDs.length > 0 && filters) {

                    this.updateFilters(filters);
                    await searchResultStore.warmSearchResults(this.productIDs, hits);

                }
                else {

                    useStateModifier(
                        searchResultStore.productState,
                        'failed',
                        `Product IDs: ${productIDs} & Filter ${filters}`,
                    );
                }
            }).catch(() => {
                useStateModifier(
                    searchResultStore.productState,
                    'failed',
                    'Could not fetch products'
                );
            })

        },

        buildSearchResultQuery(): string {
            const searchResultStore = useSearchResultStore();
            let selectedFilters = searchResultStore.selectedFilterState;

            /**
             * Search Query: lastTypedKeyword
             * Filters: selectedFilters
             * */
            if (selectedFilters.length === 0) {
                return {};
            }

            let filterQueryString = {};

            selectedFilters.forEach(filter => {
                const [key, value] = filter.split(':');
                if (key && value) {
                    if (filterQueryString[key]) {
                        filterQueryString[key] += ',' + value.trim();
                    } else {
                        filterQueryString[key] = value.trim();
                    }

                }
            });

            // Remove the leading '&' if present
            return filterQueryString;
        },


        computedStateToUpdate() {
            if (useSearchResultStore().searchResultState.status === 'success') {
                return useSearchResultStore().productState;
            }
            else return useSearchResultStore().searchResultState;
        },

        extractSelectedFilters(facetFilters: string[][]) {
            this.filterCategoryToUpdate = [];

            for (let i = 0; i < facetFilters.length; i++) {
                if (facetFilters[i].length > 0) {
                    const category = facetFilters[i][0].split(':')[0];
                    this.filterCategoryToUpdate.push(category);
                }
            }
        },

        extractSelectedFiltersValues(facetFilters: string[][]) {
            this.filterCategoryToUpdateValue = {};

            let self = this;

            for (let i = 0; i < facetFilters.length; i++) {

                if (facetFilters[i].length > 0) {
                    facetFilters[i].forEach(function (filter, key) {
                        const category_array = filter.split(':');
                        if (key === 0) {
                            self.filterCategoryToUpdateValue[category_array[0]] = [category_array[1]];
                        } else {
                            self.filterCategoryToUpdateValue[category_array[0]]
                            [self.filterCategoryToUpdateValue[category_array[0]].length] = category_array[1];
                        }

                    })

                }
            }

        },

        getFirstPage() {
            this.currentPage = 0;

            const searchResultStore = useSearchResultStore();

            this.handleFilterChange(
                this.lastSearchedKeyword,
                false,
                searchResultStore.transformSelectedFilters(),
                [],
                this.currentActiveIndex,
                this.currentPage
            );
        },

        getNextPage() {


            const searchResultStore = useSearchResultStore();

            this.currentPage += 1;
            this.handleFilterChange(
                this.lastSearchedKeyword,
                false,
                searchResultStore.transformSelectedFilters(),
                [],
                this.currentActiveIndex,
                this.currentPage
            );
        },


        getPrevPage() {

            const searchResultStore = useSearchResultStore();

            this.currentPage -= 1;
            this.handleFilterChange(
                this.lastSearchedKeyword,
                false,
                searchResultStore.transformSelectedFilters(),
                [],
                this.currentActiveIndex,
                this.currentPage
            );
        },

        getLastPage(page: number) {

            this.currentPage = page;

            const searchResultStore = useSearchResultStore();

            this.handleFilterChange(
                this.lastSearchedKeyword,
                false,
                searchResultStore.transformSelectedFilters(),
                [],
                this.currentActiveIndex,
                this.currentPage
            );
        },


        updateFilters(filters: Filters) {
            // Initialize `this.filters` if it's undefined
            if (this.filters === undefined) {
                this.filters = {};
            }

            // If `this.filters` is empty, copy the entire `filters` object and return
            if (Object.keys(this.filters).length === 0) {
                this.filters = { ...filters };

                let self = this;
                for (const category in filters) {
                    if (this.filterCategoryToUpdateValue[category]) {
                        this.filterCategoryToUpdateValue[category].forEach(function (value) {
                            if (!self.filters[category].hasOwnProperty(value)) {
                                self.filters[category][value] = 0;
                            }

                        })
                    }
                }

                return;
            }

            // Remove keys from `this.filters` that are not in the new `filters`
            for (const category in this.filters) {
                if (filters && !filters.hasOwnProperty(category)) {
                    delete this.filters[category];
                }
            }

            // Update or add keys from the new `filters` to `this.filters`
            for (const category in filters) {
                if (filters && !this.filterCategoryToUpdate.includes(category)) {
                    this.filters[category] = filters[category];
                }
            }

            let self = this;
            for (const category in filters) {

                if (this.filterCategoryToUpdateValue[category]) {
                    this.filterCategoryToUpdateValue[category].forEach(function (value) {
                        if (!self.filters[category].hasOwnProperty(value)) {
                            self.filters[category][value] = 0;
                        }

                    })
                }
            }
        },

        extractFiltersFromURL(url: string): string[] {
            const searchResultStore = useSearchResultStore();
            const filtersArray: string[] = [];

            // Split the query string from the base URL
            const queryString = url.split('?')[1];
            if (!queryString) return filtersArray;

            const urlParams = new URLSearchParams(queryString);
            const filtersParam = urlParams.get('filters');

            if (filtersParam) {
                // Split the filtersParam by '&' to get each key=value pair
                const filters = filtersParam.split('&');
                filters.forEach(filter => {
                    const [key, value] = filter.split('=');
                    if (key && value) {
                        filtersArray.push(`${key}:${value}`);
                    }
                });
            }

            searchResultStore.selectedFilterState = filtersArray;
            return filtersArray;
        },

        /**
         *
         * @param searchKeyword For future, to get data from ECOM
         */
        async getSearchHistory(searchKeyword: string) {
        },

        suggestions() {
            const suggestions = ref<ComputedSuggestions[]>([
                {
                    name: computed(() => {
                        return useTranslation('SearchDropdown-RecentlySearched', 'Recent Gezocht')
                    }),
                    canDelete: true,
                    options: computed(() => {
                        useStateModifier(this.ECOMState, 'success', 'Results Found');

                        const filteredArray = this.searchHistory.filter(suggestion =>
                            suggestion.name && suggestion.name.toLowerCase().includes(this.lastTypedKeyword.toLowerCase())
                        );

                        if (filteredArray.length === 0) {
                            return this.searchHistory.slice(0, 5); // Return first 5 elements if no match found
                        } else {
                            return filteredArray.slice(0, 5); // Return filtered array if match found
                        }
                    }),
                    stateHandler: 'ECOMState',
                    searchType: 'recent-search'
                },
                {
                    name: computed(() => {
                        return useTranslation('SearchDropdown-PopularKeywords', 'Populaire trefwoorden')
                    }),
                    canDelete: false,
                    options: computed(() => {
                        return this.algoliaResponse && this.algoliaResponse.length > 1 ? this.algoliaResponse[0] : []
                    }),
                    stateHandler: 'algoliaState',
                    searchType: 'popular-search'
                },
                {
                    name: computed(() => {
                        // Using already existing 'product' ket from builder.
                        return useTranslation('products', 'Producten')
                    }),
                    canDelete: false,
                    options: computed(() => {
                        return this.algoliaResponse && this.algoliaResponse.length === 3 ? this.algoliaResponse[2] : []
                    }),
                    stateHandler: 'algoliaState',
                    searchType: 'product-search'
                },
                {
                    name: computed(() => {
                        // Using already existing 'categories' ket from builder.
                        return useTranslation('categories', 'Categorieën')
                    }),
                    canDelete: false,
                    options: computed(() => {
                        return this.algoliaResponse && this.algoliaResponse.length > 2 ? this.algoliaResponse[1] : []
                    }),
                    stateHandler: 'algoliaState',
                    searchType: 'category-search'
                }

            ]);

            return suggestions.value;
        },

        delteSearchHistoryItem(optionToDelete: options) {
            const index = this.searchHistory.findIndex((history: { name: string | null; }) => history.name === optionToDelete.name);
            if (index !== -1) {
                this.searchHistory.splice(index, 1);
            }
        },

        deleteEntireHistory() {
            for (let index = this.searchHistory.length; index > 0; index--) {
                this.searchHistory.pop();
            }
            useStateModifier(this.algoliaState, 'idle', 'Idle');
        },

        setCurrentFlowParams(currentSearchFlow: SearchSource, subCategoryName: string | undefined, subCategoryId: number | undefined) {
            this.currentFlowParams.currentActiveSearchFlow = currentSearchFlow;
            this.currentFlowParams.subCategoryName = subCategoryName;
            this.currentFlowParams.subCategoryId = subCategoryId;
        },

        buildCategoryFilter() {
            if (this.currentFlowParams.subCategoryId !== undefined) {
                return 'categoryPageId:' + this.currentFlowParams.subCategoryId;
            }
            return '';
        },


        setReviewCountByRating() {

            for (const [r_key, r_value] of Object.entries(this.review_count)) {
                this.review_count[r_key] = 0;
                for (const [key, value] of Object.entries(this.filter_reviews)) {
                    if (r_key === key) {
                        this.review_count[r_key] += value;
                    }
                }
            }

        },

        setRatingNumericFilters() {

            this.numericFilters = [];

            if (this.selectedRating > 0) {
                this.numericFilters = ["reviewFilterValue<=6", "reviewFilterValue>=" + (this.selectedRating)];
            }

        },

        getFilterQueries(query, filters = '', facetFilters = []) {

            let queries = [];

            for (const [key, value] of Object.entries(query)) {
                if (key !== 'q' && key !== 'page' && key !== 'perPage'
                    && key !== 'sortBy' && key !== 'layout') {
                    let i = queries.length;
                    queries[i] = {
                        indexName: 'TSNL_NEW_nl_products',
                        query: query.q,
                        params: {
                            hitsPerPage: 0,
                            minWordSizefor1Typo: 3,
                            analytics: false,
                            clickAnalytics: false,
                            facets: [key === 'rating' ? 'reviewFilterValue' : key],
                            filters,
                            facetFilters: useRootStore().clone(facetFilters)
                        }
                    }

                    for (const [q_key, q_value] of Object.entries(query)) {
                        if (q_key !== 'q' && q_key !== 'page' && q_key !== 'perPage'
                            && q_key !== 'sortBy' && key !== 'layout' && q_key !== key) {

                            if (q_key !== 'rating') {
                                let split_value = q_value.split(',');

                                split_value.forEach(function (s_value) {
                                    queries[i]['params']['facetFilters']
                                    [queries[i]['params']['facetFilters'].length] = q_key + ':' + s_value
                                })
                            } else {
                                queries[i]['params']['numericFilters'] = ["reviewFilterValue<=6", "reviewFilterValue>=" + (q_value)]
                            }
                        }
                    }
                }
            }

            return queries

        },



        /**
         *
         * @param keyword
         * @param source
         * @param doNotNavigate
         * @param facets
         * @param searchIndex
         * @param filters
         * @param subCategoryName
         * @param categoryPageId
         */
        async handleTabClick(keyword: string,
            searchIndex?: string, filters?: string, facets?: string[][]
        ) {
            this.lastTypedKeyword = keyword;
            this.currentPage = 0;
            const searchResultStore = useSearchResultStore();
            /**
             * Reseting filters on new search
             */
            searchResultStore.resetFilters();

            searchIndex ? this.currentActiveIndex = searchIndex : this.currentActiveIndex;



            /**
             * This check is an important fallback.
             * Though, we have initialized Algolia before App.vue is mounted, but if due to any reason,
             * this method doesn't get an instance of Algolia, or, if the serach result page is directly
             * opened - this ensures a new instance of Algolia is initialized.
             */
            // if (!this.algolia) {
                this.algolia = new AlgoliaService();
         //   }


            useStateModifier(
                this.computedStateToUpdate(),
                'loading',
                keyword,
                {
                    searchQuery: '',
                    resultFound: true,
                }
            );

            Promise.all([
                await this.algolia.getProductDetailsOnEnter(
                    keyword,
                    {
                        hitsPerPage: this.offset,
                        cacheable: false,
                        minWordSizefor1Typo: 3,
                        facets: TsProvidedFacetFilters,
                        facetFilters: facets,
                        filters: this.getActiveCategoryFilter(filters)
                    },
                    this.currentActiveIndex
                )
            ]).then(async ([response]) => {
                const { productIDs, filters, nbHits, nbPages, page, offset, hits } = response;
                delete filters.review;
                this.productIDs = productIDs;
                this.totalNumOfResults = this.totalNumOfFilteredResults = nbHits;
                this.filters = {};
                this.nbPages = nbPages;
                this.currentPage = page;
                this.offset = offset ?? 24;
                this.updateFilters(filters);
                if (productIDs.length > 0 && filters) {

                    await searchResultStore.warmSearchResults(this.productIDs, hits);

                    useStateModifier(
                        searchResultStore.searchResultState,
                        'success',
                        '',
                        {
                            searchQuery: '',
                            resultFound: true,
                        }
                    );
                }
                else {
                    useStateModifier(
                        searchResultStore.searchResultState,
                        'failed',
                        '',
                        {
                            searchQuery: '',
                            resultFound: true,
                        }
                    );
                }
            })

        },


        createCategoriesFromFilters() {

            if (true) {
                const lvl0 = this.filters['departments.lvl0'];
                const lvl1 = this.filters['departments.lvl1'];
                const lvl2 = this.filters['departments.lvl2'];

                const categories: any = {};

                // making lvl0
                if (lvl0) {
                    Object.entries(lvl0).forEach(([key, value]) => {
                        categories[key] = { value };
                    });
                }


                // makming lvl1
                if (lvl1) {
                    Object.entries(lvl1).forEach(([key, value]) => {
                        const [parent, child] = key.split(" > ");
                        if (categories[parent]) {
                            categories[parent][child] = { value };
                        }
                    });
                }

                // making lvl2

                if (lvl2) {
                    Object.entries(lvl2).forEach(([key, value]) => {
                        const [grandparent, parent, child] = key.split(" > ");
                        if (categories[grandparent] && categories[grandparent][parent]) {
                            categories[grandparent][parent][child] = value;
                        }
                    });
                }

                this.filterCategories = categories;
            }
        },

        async createSubCategoryBreadCrumb(category = null) {
            if (!category) {
                const route = useRoute();
                const subcategoryId = route.params.subcategoryid;

                if (!subcategoryId) return;


                /**
                 * Check on id isn't required because the category route path
                 * always has a nuerical ID value which is extracted using the
                 * regex below.
                 */

                const id = subcategoryId.match(/\d+/);

                const response = await EcomService.getCategoryDetailsById(id);


                let category = response.data;
            }
            /**
             * Array.isArray(response.data.ancestors) checks if response.data.ancestors
             * exists and is an array before mapping over it.
             *
             * Category BreadCrumb Builder
             *
             */

            const breadcrumb = Array.isArray(category.ancestors)
                ? category.ancestors
                    .map((item: any) => {
                        if (item.taxonomy && item.taxonomy.name && item.taxonomy.slug && item.taxonomy.id) {
                            return {
                                text: item.taxonomy.name,
                                link: item.parent_id === 0
                                    ? `/categories/${item.taxonomy.slug}-${item.taxonomy.id}`
                                    : `/category/${item.taxonomy.slug}-${item.taxonomy.id}`,
                            };
                        }
                        // Return null if any of the required fields are missing
                        return null;
                    })
                    .filter((breadcrumbItem: any) => breadcrumbItem !== null) // Remove null values
                : []; // Return an empty array if ancestors don't exist


            let current = [];

            if (category && category.taxonomy) {
                current = [{
                    text: category.taxonomy.name,
                    link: `/category/${category.taxonomy.slug}-${category.taxonomy.id}`,
                }];
            }



            this.breadcrumb_data = [{ text: 'Home', link: '/' }, ...breadcrumb, ...current];
        },


        /**
         * Category BreadCrumb Builder
         */
        async createCategoryBreadCrumb() {
            const route = useRoute();
            const categoryId = route.params.categoryid;

            try {
                const data = await EcomService.getCategoryDetailsById(categoryId);

                // Ensure data and necessary properties exist
                if (data?.data?.taxonomy?.name && data.data.taxonomy.slug && data.data.taxonomy.id) {
                    this.breadcrumb_data = [
                        { text: 'Home', link: '/' },
                        { text: data.data.taxonomy.name, link: `/categories/${data.data.taxonomy.slug}-${data.data.taxonomy.id}` }
                    ];
                } else {
                    console.error('Missing taxonomy data for breadcrumb.');
                    this.breadcrumb_data = [{ text: 'Home', link: '/' }];
                }
            } catch (error) {
                console.error('Error fetching category details:', error);
                // Handle the error and possibly set a fallback breadcrumb
                this.breadcrumb_data = [{ text: 'Home', link: '/' }];
            }
        },
        async getProductsBasedOnDealType(dealsCategory: string, brandName: string, hitsPerCarousel: number): Promise<string[]> {
            // if (!this.algolia) {
                this.algolia = new AlgoliaService();
           // }
            const response = await Promise.all([
                this.algolia.getProductDetailsOnEnter(
                    '',
                    {
                        hitsPerPage: hitsPerCarousel,
                        cacheable: false,
                        minWordSizefor1Typo: 3,
                        facets: TsProvidedFacetFilters,
                        facetFilters: brandName,
                        filters: dealsCategory
                    },
                    this.currentActiveIndex
                )
            ]);

            const { productIDs } = response[0];
            return productIDs; // Return the productIDs array
        }
    }
});

// Pinia hot reload
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAlgoliaStore, import.meta.hot))
}

