import { data } from "happy-dom/lib/PropertySymbol.js";
import type { PaymentStrategy } from "../payment.service";
import braintree, {
  ApplePay,
  BraintreeError,
  PayPalCheckout,
  Client,
  DataCollector,
  paypal,
} from "braintree-web";

export class LocalpayStrategy implements PaymentStrategy {
  private braintree_instance: braintree.Client | null = null;
  private local_pay_instance: braintree.LocalPayment | null = null;
  private data_collector_instance: braintree.DataCollector | null = null;

  async initializePayment(client, data_collector): Promise<any> {
    this.braintree_instance = client;
    this.data_collector_instance = data_collector;

    this.local_pay_instance = await braintree.localPayment.create(
      {
        client: client,
        merchantAccountId: "TSNL-ECOM",
      },
      (err, localPaymentInstance) => {
        if (err) {
          console.error("Error initializing local payment:", err);

          useErrorHandler(err, "high");
          return;
        }

        this.handleFallback(localPaymentInstance);

        this.setupPaymentButton(localPaymentInstance, "ideal");
        this.setupBankButton(localPaymentInstance, "bancontact");
      }
    );
  }

  private setupPaymentButton(localPaymentInstance, type) {
    document
      .querySelector("#braintree-ideal-button")
      ?.addEventListener("click", (event) => {
        event.preventDefault();
        useCheckoutStore().is_transaction_loading = true;
        this.startPayment(localPaymentInstance, type);
      });
  }

  private setupBankButton(localPaymentInstance, type) {
    document
      .querySelector("#braintree-bancontact-button")
      ?.addEventListener("click", (event) => {
        event.preventDefault();

        useCheckoutStore().is_transaction_loading = true;
        this.startPayment(localPaymentInstance, type, true);
      });
  }

  private async startPayment(localPaymentInstance, type, isBank = false) {
    const paymentOptions = this.getPaymentOptions(type, isBank);

    try {
      useCheckoutStore().resetError();

      const payload = await localPaymentInstance.startPayment(paymentOptions);
      useCheckoutStore().is_transaction_loading = true;
      useCheckoutStore().payment_sucess =
        "Ideal Payment successfull, Redirecting";
      useCheckoutStore().createTransaction(payload.nonce, "braintree-" + type);
    } catch (err) {
      useCheckoutStore().payment_error.ideal_error = "Local payment cancelled";
      useCheckoutStore().is_transaction_loading = false;
      useCheckoutStore().is_ideal_loading = false;
      useErrorHandler(err, "high");
      return err;
    }
  }

  private getPaymentOptions(type, isBank) {
    const trolleyStore = useTrolleyStore();
    const checkoutStore = useCheckoutStore();
    const authStore = useAuthStore();

    let user = authStore.user ?? authStore.guest;

    checkoutStore.is_ideal_loading = false;

    const baseOptions = {
      paymentType: type,
      amount: trolleyStore.trolley.order_response?.totals?.inc_vat.raw.toString(),
      currencyCode: "EUR",
      givenName: user.first_name,
      surname: user.last_name,
      email: user.email,

      address: {
        countryCode: type === "ideal" ? "Nl" : "BE",
      },
      fallback: {
        buttonText: "Return to Merchant",
        cancelButtonText: "Cancel",
        cancelUrl: window.origin + "/checkout/fallback",
        url: window.origin + "/checkout/fallback",
      },
      onPaymentStart: this.createTrasaction,
    };

    if (type === "ideal") {
      baseOptions.bic = useCheckoutStore().selected_ideal_bank;
    }

    return baseOptions;
  }

  private async createTrasaction(data, start) {
    const trolleyStore = useTrolleyStore();
    const checkoutstore = useCheckoutStore();

    if(useAuthStore().is_guest && trolleyStore.trolley_session_token){
      await trolleyStore.convertToCustomer(false);
    }

    try {
      const params = {
        payment_type: "braintree-ideal",
        payment_id: data.paymentId,
        order_total: trolleyStore.trolley.order_response?.totals?.inc_vat.raw.toString() ,
        payment_method: "braintree", 
        trolley_id: trolleyStore.trolley_id,
        billing_address_id: checkoutstore.billing_address?.id,
        payment_device_data: this.data_collector_instance?.deviceData,
      };

       if (trolleyStore.trolley_line_items?.Delivery.length > 0 || trolleyStore.trolley_line_items?.Directship){

         params.delivery_address_id =
          checkoutstore.selected_delivery_address.id;
       }
      if (trolleyStore.trolley_line_items?.Collection.length > 0) {
        params.collection_address_id =
          checkoutstore.selected_collection_address.address_id;
      }
      if (trolleyStore.trolley_line_items?.NextDayCollection.length > 0) {
        params.next_day_collection_address_id =
          checkoutstore.selected_collection_address.address_id;
      }
      
      const options = {
        method: "post",
        params,
      };

      await useAjaxEcom("/trolleys/checkout/braintree", options);

      start();
    } catch (error) {
      console.error("Error in onPaymentStart:", error);
      useCheckoutStore().payment_error.ideal_error = "Local payment cancelled";
      useCheckoutStore().is_transaction_loading = false;
      return error;
      await this.teardown();
      // Handle the error appropriately
    }
  }

  private async handleFallback(localPaymentInstance) {
    // Ensure this runs only on the client-side
    const urlParams = new URLSearchParams(window.location.search);
    const btLpToken = urlParams.get("btLpToken");

    if (btLpToken) {
      // Check if btLpToken is not 'undefined'
      if (btLpToken !== "undefined") {
        if (localPaymentInstance.hasTokenizationParams()) {
          localPaymentInstance
            .tokenize()
            .then(function (payload) {
              useCheckoutStore().createTransaction(
                payload.nonce,
                "braintree-ideal"
              );
            })
            .catch(function (err) {
              useErrorHandler(err, "high");
            });
        }
      } else {
        useErrorHandler("btLp token undefined", "low");
      }
    }
  }

  async teardown() {
    this.local_pay_instance?.teardown();
  }
}
