import { datadogRum } from "@datadog/browser-rum"
import { useAuthStore } from '~/stores/auth.store';
import { useRootStore } from '~/stores/root.store';

export const useErrorHandler = async (
    error: unknown,
    severity?: 'low' | 'medium' | 'high' | 'critical',
    name = null,
    type?: null,
    userAction?: null,
    user_id?: null,
    device_id?: null,
    session_id?: null
) => {

    if (!severity) {
        severity = 'low';
    }

    const rootStore = useRootStore();

    //console.log('useRootStore.getDeviceId--->', rootStore.device_id);

    if(!device_id)
    {
        device_id = rootStore.device_id;
    }

    if(!session_id)
    {
        session_id = rootStore.getSessionId;
    }


    if(!user_id && !useAuthStore().is_authenticated)
    {
        user_id = useAuthStore().user?.id;
    }

    if (error.name === 'AbortError' || name) {
        let temp_error = new Error(error.message, {
            cause: error
        });
        error = temp_error;

        if (name) {
            error.name = name
        }
    }

    const data_params = {
        severity: severity,
        type: type,
        userAction: userAction,
        deviceId: device_id,
        userId: user_id,
        sessionId: session_id,
    };

    const config = useRuntimeConfig();

    if(config.public.appDebug)
    {
        console.log('useErrorHandler:error--->', error);
        console.log('useErrorHandler:data_params--->', data_params);
    }

    await datadogRum.addError(error, data_params);

};
