<template>
  <div>
    <button
      :id="'accordion-tab-' + random_id"
      type="button"
      :class="
        twMerge(
          'group w-full p-3 flex items-center justify-between gap-3 text-idle-black font-medium',
          'border-x-0 border-b first:border-t text-left',
          props.iconPos === 'center' && 'items-center',
          props.iconPos === 'top' && 'items-top',
          props.appendClass,
          isOpen && props.activeClass,
          props.disabled && 'cursor-not-allowed'
        )
      "
      :aria-expanded="isOpen"
      :aria-controls="'accordion-tab-body-' + random_id"
      :disabled="props.disabled"
      @click.prevent="toggleTab"
      :data-testid="props.dataTestid"
    >
      <span
          class="w-full flex flex-row items-center gap-2"
        :class="twMerge(
          props.disabled && 'text-natural-light-grey',
          props.headingClass,
          props.headingBold && 'font-black',
          !props.leftAligned && 'justify-between'
        )"
      >
        <slot name="heading" :heading="props.heading" :isOpen="isOpen">
          <Icon v-if="props.headingIcon" :name="props.headingIcon" :class="twMerge('h-6 w-6')" />
          {{ props.heading }}
        </slot>
      </span>
      <slot name="icon" :isOpen="isOpen">
        <ClientOnly>
        <Icon
          data-accordion-icon
          :class="
            twMerge(
              'w-8 transition-all shrink-0',
              !isOpen && 'rotate-180',
              props.iconClass,
              props.disabled && 'text-natural-light-grey',
              props.iconPos === 'top' && 'mt-1',
            )
          "
          color="currentColor"
          :name="props.icon"
        />
        </ClientOnly>
      </slot>
    </button>
    <div
      :id="'accordion-tab-body-' + random_id"
      :class="{'block': isOpen, 'hidden': !isOpen}"
      :aria-labelledby="'accordion-tab-' + random_id"
      data-collapse="collapse-target-1"
    >
      <div
        :class="twMerge('border-b px-3 pb-[18px]', props.bodyClass)"
      >
        <slot></slot>
      </div>
    </div>
    <slot name="content-middle"></slot>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRandomUUID } from "../../composables/useRandomUUID";
import { twMerge } from "tailwind-merge";

type Props = {
  heading?: string;
  disabled?: boolean;
  headingIcon?: string;
  active?: boolean;
  appendClass?: string;
  activeClass?: string;
  icon?: string;
  iconClass?: string;
  headingClass?: string;
  headingBold?:boolean;
  bodyClass?: string
  dataTestid?:string;
  variant?: string;
  leftAligned?: boolean;
  iconPos?:"top" | "center";
};

const props = withDefaults(defineProps<Props>(), {
  heading: "",
  disabled: false,
  image: "",
  active: false,
  appendClass: "",
  icon: "pepicons-pop:angle-up",
  bodyClass:"",
  headingClass: "",
  headingBold: false,
  iconPos :'center',
});

const emit = defineEmits(["clickTab"]);
const random_id = ref<string>("");

const isOpen = ref(props.active);

function toggleTab() {
  isOpen.value = !isOpen.value;
  emit("clickTab", isOpen.value);
}

onMounted(() => {
  random_id.value = useRandomUUID();
});
</script>
