<template>
  <!-- Modal Trigger -->
  <div
    @click.prevent="triggerModal"
    :data-testid="dataTestid + '-trigger'"
    role="button"
  >
    <slot :modal="modal" />
  </div>

  <!-- Main Modal / Pop Up -->
  <div
    :id="props.id || 'modal-' + randomId"
    ref="modalRef"
    :data-testid="dataTestid"
    :class="modalBaseClass"
    aria-hidden="true"
    tabindex="-1"
  >
    <!-- Modal Content -->
    <div
      role="dialog"
      v-bind="$attrs"
      tabindex="-1"
      :class="$attrs.class || modalClass"
    >
      <slot name="modal-content" :modal="modal">
        <!-- Modal Header  -->
        <slot name="modal-header" :modal="modal">
          <!-- Modal header -->
          <div
            class="flex items-center justify-between p-5 border-b border-natural-light-grey dark:border-natural-grey"
          >
            <!-- Modal Title  -->
            <span
              :data-testid="dataTestid + '-span'"
              class="font-semibold text-idle-black dark:text-idle-white"
            >
              {{ modalTitle }}
            </span>
            <!-- Dismissing Icon  -->
            <button
              v-show="!noCloseIcon" 
              class="text-natural-grey bg-transparent dark:hover:bg-natural-dark-grey/80 hover:text-natural-dark-grey hover:bg-natural-light-grey/80 dark:hover:text-idle-white rounded-md text-sm w-8 h-8 -mr-2 inline-flex justify-center items-center transition-colors duration-100"
              @click.stop="handleDismiss"
              :data-testid="dataTestid + '-button'"
            >
            <ClientOnly>
              <TsIcon name="mdi:close" size="24" class="shrink-0" />
              </ClientOnly>
              <span :data-testid="dataTestid + '-span'" class="sr-only"
                >Close Modal</span
              >
            </button>
          </div>
        </slot>
        <!-- Modal body -->
        <article
          class="text-pretty leading-relaxed p-5 space-y-4 text-natural-grey dark:text-natural-light-grey"
          :data-testid="dataTestid + '-body'"
        >
          <slot name="modal-body" :modal="modal">
            <p>
              Lorem ipsum dolor sit amet consectetur. Mi nulla pretium porttitor
              lectus. Dolor aliquam justo non ultrices pellentesque aliquam
              varius. Nibh egestas sagittis eget faucibus eros aliquam egestas.
              Vestibulum diam pretium ultrices integer volutpat placerat
              condimentum id. Diam quis fermentum sit nunc suspendisse tellus
              pulvinar. Elit maecenas vitae quis orci odio vel enim eu a.
            </p>
            <p>
              Vestibulum diam pretium ultrices integer volutpat placerat
              condimentum id. Diam quis fermentum sit nunc suspendisse tellus
              pulvinar. Elit maecenas vitae quis orci odio vel enim eu a.
            </p>
          </slot>
        </article>
        <!-- Modal footer -->
        <div class="pb-5" :data-testid="dataTestid + '-footer'">
          <slot name="modal-footer" :modal="modal">
            <div class="flex gap-x-3 items-center pr-5 justify-end">
              <TsButton
                label="Decline"
                append-class="font-normal rounded-md"
                size="sm"
                outlined
                variant="danger"
                @click.stop="handleReject"
              />
              <TsButton
                label="I accept"
                size="sm"
                append-class="font-normal rounded-md"
                @click.stop="handleAccept"
              />
            </div>
          </slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
// flowbite
import { initModals, Modal } from "flowbite";
import type { ModalOptions, ModalInterface, modalPlacement } from "flowbite";
import type { InstanceOptions } from "flowbite";
// tailwind-merge
import { twJoin, twMerge } from "tailwind-merge";
import type { ClassNameValue } from "tailwind-merge";
import { ref, watch, computed, onMounted, onBeforeUnmount } from "vue";
import { useRandomUUID } from "../../composables/useRandomUUID";

// PROPS
type Props = {
  id?: string;
  modalTitle?: string | SVGElement;
  static?: boolean;
  appendClass?: ClassNameValue;
  noCloseIcon?: boolean;
  modalPosition?: modalPlacement;
  noBackDrop?: boolean;
  backDropClass?: ClassNameValue;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  modalTitle: "Modal title",
  modalPosition: "center",
  dataTestid: "molecule-modal",
});

// STATES
const visible = defineModel<boolean>("visible");

const modal = ref<ModalInterface | null>(null);

const modalRef = ref<HTMLElement | null>(null);

// METHODS
const triggerModal = () => {
  modal.value?.show();
};

const handleAccept = () => {
  modal.value?.hide();
  emit("accept", modal.value);
};

const handleReject = () => {
  modal.value?.hide();
  emit("reject", modal.value);
};

const handleDismiss = () => {
  modal.value?.hide();
  emit("dismiss", modal.value);
};

// WATCH V-MODEL
watch(visible, (vis) => {
  if (vis) {
    modal.value?.show();
    emit("show", modal.value);
  } else {
    emit("hide", modal.value);
    modal.value?.hide();
    // Remove focus
    document.activeElement.blur();
  }
});

// EMITS
const emit = defineEmits<{
  show: [modal: ModalInterface | null];
  hide: [modal: ModalInterface | null];
  dismiss: [modal: ModalInterface | null];
  accept: [modal: ModalInterface | null];
  reject: [modal: ModalInterface | null];
}>();

// CLASSES
const modalBaseClass =
  "hidden overflow-x-hidden fixed top-0 right-0 left-0 z-[100] justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full";

const modalClass = computed<ClassNameValue>(() =>
  twMerge(
    "absolute max-w-2xl w-full max-h-full transition-all rounded-md text-natural-grey shadow-secondary-black/15 shadow-[0_4px_8px_0] bg-idle-white",
    props.modalPosition === "top-left" && "top-5 left-5",
    props.modalPosition === "top-center" && "top-5 mx-auto",
    props.modalPosition === "top-right" && "top-5 right-5",
    props.modalPosition === "center-left" && "left-5",
    props.modalPosition === "center-right" && "right-5",
    props.modalPosition === "bottom-left" && "bottom-5 left-5",
    props.modalPosition === "bottom-center" && "bottom-5 mx-auto",
    props.modalPosition === "bottom-right" && "bottom-5 right-5",
    props.appendClass
  )
);

const backDropComputedClass = computed<string>(() =>
  twJoin(
    "fixed inset-0 z-[60]",
    twMerge(!props.noBackDrop && "bg-natural-black/60", props.backDropClass)
  )
);

// OPTIONS
defineOptions({
  inheritAttrs: false,
});

// random ID for each Vue Instance
const randomId = ref<string>("");

onMounted(() => {
  //will prevent server-side code from running that requires browser APIs.
  if (typeof window !== "undefined") {
    initModals();
    randomId.value = useRandomUUID();

    const modalOptions: ModalOptions = {
      backdrop: props.static ? "static" : "dynamic",
      backdropClasses: backDropComputedClass.value,
      closable: true,
      onShow: () => {
        visible.value = true;
      },
      onHide: () => {
        visible.value = false;
      },
    };

    // instance options object
    const instanceOptions: InstanceOptions = {
      id: "modal-" + randomId.value,
      override: true,
    };

    modal.value = new Modal(modalRef.value, modalOptions, instanceOptions);

    //checking for visible state
    if (visible.value) modal.value?.toggle();
  }
});

onBeforeUnmount(() => modal.value?.destroyAndRemoveInstance());
</script>
