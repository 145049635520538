import Keycloak from "keycloak-js";

export default defineNuxtPlugin(async (app) => {

    const config = useRuntimeConfig();

    if(config.public.isKeycloakEnabled !== true){
        return false;
    }

    const keycloak = await new Keycloak({
        url: config.public.keycloakBaseUrl,
        realm: config.public.keycloakRealm,
        clientId: config.public.keycloakClientId,
    });

    const response = await useAjaxServer('/api/keycloak/validate');

    if(response.success === false){
        app.$keycloak = await keycloak;
        return;
    }

    await keycloak.init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
    }).then(authenticated => {
        if(authenticated){
            window.parent.postMessage({ type: 'AUTH_SUCCESS' }, '*');
        }

    }).catch(err => {
        console.error('Failed to initialize Keycloak', err);
    });

    app.$keycloak = await keycloak;

});