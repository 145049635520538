<script setup lang="ts">
import { ref, onMounted, watch, onBeforeUnmount } from "vue";
import { Drawer } from "flowbite";
import type { DrawerInterface, DrawerOptions, InstanceOptions } from "flowbite";
import { useRandomUUID } from "../../composables/useRandomUUID";
import { twMerge } from "tailwind-merge";

type Props = {
  position?: "top" | "bottom" | "left" | "right";
  backdrop?: boolean;
  bodyScrolling?: boolean;
  backDropClasses?: string;
  appendClass?: string;
  contentClass?: string;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  position: "left",
  backdrop: true,
  bodyScrolling: false,
});

const emit = defineEmits(["drawerOpen", "drawerClose", "drawerToggle"]);

defineOptions({
  inheritAttrs: false,
});

const visible = defineModel<boolean>("visible");

const drawer_classes: Record<typeof props.position, string> = {
  left: " top-0 left-0  h-full p-4 overflow-y-auto -translate-x-full flex justify-center w-[20rem] shadow-lg ",
  right: "top-0 right-0 h-full p-4 overflow-y-auto translate-x-full w-80 ",
  bottom:
    "bottom-0 right-0 left-0 min-h-[15rem] overflow-x-auto max-md:rounded-t-md translate-y-full ",
  top: "top-0 right-0 left-0 bg-black dark:bg-gray-800 dark:bg-gray-800  ",
};

// STATES
const drawer = ref<DrawerInterface | null>(null);
const drawerRef = ref<HTMLElement | null>(null);
const randomId = ref<string>("");

const open = () => {
  drawer.value?.show();
};

const close = () => {
  drawer.value?.hide();
};

watch(visible, (vis) => {
  if (vis) {
    drawer.value?.show();
    emit("drawerOpen", drawer.value);
  } else {
    emit("drawerClose", drawer.value);
    drawer.value?.hide();
  }
});

onMounted(() => {
  randomId.value = useRandomUUID();

  const drawerOptions: DrawerOptions = {
    placement: props.position,
    backdrop: props.backdrop,
    bodyScrolling: props.bodyScrolling,
    edge: false,
    edgeOffset: "",
    backdropClasses: "bg-black/50 fixed inset-0 z-30",
    onHide: (e) => {
      visible.value = false;
    },
    onShow: (e) => {
      visible.value = true;
    },
    onToggle: (e) => {
      emit("drawerToggle", drawer.value);
    },
  };

  const instanceOptions: InstanceOptions = {
    id: "drawer-" + randomId.value,
    override: true,
  };
  drawer.value = new Drawer(drawerRef.value, drawerOptions, instanceOptions);

  if (visible.value) drawer.value?.toggle();
});

onBeforeUnmount(() => drawer.value?.destroy());
</script>

<template>
  <!-- drawer trigger -->
  <div
    class="text-center"
    :data-testid="props.dataTestid"
    @click.prevent="open"
  >
    <slot
      name="trigger"
      :drawer="drawer"
      :openDrawer="open"
      :hideDrawer="close"
    />
  </div>
  <!-- drawer component -->
  <div
    ref="drawerRef"
    v-bind="$attrs"
    :id="'drawer-' + randomId"
    :class="
      twMerge(
        'fixed z-40 max-h-screen bg-white transition-transform dark:bg-gray-800 ',
        drawer_classes[props.position],
        props.appendClass
      )
    "
    tabindex="-1"
    aria-labelledby="drawer-label"
    :data-testid="props.dataTestid + '-drawer-container'"
  >
    <slot :openDrawer="open" :hideDrawer="close" :drawer="drawer">
      <div :class="twMerge('p-4', props.contentClass)">
        <slot
          name="content"
          :openDrawer="open"
          :hideDrawer="close"
          :drawer="drawer"
        />
      </div>
    </slot>
  </div>
</template>
