import { datadogLogs } from '@datadog/browser-logs'

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  try {
      const url = useRequestURL()

      let app_env = 'local';

      if(url.hostname.includes('feature')){
          app_env = 'feature';
      }else if(url.hostname.includes('develop') || url.hostname.includes('dev')){
          app_env = 'develop';
      }else if(url.hostname.includes('staging')){
          app_env = 'staging';
      }

      if(app_env === 'local'){
          return false;
      }

      datadogLogs.init({
          clientToken: config.public.ddApplicationToken,
          site: 'datadoghq.eu',
          version:config.public.version,
          env: app_env,
          service: 'eu24-web',
          forwardErrorsToLogs: true,
          forwardConsoleLogs:'all',
          sessionSampleRate: 100
      })

      // Add custom 'host' and 'source' attributes
      datadogLogs.setGlobalContext({
          host: url.host
      });

      const authStore = useAuthStore();

      if(authStore.is_authenticated){
          datadogLogs.setUser(authStore.user);
      }

  } catch (error) {
      useErrorHandler(error);
      return error;
  }
});
