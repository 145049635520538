/**
 * Type safety for Algolia Store and Algolia Service
 */

import type { RankingInfo, RequestOptions, SearchOptions, SearchResponse } from "@nuxtjs/algolia";

export const sections = {
    History: 'Recently Searched',
    Popular: 'Popular Keywords',
    Results: 'Search Results',
    Toolstation: 'From Toolstation'
} as const;

export type Sections = string | ComputedRef<string> | null

export type SearchSource = 'direct-search' | 'category-search' | 'brand-search';

// This type is bifurcate and handle click on dropdown item. This acts as a key
export type SearchType = 'recent-search' | 'popular-search' | 'category-search' | 'product-search'


export type options = {
    name: string | null,
    image: string | null,
    priceRange: string | null,
    slug: string | null,
    productCode: number | null,
    price: string | null
}

export type ComputedSuggestions = {
    name: Sections,
    canDelete: boolean,
    options: options[] | ComputedRef<options[]>
    stateHandler: StateName,
    searchType : SearchType
}


export type AlgoliaRequestOptions = RequestOptions | SearchOptions;

export type AlgoliaSearchResponse = SearchResponse<unknown>;

export type StateName = 'algoliaState' | 'ECOMState';

export type Filters = Record<string, Record<string, number>> | undefined;

export interface ProductDetails {
    productIDs: string[],
    filters: Filters,
    nbHits: number,
    nbPages: number,
    page: number,
    offset?: number | undefined
    hits?: string[] 
}

export type Hits = {
    readonly objectID: string;
    readonly _highlightResult?: {} | undefined;
    readonly _snippetResult?: {} | undefined;
    readonly _rankingInfo?: RankingInfo;
    readonly _distinctSeqID?: number;
}[];

export const SortByIndexMap = {
    "Default": "TSNL_NEW_nl_products",
    "Price (Lowest to Highest)": "TSNL_NEW_nl_products_by_price_asc", //@todo: it needs to be dynamic
    "Price (Highest to Lowest)": "TSNL_NEW_nl_products_by_price_desc"
}

export const ProductSortByIndexMap = {
    "default": "TSNL_NEW_nl_products",
    "price_asc": "TSNL_NEW_nl_products_by_price_asc", //@todo: it needs to be dynamic
    "price_desc": "TSNL_NEW_nl_products_by_price_desc"
}

export const TsProvidedFacetFilters = <String[]>[
    'departments',
    'departmentListing',
    'brand',
    'review',
    'reviewFilterValue',
    'width',
    'widthfeet',
    'height',
    'heightfeet',
    'depthfeet',
    'weight',
    'length',
    'lengthmeters',
    'pipecentres',
    'fitteddepth',
    'cablecode',
    'volumeml',
    'volume',
    'containertype',
    'colour',
    'wattage',
    'fitting',
    'lumens',
    'btu',
    'voltage',
    'batterytype',
    'lighttechnology',
    'lightbulbcolour',
    'energysaving',
    'dimmable',
    'colourtemp',
    'clothingsize_eu',
    'gender',
    'technology',
    'chiselfitting',
    'gangs',
    'clothingsize',
    'amprating',
    'finish',
    'screwhead',
    'thread',
    'leglength',
    'battery',
    'cabletype',
    'conductorsize',
    'ways',
    'batteriesincluded',
    'lengthhandle',
    'energyclass',
    'lengthbackplate',
    'valve',
    'boresize',
    'shape',
    'teeth',
    'grit',
    'openingwidth',
    'maxpressure',
    'cordless',
    'batterysize',
    'maxheight',
    'shanksize',
    'treads',
    'minpressure',
    'headtype',
    'maxweight',
    'rating',
    'iprating',
    'alarmtype',
    'valveposition',
    'shoesize',
    'waterresistance',
    'type',
    'material',
    'profile',
    'kw',
    'brushless',
    'enginesize',
    'enginetype',
    'lampcaptype',
    'substrate',
    'drillbitdiameter',
    'spannertype',
    'pumptype',
    'impellertype',
    'animaltype',
    'showertrayshape',
    'size',
    'sizemm',
    'sizeinch',
    'resolution',
    'maxsplittingforce',
    'tipsize',
    'bturange',
    'toiletseatclosetype',
    'toiletseatfixingtype',
    'numberofbowls',
    'numberoftapholes',
    'agegroup',
    'prices',
    'priceRange',
    'formattedPrices',
    'departments.lvl0',
    'departments.lvl1',
    'departments.lvl2',
    'assetb',
    'assetbl',
    'assetbr',
    // 'assettr',
    'chucktype',
    'detaileddescription',
    'diameter',
    'keywords',
    'lamphours',
    'colourtemperature',
    'numberofreviews',
    'openingwidth',
    'pattern',
    'taxonomy',
    'tvsize',
    'unitquantity',
    'unittype',
    'wras',
];


export const facetFilterLabels = {
    departments: 'Departments',
    departmentListing: 'Department Listing',
    brand: 'Brand',
    review: 'Review',
    width: 'Width',
    widthfeet: 'Width (Feet)',
    height: 'Height',
    heightfeet: 'Height (Feet)',
    depthfeet: 'Depth (Feet)',
    weight: 'Weight',
    length: 'Length',
    lengthmeters: 'Length (Meters)',
    pipecentres: 'Pipe Centres',
    fitteddepth: 'Fitted Depth',
    cablecode: 'Cable Code',
    volumeml: 'Volume (mL)',
    volume: 'Volume',
    containertype: 'Container Type',
    colour: 'Colour',
    wattage: 'Wattage',
    fitting: 'Fitting',
    lumens: 'Lumens',
    btu: 'BTU',
    voltage: 'Voltage',
    batterytype: 'Battery Type',
    lighttechnology: 'Light Technology',
    lightbulbcolour: 'Light Bulb Colour',
    energysaving: 'Energy Saving',
    dimmable: 'Dimmable',
    colourtemp: 'Colour Temperature',
    clothingsize_eu: 'Clothing Size (EU)',
    gender: 'Gender',
    technology: 'Technology',
    chiselfitting: 'Chisel Fitting',
    gangs: 'Gangs',
    clothingsize: 'Clothing Size',
    amprating: 'Amp Rating',
    finish: 'Finish',
    screwhead: 'Screw Head',
    thread: 'Thread',
    leglength: 'Leg Length',
    battery: 'Battery',
    cabletype: 'Cable Type',
    conductorsize: 'Conductor Size',
    ways: 'Ways',
    batteriesincluded: 'Batteries Included',
    lengthhandle: 'Length Handle',
    energyclass: 'Energy Class',
    lengthbackplate: 'Length Backplate',
    valve: 'Valve',
    boresize: 'Bore Size',
    shape: 'Shape',
    teeth: 'Teeth',
    grit: 'Grit',
    openingwidth: 'Opening Width',
    maxpressure: 'Max Pressure',
    cordless: 'Cordless',
    batterysize: 'Battery Size',
    maxheight: 'Max Height',
    shanksize: 'Shank Size',
    treads: 'Treads',
    minpressure: 'Min Pressure',
    headtype: 'Head Type',
    maxweight: 'Max Weight',
    rating: 'Reviews',
    iprating: 'IP Rating',
    alarmtype: 'Alarm Type',
    valveposition: 'Valve Position',
    shoesize: 'Shoe Size',
    waterresistance: 'Water Resistance',
    type: 'Type',
    material: 'Material',
    profile: 'Profile',
    kw: 'KW',
    brushless: 'Brushless',
    enginesize: 'Engine Size',
    enginetype: 'Engine Type',
    lampcaptype: 'Lamp Cap Type',
    substrate: 'Substrate',
    drillbitdiameter: 'Drill Bit Diameter',
    spannertype: 'Spanner Type',
    pumptype: 'Pump Type',
    impellertype: 'Impeller Type',
    animaltype: 'Animal Type',
    showertrayshape: 'Shower Tray Shape',
    size: 'Size',
    sizemm: 'Size (mm)',
    sizeinch: 'Size (inch)',
    resolution: 'Resolution',
    maxsplittingforce: 'Max Splitting Force',
    tipsize: 'Tip Size',
    bturange: 'BTU Range',
    toiletseatclosetype: 'Toilet Seat Close Type',
    toiletseatfixingtype: 'Toilet Seat Fixing Type',
    numberofbowls: 'Number of Bowls',
    numberoftapholes: 'Number of Tap Holes',
    agegroup: 'Age Group',
    prices: 'Prices',
    priceRange: 'Price Range',
    formattedPrices: 'Formatted Prices',
    assetb: 'Asset B',
    assetbl: 'Asset BL',
    assetbr: 'Asset BR',
    assettr: 'Asset TR',
    chucktype: 'Chuck Type',
    detaileddescription: 'Detailed Description',
    diameter: 'Diameter',
    keywords: 'Keywords',
    lamphours: 'Lamp Hours',
    colourtemperature: 'Colour Temperature',
    numberofreviews: 'Number of Ratings',
    pattern: 'Pattern',
    taxonomy: 'Taxonomy',
    tvsize: 'TV Size',
    unitquantity: 'Unit Quantity',
    unittype: 'Unit Type',
    wras: 'WRAS'
};


