import type { APIState, State } from "~/types/api-state.type";

export const useStateModifier = (
  localState: APIState,
  newState: State,
  newStateMessage?: string,
  newData?: object
) => {
  localState.status = newState;
  if (typeof newStateMessage === "string") {
    localState.message = newStateMessage;
  }
  if (newData) {
    localState.data = newData;
  }
};
