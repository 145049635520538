<template>
  <NuxtLink v-if="props.to && typeof props.to === 'string'"
            :to="(props.to.startsWith('#') || props.to.startsWith('https')
  || props.to.startsWith('http')) ? props.to :
localPath(`${props.to}`)" v-bind="$attrs">
    <slot></slot>
  </NuxtLink>
</template>

<script setup lang="ts">
type Props = {
  to: string;
}

const props = defineProps<Props>();

const localPath = useLocalePath();
</script>