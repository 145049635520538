import * as z from "zod";

export enum CustomerAddressType {
  PRIMARY = 1,
  DELIVERY,
  PAYMENT,
  BRANCH = 5,
}

export type CustomerAddressItem = {
  id: string;
  type: CustomerAddressType;
  line_1?: string;
  line_2?: string;
  line_3?: string;
  town: string;
  county?: string;
  postcode: string;
  country_id: number;
  formatted?: string[];
};

export const AddressFormSchema = z.object({
  type: z.number().default(2),
  line_1: z.string().optional(),
  line_2: z.string().optional(),
  line_3: z.string().optional(),
  town: z.string().optional(),
  county: z.string().optional(),
  postcode: z.string().optional(),
});

export type AddressFieldsType = z.infer<typeof AddressFormSchema>;

export type EcomPostNLAddressType = {
  line_1: string;
  line_2?: string;
  line_3?: string;
  geo_location?: {
    latitude: number;
    longitude: number;
  };
  postcode: string;
  town?: string;
  pickup_point_name?: string;
};

export type LoqateAutocompletePrediction = {
  Id: string;
  Type: string;
  Text: string;
  Highlight: string;
  Description: string;
};
