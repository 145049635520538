
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
        console.log('errorHandler--->', error);
        useErrorHandler(error, 'low', )
    }

    // Also possible
    nuxtApp.hook('vue:error', (error, instance, info) => {
        console.log('vue:error--->', error);
        useErrorHandler(error, 'low', null, 'vue:error')
        // handle error, e.g. report to a service
    })

    // Also possible
    nuxtApp.hook('app:error', (error, context) => {

        console.log('app:error--->', error);

        useErrorHandler(error, null, 'app:error')
        // You can redirect to an error page or show a notification here
        // context.redirect('/error');
    });
})
