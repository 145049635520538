<template>
    <span class="font-bold text-primary" data-testid="header-vat-text">{{ useTranslation("vatex", "VAT") }}
    </span>
    <span :class="!rootStore.isIncVat && 'font-bold'" class="text-primary" data-testid="header-exculded-text">EX</span>
    <TsToggle @click="handleVATToggle" variant="switchVat" size="md" v-model="rootStore.isIncVat" :data-testid="rootStore.isIncVat
        ? baseDataTestId + '_header-vat-toggle-inc'
        : baseDataTestId + '_header-vat-toggle-ex'
        " />
    <span :class="rootStore.isIncVat && 'font-bold'" class="text-primary" data-testid="header-includedvat-text">INC</span>
</template>

<script setup lang="ts">
const baseDataTestId = 'menu-vat-toggle'
const rootStore = useRootStore();

function handleVATToggle() {
    rootStore.isIncVat = !rootStore.isIncVat;
    useCookie("vat").value = rootStore.isIncVat;
    logEvent({
        options: {
          eventAction: "click",
          eventProperties: {
            isIncVat: rootStore.isIncVat,
          },
          eventInteraction: true,
          eventLabel: `Toggle VAT value : ${rootStore.isIncVat}`,
          eventName: "trackVatToggle",
        },
      });
}

</script>