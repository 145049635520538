<template>
  <Head>

    <Title>Toolstation</Title>
    <Meta name="description" content="Beste gereedschap & bouwbenodigdheden voor de
      professional. Meer dan 15.000 producten op voorraad. Laagste prijzen & snelste service. Alleen bij Toolstation!" />
    <!-- Facebook Meta Tags -->
    <Meta property="og:url" :content="useRuntimeConfig().public.siteUrl" />
    <Meta property="og:type" content="website" />
    <Meta property="og:title" content="Gereedschap voor de professional - Toolstation" />
    <Meta property="og:description" content="Beste gereedschap & bouwbenodigdheden voor de
      professional. Meer dan 15.000 producten op voorraad. Laagste prijzen & snelste service. Alleen bij Toolstation!" />
    <Meta property="og:image" :content="useRuntimeConfig().public.siteUrl+'/images/toolstation.svg'" />

    <!-- Twitter Meta Tags -->
    <Meta name="twitter:card" content="summary_large_image" />
    <Meta property="twitter:domain" content="toolstation.nl" />
    <Meta property="twitter:url" :content="useRuntimeConfig().public.siteUrl" />
    <Meta name="twitter:title" content="Gereedschap voor de professional - Toolstation" />
    <Meta name="twitter:description" content="Beste gereedschap & bouwbenodigdheden voor de professional.
      Meer dan 15.000 producten op voorraad. Laagste prijzen & snelste service. Alleen bij Toolstation!" />
    <Meta name="twitter:image" :content="useRuntimeConfig().public.siteUrl+'/images/toolstation.svg'" />

  </Head>
</template>

<script setup>

</script>

