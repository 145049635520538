<script setup lang="ts">
import { twMerge, type ClassNameValue } from "tailwind-merge";

defineOptions({
  inheritAttrs: false,
});

enum Variants {
  Primary = "primary",
  Success = "success",
  Info = "info",
  Danger = "danger",
  Warning = "warning",
  Disabled = "disabled",
}

enum LabelPositions {
  Top = "top",
  Left = "left",
  Right = "right",
}

type Props = {
  variant?: `${Variants}`;
  inputValue?: string | number;
  position?: `${LabelPositions}`;
  floating?: boolean;
  message?: string;
  label?: string;
  labelClass?: string;
  appendClass?: string;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  variant: Variants.Primary,
  position: LabelPositions.Top,
  floating: false,
  dataTestid:"atom-label"
});

const labelClasses: Record<string, any> = {
  default: "w-full text-xs font-normal text-natural-grey inline-flex gap-1 group",
  floating: [
    "absolute transition-all duration-100 top-1/2 -translate-y-1/2 ml-3 text-base font-normal z-10",
    "group-has-[:focus]:top-0 group-has-[:focus]:text-xs group-has-[:focus]:px-1",
  ],
  position: {
    top: 'flex-col bg-red-500',
    left: 'flex-row items-center',
    right: 'flex-row-reverse items-center'
  }
};

const messageClasses: Record<string, ClassNameValue> = {
  default: "text-xs font-normal text-natural-grey mt-1 mx-1",
};

const variantClass: Record<`${Variants}` | string, ClassNameValue> = {
  primary: "has-[:focus]:text-primary has-[:focus]:dark:text-info-dark",
  info : "!text-info dark:!text-info-dark'",
  success: "!text-success dark:!text-success-dark",
  danger: "!text-danger dark:!text-danger-dark",
  warning: "!text-warning dark:!text-warning-dark",
};

const labelClass = () => {
  return twMerge(
      labelClasses.position[props.position],
      labelClasses.default,
      variantClass.primary,
      variantClass[props.variant.toLocaleLowerCase()],
      props.labelClass
  );
};

const messageClass = () => {
  return twMerge(
      messageClasses.default,
      variantClass.primary,
      variantClass[props.variant.toLocaleLowerCase()]
  );
};
</script>
<template>
  <div :class="$attrs.class || twMerge(
    props.position === 'right' && 'flex items-center justify-start',
    props.appendClass
  )">
    <div class="relative flex items-center" style="background-color: inherit">
      <label
          :class="labelClass()"
          v-bind="$attrs"
          :data-testid="props.dataTestid+'-label'"
          style="background-color: inherit"
      >
        <span
            v-if="props.label"
            :class="
            twMerge(
              props.floating && labelClasses.floating,
              props.inputValue && 'top-0 text-xs px-1'
            )
          "
            :data-testid="dataTestid+'-span'"
            style="background-color: inherit"
        >
          {{ label }}
        </span>
        <slot />
      </label>
    </div>
    <slot name="message">
      <p v-if="props.message" :class="messageClass()" v-html="message">
      </p>
    </slot>
  </div>
</template>
