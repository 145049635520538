<template>
  <div
    :id="'select-box-' + randomId"
    role="form"
    :class="twMerge('relative', props.appendClass)"
  >
    <!-- Triggering Input Field -->
    <div
      ref="selectRef"
      @keydown="onKeydown($event)"
      role="combobox"
      :tabindex="!disabled ? Number($attrs.tabindex) || 0 : -1"
      aria-haspopup="listbox"
      :aria-expanded="dropdownVisible"
      :aria-controls="'listbox-' + randomId"
      :aria-activedescendant="activeOptionIndex ? String(activeOptionIndex) : undefined"
      :data-testid="'root-' + dataTestid"
      :aria-disabled="disabled"
      :class="
        twMerge(
          'inline relative',
          props.disabled ? 'cursor-not-allowed border-natural-light-grey' : !props.editable ? 'cursor-pointer' : '',
          rootClass
        )
      "
    >
      <slot name="trigger" :dropdown="dropdown" :triggerRef="selectRef">
        <!-- Select Input Box -->
        <!-- TODO: Floating label click issue  -->
        <TsLabel
          :label="props.label"
          :input-value="props.editable ? searchQuery : JSON.stringify(activeOption)"
          :floating="props.labelType === 'floating'"
          :variant="props.variant"
          :message="props.message"
          :label-class="props.labelClass"
        >
          <TsInput
            :id="'select-input-' + randomId"
            v-bind="$attrs"
            v-model="searchQuery"
            :variant="props.variant"
            :size="props.size"
            :readonly="!editable"
            :disabled="props.disabled"
            autocomplete="off"
            :append-class="
              twMerge(
                'w-full bg-transparent',
                props.disabled
                  ? 'cursor-not-allowed'
                  : !props.editable
                  ? 'cursor-pointer'
                  : '',
                props.rootInputClass
              )
            "
            :input-class="
              twMerge(
                props.inputClass,
                props.disabled
                  ? 'cursor-not-allowed text-natural-light-grey'
                  : !props.editable
                  ? 'cursor-pointer'
                  : ''
              )
            "
            @input="onSearch($event)"
            @change="onChange($event)"
            @focus="onFocus($event)"
            @blur="onBlur($event)"
            @keydown.stop="onKeydown($event)"
          >
            <template #left?>
              <slot
                name="input-left"
                :dropdown="dropdown"
                :triggerRef="selectRef"
                :loading="isLoading"
              />
            </template>
            <template #right>
              <div class="shrink-0">
                <slot
                  v-if="isLoading"
                  name="loading-icon"
                  :dropdown="dropdown"
                  :triggerRef="selectRef"
                >
                  <Icon
                    :name="props.loadingIcon"
                    class="animate-spin"
                    aria-hidden="true"
                    size="24"
                  />
                </slot>
                <slot
                  v-else
                  name="input-right"
                  :dropdown="dropdown"
                  :triggerRef="selectRef"
                  :loading="isLoading"
                >
                  <Icon
                    :class="
                      twMerge(
                        'transition-transform duration-200 cursor-pointer',
                        dropdownVisible && !props.disabled ? 'rotate-180' : '',
                        props.disabled && 'cursor-not-allowed text-natural-light-grey'
                      )
                    "
                    :name="props.dropdownIcon"
                    size="18"
                    aria-hidden="true"
                    @click.stop=""
                  />
                </slot>
              </div>
            </template>
          </TsInput>
          <template #message?>
            <slot
              name="message"
              :dropdown="dropdown"
              :triggerRef="selectRef"
              :loading="isLoading"
            />
          </template>
        </TsLabel>
      </slot>
    </div>

    <!-- Dropdown -->
    <template v-if="!disabled && !isLoading">
      <ul
        ref="dropdownRef"
        :id="'listbox-' + randomId"
        :data-testid="props.dataTestid"
        role="listbox"
        :class="
          twMerge(
            twJoin(
              'absolute w-full max-w-full z-20 hidden overflow-y-auto border border-natural-light-grey dark:border-natural-grey bg-[#FAFAFA] dark:bg-[#1F1F1F] text-idle-black dark:text-idle-white py-2 mt-2 rounded-md',
              props.divide && 'divide-y divide-gray-200 dark:divide-gray-700',
              sizeBasedClass
            ),
            props.dropdownClass
          )
        "
        :style="{ 'max-height': props.height }"
      >
        <slot
          name="header"
          :dropdown="dropdown"
          :triggerRef="selectRef"
          :options="visibleOptions"
          :isSelected="isSelected"
          :isEmpty="isSuggestionsEmpty"
          :isDropdownVisible="dropdownVisible"
        />
        <slot
          name="options"
          :dropdown="dropdown"
          :triggerRef="selectRef"
          :options="visibleOptions"
          :isSelected="isSelected"
          :activeOption="activeOption"
          :activeOptionIndex="activeOptionIndex"
        >
          <!-- Option Group --- TODO : Key and OptGrp -->
          <div v-if="props.optionGroupLabel">
            <li
              v-for="(option, i) in options"
              :key="i"
              :class="itemClass"
              role="option"
              :aria-disabled="true"
            >
              <slot
                name="option-group"
                :dropdown="dropdown"
                :triggerRef="selectRef"
                :options="visibleOptions"
                :activeOption="activeOption"
                :activeOptionIndex="activeOptionIndex"
              >
                <span>{{ option[props.optionGroupLabel as keyof T] }}</span>
              </slot>
            </li>
          </div>

          <!-- Indivdual Options -->
          <li
            v-for="(option, index) in visibleOptions"
            :id="`option-${index}-` + randomId"
            :key="getKey(option)"
            :ref="listRefs.set"
            role="option"
            :data-testid="`option-${index}-` + dataTestid"
            :aria-label="String(getLabel(option))"
            :aria-selected="isSelected(option)"
            :class="
              twMerge(
                itemClass,
                'cursor-pointer',
                activeOptionIndex === index &&
                  'text-primary font-semibold bg-blue-200/35',
                optionClass
              )
            "
            @click="onSelect(option, index)"
            @mousemove="onHover(option, index)"
          >
            <!-- List Start -->
            <div v-if="$slots['option-start']" class="me-1 shrink-0">
              <slot
                name="option-start"
                :dropdown="dropdown"
                :triggerRef="selectRef"
                :options="visibleOptions"
                :option="option"
                :isSelected="isSelected"
                :activeOption="activeOption"
                :activeOptionIndex="activeOptionIndex"
                v-if="props.checkmark"
              >
                <Icon
                  name="mdi:check"
                  size="18"
                  v-if="isSelected(option)"
                  class="max-w-fit"
                />
              </slot>
            </div>
            <!-- OPTION LABEL -->
            <slot
              name="option"
              :dropdown="dropdown"
              :triggerRef="selectRef"
              :options="visibleOptions"
              :option="option"
              :isSelected="isSelected"
              :activeOption="activeOption"
              :activeOptionIndex="activeOptionIndex"
            >
              <span role="listitem">{{ getLabel(option) }}</span>
            </slot>
            <!-- List End -->
            <div v-if="$slots['option-end']" class="ms-auto shrink-0">
              <slot
                name="option-end"
                :dropdown="dropdown"
                :triggerRef="selectRef"
                :options="visibleOptions"
                :option="option"
                :isSelected="isSelected"
                :activeOption="activeOption"
                :activeOptionIndex="activeOptionIndex"
              />
            </div>
          </li>
          <!-- Empty Message -->
          <li
            v-if="!options || (options && options.length === 0) || isSuggestionsEmpty"
            :class="itemClass"
            role="option"
            :aria-disabled="true"
          >
            <slot
              name="empty"
              :dropdown="dropdown"
              :triggerRef="selectRef"
              :options="visibleOptions"
              :query="searchQuery"
              :isSelected="isSelected"
              :isEmpty="isSuggestionsEmpty"
              >{{ props.emptyMessage }}</slot
            >
          </li>
        </slot>
        <slot
          name="footer"
          :dropdown="dropdown"
          :triggerRef="selectRef"
          :options="visibleOptions"
          :isSelected="isSelected"
          :isEmpty="isSuggestionsEmpty"
          :isDropdownVisible="dropdownVisible"
        />
      </ul>
    </template>
  </div>
</template>

<script setup lang="ts" generic="T">
import { twMerge, type ClassNameValue, twJoin } from "tailwind-merge";
import { isEqual } from "../../utils/isEqual"; // tilder was there
import { ref, computed, onMounted, onBeforeUnmount, watch, watchEffect } from "vue";
import { useRandomUUID } from "../../composables/useRandomUUID";
import { useTemplateRefsList } from "@vueuse/core";
import {
  Dropdown,
  type DropdownInterface,
  type DropdownOptions,
  type InstanceOptions,
} from "flowbite";

// Component Refs
const selectRef = ref<HTMLDivElement | null>(null);
const dropdownRef = ref<HTMLUListElement | null>(null);
const listRefs = useTemplateRefsList<HTMLLIElement>();
const dropdown = ref<DropdownInterface | null>(null);

// v-model
const selectedOption = defineModel<T>();

const emit = defineEmits<{
  search: [
    e: Event,
    query: string,
    activeOption: T | undefined,
    activeIndex: number,
    dropdownVisible: boolean,
    dropdown: DropdownInterface | null
  ];
  inputChange: [
    e: Event,
    query: string,
    activeOption: T | undefined,
    activeIndex: number,
    dropdownVisible: boolean,
    dropdown: DropdownInterface | null
  ];
  focus: [
    e: Event,
    query: string,
    activeOption: T | undefined,
    activeIndex: number,
    dropdownVisible: boolean,
    dropdown: DropdownInterface | null
  ];
  blur: [
    e: Event,
    query: string,
    activeOption: T | undefined,
    activeIndex: number,
    dropdownVisible: boolean,
    dropdown: DropdownInterface | null
  ];
  select: [
    option: T,
    visibleOptions: T[],
    loading: boolean,
    query: string,
    activeId: number,
    dropdown: DropdownInterface | null
  ];
  hover: [
    option: T,
    visibleOptions: T[],
    loading: boolean,
    query: string,
    activeOption: T | undefined,
    activeId: number,
    dropdownVisible: boolean,
    dropdown: DropdownInterface | null
  ];
  navigate: [
    e: KeyboardEvent,
    visibleOptions: T[],
    loading: boolean,
    query: string,
    activeOption: T | undefined,
    activeId: number,
    dropdown: DropdownInterface | null
  ];
}>();

// attribute binding
defineOptions({
  inheritAttrs: false,
});

// PROPS
const VARIANTS = [
  "primary",
  "success",
  "info",
  "danger",
  "warning",
  "disabled",
] as const;

const SIZES = ["xs", "sm", "md", "lg"] as const;

const LABEL_TYPES = ["floating", "fixed"] as const;

type PlacementType = DropdownOptions["placement"];

type Props = {
  options: T[];
  optionLabel?: keyof T | ((opt: T) => string);
  optionValue?: keyof T;
  editable?: boolean;
  variant?: (typeof VARIANTS)[number];
  size?: (typeof SIZES)[number];
  labelType?: (typeof LABEL_TYPES)[number];
  dataTestid?: string;
  label?: string;
  message?: string;
  optionGroupLabel?: keyof T | ((opt: T) => string);
  selectOnHover?: boolean;
  loading?: boolean;
  loadingIcon?: string;
  dropdownIcon?: string;
  disabled?: boolean;
  divide?: boolean;
  checkmark?: boolean;
  height?: string;
  emptyMessage?: string;
  searchTimeOutDelay?: number;
  dropdownPosition?: PlacementType;
  triggerType?: "click" | "hover";
  offsetSkidding?: number;
  offsetDistance?: number;
  dropdownDelay?: number;
  rootClass?: ClassNameValue;
  appendClass?: ClassNameValue;
  rootInputClass?: ClassNameValue;
  inputClass?: ClassNameValue;
  labelClass?: string;
  optionClass?: ClassNameValue;
  dropdownClass?: ClassNameValue;
};

const props = withDefaults(defineProps<Props>(), {
  editable: false,
  size: "md",
  labelType: "fixed",
  dataTestid: "atom-select-box",
  label: "",
  message: "",
  loading: false,
  loadingIcon: "mingcute:loading-line",
  dropdownIcon: "uiw:down",
  disabled: false,
  height: "216px",
  emptyMessage: "Nothing to show",
  searchTimeOutDelay: 400,
  dropdownPosition: "bottom",
  triggerType: "click",
  offsetSkidding: 0,
  offsetDistance: 5,
  dropdownDelay: 0,
  rootClass: "",
  inputClass: "",
  optionClass: "",
});

// STATES
const searchQuery = ref("");
const dropdownVisible = ref<boolean>(false);
const activeOption = ref<T>();
const activeOptionIndex = ref<number>(-1);

// GETTERS
const visibleOptions = ref([...props.options]) as Ref<T[]>;
const isLoading = ref<boolean>(props.loading);
const isSelected = (opt: T) => isEqual(opt, selectedOption.value);
const isSuggestionsEmpty = ref<boolean>(false);

const getKey = (opt: T) => {
  return JSON.stringify(opt);
};

const getLabel = (opt: T) => {
  if(!opt) return '';
  if (props.optionLabel && typeof opt === "object")
    return typeof props.optionLabel === "function"
        ? props.optionLabel(opt)
        : opt[props.optionLabel];
  if (props.optionValue && typeof opt === "object"){
    return String(opt[props.optionValue])
  }
  return String(opt);
};

//--------- WATCHERS
watch(() => props.options, (updatedOptions) => {
  visibleOptions.value = [...updatedOptions];
});
watch(() => selectedOption.value, (option) => {
  searchQuery.value = String(getLabel(option));
});
//--------- separating the two watchers to avoid infinite loop 
watchEffect(() => {
  if (!visibleOptions.value.length) isSuggestionsEmpty.value = true;
});

//--------- METHODS & EMITS
let searchTimeout: ReturnType<typeof setTimeout>;

//-------- Helper functions
function setActiveOption(activeId: number) {
  if (visibleOptions.value.length > 0) {
    activeOption.value = visibleOptions.value[activeId];
    searchQuery.value = String(getLabel(activeOption.value));
  }
}

function scrollInView(listIndex: number) {
  nextTick(() => {
    const activeListElement = listRefs.value[listIndex];
    if (activeListElement) {
      activeListElement.scrollIntoView &&
      activeListElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  });
}

const onSearch = (e: Event) => {
  if (props.disabled) return;
  // reset nothing found state
  isSuggestionsEmpty.value = false;
  // if search query is blank
  if (!searchQuery.value.trim().length) {
    visibleOptions.value = props.options;
    return;
  } else dropdown.value?.show();
  if (props.options.length === 0) return;
  // Search and Update
  emit(
      "search",
      e,
      searchQuery.value,
      activeOption.value,
      activeOptionIndex.value,
      dropdownVisible.value,
      dropdown.value
  );
  activeOptionIndex.value = -1;
  if (searchTimeout) {
    clearTimeout(searchTimeout);
  }

  // TODO: fuse.js for internal search feature
  searchTimeout = setTimeout(() => {
    visibleOptions.value = props.options.filter((opt: T) => {
      return (getLabel(opt) as string)
          .toLowerCase()
          .includes(searchQuery.value.trim().toLowerCase());
    });
    // update activeId if there are search results
    if (visibleOptions.value.length) activeOptionIndex.value = 0;
  }, props.searchTimeOutDelay);
};

const onSelect = (option: T, index: number) => {
  // get the option value
  const optionVal = props.optionValue
      ? option[props.optionValue as keyof T]
      : option;
  // update v-model and search query
  selectedOption.value = optionVal as T;
  searchQuery.value = String(getLabel(option));
  // update the active option
  activeOption.value = option;
  activeOptionIndex.value = index;

  dropdown.value?.hide();

  emit(
      "select",
      option,
      visibleOptions.value,
      isLoading.value,
      searchQuery.value,
      activeOptionIndex.value,
      dropdown.value
  );
};

const onHover = (option: T, index: number) => {
  if (!props.editable && props.selectOnHover) {
    searchQuery.value = String(getLabel(option));
    activeOption.value = option;
  }
  activeOptionIndex.value = index;
  emit(
      "hover",
      option,
      visibleOptions.value,
      isLoading.value,
      searchQuery.value,
      activeOption.value,
      activeOptionIndex.value,
      dropdownVisible.value,
      dropdown.value
  );
};

const onFocus = (e: Event) => {
  if (props.disabled) return;
  emit(
      "focus",
      e,
      searchQuery.value,
      activeOption.value,
      activeOptionIndex.value,
      dropdownVisible.value,
      dropdown.value
  );
};

const onChange = (e: Event) => {
  if (props.disabled) return;
  emit(
      "inputChange",
      e,
      searchQuery.value,
      activeOption.value,
      activeOptionIndex.value,
      dropdownVisible.value,
      dropdown.value
  );
};

const onBlur = (e: Event) => {
  if (props.disabled) return;
  emit(
      "blur",
      e,
      searchQuery.value,
      activeOption.value,
      activeOptionIndex.value,
      dropdownVisible.value,
      dropdown.value
  );
};

const onArrowDownKey = (event: Event) => {
  activeOptionIndex.value++;
  if (activeOptionIndex.value >= visibleOptions.value.length) {
    activeOptionIndex.value = 0;
  }
  scrollInView(activeOptionIndex.value);
  setActiveOption(activeOptionIndex.value);
  emit(
      "navigate",
      event as KeyboardEvent,
      visibleOptions.value,
      isLoading.value,
      searchQuery.value,
      activeOption.value,
      activeOptionIndex.value,
      dropdown.value
  );
};

const onArrowUpKey = (event: Event) => {
  activeOptionIndex.value--;
  if (activeOptionIndex.value < 0) {
    activeOptionIndex.value = visibleOptions.value.length - 1;
  }
  scrollInView(activeOptionIndex.value);
  setActiveOption(activeOptionIndex.value);
  emit(
      "navigate",
      event as KeyboardEvent,
      visibleOptions.value,
      isLoading.value,
      searchQuery.value,
      activeOption.value,
      activeOptionIndex.value,
      dropdown.value
  );
};

const onDeleteKey = (event: Event) => {
  activeOption.value = undefined;
  activeOptionIndex.value = -1;
  searchQuery.value = "";
  visibleOptions.value = [...props.options];
  if (selectedOption.value) {
    selectedOption.value = undefined;
  }
};

const onEscapeKey = (event: Event) => {
  dropdown.value?.hide();
};

const onEnterKey = (event: Event) => {
  if (activeOptionIndex.value > -1) {
    // update the active option if there is visible options
    setActiveOption(activeOptionIndex.value);
    if (!activeOption.value) return;
    // select on enter
    onSelect(activeOption.value, activeOptionIndex.value);
  }
};

const onSpaceKey = (event: Event, editable: boolean) => {
  if (!editable) dropdown.value?.toggle();
};

function onKeydown(event: Event) {
  if (props.disabled) return;
  if (!props.editable) event.preventDefault();

  switch ((event as KeyboardEvent).code) {
    case "ArrowDown":
      onArrowDownKey(event);
      break;
    case "ArrowUp":
      onArrowUpKey(event);
      break;

    case "Delete":
      onDeleteKey(event);
      break;

    case "PageUp":
      onArrowUpKey(event);
      break;
    case "PageDown":
      onArrowDownKey(event);
      break;

    case "Space":
      onSpaceKey(event, props.editable);
      break;

    case "Enter":
    case "NumpadEnter":
      onEnterKey(event);
      break;

    case "Escape":
      onEscapeKey(event);
      break;
  }
}

// CLASSES
const sizedClass: Record<typeof props.size, ClassNameValue> = {
  xs: "text-[12px]",
  sm: "text-[14px]",
  md: "text-[16px]",
  lg: "text-[18px]",
};

const itemClass = "flex px-3 py-2 w-full transition duration-150";

const sizeBasedClass = computed(() => sizedClass[props.size]);

function setInitialValue(){
  if (selectedOption.value) {
    activeOption.value = selectedOption.value;

    if(props.optionValue) activeOptionIndex.value = props.options.findIndex(option => isEqual(option[props.optionValue as keyof T], selectedOption.value));
    else activeOptionIndex.value = props.options.findIndex(option => isEqual(option, selectedOption.value));

  } else {
    searchQuery.value = "";
    activeOption.value = undefined;
    activeOptionIndex.value = -1;
  }
}

// random ID for each vue instance
const randomId = ref<string>("");

onMounted(() => {
  randomId.value = useRandomUUID();

  const options: DropdownOptions = {
    placement: props.dropdownPosition,
    triggerType: props.triggerType,
    offsetSkidding: props.offsetSkidding,
    offsetDistance: props.offsetDistance,
    delay: props.dropdownDelay,
    onHide: () => {
      dropdownVisible.value = false;
    },
    onShow: () => {
      setInitialValue();
      dropdownVisible.value = true;
    },
  };

  const instanceOptions: InstanceOptions = {
    id: "listbox-" + randomId.value,
    override: true,
  };

  dropdown.value = new Dropdown(
      dropdownRef.value,
      selectRef.value,
      options,
      instanceOptions
  );
});

onBeforeUnmount(() => dropdown && dropdown.value && dropdown.value._initialized ? dropdown.value.destroyAndRemoveInstance():'');
</script>
