import { fetchEntries, fetchOneEntry } from '@builder.io/sdk-vue';

export class BuilderService {


    private api_key: string;

    constructor(api_key: string) {
        this.api_key = api_key;
    }

    async getList(model = 'pages', query = {},
        sort = {}, limit = 10, offset = 0) {
        const i18n = { global: useNuxtApp().$i18n };
        let apiKey = this.api_key;
        try {
            return await fetchEntries({
                locale: i18n.global.locale.value,
                model,
                apiKey,
                query,
                sort,
                limit,
                offset
            });
        } catch (error) {
            await useErrorHandler(error);
            return error;
        }
    }

    async getItem(model = 'pages', userAttributes = {}, options = {}) {
        let apiKey = this.api_key;
        const i18n = { global: useNuxtApp().$i18n };
        try {
            const response = await fetchOneEntry({
                locale: i18n.global.locale.value,
                model,
                apiKey,
                userAttributes,
                options
            });


            return response;
        } catch (error) {
            await useErrorHandler(error);
            return error;
        }
    }


    async getContentModel(model: string, apiKey: string) {
        return await useAjax(useRuntimeConfig().public.builderBaseUrl + model + `?apiKey=${apiKey}`,
            {
                headers: {
                    accept: "*/*"
                },
                show_notifications: false,
                show_loading_indicator: false
            }

        );
    }
}
