<template>
  <TsAutocompleteV2
    v-model:visible="dropDownState"
    data-testid="header-search-input-field"
    :options="props.suggestions ?? []"
    :placeholder="props.placeholder"
    @focus="(q: any, dropdown: any) => handleFocus(q, dropdown)"
    @input="(q:any) => handleSearch(q)"
    @delete="(option:any) => handleDelete(option)"
    @enter="(searchQuery: string | undefined, dropdownVisible : boolean) => handleEnter(searchQuery, dropdownVisible)"
    dropdown-class="max-h-96"
  >
    <template #dropdown-parent="{ section }" id="search-dropdown">
      <div v-for="(singleSection, s_key) in section" @click.stop>
        <div v-if="algoliaStore[singleSection.stateHandler].status !== 'idle'">
          <hr v-if="s_key > 0 && section[s_key - 1].options.length > 0" />
          <div
            class="section-header flex flex-row justify-between items-baseline py-3"
            v-if="singleSection.options.length > 0"
          >
            <TsTypography variant="xs">
              {{ singleSection?.name }}
            </TsTypography>
            <TsTypography
              as="base"
              size="xs"
              weight="semibold"
              append-class="underline hover:no-underline cursor-pointer text-info"
              v-if="singleSection.canDelete"
              @click.stop="algoliaStore.deleteEntireHistory()"
            >
              Clear all
            </TsTypography>
          </div>
          <div
            v-if="algoliaStore[singleSection.stateHandler].status === 'success'"
          >
            <div v-if="singleSection.options.length > 0">
              <div v-for="option in singleSection.options">
                <TsMedia
                  class="flex justify-between items-center cursor-pointer mb-3"
                  @click="
                    handleClick(
                      singleSection.searchType,
                      option.name,
                      option.slug,
                      option.productCode
                    )
                  "
                >
                  <TsMediaStart
                    v-if="option.image"
                    append-class="size-16 border rounded-sm grid place-content-center mr-2"
                  >
                    <NuxtImg
                      :src="option.image"
                      width="48"
                      placeholder="https://img.site/p/64/64/BDC3C8/solid-stripes?label=TS&label_color=080808&label_font_size=17"
                    ></NuxtImg>
                  </TsMediaStart>
                  <TsMediaContent v-if="option.name" append-class="mr-2">
                    <TsTypography
                      append-class="hover:underline flex flex-col items-start justify-evenly text-idle-black line-clamp-3 max-md:text-sm"
                    >
                      <span
                        v-html="
                          useBoldMatchingText(
                            option.name,
                            props.lastSearchedKeyword
                          )
                        "
                      ></span>
                    </TsTypography>
                  </TsMediaContent>
                  <TsMediaEnd v-if="option.price">
                    <TsTypography
                      variant="base"
                      weight="bold"
                      append-class="text-info"
                    >
                      {{
                        !rootStore.isIncVat
                          ? option.price.net
                          : option.price.gross
                      }}
                    </TsTypography>
                  </TsMediaEnd>
                  <ClientOnly>
                    <TsIcon
                      v-if="singleSection.canDelete"
                      name="bitcoin-icons:cross-filled"
                      size="24"
                      @click.stop="emit('delete', option)"
                      :data-testid="props.dataTestid + '-icon'"
                    />
                  </ClientOnly>
                </TsMedia>
              </div>
            </div>
          </div>
          <div
            v-if="algoliaStore[singleSection.stateHandler].status === 'loading'"
          >
            <TsSkeletonSearchResult></TsSkeletonSearchResult>
          </div>

          <div
            v-if="algoliaStore[singleSection.stateHandler].status === 'failed'"
          >
            <div class="card flex flex-col justify-center items-center p-4">
              <TsTypography as="h2">
                {{ algoliaStore[singleSection.stateHandler].message }}
              </TsTypography>
            </div>
          </div>
        </div>
        <div v-else-if="s_key === 1">
          <div class="card flex flex-col justify-center items-center p-4">
            <TsTypography as="h2">
              {{ handleIdleStateTypography() }}
            </TsTypography>
            <NuxtImg
              src="/images/new-dewalt-deals.jpg"
              width=""
              class="rounded w-full"
            >
            </NuxtImg>
          </div>
        </div>
      </div>
    </template>
  </TsAutocompleteV2>
</template>

<script setup lang="ts" generic="TAlgolia">
import type {
  ComputedSuggestions,
  options,
  SearchType,
  Sections,
} from "~/types/algolia/algolia.type";

const dropDownState = ref<boolean>(false);

const rootStore = useRootStore();

const algoliaStore = useAlgoliaStore();

onMounted(() => {});
type Props = {
  placeholder?: string;
  suggestions?: ComputedSuggestions[] | null;
  dataTestid?: string;
  lastSearchedKeyword: string;
};

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  input: [inputQuery: string];
  enter: any;
  delete: [option: options];
}>();

function handleSearch(query: string) {
  query = query.replace('"', '\\"').replace(/^\s+|\s+$/g, "");
  if (query !== "" && query.length > 0) {
    emit("input", query);
  }
}

const handleIdleStateTypography = (idleText?: string) => {
  return idleText ? idleText : "Buy something interesting today!";
};

const handleFocus = (q: any, dropdown: boolean) => {
  algoliaStore.currentDropdownStatus = dropdown;
};

function handleClick(
  searchType: SearchType,
  optionName: string,
  optionSlug: string,
  optionCode: number
) {
  if (
    searchType === "recent-search" ||
    searchType === "popular-search"
  ) {
    algoliaStore.handleEnterClick(optionName, "direct-search", false);
  }
  if (searchType === "product-search") {
    const sanitizedOptionSlug = optionSlug.replace(/[!@#$%^&*()]/g, "-");
    navigateTo({
      path: `/product/${sanitizedOptionSlug}-${optionCode}`,
    });
  }
  if (searchType === "category-search") {
    let slug_array = optionSlug.split("/");

    // navigateTo({
    //   path: `/category/${slug_array[slug_array.length - 2]}-${optionCode}`,
    // });
    handleCategoryClick(slug_array[0], optionCode);
  }
}

function handleDelete(option: options) {
  emit("delete", option);
}

function handleEnter(keyword: string | undefined, dropdownVisible: boolean) {
  if (keyword && keyword.trim() !== "") {
    emit("enter", keyword, dropdownVisible);
    logEvent({
      options: {
        eventAction: "form_submit",
        eventProperties: {
          search: keyword,
        },
        eventInteraction: true,
        eventLabel: `User Search for "${keyword}"`,
        eventName: "Search",
      },
    });
  }
}

const handleCategoryClick = (
  subCategoryName: string,
  subCategoryId: number
) => {
  subCategoryName = subCategoryName.toLowerCase().replaceAll(" ", "-");
  algoliaStore.currentActiveCategoryFilter =
    bakeCategoryAsFilter(subCategoryId);

  algoliaStore.handleEnterClick(
    "",
    "category-search",
    false,
    [],
    "",
    bakeCategoryAsFilter(subCategoryId),
    subCategoryName,
    subCategoryId
  );
  // navigateTo(`/categories/${subCategoryName}-${subCategoryId}`);
};

const bakeCategoryAsFilter = (subCategoryId: number) => {
  return "categoryPageId:" + subCategoryId;
};
</script>
