import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as addressesqvDZbeBZ5GMeta } from "/vercel/path0/pages/account/addresses.vue?macro=true";
import { default as branchhoObrwM4PlMeta } from "/vercel/path0/pages/account/branch.vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as index9VK0z6UVHKMeta } from "/vercel/path0/pages/account/message/index.vue?macro=true";
import { default as order_45againANaqA11ppXMeta } from "/vercel/path0/pages/account/order-again.vue?macro=true";
import { default as indexrVzRtN8So8Meta } from "/vercel/path0/pages/account/orders/index.vue?macro=true";
import { default as _91slug_93t5CPmvg3CcMeta } from "/vercel/path0/pages/account/orders/product-review/[slug].vue?macro=true";
import { default as preferencesrM2HItr5JoMeta } from "/vercel/path0/pages/account/preferences.vue?macro=true";
import { default as index0xnYCToeIkMeta } from "/vercel/path0/pages/account/pro-card/index.vue?macro=true";
import { default as ordersq25rcXNzFfMeta } from "/vercel/path0/pages/account/pro-card/orders.vue?macro=true";
import { default as profileLQWMAtlTUmMeta } from "/vercel/path0/pages/account/profile.vue?macro=true";
import { default as save_45listjxLWLv5NchMeta } from "/vercel/path0/pages/account/save-list.vue?macro=true";
import { default as stock_45notificationdxuq5JL3hVMeta } from "/vercel/path0/pages/account/stock-notification.vue?macro=true";
import { default as _91_46_46_46slug_93G5kfklJFlZMeta } from "/vercel/path0/pages/advices/[...slug].vue?macro=true";
import { default as indexN48Bd3ORtzMeta } from "/vercel/path0/pages/advices/index.vue?macro=true";
import { default as _91_46_46_46slug_93lR4gcZU9ssMeta } from "/vercel/path0/pages/advices/search/[...slug].vue?macro=true";
import { default as indexbx70esBzlxMeta } from "/vercel/path0/pages/archieved/contact-us/index.vue?macro=true";
import { default as forgot_45passwordRoBld5gkuaMeta } from "/vercel/path0/pages/auth/forgot-password.vue?macro=true";
import { default as _91token_932ANWZuVmXWMeta } from "/vercel/path0/pages/auth/password/reset/[token].vue?macro=true";
import { default as redirectnmyqu1G1HCMeta } from "/vercel/path0/pages/auth/redirect.vue?macro=true";
import { default as signinBiAH3O7TaSMeta } from "/vercel/path0/pages/auth/signin.vue?macro=true";
import { default as signupVML5S8aMRqMeta } from "/vercel/path0/pages/auth/signup.vue?macro=true";
import { default as validateHdLtju0ZyfMeta } from "/vercel/path0/pages/auth/validate.vue?macro=true";
import { default as blankt6Q2gqvoEnMeta } from "/vercel/path0/pages/blank.vue?macro=true";
import { default as indexCwLBMxapv2Meta } from "/vercel/path0/pages/blogs-news-advices/index.vue?macro=true";
import { default as _91_46_46_46slug_93UQ5kBRahFsMeta } from "/vercel/path0/pages/blogs/[...slug].vue?macro=true";
import { default as indexdWTe5sxi2mMeta } from "/vercel/path0/pages/blogs/index.vue?macro=true";
import { default as _91_46_46_46slug_93AbO8qh0uJrMeta } from "/vercel/path0/pages/blogs/search/[...slug].vue?macro=true";
import { default as _91slug_936Ea5lWnFfWMeta } from "/vercel/path0/pages/branches/[slug].vue?macro=true";
import { default as indexN58rpMs0YsMeta } from "/vercel/path0/pages/branches/index.vue?macro=true";
import { default as dealszVATla1LgxMeta } from "/vercel/path0/pages/brands/[brand-name]/deals.vue?macro=true";
import { default as indexEHJjEciyQkMeta } from "/vercel/path0/pages/brands/[brand-name]/index.vue?macro=true";
import { default as productstQzgCa7AOaMeta } from "/vercel/path0/pages/brands/[brand-name]/products.vue?macro=true";
import { default as builderQCxRu2lH5MMeta } from "/vercel/path0/pages/brands/builder.vue?macro=true";
import { default as index8bNsuUinLTMeta } from "/vercel/path0/pages/brands/index.vue?macro=true";
import { default as indexZ5CE53LvwzMeta } from "/vercel/path0/pages/buttons/index.vue?macro=true";
import { default as indexL1gcmFVLA9Meta } from "/vercel/path0/pages/buy-now/index.vue?macro=true";
import { default as carduVzfQksec1Meta } from "/vercel/path0/pages/card.vue?macro=true";
import { default as _91_46_46_46sub_45category_45name_93_45_91sub_45category_45id_93hqdP2c2OJYMeta } from "/vercel/path0/pages/categories/[...sub-category-name]-[sub-category-id].vue?macro=true";
import { default as _91category_45id_93UyyLgrNt5uMeta } from "/vercel/path0/pages/categories/[category]/[category-id].vue?macro=true";
import { default as indexwxlq7WfK5WMeta } from "/vercel/path0/pages/categories/index.vue?macro=true";
import { default as _91_46_46_46sub_45category_45name_93_45_91sub_45category_45id_93IsgSmQuGJrMeta } from "/vercel/path0/pages/category/[...sub-category-name]-[sub-category-id].vue?macro=true";
import { default as fallbackJJNJo93HruMeta } from "/vercel/path0/pages/checkout/fallback.vue?macro=true";
import { default as indexraiq4bUafSMeta } from "/vercel/path0/pages/checkout/index.vue?macro=true";
import { default as _91slug_93VXahw1BA7rMeta } from "/vercel/path0/pages/checkout/order-confirmation/[slug].vue?macro=true";
import { default as indexFRpi3n6SmvMeta } from "/vercel/path0/pages/colors/index.vue?macro=true";
import { default as demo8GGAMPL777Meta } from "/vercel/path0/pages/demo.vue?macro=true";
import { default as _91_46_46_46slug_93hDrTXGRwuOMeta } from "/vercel/path0/pages/events/[...slug].vue?macro=true";
import { default as indexuedj0fLb5OMeta } from "/vercel/path0/pages/events/index.vue?macro=true";
import { default as _91_46_46_46slug_93HYnRcvBn78Meta } from "/vercel/path0/pages/events/offline/[...slug].vue?macro=true";
import { default as indexR7yMHzbnMyMeta } from "/vercel/path0/pages/events/offline/index.vue?macro=true";
import { default as _91_46_46_46slug_93E5MJ6ZeLFgMeta } from "/vercel/path0/pages/events/online/[...slug].vue?macro=true";
import { default as indexmuhaaXYAilMeta } from "/vercel/path0/pages/events/online/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93Zf86rRDqqNMeta } from "/vercel/path0/pages/news/[...slug].vue?macro=true";
import { default as indexxeWP9UFz4RMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as _91_46_46_46slug_93rOAb2UG6OtMeta } from "/vercel/path0/pages/news/search/[...slug].vue?macro=true";
import { default as _91token_93XzTxypJMaqMeta } from "/vercel/path0/pages/password/reset/[token].vue?macro=true";
import { default as fallback0cI1Dgtu8gMeta } from "/vercel/path0/pages/payment-test/fallback.vue?macro=true";
import { default as indexHr8VLkKdzwMeta } from "/vercel/path0/pages/payment-test/index.vue?macro=true";
import { default as thank_45youuRncU3qwmnMeta } from "/vercel/path0/pages/payment-test/thank-you.vue?macro=true";
import { default as dealsJbSJ5o7JXvMeta } from "/vercel/path0/pages/poc/deals.vue?macro=true";
import { default as authorized_45personZiG7d0li06Meta } from "/vercel/path0/pages/pro-card/application/authorized-person.vue?macro=true";
import { default as bank_45detailspWBPmCTK6jMeta } from "/vercel/path0/pages/pro-card/application/bank-details.vue?macro=true";
import { default as company_45detailsQoeAAC8sNtMeta } from "/vercel/path0/pages/pro-card/application/company-details.vue?macro=true";
import { default as company_45listZTGBSTmq5jMeta } from "/vercel/path0/pages/pro-card/application/company-list.vue?macro=true";
import { default as completeIUXwXNBOUYMeta } from "/vercel/path0/pages/pro-card/application/complete.vue?macro=true";
import { default as index2aFkxCcP1EMeta } from "/vercel/path0/pages/pro-card/application/index.vue?macro=true";
import { default as summaryMfziJQEBdGMeta } from "/vercel/path0/pages/pro-card/application/summary.vue?macro=true";
import { default as index7FKzvjTvvjMeta } from "/vercel/path0/pages/pro-card/index.vue?macro=true";
import { default as _91slug_933KQDXcbrccMeta } from "/vercel/path0/pages/product/[slug].vue?macro=true";
import { default as _91slug_93dcytAL0IurMeta } from "/vercel/path0/pages/product/product-review/[slug].vue?macro=true";
import { default as protectedxzt8pfNqY7Meta } from "/vercel/path0/pages/protected.vue?macro=true";
import { default as _404jCbxriY6fiMeta } from "/vercel/path0/pages/samples/404.vue?macro=true";
import { default as _500apIef6pzCWMeta } from "/vercel/path0/pages/samples/500.vue?macro=true";
import { default as about_45usGIgLIeAY6OMeta } from "/vercel/path0/pages/samples/about-us.vue?macro=true";
import { default as account_45preferences7Kiqy4tJe0Meta } from "/vercel/path0/pages/samples/account-preferences.vue?macro=true";
import { default as accountfOAwmdnlWEMeta } from "/vercel/path0/pages/samples/account.vue?macro=true";
import { default as addresses0Jxsjf9FRtMeta } from "/vercel/path0/pages/samples/addresses.vue?macro=true";
import { default as all_45brandsO0Y6aIAKjuMeta } from "/vercel/path0/pages/samples/all-brands.vue?macro=true";
import { default as benifits_45terms_45conditionsh0nmmf99XMMeta } from "/vercel/path0/pages/samples/benifits-terms-conditions.vue?macro=true";
import { default as benifitsDk9abGMLrTMeta } from "/vercel/path0/pages/samples/benifits.vue?macro=true";
import { default as blogs_45news_45and_45advicev0aALq9FM5Meta } from "/vercel/path0/pages/samples/blogs-news-and-advice.vue?macro=true";
import { default as brand_45dealsBVKPzhbXsXMeta } from "/vercel/path0/pages/samples/brand-deals.vue?macro=true";
import { default as brand_45detailsg5TdbuKexqMeta } from "/vercel/path0/pages/samples/brand-details.vue?macro=true";
import { default as brand_45listingAWRgz9165zMeta } from "/vercel/path0/pages/samples/brand-listing.vue?macro=true";
import { default as buy_45now_45demoVy2BmD4LYbMeta } from "/vercel/path0/pages/samples/buy-now-demo.vue?macro=true";
import { default as carousels31BFY1q5EXMeta } from "/vercel/path0/pages/samples/carousels.vue?macro=true";
import { default as catalogueLqtsj9FTQMMeta } from "/vercel/path0/pages/samples/catalogue.vue?macro=true";
import { default as categories8xOHzvvB6iMeta } from "/vercel/path0/pages/samples/categories.vue?macro=true";
import { default as checkoutf4pAzQDMMxMeta } from "/vercel/path0/pages/samples/checkout.vue?macro=true";
import { default as click_45and_45collecthw7DhB3lqAMeta } from "/vercel/path0/pages/samples/click-and-collect.vue?macro=true";
import { default as contact_45us4ha67zUQHGMeta } from "/vercel/path0/pages/samples/contact-us.vue?macro=true";
import { default as cookie_45policy1yYGyVtCPeMeta } from "/vercel/path0/pages/samples/cookie-policy.vue?macro=true";
import { default as cookie_45pop_45up_45modaldL66v0psNVMeta } from "/vercel/path0/pages/samples/cookie-pop-up-modal.vue?macro=true";
import { default as copyright3xCfaLRDGFMeta } from "/vercel/path0/pages/samples/copyright.vue?macro=true";
import { default as custom_45bannerAorqQGeQtHMeta } from "/vercel/path0/pages/samples/custom-banner.vue?macro=true";
import { default as dealsCKnCLe4ilaMeta } from "/vercel/path0/pages/samples/deals.vue?macro=true";
import { default as default_45branchM5sXMPn6kgMeta } from "/vercel/path0/pages/samples/default-branch.vue?macro=true";
import { default as deliverysgKUeiQtvLMeta } from "/vercel/path0/pages/samples/delivery.vue?macro=true";
import { default as detailsv5LyAUrUfAMeta } from "/vercel/path0/pages/samples/details.vue?macro=true";
import { default as event_45detailuX0r9nSOnlMeta } from "/vercel/path0/pages/samples/event-detail.vue?macro=true";
import { default as event2mt1vUDBAQMeta } from "/vercel/path0/pages/samples/event.vue?macro=true";
import { default as faqAaa5l33tZ4Meta } from "/vercel/path0/pages/samples/faq.vue?macro=true";
import { default as general_45termsnFBLeBQmp2Meta } from "/vercel/path0/pages/samples/general-terms.vue?macro=true";
import { default as guaranteeuhikknLi0ZMeta } from "/vercel/path0/pages/samples/guarantee.vue?macro=true";
import { default as helplBxPI6BfzBMeta } from "/vercel/path0/pages/samples/help.vue?macro=true";
import { default as homep28teoC8reMeta } from "/vercel/path0/pages/samples/home.vue?macro=true";
import { default as indexbwlIgouuYDMeta } from "/vercel/path0/pages/samples/index.vue?macro=true";
import { default as legal_45noticeNCKHOMJEksMeta } from "/vercel/path0/pages/samples/legal-notice.vue?macro=true";
import { default as message_45centerrIr7mYGvrNMeta } from "/vercel/path0/pages/samples/message-center.vue?macro=true";
import { default as modern_45slavery_45statement2bfMMDbDMbMeta } from "/vercel/path0/pages/samples/modern-slavery-statement.vue?macro=true";
import { default as monetatewKFEB2DJdOMeta } from "/vercel/path0/pages/samples/monetate.vue?macro=true";
import { default as new_45launchQXvu2KV2A3Meta } from "/vercel/path0/pages/samples/new-launch.vue?macro=true";
import { default as newsletterTGRWfhvrvuMeta } from "/vercel/path0/pages/samples/newsletter.vue?macro=true";
import { default as no_45search_45resultwXrMUB1OW0Meta } from "/vercel/path0/pages/samples/no-search-result.vue?macro=true";
import { default as offersHqKxRwG5lvMeta } from "/vercel/path0/pages/samples/offers.vue?macro=true";
import { default as ordersGxO6enFDEVMeta } from "/vercel/path0/pages/samples/orders.vue?macro=true";
import { default as payment_45methodsFRU0h1GTkQMeta } from "/vercel/path0/pages/samples/payment-methods.vue?macro=true";
import { default as plp_45new_45flownVZQyclwEPMeta } from "/vercel/path0/pages/samples/plp-new-flow.vue?macro=true";
import { default as postnlIB8ke3jO5zMeta } from "/vercel/path0/pages/samples/postnl.vue?macro=true";
import { default as preferencesANfeyJwFlyMeta } from "/vercel/path0/pages/samples/preferences.vue?macro=true";
import { default as privacy_45declarationqDCJ8vrf4XMeta } from "/vercel/path0/pages/samples/privacy-declaration.vue?macro=true";
import { default as pro_45card_45applyBN2BCn01T4Meta } from "/vercel/path0/pages/samples/pro-card-apply.vue?macro=true";
import { default as pro_45card_45demosxJ5yngN9gMeta } from "/vercel/path0/pages/samples/pro-card-demo.vue?macro=true";
import { default as pro_45card_45withqrSJWLdOsK82Meta } from "/vercel/path0/pages/samples/pro-card-withqr.vue?macro=true";
import { default as product_45detail7JjQs3y1QuMeta } from "/vercel/path0/pages/samples/product-detail.vue?macro=true";
import { default as product_45listingp3VLeIVUXuMeta } from "/vercel/path0/pages/samples/product-listing.vue?macro=true";
import { default as product_45reviewz7GzvchWpzMeta } from "/vercel/path0/pages/samples/product-review.vue?macro=true";
import { default as profilexRJCcBfPLlMeta } from "/vercel/path0/pages/samples/profile.vue?macro=true";
import { default as promotionDxAjxOREsSMeta } from "/vercel/path0/pages/samples/promotion.vue?macro=true";
import { default as returns_45exchangeR8AwCjPEVwMeta } from "/vercel/path0/pages/samples/returns-exchange.vue?macro=true";
import { default as reviews7SK0y0xaO6Meta } from "/vercel/path0/pages/samples/reviews.vue?macro=true";
import { default as salew8C9z26c1xMeta } from "/vercel/path0/pages/samples/sale.vue?macro=true";
import { default as save_45listTztPT1MuMTMeta } from "/vercel/path0/pages/samples/save-list.vue?macro=true";
import { default as search_45resultUah73mIpMTMeta } from "/vercel/path0/pages/samples/search-result.vue?macro=true";
import { default as site_45mapHt6sGWpYZtMeta } from "/vercel/path0/pages/samples/site-map.vue?macro=true";
import { default as stock_45notificationuhNNGgr7m5Meta } from "/vercel/path0/pages/samples/stock-notification.vue?macro=true";
import { default as terms_45conditionsXchXYCLOCRMeta } from "/vercel/path0/pages/samples/terms-conditions.vue?macro=true";
import { default as test_45error_45logging7sHix76jfbMeta } from "/vercel/path0/pages/samples/test-error-logging.vue?macro=true";
import { default as thank_45youWaBKNKPgplMeta } from "/vercel/path0/pages/samples/thank-you.vue?macro=true";
import { default as toolstation_45appuiTeIEjGQyMeta } from "/vercel/path0/pages/samples/toolstation-app.vue?macro=true";
import { default as trolley_45codevuez1xdEHHMeta } from "/vercel/path0/pages/samples/trolley-code.vue?macro=true";
import { default as trolleyozWJgd0aHWMeta } from "/vercel/path0/pages/samples/trolley.vue?macro=true";
import { default as trust_45pilothCOEW69ATkMeta } from "/vercel/path0/pages/samples/trust-pilot.vue?macro=true";
import { default as under_45maintenancehV20OeD3VgMeta } from "/vercel/path0/pages/samples/under-maintenance.vue?macro=true";
import { default as vacanciesKmNxLjgWhAMeta } from "/vercel/path0/pages/samples/vacancies.vue?macro=true";
import { default as weeWxAX5zmx68Meta } from "/vercel/path0/pages/samples/wee.vue?macro=true";
import { default as save_45list_45codeOhIW4gcDUKMeta } from "/vercel/path0/pages/save-list-code.vue?macro=true";
import { default as indexDqV3Lxa5AvMeta } from "/vercel/path0/pages/search/index.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as trolley_45codeeoEwCbN65BMeta } from "/vercel/path0/pages/trolley-code.vue?macro=true";
import { default as trolleyWNFg4WHReTMeta } from "/vercel/path0/pages/trolley.vue?macro=true";
import { default as _91_46_46_46slug_93xRdUlEOi0UMeta } from "/vercel/path0/pages/ui/[...slug].vue?macro=true";
import { default as indexSDV5zexPEqMeta } from "/vercel/path0/pages/ui/index.vue?macro=true";
import { default as under_45maintenanceP9qmbuqlofMeta } from "/vercel/path0/pages/under-maintenance.vue?macro=true";
import { default as with_45blankD5ybUwbyoUMeta } from "/vercel/path0/pages/with-blank.vue?macro=true";
import { default as with_45footer2XhL01PTTkMeta } from "/vercel/path0/pages/with-footer.vue?macro=true";
import { default as with_45header_45footerasjQUEGbfyMeta } from "/vercel/path0/pages/with-header-footer.vue?macro=true";
import { default as with_45header09ivHq3APOMeta } from "/vercel/path0/pages/with-header.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___nl-NL___default",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___nl-NL",
    path: "/nl-NL/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en-US",
    path: "/en-US/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr-FR",
    path: "/fr-FR/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___nl-BE",
    path: "/nl-BE/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___nl-NL___default",
    path: "/account/addresses",
    meta: addressesqvDZbeBZ5GMeta || {},
    component: () => import("/vercel/path0/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___nl-NL",
    path: "/nl-NL/account/addresses",
    meta: addressesqvDZbeBZ5GMeta || {},
    component: () => import("/vercel/path0/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___en-US",
    path: "/en-US/account/addresses",
    meta: addressesqvDZbeBZ5GMeta || {},
    component: () => import("/vercel/path0/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___fr-FR",
    path: "/fr-FR/account/addresses",
    meta: addressesqvDZbeBZ5GMeta || {},
    component: () => import("/vercel/path0/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___nl-BE",
    path: "/nl-BE/account/addresses",
    meta: addressesqvDZbeBZ5GMeta || {},
    component: () => import("/vercel/path0/pages/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "account-branch___nl-NL___default",
    path: "/account/branch",
    meta: branchhoObrwM4PlMeta || {},
    component: () => import("/vercel/path0/pages/account/branch.vue").then(m => m.default || m)
  },
  {
    name: "account-branch___nl-NL",
    path: "/nl-NL/account/branch",
    meta: branchhoObrwM4PlMeta || {},
    component: () => import("/vercel/path0/pages/account/branch.vue").then(m => m.default || m)
  },
  {
    name: "account-branch___en-US",
    path: "/en-US/account/branch",
    meta: branchhoObrwM4PlMeta || {},
    component: () => import("/vercel/path0/pages/account/branch.vue").then(m => m.default || m)
  },
  {
    name: "account-branch___fr-FR",
    path: "/fr-FR/account/branch",
    meta: branchhoObrwM4PlMeta || {},
    component: () => import("/vercel/path0/pages/account/branch.vue").then(m => m.default || m)
  },
  {
    name: "account-branch___nl-BE",
    path: "/nl-BE/account/branch",
    meta: branchhoObrwM4PlMeta || {},
    component: () => import("/vercel/path0/pages/account/branch.vue").then(m => m.default || m)
  },
  {
    name: "account___nl-NL___default",
    path: "/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___nl-NL",
    path: "/nl-NL/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en-US",
    path: "/en-US/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___fr-FR",
    path: "/fr-FR/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___nl-BE",
    path: "/nl-BE/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-message___nl-NL___default",
    path: "/account/message",
    meta: index9VK0z6UVHKMeta || {},
    component: () => import("/vercel/path0/pages/account/message/index.vue").then(m => m.default || m)
  },
  {
    name: "account-message___nl-NL",
    path: "/nl-NL/account/message",
    meta: index9VK0z6UVHKMeta || {},
    component: () => import("/vercel/path0/pages/account/message/index.vue").then(m => m.default || m)
  },
  {
    name: "account-message___en-US",
    path: "/en-US/account/message",
    meta: index9VK0z6UVHKMeta || {},
    component: () => import("/vercel/path0/pages/account/message/index.vue").then(m => m.default || m)
  },
  {
    name: "account-message___fr-FR",
    path: "/fr-FR/account/message",
    meta: index9VK0z6UVHKMeta || {},
    component: () => import("/vercel/path0/pages/account/message/index.vue").then(m => m.default || m)
  },
  {
    name: "account-message___nl-BE",
    path: "/nl-BE/account/message",
    meta: index9VK0z6UVHKMeta || {},
    component: () => import("/vercel/path0/pages/account/message/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-again___nl-NL___default",
    path: "/account/order-again",
    meta: order_45againANaqA11ppXMeta || {},
    component: () => import("/vercel/path0/pages/account/order-again.vue").then(m => m.default || m)
  },
  {
    name: "account-order-again___nl-NL",
    path: "/nl-NL/account/order-again",
    meta: order_45againANaqA11ppXMeta || {},
    component: () => import("/vercel/path0/pages/account/order-again.vue").then(m => m.default || m)
  },
  {
    name: "account-order-again___en-US",
    path: "/en-US/account/order-again",
    meta: order_45againANaqA11ppXMeta || {},
    component: () => import("/vercel/path0/pages/account/order-again.vue").then(m => m.default || m)
  },
  {
    name: "account-order-again___fr-FR",
    path: "/fr-FR/account/order-again",
    meta: order_45againANaqA11ppXMeta || {},
    component: () => import("/vercel/path0/pages/account/order-again.vue").then(m => m.default || m)
  },
  {
    name: "account-order-again___nl-BE",
    path: "/nl-BE/account/order-again",
    meta: order_45againANaqA11ppXMeta || {},
    component: () => import("/vercel/path0/pages/account/order-again.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___nl-NL___default",
    path: "/account/orders",
    meta: indexrVzRtN8So8Meta || {},
    component: () => import("/vercel/path0/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___nl-NL",
    path: "/nl-NL/account/orders",
    meta: indexrVzRtN8So8Meta || {},
    component: () => import("/vercel/path0/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en-US",
    path: "/en-US/account/orders",
    meta: indexrVzRtN8So8Meta || {},
    component: () => import("/vercel/path0/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___fr-FR",
    path: "/fr-FR/account/orders",
    meta: indexrVzRtN8So8Meta || {},
    component: () => import("/vercel/path0/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___nl-BE",
    path: "/nl-BE/account/orders",
    meta: indexrVzRtN8So8Meta || {},
    component: () => import("/vercel/path0/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-product-review-slug___nl-NL___default",
    path: "/account/orders/product-review/:slug()",
    component: () => import("/vercel/path0/pages/account/orders/product-review/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-orders-product-review-slug___nl-NL",
    path: "/nl-NL/account/orders/product-review/:slug()",
    component: () => import("/vercel/path0/pages/account/orders/product-review/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-orders-product-review-slug___en-US",
    path: "/en-US/account/orders/product-review/:slug()",
    component: () => import("/vercel/path0/pages/account/orders/product-review/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-orders-product-review-slug___fr-FR",
    path: "/fr-FR/account/orders/product-review/:slug()",
    component: () => import("/vercel/path0/pages/account/orders/product-review/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-orders-product-review-slug___nl-BE",
    path: "/nl-BE/account/orders/product-review/:slug()",
    component: () => import("/vercel/path0/pages/account/orders/product-review/[slug].vue").then(m => m.default || m)
  },
  {
    name: "account-preferences___nl-NL___default",
    path: "/account/preferences",
    meta: preferencesrM2HItr5JoMeta || {},
    component: () => import("/vercel/path0/pages/account/preferences.vue").then(m => m.default || m)
  },
  {
    name: "account-preferences___nl-NL",
    path: "/nl-NL/account/preferences",
    meta: preferencesrM2HItr5JoMeta || {},
    component: () => import("/vercel/path0/pages/account/preferences.vue").then(m => m.default || m)
  },
  {
    name: "account-preferences___en-US",
    path: "/en-US/account/preferences",
    meta: preferencesrM2HItr5JoMeta || {},
    component: () => import("/vercel/path0/pages/account/preferences.vue").then(m => m.default || m)
  },
  {
    name: "account-preferences___fr-FR",
    path: "/fr-FR/account/preferences",
    meta: preferencesrM2HItr5JoMeta || {},
    component: () => import("/vercel/path0/pages/account/preferences.vue").then(m => m.default || m)
  },
  {
    name: "account-preferences___nl-BE",
    path: "/nl-BE/account/preferences",
    meta: preferencesrM2HItr5JoMeta || {},
    component: () => import("/vercel/path0/pages/account/preferences.vue").then(m => m.default || m)
  },
  {
    name: "account-pro-card___nl-NL___default",
    path: "/account/pro-card",
    meta: index0xnYCToeIkMeta || {},
    component: () => import("/vercel/path0/pages/account/pro-card/index.vue").then(m => m.default || m)
  },
  {
    name: "account-pro-card___nl-NL",
    path: "/nl-NL/account/pro-card",
    meta: index0xnYCToeIkMeta || {},
    component: () => import("/vercel/path0/pages/account/pro-card/index.vue").then(m => m.default || m)
  },
  {
    name: "account-pro-card___en-US",
    path: "/en-US/account/pro-card",
    meta: index0xnYCToeIkMeta || {},
    component: () => import("/vercel/path0/pages/account/pro-card/index.vue").then(m => m.default || m)
  },
  {
    name: "account-pro-card___fr-FR",
    path: "/fr-FR/account/pro-card",
    meta: index0xnYCToeIkMeta || {},
    component: () => import("/vercel/path0/pages/account/pro-card/index.vue").then(m => m.default || m)
  },
  {
    name: "account-pro-card___nl-BE",
    path: "/nl-BE/account/pro-card",
    meta: index0xnYCToeIkMeta || {},
    component: () => import("/vercel/path0/pages/account/pro-card/index.vue").then(m => m.default || m)
  },
  {
    name: "account-pro-card-orders___nl-NL___default",
    path: "/account/pro-card/orders",
    meta: ordersq25rcXNzFfMeta || {},
    component: () => import("/vercel/path0/pages/account/pro-card/orders.vue").then(m => m.default || m)
  },
  {
    name: "account-pro-card-orders___nl-NL",
    path: "/nl-NL/account/pro-card/orders",
    meta: ordersq25rcXNzFfMeta || {},
    component: () => import("/vercel/path0/pages/account/pro-card/orders.vue").then(m => m.default || m)
  },
  {
    name: "account-pro-card-orders___en-US",
    path: "/en-US/account/pro-card/orders",
    meta: ordersq25rcXNzFfMeta || {},
    component: () => import("/vercel/path0/pages/account/pro-card/orders.vue").then(m => m.default || m)
  },
  {
    name: "account-pro-card-orders___fr-FR",
    path: "/fr-FR/account/pro-card/orders",
    meta: ordersq25rcXNzFfMeta || {},
    component: () => import("/vercel/path0/pages/account/pro-card/orders.vue").then(m => m.default || m)
  },
  {
    name: "account-pro-card-orders___nl-BE",
    path: "/nl-BE/account/pro-card/orders",
    meta: ordersq25rcXNzFfMeta || {},
    component: () => import("/vercel/path0/pages/account/pro-card/orders.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___nl-NL___default",
    path: "/account/profile",
    meta: profileLQWMAtlTUmMeta || {},
    component: () => import("/vercel/path0/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___nl-NL",
    path: "/nl-NL/account/profile",
    meta: profileLQWMAtlTUmMeta || {},
    component: () => import("/vercel/path0/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___en-US",
    path: "/en-US/account/profile",
    meta: profileLQWMAtlTUmMeta || {},
    component: () => import("/vercel/path0/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___fr-FR",
    path: "/fr-FR/account/profile",
    meta: profileLQWMAtlTUmMeta || {},
    component: () => import("/vercel/path0/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___nl-BE",
    path: "/nl-BE/account/profile",
    meta: profileLQWMAtlTUmMeta || {},
    component: () => import("/vercel/path0/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-save-list___nl-NL___default",
    path: "/account/save-list",
    meta: save_45listjxLWLv5NchMeta || {},
    component: () => import("/vercel/path0/pages/account/save-list.vue").then(m => m.default || m)
  },
  {
    name: "account-save-list___nl-NL",
    path: "/nl-NL/account/save-list",
    meta: save_45listjxLWLv5NchMeta || {},
    component: () => import("/vercel/path0/pages/account/save-list.vue").then(m => m.default || m)
  },
  {
    name: "account-save-list___en-US",
    path: "/en-US/account/save-list",
    meta: save_45listjxLWLv5NchMeta || {},
    component: () => import("/vercel/path0/pages/account/save-list.vue").then(m => m.default || m)
  },
  {
    name: "account-save-list___fr-FR",
    path: "/fr-FR/account/save-list",
    meta: save_45listjxLWLv5NchMeta || {},
    component: () => import("/vercel/path0/pages/account/save-list.vue").then(m => m.default || m)
  },
  {
    name: "account-save-list___nl-BE",
    path: "/nl-BE/account/save-list",
    meta: save_45listjxLWLv5NchMeta || {},
    component: () => import("/vercel/path0/pages/account/save-list.vue").then(m => m.default || m)
  },
  {
    name: "account-stock-notification___nl-NL___default",
    path: "/account/stock-notification",
    meta: stock_45notificationdxuq5JL3hVMeta || {},
    component: () => import("/vercel/path0/pages/account/stock-notification.vue").then(m => m.default || m)
  },
  {
    name: "account-stock-notification___nl-NL",
    path: "/nl-NL/account/stock-notification",
    meta: stock_45notificationdxuq5JL3hVMeta || {},
    component: () => import("/vercel/path0/pages/account/stock-notification.vue").then(m => m.default || m)
  },
  {
    name: "account-stock-notification___en-US",
    path: "/en-US/account/stock-notification",
    meta: stock_45notificationdxuq5JL3hVMeta || {},
    component: () => import("/vercel/path0/pages/account/stock-notification.vue").then(m => m.default || m)
  },
  {
    name: "account-stock-notification___fr-FR",
    path: "/fr-FR/account/stock-notification",
    meta: stock_45notificationdxuq5JL3hVMeta || {},
    component: () => import("/vercel/path0/pages/account/stock-notification.vue").then(m => m.default || m)
  },
  {
    name: "account-stock-notification___nl-BE",
    path: "/nl-BE/account/stock-notification",
    meta: stock_45notificationdxuq5JL3hVMeta || {},
    component: () => import("/vercel/path0/pages/account/stock-notification.vue").then(m => m.default || m)
  },
  {
    name: "advices-slug___nl-NL___default",
    path: "/advices/:slug(.*)*",
    component: () => import("/vercel/path0/pages/advices/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "advices-slug___nl-NL",
    path: "/nl-NL/advices/:slug(.*)*",
    component: () => import("/vercel/path0/pages/advices/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "advices-slug___en-US",
    path: "/en-US/advices/:slug(.*)*",
    component: () => import("/vercel/path0/pages/advices/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "advices-slug___fr-FR",
    path: "/fr-FR/advices/:slug(.*)*",
    component: () => import("/vercel/path0/pages/advices/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "advices-slug___nl-BE",
    path: "/nl-BE/advices/:slug(.*)*",
    component: () => import("/vercel/path0/pages/advices/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "advices___nl-NL___default",
    path: "/advices",
    component: () => import("/vercel/path0/pages/advices/index.vue").then(m => m.default || m)
  },
  {
    name: "advices___nl-NL",
    path: "/nl-NL/advices",
    component: () => import("/vercel/path0/pages/advices/index.vue").then(m => m.default || m)
  },
  {
    name: "advices___en-US",
    path: "/en-US/advices",
    component: () => import("/vercel/path0/pages/advices/index.vue").then(m => m.default || m)
  },
  {
    name: "advices___fr-FR",
    path: "/fr-FR/advices",
    component: () => import("/vercel/path0/pages/advices/index.vue").then(m => m.default || m)
  },
  {
    name: "advices___nl-BE",
    path: "/nl-BE/advices",
    component: () => import("/vercel/path0/pages/advices/index.vue").then(m => m.default || m)
  },
  {
    name: "advices-search-slug___nl-NL___default",
    path: "/advices/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/advices/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "advices-search-slug___nl-NL",
    path: "/nl-NL/advices/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/advices/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "advices-search-slug___en-US",
    path: "/en-US/advices/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/advices/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "advices-search-slug___fr-FR",
    path: "/fr-FR/advices/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/advices/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "advices-search-slug___nl-BE",
    path: "/nl-BE/advices/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/advices/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "archieved-contact-us___nl-NL___default",
    path: "/archieved/contact-us",
    component: () => import("/vercel/path0/pages/archieved/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "archieved-contact-us___nl-NL",
    path: "/nl-NL/archieved/contact-us",
    component: () => import("/vercel/path0/pages/archieved/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "archieved-contact-us___en-US",
    path: "/en-US/archieved/contact-us",
    component: () => import("/vercel/path0/pages/archieved/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "archieved-contact-us___fr-FR",
    path: "/fr-FR/archieved/contact-us",
    component: () => import("/vercel/path0/pages/archieved/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "archieved-contact-us___nl-BE",
    path: "/nl-BE/archieved/contact-us",
    component: () => import("/vercel/path0/pages/archieved/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password___nl-NL___default",
    path: "/auth/forgot-password",
    meta: forgot_45passwordRoBld5gkuaMeta || {},
    component: () => import("/vercel/path0/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password___nl-NL",
    path: "/nl-NL/auth/forgot-password",
    meta: forgot_45passwordRoBld5gkuaMeta || {},
    component: () => import("/vercel/path0/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password___en-US",
    path: "/en-US/auth/forgot-password",
    meta: forgot_45passwordRoBld5gkuaMeta || {},
    component: () => import("/vercel/path0/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password___fr-FR",
    path: "/fr-FR/auth/forgot-password",
    meta: forgot_45passwordRoBld5gkuaMeta || {},
    component: () => import("/vercel/path0/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password___nl-BE",
    path: "/nl-BE/auth/forgot-password",
    meta: forgot_45passwordRoBld5gkuaMeta || {},
    component: () => import("/vercel/path0/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset-token___nl-NL___default",
    path: "/auth/password/reset/:token()",
    meta: _91token_932ANWZuVmXWMeta || {},
    component: () => import("/vercel/path0/pages/auth/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset-token___nl-NL",
    path: "/nl-NL/auth/password/reset/:token()",
    meta: _91token_932ANWZuVmXWMeta || {},
    component: () => import("/vercel/path0/pages/auth/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset-token___en-US",
    path: "/en-US/auth/password/reset/:token()",
    meta: _91token_932ANWZuVmXWMeta || {},
    component: () => import("/vercel/path0/pages/auth/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset-token___fr-FR",
    path: "/fr-FR/auth/password/reset/:token()",
    meta: _91token_932ANWZuVmXWMeta || {},
    component: () => import("/vercel/path0/pages/auth/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset-token___nl-BE",
    path: "/nl-BE/auth/password/reset/:token()",
    meta: _91token_932ANWZuVmXWMeta || {},
    component: () => import("/vercel/path0/pages/auth/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-redirect___nl-NL___default",
    path: "/auth/redirect",
    meta: redirectnmyqu1G1HCMeta || {},
    component: () => import("/vercel/path0/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: "auth-redirect___nl-NL",
    path: "/nl-NL/auth/redirect",
    meta: redirectnmyqu1G1HCMeta || {},
    component: () => import("/vercel/path0/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: "auth-redirect___en-US",
    path: "/en-US/auth/redirect",
    meta: redirectnmyqu1G1HCMeta || {},
    component: () => import("/vercel/path0/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: "auth-redirect___fr-FR",
    path: "/fr-FR/auth/redirect",
    meta: redirectnmyqu1G1HCMeta || {},
    component: () => import("/vercel/path0/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: "auth-redirect___nl-BE",
    path: "/nl-BE/auth/redirect",
    meta: redirectnmyqu1G1HCMeta || {},
    component: () => import("/vercel/path0/pages/auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin___nl-NL___default",
    path: "/auth/signin",
    meta: signinBiAH3O7TaSMeta || {},
    component: () => import("/vercel/path0/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin___nl-NL",
    path: "/nl-NL/auth/signin",
    meta: signinBiAH3O7TaSMeta || {},
    component: () => import("/vercel/path0/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin___en-US",
    path: "/en-US/auth/signin",
    meta: signinBiAH3O7TaSMeta || {},
    component: () => import("/vercel/path0/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin___fr-FR",
    path: "/fr-FR/auth/signin",
    meta: signinBiAH3O7TaSMeta || {},
    component: () => import("/vercel/path0/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signin___nl-BE",
    path: "/nl-BE/auth/signin",
    meta: signinBiAH3O7TaSMeta || {},
    component: () => import("/vercel/path0/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___nl-NL___default",
    path: "/auth/signup",
    meta: signupVML5S8aMRqMeta || {},
    component: () => import("/vercel/path0/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___nl-NL",
    path: "/nl-NL/auth/signup",
    meta: signupVML5S8aMRqMeta || {},
    component: () => import("/vercel/path0/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___en-US",
    path: "/en-US/auth/signup",
    meta: signupVML5S8aMRqMeta || {},
    component: () => import("/vercel/path0/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___fr-FR",
    path: "/fr-FR/auth/signup",
    meta: signupVML5S8aMRqMeta || {},
    component: () => import("/vercel/path0/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___nl-BE",
    path: "/nl-BE/auth/signup",
    meta: signupVML5S8aMRqMeta || {},
    component: () => import("/vercel/path0/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate___nl-NL___default",
    path: "/auth/validate",
    component: () => import("/vercel/path0/pages/auth/validate.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate___nl-NL",
    path: "/nl-NL/auth/validate",
    component: () => import("/vercel/path0/pages/auth/validate.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate___en-US",
    path: "/en-US/auth/validate",
    component: () => import("/vercel/path0/pages/auth/validate.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate___fr-FR",
    path: "/fr-FR/auth/validate",
    component: () => import("/vercel/path0/pages/auth/validate.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate___nl-BE",
    path: "/nl-BE/auth/validate",
    component: () => import("/vercel/path0/pages/auth/validate.vue").then(m => m.default || m)
  },
  {
    name: "blank___nl-NL___default",
    path: "/blank",
    meta: blankt6Q2gqvoEnMeta || {},
    component: () => import("/vercel/path0/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: "blank___nl-NL",
    path: "/nl-NL/blank",
    meta: blankt6Q2gqvoEnMeta || {},
    component: () => import("/vercel/path0/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: "blank___en-US",
    path: "/en-US/blank",
    meta: blankt6Q2gqvoEnMeta || {},
    component: () => import("/vercel/path0/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: "blank___fr-FR",
    path: "/fr-FR/blank",
    meta: blankt6Q2gqvoEnMeta || {},
    component: () => import("/vercel/path0/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: "blank___nl-BE",
    path: "/nl-BE/blank",
    meta: blankt6Q2gqvoEnMeta || {},
    component: () => import("/vercel/path0/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: "blogs-news-advices___nl-NL___default",
    path: "/blogs-news-advices",
    component: () => import("/vercel/path0/pages/blogs-news-advices/index.vue").then(m => m.default || m)
  },
  {
    name: "blogs-news-advices___nl-NL",
    path: "/nl-NL/blogs-news-advices",
    component: () => import("/vercel/path0/pages/blogs-news-advices/index.vue").then(m => m.default || m)
  },
  {
    name: "blogs-news-advices___en-US",
    path: "/en-US/blogs-news-advices",
    component: () => import("/vercel/path0/pages/blogs-news-advices/index.vue").then(m => m.default || m)
  },
  {
    name: "blogs-news-advices___fr-FR",
    path: "/fr-FR/blogs-news-advices",
    component: () => import("/vercel/path0/pages/blogs-news-advices/index.vue").then(m => m.default || m)
  },
  {
    name: "blogs-news-advices___nl-BE",
    path: "/nl-BE/blogs-news-advices",
    component: () => import("/vercel/path0/pages/blogs-news-advices/index.vue").then(m => m.default || m)
  },
  {
    name: "blogs-slug___nl-NL___default",
    path: "/blogs/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blogs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogs-slug___nl-NL",
    path: "/nl-NL/blogs/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blogs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogs-slug___en-US",
    path: "/en-US/blogs/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blogs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogs-slug___fr-FR",
    path: "/fr-FR/blogs/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blogs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogs-slug___nl-BE",
    path: "/nl-BE/blogs/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blogs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogs___nl-NL___default",
    path: "/blogs",
    component: () => import("/vercel/path0/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: "blogs___nl-NL",
    path: "/nl-NL/blogs",
    component: () => import("/vercel/path0/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: "blogs___en-US",
    path: "/en-US/blogs",
    component: () => import("/vercel/path0/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: "blogs___fr-FR",
    path: "/fr-FR/blogs",
    component: () => import("/vercel/path0/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: "blogs___nl-BE",
    path: "/nl-BE/blogs",
    component: () => import("/vercel/path0/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: "blogs-search-slug___nl-NL___default",
    path: "/blogs/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blogs/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogs-search-slug___nl-NL",
    path: "/nl-NL/blogs/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blogs/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogs-search-slug___en-US",
    path: "/en-US/blogs/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blogs/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogs-search-slug___fr-FR",
    path: "/fr-FR/blogs/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blogs/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blogs-search-slug___nl-BE",
    path: "/nl-BE/blogs/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blogs/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "branches-slug___nl-NL___default",
    path: "/branches/:slug()",
    component: () => import("/vercel/path0/pages/branches/[slug].vue").then(m => m.default || m)
  },
  {
    name: "branches-slug___nl-NL",
    path: "/nl-NL/branches/:slug()",
    component: () => import("/vercel/path0/pages/branches/[slug].vue").then(m => m.default || m)
  },
  {
    name: "branches-slug___en-US",
    path: "/en-US/branches/:slug()",
    component: () => import("/vercel/path0/pages/branches/[slug].vue").then(m => m.default || m)
  },
  {
    name: "branches-slug___fr-FR",
    path: "/fr-FR/branches/:slug()",
    component: () => import("/vercel/path0/pages/branches/[slug].vue").then(m => m.default || m)
  },
  {
    name: "branches-slug___nl-BE",
    path: "/nl-BE/branches/:slug()",
    component: () => import("/vercel/path0/pages/branches/[slug].vue").then(m => m.default || m)
  },
  {
    name: "branches___nl-NL___default",
    path: "/branches",
    component: () => import("/vercel/path0/pages/branches/index.vue").then(m => m.default || m)
  },
  {
    name: "branches___nl-NL",
    path: "/nl-NL/branches",
    component: () => import("/vercel/path0/pages/branches/index.vue").then(m => m.default || m)
  },
  {
    name: "branches___en-US",
    path: "/en-US/branches",
    component: () => import("/vercel/path0/pages/branches/index.vue").then(m => m.default || m)
  },
  {
    name: "branches___fr-FR",
    path: "/fr-FR/branches",
    component: () => import("/vercel/path0/pages/branches/index.vue").then(m => m.default || m)
  },
  {
    name: "branches___nl-BE",
    path: "/nl-BE/branches",
    component: () => import("/vercel/path0/pages/branches/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname-deals___nl-NL___default",
    path: "/brands/:brandname()/deals",
    meta: dealszVATla1LgxMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/deals.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname-deals___nl-NL",
    path: "/nl-NL/brands/:brandname()/deals",
    meta: dealszVATla1LgxMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/deals.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname-deals___en-US",
    path: "/en-US/brands/:brandname()/deals",
    meta: dealszVATla1LgxMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/deals.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname-deals___fr-FR",
    path: "/fr-FR/brands/:brandname()/deals",
    meta: dealszVATla1LgxMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/deals.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname-deals___nl-BE",
    path: "/nl-BE/brands/:brandname()/deals",
    meta: dealszVATla1LgxMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/deals.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname___nl-NL___default",
    path: "/brands/:brandname()",
    meta: indexEHJjEciyQkMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname___nl-NL",
    path: "/nl-NL/brands/:brandname()",
    meta: indexEHJjEciyQkMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname___en-US",
    path: "/en-US/brands/:brandname()",
    meta: indexEHJjEciyQkMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname___fr-FR",
    path: "/fr-FR/brands/:brandname()",
    meta: indexEHJjEciyQkMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname___nl-BE",
    path: "/nl-BE/brands/:brandname()",
    meta: indexEHJjEciyQkMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/index.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname-products___nl-NL___default",
    path: "/brands/:brandname()/products",
    meta: productstQzgCa7AOaMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/products.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname-products___nl-NL",
    path: "/nl-NL/brands/:brandname()/products",
    meta: productstQzgCa7AOaMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/products.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname-products___en-US",
    path: "/en-US/brands/:brandname()/products",
    meta: productstQzgCa7AOaMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/products.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname-products___fr-FR",
    path: "/fr-FR/brands/:brandname()/products",
    meta: productstQzgCa7AOaMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/products.vue").then(m => m.default || m)
  },
  {
    name: "brands-brandname-products___nl-BE",
    path: "/nl-BE/brands/:brandname()/products",
    meta: productstQzgCa7AOaMeta || {},
    component: () => import("/vercel/path0/pages/brands/[brand-name]/products.vue").then(m => m.default || m)
  },
  {
    name: "brands-builder___nl-NL___default",
    path: "/brands/builder",
    component: () => import("/vercel/path0/pages/brands/builder.vue").then(m => m.default || m)
  },
  {
    name: "brands-builder___nl-NL",
    path: "/nl-NL/brands/builder",
    component: () => import("/vercel/path0/pages/brands/builder.vue").then(m => m.default || m)
  },
  {
    name: "brands-builder___en-US",
    path: "/en-US/brands/builder",
    component: () => import("/vercel/path0/pages/brands/builder.vue").then(m => m.default || m)
  },
  {
    name: "brands-builder___fr-FR",
    path: "/fr-FR/brands/builder",
    component: () => import("/vercel/path0/pages/brands/builder.vue").then(m => m.default || m)
  },
  {
    name: "brands-builder___nl-BE",
    path: "/nl-BE/brands/builder",
    component: () => import("/vercel/path0/pages/brands/builder.vue").then(m => m.default || m)
  },
  {
    name: "brands___nl-NL___default",
    path: "/brands",
    component: () => import("/vercel/path0/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "brands___nl-NL",
    path: "/nl-NL/brands",
    component: () => import("/vercel/path0/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "brands___en-US",
    path: "/en-US/brands",
    component: () => import("/vercel/path0/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "brands___fr-FR",
    path: "/fr-FR/brands",
    component: () => import("/vercel/path0/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "brands___nl-BE",
    path: "/nl-BE/brands",
    component: () => import("/vercel/path0/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "buttons___nl-NL___default",
    path: "/buttons",
    component: () => import("/vercel/path0/pages/buttons/index.vue").then(m => m.default || m)
  },
  {
    name: "buttons___nl-NL",
    path: "/nl-NL/buttons",
    component: () => import("/vercel/path0/pages/buttons/index.vue").then(m => m.default || m)
  },
  {
    name: "buttons___en-US",
    path: "/en-US/buttons",
    component: () => import("/vercel/path0/pages/buttons/index.vue").then(m => m.default || m)
  },
  {
    name: "buttons___fr-FR",
    path: "/fr-FR/buttons",
    component: () => import("/vercel/path0/pages/buttons/index.vue").then(m => m.default || m)
  },
  {
    name: "buttons___nl-BE",
    path: "/nl-BE/buttons",
    component: () => import("/vercel/path0/pages/buttons/index.vue").then(m => m.default || m)
  },
  {
    name: "buy-now___nl-NL___default",
    path: "/buy-now",
    meta: indexL1gcmFVLA9Meta || {},
    component: () => import("/vercel/path0/pages/buy-now/index.vue").then(m => m.default || m)
  },
  {
    name: "buy-now___nl-NL",
    path: "/nl-NL/buy-now",
    meta: indexL1gcmFVLA9Meta || {},
    component: () => import("/vercel/path0/pages/buy-now/index.vue").then(m => m.default || m)
  },
  {
    name: "buy-now___en-US",
    path: "/en-US/buy-now",
    meta: indexL1gcmFVLA9Meta || {},
    component: () => import("/vercel/path0/pages/buy-now/index.vue").then(m => m.default || m)
  },
  {
    name: "buy-now___fr-FR",
    path: "/fr-FR/buy-now",
    meta: indexL1gcmFVLA9Meta || {},
    component: () => import("/vercel/path0/pages/buy-now/index.vue").then(m => m.default || m)
  },
  {
    name: "buy-now___nl-BE",
    path: "/nl-BE/buy-now",
    meta: indexL1gcmFVLA9Meta || {},
    component: () => import("/vercel/path0/pages/buy-now/index.vue").then(m => m.default || m)
  },
  {
    name: "card___nl-NL___default",
    path: "/card",
    component: () => import("/vercel/path0/pages/card.vue").then(m => m.default || m)
  },
  {
    name: "card___nl-NL",
    path: "/nl-NL/card",
    component: () => import("/vercel/path0/pages/card.vue").then(m => m.default || m)
  },
  {
    name: "card___en-US",
    path: "/en-US/card",
    component: () => import("/vercel/path0/pages/card.vue").then(m => m.default || m)
  },
  {
    name: "card___fr-FR",
    path: "/fr-FR/card",
    component: () => import("/vercel/path0/pages/card.vue").then(m => m.default || m)
  },
  {
    name: "card___nl-BE",
    path: "/nl-BE/card",
    component: () => import("/vercel/path0/pages/card.vue").then(m => m.default || m)
  },
  {
    name: "categories-subcategoryname-subcategoryid___nl-NL___default",
    path: "/categories/:subcategoryname(.*)*-:subcategoryid()",
    component: () => import("/vercel/path0/pages/categories/[...sub-category-name]-[sub-category-id].vue").then(m => m.default || m)
  },
  {
    name: "categories-subcategoryname-subcategoryid___nl-NL",
    path: "/nl-NL/categories/:subcategoryname(.*)*-:subcategoryid()",
    component: () => import("/vercel/path0/pages/categories/[...sub-category-name]-[sub-category-id].vue").then(m => m.default || m)
  },
  {
    name: "categories-subcategoryname-subcategoryid___en-US",
    path: "/en-US/categories/:subcategoryname(.*)*-:subcategoryid()",
    component: () => import("/vercel/path0/pages/categories/[...sub-category-name]-[sub-category-id].vue").then(m => m.default || m)
  },
  {
    name: "categories-subcategoryname-subcategoryid___fr-FR",
    path: "/fr-FR/categories/:subcategoryname(.*)*-:subcategoryid()",
    component: () => import("/vercel/path0/pages/categories/[...sub-category-name]-[sub-category-id].vue").then(m => m.default || m)
  },
  {
    name: "categories-subcategoryname-subcategoryid___nl-BE",
    path: "/nl-BE/categories/:subcategoryname(.*)*-:subcategoryid()",
    component: () => import("/vercel/path0/pages/categories/[...sub-category-name]-[sub-category-id].vue").then(m => m.default || m)
  },
  {
    name: "categories-category-categoryid___nl-NL___default",
    path: "/categories/:category()/:categoryid()",
    component: () => import("/vercel/path0/pages/categories/[category]/[category-id].vue").then(m => m.default || m)
  },
  {
    name: "categories-category-categoryid___nl-NL",
    path: "/nl-NL/categories/:category()/:categoryid()",
    component: () => import("/vercel/path0/pages/categories/[category]/[category-id].vue").then(m => m.default || m)
  },
  {
    name: "categories-category-categoryid___en-US",
    path: "/en-US/categories/:category()/:categoryid()",
    component: () => import("/vercel/path0/pages/categories/[category]/[category-id].vue").then(m => m.default || m)
  },
  {
    name: "categories-category-categoryid___fr-FR",
    path: "/fr-FR/categories/:category()/:categoryid()",
    component: () => import("/vercel/path0/pages/categories/[category]/[category-id].vue").then(m => m.default || m)
  },
  {
    name: "categories-category-categoryid___nl-BE",
    path: "/nl-BE/categories/:category()/:categoryid()",
    component: () => import("/vercel/path0/pages/categories/[category]/[category-id].vue").then(m => m.default || m)
  },
  {
    name: "categories___nl-NL___default",
    path: "/categories",
    component: () => import("/vercel/path0/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "categories___nl-NL",
    path: "/nl-NL/categories",
    component: () => import("/vercel/path0/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "categories___en-US",
    path: "/en-US/categories",
    component: () => import("/vercel/path0/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "categories___fr-FR",
    path: "/fr-FR/categories",
    component: () => import("/vercel/path0/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "categories___nl-BE",
    path: "/nl-BE/categories",
    component: () => import("/vercel/path0/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "category-subcategoryname-subcategoryid___nl-NL___default",
    path: "/category/:subcategoryname(.*)*-:subcategoryid()",
    component: () => import("/vercel/path0/pages/category/[...sub-category-name]-[sub-category-id].vue").then(m => m.default || m)
  },
  {
    name: "category-subcategoryname-subcategoryid___nl-NL",
    path: "/nl-NL/category/:subcategoryname(.*)*-:subcategoryid()",
    component: () => import("/vercel/path0/pages/category/[...sub-category-name]-[sub-category-id].vue").then(m => m.default || m)
  },
  {
    name: "category-subcategoryname-subcategoryid___en-US",
    path: "/en-US/category/:subcategoryname(.*)*-:subcategoryid()",
    component: () => import("/vercel/path0/pages/category/[...sub-category-name]-[sub-category-id].vue").then(m => m.default || m)
  },
  {
    name: "category-subcategoryname-subcategoryid___fr-FR",
    path: "/fr-FR/category/:subcategoryname(.*)*-:subcategoryid()",
    component: () => import("/vercel/path0/pages/category/[...sub-category-name]-[sub-category-id].vue").then(m => m.default || m)
  },
  {
    name: "category-subcategoryname-subcategoryid___nl-BE",
    path: "/nl-BE/category/:subcategoryname(.*)*-:subcategoryid()",
    component: () => import("/vercel/path0/pages/category/[...sub-category-name]-[sub-category-id].vue").then(m => m.default || m)
  },
  {
    name: "checkout-fallback___nl-NL___default",
    path: "/checkout/fallback",
    component: () => import("/vercel/path0/pages/checkout/fallback.vue").then(m => m.default || m)
  },
  {
    name: "checkout-fallback___nl-NL",
    path: "/nl-NL/checkout/fallback",
    component: () => import("/vercel/path0/pages/checkout/fallback.vue").then(m => m.default || m)
  },
  {
    name: "checkout-fallback___en-US",
    path: "/en-US/checkout/fallback",
    component: () => import("/vercel/path0/pages/checkout/fallback.vue").then(m => m.default || m)
  },
  {
    name: "checkout-fallback___fr-FR",
    path: "/fr-FR/checkout/fallback",
    component: () => import("/vercel/path0/pages/checkout/fallback.vue").then(m => m.default || m)
  },
  {
    name: "checkout-fallback___nl-BE",
    path: "/nl-BE/checkout/fallback",
    component: () => import("/vercel/path0/pages/checkout/fallback.vue").then(m => m.default || m)
  },
  {
    name: "checkout___nl-NL___default",
    path: "/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___nl-NL",
    path: "/nl-NL/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___en-US",
    path: "/en-US/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___fr-FR",
    path: "/fr-FR/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___nl-BE",
    path: "/nl-BE/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation-slug___nl-NL___default",
    path: "/checkout/order-confirmation/:slug()",
    meta: _91slug_93VXahw1BA7rMeta || {},
    component: () => import("/vercel/path0/pages/checkout/order-confirmation/[slug].vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation-slug___nl-NL",
    path: "/nl-NL/checkout/order-confirmation/:slug()",
    meta: _91slug_93VXahw1BA7rMeta || {},
    component: () => import("/vercel/path0/pages/checkout/order-confirmation/[slug].vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation-slug___en-US",
    path: "/en-US/checkout/order-confirmation/:slug()",
    meta: _91slug_93VXahw1BA7rMeta || {},
    component: () => import("/vercel/path0/pages/checkout/order-confirmation/[slug].vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation-slug___fr-FR",
    path: "/fr-FR/checkout/order-confirmation/:slug()",
    meta: _91slug_93VXahw1BA7rMeta || {},
    component: () => import("/vercel/path0/pages/checkout/order-confirmation/[slug].vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation-slug___nl-BE",
    path: "/nl-BE/checkout/order-confirmation/:slug()",
    meta: _91slug_93VXahw1BA7rMeta || {},
    component: () => import("/vercel/path0/pages/checkout/order-confirmation/[slug].vue").then(m => m.default || m)
  },
  {
    name: "colors___nl-NL___default",
    path: "/colors",
    component: () => import("/vercel/path0/pages/colors/index.vue").then(m => m.default || m)
  },
  {
    name: "colors___nl-NL",
    path: "/nl-NL/colors",
    component: () => import("/vercel/path0/pages/colors/index.vue").then(m => m.default || m)
  },
  {
    name: "colors___en-US",
    path: "/en-US/colors",
    component: () => import("/vercel/path0/pages/colors/index.vue").then(m => m.default || m)
  },
  {
    name: "colors___fr-FR",
    path: "/fr-FR/colors",
    component: () => import("/vercel/path0/pages/colors/index.vue").then(m => m.default || m)
  },
  {
    name: "colors___nl-BE",
    path: "/nl-BE/colors",
    component: () => import("/vercel/path0/pages/colors/index.vue").then(m => m.default || m)
  },
  {
    name: "demo___nl-NL___default",
    path: "/demo",
    component: () => import("/vercel/path0/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: "demo___nl-NL",
    path: "/nl-NL/demo",
    component: () => import("/vercel/path0/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: "demo___en-US",
    path: "/en-US/demo",
    component: () => import("/vercel/path0/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: "demo___fr-FR",
    path: "/fr-FR/demo",
    component: () => import("/vercel/path0/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: "demo___nl-BE",
    path: "/nl-BE/demo",
    component: () => import("/vercel/path0/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: "events-slug___nl-NL___default",
    path: "/events/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-slug___nl-NL",
    path: "/nl-NL/events/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-slug___en-US",
    path: "/en-US/events/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-slug___fr-FR",
    path: "/fr-FR/events/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-slug___nl-BE",
    path: "/nl-BE/events/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events___nl-NL___default",
    path: "/events",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___nl-NL",
    path: "/nl-NL/events",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___en-US",
    path: "/en-US/events",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___fr-FR",
    path: "/fr-FR/events",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events___nl-BE",
    path: "/nl-BE/events",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "events-offline-slug___nl-NL___default",
    path: "/events/offline/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/offline/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-offline-slug___nl-NL",
    path: "/nl-NL/events/offline/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/offline/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-offline-slug___en-US",
    path: "/en-US/events/offline/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/offline/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-offline-slug___fr-FR",
    path: "/fr-FR/events/offline/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/offline/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-offline-slug___nl-BE",
    path: "/nl-BE/events/offline/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/offline/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-offline___nl-NL___default",
    path: "/events/offline",
    component: () => import("/vercel/path0/pages/events/offline/index.vue").then(m => m.default || m)
  },
  {
    name: "events-offline___nl-NL",
    path: "/nl-NL/events/offline",
    component: () => import("/vercel/path0/pages/events/offline/index.vue").then(m => m.default || m)
  },
  {
    name: "events-offline___en-US",
    path: "/en-US/events/offline",
    component: () => import("/vercel/path0/pages/events/offline/index.vue").then(m => m.default || m)
  },
  {
    name: "events-offline___fr-FR",
    path: "/fr-FR/events/offline",
    component: () => import("/vercel/path0/pages/events/offline/index.vue").then(m => m.default || m)
  },
  {
    name: "events-offline___nl-BE",
    path: "/nl-BE/events/offline",
    component: () => import("/vercel/path0/pages/events/offline/index.vue").then(m => m.default || m)
  },
  {
    name: "events-online-slug___nl-NL___default",
    path: "/events/online/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/online/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-online-slug___nl-NL",
    path: "/nl-NL/events/online/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/online/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-online-slug___en-US",
    path: "/en-US/events/online/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/online/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-online-slug___fr-FR",
    path: "/fr-FR/events/online/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/online/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-online-slug___nl-BE",
    path: "/nl-BE/events/online/:slug(.*)*",
    component: () => import("/vercel/path0/pages/events/online/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-online___nl-NL___default",
    path: "/events/online",
    component: () => import("/vercel/path0/pages/events/online/index.vue").then(m => m.default || m)
  },
  {
    name: "events-online___nl-NL",
    path: "/nl-NL/events/online",
    component: () => import("/vercel/path0/pages/events/online/index.vue").then(m => m.default || m)
  },
  {
    name: "events-online___en-US",
    path: "/en-US/events/online",
    component: () => import("/vercel/path0/pages/events/online/index.vue").then(m => m.default || m)
  },
  {
    name: "events-online___fr-FR",
    path: "/fr-FR/events/online",
    component: () => import("/vercel/path0/pages/events/online/index.vue").then(m => m.default || m)
  },
  {
    name: "events-online___nl-BE",
    path: "/nl-BE/events/online",
    component: () => import("/vercel/path0/pages/events/online/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl-NL___default",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl-NL",
    path: "/nl-NL",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en-US",
    path: "/en-US",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr-FR",
    path: "/fr-FR",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl-BE",
    path: "/nl-BE",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "news-slug___nl-NL___default",
    path: "/news/:slug(.*)*",
    component: () => import("/vercel/path0/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "news-slug___nl-NL",
    path: "/nl-NL/news/:slug(.*)*",
    component: () => import("/vercel/path0/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "news-slug___en-US",
    path: "/en-US/news/:slug(.*)*",
    component: () => import("/vercel/path0/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "news-slug___fr-FR",
    path: "/fr-FR/news/:slug(.*)*",
    component: () => import("/vercel/path0/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "news-slug___nl-BE",
    path: "/nl-BE/news/:slug(.*)*",
    component: () => import("/vercel/path0/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "news___nl-NL___default",
    path: "/news",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___nl-NL",
    path: "/nl-NL/news",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___en-US",
    path: "/en-US/news",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___fr-FR",
    path: "/fr-FR/news",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news___nl-BE",
    path: "/nl-BE/news",
    component: () => import("/vercel/path0/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "news-search-slug___nl-NL___default",
    path: "/news/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/news/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "news-search-slug___nl-NL",
    path: "/nl-NL/news/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/news/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "news-search-slug___en-US",
    path: "/en-US/news/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/news/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "news-search-slug___fr-FR",
    path: "/fr-FR/news/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/news/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "news-search-slug___nl-BE",
    path: "/nl-BE/news/search/:slug(.*)*",
    component: () => import("/vercel/path0/pages/news/search/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token___nl-NL___default",
    path: "/password/reset/:token()",
    meta: _91token_93XzTxypJMaqMeta || {},
    component: () => import("/vercel/path0/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token___nl-NL",
    path: "/nl-NL/password/reset/:token()",
    meta: _91token_93XzTxypJMaqMeta || {},
    component: () => import("/vercel/path0/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token___en-US",
    path: "/en-US/password/reset/:token()",
    meta: _91token_93XzTxypJMaqMeta || {},
    component: () => import("/vercel/path0/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token___fr-FR",
    path: "/fr-FR/password/reset/:token()",
    meta: _91token_93XzTxypJMaqMeta || {},
    component: () => import("/vercel/path0/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token___nl-BE",
    path: "/nl-BE/password/reset/:token()",
    meta: _91token_93XzTxypJMaqMeta || {},
    component: () => import("/vercel/path0/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "payment-test-fallback___nl-NL___default",
    path: "/payment-test/fallback",
    component: () => import("/vercel/path0/pages/payment-test/fallback.vue").then(m => m.default || m)
  },
  {
    name: "payment-test-fallback___nl-NL",
    path: "/nl-NL/payment-test/fallback",
    component: () => import("/vercel/path0/pages/payment-test/fallback.vue").then(m => m.default || m)
  },
  {
    name: "payment-test-fallback___en-US",
    path: "/en-US/payment-test/fallback",
    component: () => import("/vercel/path0/pages/payment-test/fallback.vue").then(m => m.default || m)
  },
  {
    name: "payment-test-fallback___fr-FR",
    path: "/fr-FR/payment-test/fallback",
    component: () => import("/vercel/path0/pages/payment-test/fallback.vue").then(m => m.default || m)
  },
  {
    name: "payment-test-fallback___nl-BE",
    path: "/nl-BE/payment-test/fallback",
    component: () => import("/vercel/path0/pages/payment-test/fallback.vue").then(m => m.default || m)
  },
  {
    name: "payment-test___nl-NL___default",
    path: "/payment-test",
    component: () => import("/vercel/path0/pages/payment-test/index.vue").then(m => m.default || m)
  },
  {
    name: "payment-test___nl-NL",
    path: "/nl-NL/payment-test",
    component: () => import("/vercel/path0/pages/payment-test/index.vue").then(m => m.default || m)
  },
  {
    name: "payment-test___en-US",
    path: "/en-US/payment-test",
    component: () => import("/vercel/path0/pages/payment-test/index.vue").then(m => m.default || m)
  },
  {
    name: "payment-test___fr-FR",
    path: "/fr-FR/payment-test",
    component: () => import("/vercel/path0/pages/payment-test/index.vue").then(m => m.default || m)
  },
  {
    name: "payment-test___nl-BE",
    path: "/nl-BE/payment-test",
    component: () => import("/vercel/path0/pages/payment-test/index.vue").then(m => m.default || m)
  },
  {
    name: "payment-test-thank-you___nl-NL___default",
    path: "/payment-test/thank-you",
    meta: thank_45youuRncU3qwmnMeta || {},
    component: () => import("/vercel/path0/pages/payment-test/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "payment-test-thank-you___nl-NL",
    path: "/nl-NL/payment-test/thank-you",
    meta: thank_45youuRncU3qwmnMeta || {},
    component: () => import("/vercel/path0/pages/payment-test/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "payment-test-thank-you___en-US",
    path: "/en-US/payment-test/thank-you",
    meta: thank_45youuRncU3qwmnMeta || {},
    component: () => import("/vercel/path0/pages/payment-test/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "payment-test-thank-you___fr-FR",
    path: "/fr-FR/payment-test/thank-you",
    meta: thank_45youuRncU3qwmnMeta || {},
    component: () => import("/vercel/path0/pages/payment-test/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "payment-test-thank-you___nl-BE",
    path: "/nl-BE/payment-test/thank-you",
    meta: thank_45youuRncU3qwmnMeta || {},
    component: () => import("/vercel/path0/pages/payment-test/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "poc-deals___nl-NL___default",
    path: "/poc/deals",
    component: () => import("/vercel/path0/pages/poc/deals.vue").then(m => m.default || m)
  },
  {
    name: "poc-deals___nl-NL",
    path: "/nl-NL/poc/deals",
    component: () => import("/vercel/path0/pages/poc/deals.vue").then(m => m.default || m)
  },
  {
    name: "poc-deals___en-US",
    path: "/en-US/poc/deals",
    component: () => import("/vercel/path0/pages/poc/deals.vue").then(m => m.default || m)
  },
  {
    name: "poc-deals___fr-FR",
    path: "/fr-FR/poc/deals",
    component: () => import("/vercel/path0/pages/poc/deals.vue").then(m => m.default || m)
  },
  {
    name: "poc-deals___nl-BE",
    path: "/nl-BE/poc/deals",
    component: () => import("/vercel/path0/pages/poc/deals.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-authorized-person___nl-NL___default",
    path: "/pro-card/application/authorized-person",
    component: () => import("/vercel/path0/pages/pro-card/application/authorized-person.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-authorized-person___nl-NL",
    path: "/nl-NL/pro-card/application/authorized-person",
    component: () => import("/vercel/path0/pages/pro-card/application/authorized-person.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-authorized-person___en-US",
    path: "/en-US/pro-card/application/authorized-person",
    component: () => import("/vercel/path0/pages/pro-card/application/authorized-person.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-authorized-person___fr-FR",
    path: "/fr-FR/pro-card/application/authorized-person",
    component: () => import("/vercel/path0/pages/pro-card/application/authorized-person.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-authorized-person___nl-BE",
    path: "/nl-BE/pro-card/application/authorized-person",
    component: () => import("/vercel/path0/pages/pro-card/application/authorized-person.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-bank-details___nl-NL___default",
    path: "/pro-card/application/bank-details",
    component: () => import("/vercel/path0/pages/pro-card/application/bank-details.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-bank-details___nl-NL",
    path: "/nl-NL/pro-card/application/bank-details",
    component: () => import("/vercel/path0/pages/pro-card/application/bank-details.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-bank-details___en-US",
    path: "/en-US/pro-card/application/bank-details",
    component: () => import("/vercel/path0/pages/pro-card/application/bank-details.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-bank-details___fr-FR",
    path: "/fr-FR/pro-card/application/bank-details",
    component: () => import("/vercel/path0/pages/pro-card/application/bank-details.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-bank-details___nl-BE",
    path: "/nl-BE/pro-card/application/bank-details",
    component: () => import("/vercel/path0/pages/pro-card/application/bank-details.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-company-details___nl-NL___default",
    path: "/pro-card/application/company-details",
    component: () => import("/vercel/path0/pages/pro-card/application/company-details.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-company-details___nl-NL",
    path: "/nl-NL/pro-card/application/company-details",
    component: () => import("/vercel/path0/pages/pro-card/application/company-details.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-company-details___en-US",
    path: "/en-US/pro-card/application/company-details",
    component: () => import("/vercel/path0/pages/pro-card/application/company-details.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-company-details___fr-FR",
    path: "/fr-FR/pro-card/application/company-details",
    component: () => import("/vercel/path0/pages/pro-card/application/company-details.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-company-details___nl-BE",
    path: "/nl-BE/pro-card/application/company-details",
    component: () => import("/vercel/path0/pages/pro-card/application/company-details.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-company-list___nl-NL___default",
    path: "/pro-card/application/company-list",
    component: () => import("/vercel/path0/pages/pro-card/application/company-list.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-company-list___nl-NL",
    path: "/nl-NL/pro-card/application/company-list",
    component: () => import("/vercel/path0/pages/pro-card/application/company-list.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-company-list___en-US",
    path: "/en-US/pro-card/application/company-list",
    component: () => import("/vercel/path0/pages/pro-card/application/company-list.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-company-list___fr-FR",
    path: "/fr-FR/pro-card/application/company-list",
    component: () => import("/vercel/path0/pages/pro-card/application/company-list.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-company-list___nl-BE",
    path: "/nl-BE/pro-card/application/company-list",
    component: () => import("/vercel/path0/pages/pro-card/application/company-list.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-complete___nl-NL___default",
    path: "/pro-card/application/complete",
    component: () => import("/vercel/path0/pages/pro-card/application/complete.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-complete___nl-NL",
    path: "/nl-NL/pro-card/application/complete",
    component: () => import("/vercel/path0/pages/pro-card/application/complete.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-complete___en-US",
    path: "/en-US/pro-card/application/complete",
    component: () => import("/vercel/path0/pages/pro-card/application/complete.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-complete___fr-FR",
    path: "/fr-FR/pro-card/application/complete",
    component: () => import("/vercel/path0/pages/pro-card/application/complete.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-complete___nl-BE",
    path: "/nl-BE/pro-card/application/complete",
    component: () => import("/vercel/path0/pages/pro-card/application/complete.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application___nl-NL___default",
    path: "/pro-card/application",
    component: () => import("/vercel/path0/pages/pro-card/application/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application___nl-NL",
    path: "/nl-NL/pro-card/application",
    component: () => import("/vercel/path0/pages/pro-card/application/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application___en-US",
    path: "/en-US/pro-card/application",
    component: () => import("/vercel/path0/pages/pro-card/application/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application___fr-FR",
    path: "/fr-FR/pro-card/application",
    component: () => import("/vercel/path0/pages/pro-card/application/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application___nl-BE",
    path: "/nl-BE/pro-card/application",
    component: () => import("/vercel/path0/pages/pro-card/application/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-summary___nl-NL___default",
    path: "/pro-card/application/summary",
    component: () => import("/vercel/path0/pages/pro-card/application/summary.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-summary___nl-NL",
    path: "/nl-NL/pro-card/application/summary",
    component: () => import("/vercel/path0/pages/pro-card/application/summary.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-summary___en-US",
    path: "/en-US/pro-card/application/summary",
    component: () => import("/vercel/path0/pages/pro-card/application/summary.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-summary___fr-FR",
    path: "/fr-FR/pro-card/application/summary",
    component: () => import("/vercel/path0/pages/pro-card/application/summary.vue").then(m => m.default || m)
  },
  {
    name: "pro-card-application-summary___nl-BE",
    path: "/nl-BE/pro-card/application/summary",
    component: () => import("/vercel/path0/pages/pro-card/application/summary.vue").then(m => m.default || m)
  },
  {
    name: "pro-card___nl-NL___default",
    path: "/pro-card",
    component: () => import("/vercel/path0/pages/pro-card/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-card___nl-NL",
    path: "/nl-NL/pro-card",
    component: () => import("/vercel/path0/pages/pro-card/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-card___en-US",
    path: "/en-US/pro-card",
    component: () => import("/vercel/path0/pages/pro-card/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-card___fr-FR",
    path: "/fr-FR/pro-card",
    component: () => import("/vercel/path0/pages/pro-card/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-card___nl-BE",
    path: "/nl-BE/pro-card",
    component: () => import("/vercel/path0/pages/pro-card/index.vue").then(m => m.default || m)
  },
  {
    name: "product-slug___nl-NL___default",
    path: "/product/:slug()",
    meta: _91slug_933KQDXcbrccMeta || {},
    component: () => import("/vercel/path0/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: "product-slug___nl-NL",
    path: "/nl-NL/product/:slug()",
    meta: _91slug_933KQDXcbrccMeta || {},
    component: () => import("/vercel/path0/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: "product-slug___en-US",
    path: "/en-US/product/:slug()",
    meta: _91slug_933KQDXcbrccMeta || {},
    component: () => import("/vercel/path0/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: "product-slug___fr-FR",
    path: "/fr-FR/product/:slug()",
    meta: _91slug_933KQDXcbrccMeta || {},
    component: () => import("/vercel/path0/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: "product-slug___nl-BE",
    path: "/nl-BE/product/:slug()",
    meta: _91slug_933KQDXcbrccMeta || {},
    component: () => import("/vercel/path0/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: "product-product-review-slug___nl-NL___default",
    path: "/product/product-review/:slug()",
    component: () => import("/vercel/path0/pages/product/product-review/[slug].vue").then(m => m.default || m)
  },
  {
    name: "product-product-review-slug___nl-NL",
    path: "/nl-NL/product/product-review/:slug()",
    component: () => import("/vercel/path0/pages/product/product-review/[slug].vue").then(m => m.default || m)
  },
  {
    name: "product-product-review-slug___en-US",
    path: "/en-US/product/product-review/:slug()",
    component: () => import("/vercel/path0/pages/product/product-review/[slug].vue").then(m => m.default || m)
  },
  {
    name: "product-product-review-slug___fr-FR",
    path: "/fr-FR/product/product-review/:slug()",
    component: () => import("/vercel/path0/pages/product/product-review/[slug].vue").then(m => m.default || m)
  },
  {
    name: "product-product-review-slug___nl-BE",
    path: "/nl-BE/product/product-review/:slug()",
    component: () => import("/vercel/path0/pages/product/product-review/[slug].vue").then(m => m.default || m)
  },
  {
    name: "protected___nl-NL___default",
    path: "/protected",
    meta: protectedxzt8pfNqY7Meta || {},
    component: () => import("/vercel/path0/pages/protected.vue").then(m => m.default || m)
  },
  {
    name: "protected___nl-NL",
    path: "/nl-NL/protected",
    meta: protectedxzt8pfNqY7Meta || {},
    component: () => import("/vercel/path0/pages/protected.vue").then(m => m.default || m)
  },
  {
    name: "protected___en-US",
    path: "/en-US/protected",
    meta: protectedxzt8pfNqY7Meta || {},
    component: () => import("/vercel/path0/pages/protected.vue").then(m => m.default || m)
  },
  {
    name: "protected___fr-FR",
    path: "/fr-FR/protected",
    meta: protectedxzt8pfNqY7Meta || {},
    component: () => import("/vercel/path0/pages/protected.vue").then(m => m.default || m)
  },
  {
    name: "protected___nl-BE",
    path: "/nl-BE/protected",
    meta: protectedxzt8pfNqY7Meta || {},
    component: () => import("/vercel/path0/pages/protected.vue").then(m => m.default || m)
  },
  {
    name: "samples-404___nl-NL___default",
    path: "/samples/404",
    component: () => import("/vercel/path0/pages/samples/404.vue").then(m => m.default || m)
  },
  {
    name: "samples-404___nl-NL",
    path: "/nl-NL/samples/404",
    component: () => import("/vercel/path0/pages/samples/404.vue").then(m => m.default || m)
  },
  {
    name: "samples-404___en-US",
    path: "/en-US/samples/404",
    component: () => import("/vercel/path0/pages/samples/404.vue").then(m => m.default || m)
  },
  {
    name: "samples-404___fr-FR",
    path: "/fr-FR/samples/404",
    component: () => import("/vercel/path0/pages/samples/404.vue").then(m => m.default || m)
  },
  {
    name: "samples-404___nl-BE",
    path: "/nl-BE/samples/404",
    component: () => import("/vercel/path0/pages/samples/404.vue").then(m => m.default || m)
  },
  {
    name: "samples-500___nl-NL___default",
    path: "/samples/500",
    component: () => import("/vercel/path0/pages/samples/500.vue").then(m => m.default || m)
  },
  {
    name: "samples-500___nl-NL",
    path: "/nl-NL/samples/500",
    component: () => import("/vercel/path0/pages/samples/500.vue").then(m => m.default || m)
  },
  {
    name: "samples-500___en-US",
    path: "/en-US/samples/500",
    component: () => import("/vercel/path0/pages/samples/500.vue").then(m => m.default || m)
  },
  {
    name: "samples-500___fr-FR",
    path: "/fr-FR/samples/500",
    component: () => import("/vercel/path0/pages/samples/500.vue").then(m => m.default || m)
  },
  {
    name: "samples-500___nl-BE",
    path: "/nl-BE/samples/500",
    component: () => import("/vercel/path0/pages/samples/500.vue").then(m => m.default || m)
  },
  {
    name: "samples-about-us___nl-NL___default",
    path: "/samples/about-us",
    component: () => import("/vercel/path0/pages/samples/about-us.vue").then(m => m.default || m)
  },
  {
    name: "samples-about-us___nl-NL",
    path: "/nl-NL/samples/about-us",
    component: () => import("/vercel/path0/pages/samples/about-us.vue").then(m => m.default || m)
  },
  {
    name: "samples-about-us___en-US",
    path: "/en-US/samples/about-us",
    component: () => import("/vercel/path0/pages/samples/about-us.vue").then(m => m.default || m)
  },
  {
    name: "samples-about-us___fr-FR",
    path: "/fr-FR/samples/about-us",
    component: () => import("/vercel/path0/pages/samples/about-us.vue").then(m => m.default || m)
  },
  {
    name: "samples-about-us___nl-BE",
    path: "/nl-BE/samples/about-us",
    component: () => import("/vercel/path0/pages/samples/about-us.vue").then(m => m.default || m)
  },
  {
    name: "samples-account-preferences___nl-NL___default",
    path: "/samples/account-preferences",
    component: () => import("/vercel/path0/pages/samples/account-preferences.vue").then(m => m.default || m)
  },
  {
    name: "samples-account-preferences___nl-NL",
    path: "/nl-NL/samples/account-preferences",
    component: () => import("/vercel/path0/pages/samples/account-preferences.vue").then(m => m.default || m)
  },
  {
    name: "samples-account-preferences___en-US",
    path: "/en-US/samples/account-preferences",
    component: () => import("/vercel/path0/pages/samples/account-preferences.vue").then(m => m.default || m)
  },
  {
    name: "samples-account-preferences___fr-FR",
    path: "/fr-FR/samples/account-preferences",
    component: () => import("/vercel/path0/pages/samples/account-preferences.vue").then(m => m.default || m)
  },
  {
    name: "samples-account-preferences___nl-BE",
    path: "/nl-BE/samples/account-preferences",
    component: () => import("/vercel/path0/pages/samples/account-preferences.vue").then(m => m.default || m)
  },
  {
    name: "samples-account___nl-NL___default",
    path: "/samples/account",
    meta: accountfOAwmdnlWEMeta || {},
    component: () => import("/vercel/path0/pages/samples/account.vue").then(m => m.default || m)
  },
  {
    name: "samples-account___nl-NL",
    path: "/nl-NL/samples/account",
    meta: accountfOAwmdnlWEMeta || {},
    component: () => import("/vercel/path0/pages/samples/account.vue").then(m => m.default || m)
  },
  {
    name: "samples-account___en-US",
    path: "/en-US/samples/account",
    meta: accountfOAwmdnlWEMeta || {},
    component: () => import("/vercel/path0/pages/samples/account.vue").then(m => m.default || m)
  },
  {
    name: "samples-account___fr-FR",
    path: "/fr-FR/samples/account",
    meta: accountfOAwmdnlWEMeta || {},
    component: () => import("/vercel/path0/pages/samples/account.vue").then(m => m.default || m)
  },
  {
    name: "samples-account___nl-BE",
    path: "/nl-BE/samples/account",
    meta: accountfOAwmdnlWEMeta || {},
    component: () => import("/vercel/path0/pages/samples/account.vue").then(m => m.default || m)
  },
  {
    name: "samples-addresses___nl-NL___default",
    path: "/samples/addresses",
    meta: addresses0Jxsjf9FRtMeta || {},
    component: () => import("/vercel/path0/pages/samples/addresses.vue").then(m => m.default || m)
  },
  {
    name: "samples-addresses___nl-NL",
    path: "/nl-NL/samples/addresses",
    meta: addresses0Jxsjf9FRtMeta || {},
    component: () => import("/vercel/path0/pages/samples/addresses.vue").then(m => m.default || m)
  },
  {
    name: "samples-addresses___en-US",
    path: "/en-US/samples/addresses",
    meta: addresses0Jxsjf9FRtMeta || {},
    component: () => import("/vercel/path0/pages/samples/addresses.vue").then(m => m.default || m)
  },
  {
    name: "samples-addresses___fr-FR",
    path: "/fr-FR/samples/addresses",
    meta: addresses0Jxsjf9FRtMeta || {},
    component: () => import("/vercel/path0/pages/samples/addresses.vue").then(m => m.default || m)
  },
  {
    name: "samples-addresses___nl-BE",
    path: "/nl-BE/samples/addresses",
    meta: addresses0Jxsjf9FRtMeta || {},
    component: () => import("/vercel/path0/pages/samples/addresses.vue").then(m => m.default || m)
  },
  {
    name: "samples-all-brands___nl-NL___default",
    path: "/samples/all-brands",
    component: () => import("/vercel/path0/pages/samples/all-brands.vue").then(m => m.default || m)
  },
  {
    name: "samples-all-brands___nl-NL",
    path: "/nl-NL/samples/all-brands",
    component: () => import("/vercel/path0/pages/samples/all-brands.vue").then(m => m.default || m)
  },
  {
    name: "samples-all-brands___en-US",
    path: "/en-US/samples/all-brands",
    component: () => import("/vercel/path0/pages/samples/all-brands.vue").then(m => m.default || m)
  },
  {
    name: "samples-all-brands___fr-FR",
    path: "/fr-FR/samples/all-brands",
    component: () => import("/vercel/path0/pages/samples/all-brands.vue").then(m => m.default || m)
  },
  {
    name: "samples-all-brands___nl-BE",
    path: "/nl-BE/samples/all-brands",
    component: () => import("/vercel/path0/pages/samples/all-brands.vue").then(m => m.default || m)
  },
  {
    name: "samples-benifits-terms-conditions___nl-NL___default",
    path: "/samples/benifits-terms-conditions",
    component: () => import("/vercel/path0/pages/samples/benifits-terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "samples-benifits-terms-conditions___nl-NL",
    path: "/nl-NL/samples/benifits-terms-conditions",
    component: () => import("/vercel/path0/pages/samples/benifits-terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "samples-benifits-terms-conditions___en-US",
    path: "/en-US/samples/benifits-terms-conditions",
    component: () => import("/vercel/path0/pages/samples/benifits-terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "samples-benifits-terms-conditions___fr-FR",
    path: "/fr-FR/samples/benifits-terms-conditions",
    component: () => import("/vercel/path0/pages/samples/benifits-terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "samples-benifits-terms-conditions___nl-BE",
    path: "/nl-BE/samples/benifits-terms-conditions",
    component: () => import("/vercel/path0/pages/samples/benifits-terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "samples-benifits___nl-NL___default",
    path: "/samples/benifits",
    component: () => import("/vercel/path0/pages/samples/benifits.vue").then(m => m.default || m)
  },
  {
    name: "samples-benifits___nl-NL",
    path: "/nl-NL/samples/benifits",
    component: () => import("/vercel/path0/pages/samples/benifits.vue").then(m => m.default || m)
  },
  {
    name: "samples-benifits___en-US",
    path: "/en-US/samples/benifits",
    component: () => import("/vercel/path0/pages/samples/benifits.vue").then(m => m.default || m)
  },
  {
    name: "samples-benifits___fr-FR",
    path: "/fr-FR/samples/benifits",
    component: () => import("/vercel/path0/pages/samples/benifits.vue").then(m => m.default || m)
  },
  {
    name: "samples-benifits___nl-BE",
    path: "/nl-BE/samples/benifits",
    component: () => import("/vercel/path0/pages/samples/benifits.vue").then(m => m.default || m)
  },
  {
    name: "samples-blogs-news-and-advice___nl-NL___default",
    path: "/samples/blogs-news-and-advice",
    component: () => import("/vercel/path0/pages/samples/blogs-news-and-advice.vue").then(m => m.default || m)
  },
  {
    name: "samples-blogs-news-and-advice___nl-NL",
    path: "/nl-NL/samples/blogs-news-and-advice",
    component: () => import("/vercel/path0/pages/samples/blogs-news-and-advice.vue").then(m => m.default || m)
  },
  {
    name: "samples-blogs-news-and-advice___en-US",
    path: "/en-US/samples/blogs-news-and-advice",
    component: () => import("/vercel/path0/pages/samples/blogs-news-and-advice.vue").then(m => m.default || m)
  },
  {
    name: "samples-blogs-news-and-advice___fr-FR",
    path: "/fr-FR/samples/blogs-news-and-advice",
    component: () => import("/vercel/path0/pages/samples/blogs-news-and-advice.vue").then(m => m.default || m)
  },
  {
    name: "samples-blogs-news-and-advice___nl-BE",
    path: "/nl-BE/samples/blogs-news-and-advice",
    component: () => import("/vercel/path0/pages/samples/blogs-news-and-advice.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-deals___nl-NL___default",
    path: "/samples/brand-deals",
    component: () => import("/vercel/path0/pages/samples/brand-deals.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-deals___nl-NL",
    path: "/nl-NL/samples/brand-deals",
    component: () => import("/vercel/path0/pages/samples/brand-deals.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-deals___en-US",
    path: "/en-US/samples/brand-deals",
    component: () => import("/vercel/path0/pages/samples/brand-deals.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-deals___fr-FR",
    path: "/fr-FR/samples/brand-deals",
    component: () => import("/vercel/path0/pages/samples/brand-deals.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-deals___nl-BE",
    path: "/nl-BE/samples/brand-deals",
    component: () => import("/vercel/path0/pages/samples/brand-deals.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-details___nl-NL___default",
    path: "/samples/brand-details",
    component: () => import("/vercel/path0/pages/samples/brand-details.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-details___nl-NL",
    path: "/nl-NL/samples/brand-details",
    component: () => import("/vercel/path0/pages/samples/brand-details.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-details___en-US",
    path: "/en-US/samples/brand-details",
    component: () => import("/vercel/path0/pages/samples/brand-details.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-details___fr-FR",
    path: "/fr-FR/samples/brand-details",
    component: () => import("/vercel/path0/pages/samples/brand-details.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-details___nl-BE",
    path: "/nl-BE/samples/brand-details",
    component: () => import("/vercel/path0/pages/samples/brand-details.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-listing___nl-NL___default",
    path: "/samples/brand-listing",
    component: () => import("/vercel/path0/pages/samples/brand-listing.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-listing___nl-NL",
    path: "/nl-NL/samples/brand-listing",
    component: () => import("/vercel/path0/pages/samples/brand-listing.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-listing___en-US",
    path: "/en-US/samples/brand-listing",
    component: () => import("/vercel/path0/pages/samples/brand-listing.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-listing___fr-FR",
    path: "/fr-FR/samples/brand-listing",
    component: () => import("/vercel/path0/pages/samples/brand-listing.vue").then(m => m.default || m)
  },
  {
    name: "samples-brand-listing___nl-BE",
    path: "/nl-BE/samples/brand-listing",
    component: () => import("/vercel/path0/pages/samples/brand-listing.vue").then(m => m.default || m)
  },
  {
    name: "samples-buy-now-demo___nl-NL___default",
    path: "/samples/buy-now-demo",
    component: () => import("/vercel/path0/pages/samples/buy-now-demo.vue").then(m => m.default || m)
  },
  {
    name: "samples-buy-now-demo___nl-NL",
    path: "/nl-NL/samples/buy-now-demo",
    component: () => import("/vercel/path0/pages/samples/buy-now-demo.vue").then(m => m.default || m)
  },
  {
    name: "samples-buy-now-demo___en-US",
    path: "/en-US/samples/buy-now-demo",
    component: () => import("/vercel/path0/pages/samples/buy-now-demo.vue").then(m => m.default || m)
  },
  {
    name: "samples-buy-now-demo___fr-FR",
    path: "/fr-FR/samples/buy-now-demo",
    component: () => import("/vercel/path0/pages/samples/buy-now-demo.vue").then(m => m.default || m)
  },
  {
    name: "samples-buy-now-demo___nl-BE",
    path: "/nl-BE/samples/buy-now-demo",
    component: () => import("/vercel/path0/pages/samples/buy-now-demo.vue").then(m => m.default || m)
  },
  {
    name: "samples-carousels___nl-NL___default",
    path: "/samples/carousels",
    component: () => import("/vercel/path0/pages/samples/carousels.vue").then(m => m.default || m)
  },
  {
    name: "samples-carousels___nl-NL",
    path: "/nl-NL/samples/carousels",
    component: () => import("/vercel/path0/pages/samples/carousels.vue").then(m => m.default || m)
  },
  {
    name: "samples-carousels___en-US",
    path: "/en-US/samples/carousels",
    component: () => import("/vercel/path0/pages/samples/carousels.vue").then(m => m.default || m)
  },
  {
    name: "samples-carousels___fr-FR",
    path: "/fr-FR/samples/carousels",
    component: () => import("/vercel/path0/pages/samples/carousels.vue").then(m => m.default || m)
  },
  {
    name: "samples-carousels___nl-BE",
    path: "/nl-BE/samples/carousels",
    component: () => import("/vercel/path0/pages/samples/carousels.vue").then(m => m.default || m)
  },
  {
    name: "samples-catalogue___nl-NL___default",
    path: "/samples/catalogue",
    meta: catalogueLqtsj9FTQMMeta || {},
    component: () => import("/vercel/path0/pages/samples/catalogue.vue").then(m => m.default || m)
  },
  {
    name: "samples-catalogue___nl-NL",
    path: "/nl-NL/samples/catalogue",
    meta: catalogueLqtsj9FTQMMeta || {},
    component: () => import("/vercel/path0/pages/samples/catalogue.vue").then(m => m.default || m)
  },
  {
    name: "samples-catalogue___en-US",
    path: "/en-US/samples/catalogue",
    meta: catalogueLqtsj9FTQMMeta || {},
    component: () => import("/vercel/path0/pages/samples/catalogue.vue").then(m => m.default || m)
  },
  {
    name: "samples-catalogue___fr-FR",
    path: "/fr-FR/samples/catalogue",
    meta: catalogueLqtsj9FTQMMeta || {},
    component: () => import("/vercel/path0/pages/samples/catalogue.vue").then(m => m.default || m)
  },
  {
    name: "samples-catalogue___nl-BE",
    path: "/nl-BE/samples/catalogue",
    meta: catalogueLqtsj9FTQMMeta || {},
    component: () => import("/vercel/path0/pages/samples/catalogue.vue").then(m => m.default || m)
  },
  {
    name: "samples-categories___nl-NL___default",
    path: "/samples/categories",
    component: () => import("/vercel/path0/pages/samples/categories.vue").then(m => m.default || m)
  },
  {
    name: "samples-categories___nl-NL",
    path: "/nl-NL/samples/categories",
    component: () => import("/vercel/path0/pages/samples/categories.vue").then(m => m.default || m)
  },
  {
    name: "samples-categories___en-US",
    path: "/en-US/samples/categories",
    component: () => import("/vercel/path0/pages/samples/categories.vue").then(m => m.default || m)
  },
  {
    name: "samples-categories___fr-FR",
    path: "/fr-FR/samples/categories",
    component: () => import("/vercel/path0/pages/samples/categories.vue").then(m => m.default || m)
  },
  {
    name: "samples-categories___nl-BE",
    path: "/nl-BE/samples/categories",
    component: () => import("/vercel/path0/pages/samples/categories.vue").then(m => m.default || m)
  },
  {
    name: "samples-checkout___nl-NL___default",
    path: "/samples/checkout",
    meta: checkoutf4pAzQDMMxMeta || {},
    component: () => import("/vercel/path0/pages/samples/checkout.vue").then(m => m.default || m)
  },
  {
    name: "samples-checkout___nl-NL",
    path: "/nl-NL/samples/checkout",
    meta: checkoutf4pAzQDMMxMeta || {},
    component: () => import("/vercel/path0/pages/samples/checkout.vue").then(m => m.default || m)
  },
  {
    name: "samples-checkout___en-US",
    path: "/en-US/samples/checkout",
    meta: checkoutf4pAzQDMMxMeta || {},
    component: () => import("/vercel/path0/pages/samples/checkout.vue").then(m => m.default || m)
  },
  {
    name: "samples-checkout___fr-FR",
    path: "/fr-FR/samples/checkout",
    meta: checkoutf4pAzQDMMxMeta || {},
    component: () => import("/vercel/path0/pages/samples/checkout.vue").then(m => m.default || m)
  },
  {
    name: "samples-checkout___nl-BE",
    path: "/nl-BE/samples/checkout",
    meta: checkoutf4pAzQDMMxMeta || {},
    component: () => import("/vercel/path0/pages/samples/checkout.vue").then(m => m.default || m)
  },
  {
    name: "samples-click-and-collect___nl-NL___default",
    path: "/samples/click-and-collect",
    component: () => import("/vercel/path0/pages/samples/click-and-collect.vue").then(m => m.default || m)
  },
  {
    name: "samples-click-and-collect___nl-NL",
    path: "/nl-NL/samples/click-and-collect",
    component: () => import("/vercel/path0/pages/samples/click-and-collect.vue").then(m => m.default || m)
  },
  {
    name: "samples-click-and-collect___en-US",
    path: "/en-US/samples/click-and-collect",
    component: () => import("/vercel/path0/pages/samples/click-and-collect.vue").then(m => m.default || m)
  },
  {
    name: "samples-click-and-collect___fr-FR",
    path: "/fr-FR/samples/click-and-collect",
    component: () => import("/vercel/path0/pages/samples/click-and-collect.vue").then(m => m.default || m)
  },
  {
    name: "samples-click-and-collect___nl-BE",
    path: "/nl-BE/samples/click-and-collect",
    component: () => import("/vercel/path0/pages/samples/click-and-collect.vue").then(m => m.default || m)
  },
  {
    name: "samples-contact-us___nl-NL___default",
    path: "/samples/contact-us",
    component: () => import("/vercel/path0/pages/samples/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "samples-contact-us___nl-NL",
    path: "/nl-NL/samples/contact-us",
    component: () => import("/vercel/path0/pages/samples/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "samples-contact-us___en-US",
    path: "/en-US/samples/contact-us",
    component: () => import("/vercel/path0/pages/samples/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "samples-contact-us___fr-FR",
    path: "/fr-FR/samples/contact-us",
    component: () => import("/vercel/path0/pages/samples/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "samples-contact-us___nl-BE",
    path: "/nl-BE/samples/contact-us",
    component: () => import("/vercel/path0/pages/samples/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "samples-cookie-policy___nl-NL___default",
    path: "/samples/cookie-policy",
    component: () => import("/vercel/path0/pages/samples/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "samples-cookie-policy___nl-NL",
    path: "/nl-NL/samples/cookie-policy",
    component: () => import("/vercel/path0/pages/samples/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "samples-cookie-policy___en-US",
    path: "/en-US/samples/cookie-policy",
    component: () => import("/vercel/path0/pages/samples/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "samples-cookie-policy___fr-FR",
    path: "/fr-FR/samples/cookie-policy",
    component: () => import("/vercel/path0/pages/samples/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "samples-cookie-policy___nl-BE",
    path: "/nl-BE/samples/cookie-policy",
    component: () => import("/vercel/path0/pages/samples/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "samples-cookie-pop-up-modal___nl-NL___default",
    path: "/samples/cookie-pop-up-modal",
    component: () => import("/vercel/path0/pages/samples/cookie-pop-up-modal.vue").then(m => m.default || m)
  },
  {
    name: "samples-cookie-pop-up-modal___nl-NL",
    path: "/nl-NL/samples/cookie-pop-up-modal",
    component: () => import("/vercel/path0/pages/samples/cookie-pop-up-modal.vue").then(m => m.default || m)
  },
  {
    name: "samples-cookie-pop-up-modal___en-US",
    path: "/en-US/samples/cookie-pop-up-modal",
    component: () => import("/vercel/path0/pages/samples/cookie-pop-up-modal.vue").then(m => m.default || m)
  },
  {
    name: "samples-cookie-pop-up-modal___fr-FR",
    path: "/fr-FR/samples/cookie-pop-up-modal",
    component: () => import("/vercel/path0/pages/samples/cookie-pop-up-modal.vue").then(m => m.default || m)
  },
  {
    name: "samples-cookie-pop-up-modal___nl-BE",
    path: "/nl-BE/samples/cookie-pop-up-modal",
    component: () => import("/vercel/path0/pages/samples/cookie-pop-up-modal.vue").then(m => m.default || m)
  },
  {
    name: "samples-copyright___nl-NL___default",
    path: "/samples/copyright",
    component: () => import("/vercel/path0/pages/samples/copyright.vue").then(m => m.default || m)
  },
  {
    name: "samples-copyright___nl-NL",
    path: "/nl-NL/samples/copyright",
    component: () => import("/vercel/path0/pages/samples/copyright.vue").then(m => m.default || m)
  },
  {
    name: "samples-copyright___en-US",
    path: "/en-US/samples/copyright",
    component: () => import("/vercel/path0/pages/samples/copyright.vue").then(m => m.default || m)
  },
  {
    name: "samples-copyright___fr-FR",
    path: "/fr-FR/samples/copyright",
    component: () => import("/vercel/path0/pages/samples/copyright.vue").then(m => m.default || m)
  },
  {
    name: "samples-copyright___nl-BE",
    path: "/nl-BE/samples/copyright",
    component: () => import("/vercel/path0/pages/samples/copyright.vue").then(m => m.default || m)
  },
  {
    name: "samples-custom-banner___nl-NL___default",
    path: "/samples/custom-banner",
    component: () => import("/vercel/path0/pages/samples/custom-banner.vue").then(m => m.default || m)
  },
  {
    name: "samples-custom-banner___nl-NL",
    path: "/nl-NL/samples/custom-banner",
    component: () => import("/vercel/path0/pages/samples/custom-banner.vue").then(m => m.default || m)
  },
  {
    name: "samples-custom-banner___en-US",
    path: "/en-US/samples/custom-banner",
    component: () => import("/vercel/path0/pages/samples/custom-banner.vue").then(m => m.default || m)
  },
  {
    name: "samples-custom-banner___fr-FR",
    path: "/fr-FR/samples/custom-banner",
    component: () => import("/vercel/path0/pages/samples/custom-banner.vue").then(m => m.default || m)
  },
  {
    name: "samples-custom-banner___nl-BE",
    path: "/nl-BE/samples/custom-banner",
    component: () => import("/vercel/path0/pages/samples/custom-banner.vue").then(m => m.default || m)
  },
  {
    name: "samples-deals___nl-NL___default",
    path: "/samples/deals",
    component: () => import("/vercel/path0/pages/samples/deals.vue").then(m => m.default || m)
  },
  {
    name: "samples-deals___nl-NL",
    path: "/nl-NL/samples/deals",
    component: () => import("/vercel/path0/pages/samples/deals.vue").then(m => m.default || m)
  },
  {
    name: "samples-deals___en-US",
    path: "/en-US/samples/deals",
    component: () => import("/vercel/path0/pages/samples/deals.vue").then(m => m.default || m)
  },
  {
    name: "samples-deals___fr-FR",
    path: "/fr-FR/samples/deals",
    component: () => import("/vercel/path0/pages/samples/deals.vue").then(m => m.default || m)
  },
  {
    name: "samples-deals___nl-BE",
    path: "/nl-BE/samples/deals",
    component: () => import("/vercel/path0/pages/samples/deals.vue").then(m => m.default || m)
  },
  {
    name: "samples-default-branch___nl-NL___default",
    path: "/samples/default-branch",
    meta: default_45branchM5sXMPn6kgMeta || {},
    component: () => import("/vercel/path0/pages/samples/default-branch.vue").then(m => m.default || m)
  },
  {
    name: "samples-default-branch___nl-NL",
    path: "/nl-NL/samples/default-branch",
    meta: default_45branchM5sXMPn6kgMeta || {},
    component: () => import("/vercel/path0/pages/samples/default-branch.vue").then(m => m.default || m)
  },
  {
    name: "samples-default-branch___en-US",
    path: "/en-US/samples/default-branch",
    meta: default_45branchM5sXMPn6kgMeta || {},
    component: () => import("/vercel/path0/pages/samples/default-branch.vue").then(m => m.default || m)
  },
  {
    name: "samples-default-branch___fr-FR",
    path: "/fr-FR/samples/default-branch",
    meta: default_45branchM5sXMPn6kgMeta || {},
    component: () => import("/vercel/path0/pages/samples/default-branch.vue").then(m => m.default || m)
  },
  {
    name: "samples-default-branch___nl-BE",
    path: "/nl-BE/samples/default-branch",
    meta: default_45branchM5sXMPn6kgMeta || {},
    component: () => import("/vercel/path0/pages/samples/default-branch.vue").then(m => m.default || m)
  },
  {
    name: "samples-delivery___nl-NL___default",
    path: "/samples/delivery",
    component: () => import("/vercel/path0/pages/samples/delivery.vue").then(m => m.default || m)
  },
  {
    name: "samples-delivery___nl-NL",
    path: "/nl-NL/samples/delivery",
    component: () => import("/vercel/path0/pages/samples/delivery.vue").then(m => m.default || m)
  },
  {
    name: "samples-delivery___en-US",
    path: "/en-US/samples/delivery",
    component: () => import("/vercel/path0/pages/samples/delivery.vue").then(m => m.default || m)
  },
  {
    name: "samples-delivery___fr-FR",
    path: "/fr-FR/samples/delivery",
    component: () => import("/vercel/path0/pages/samples/delivery.vue").then(m => m.default || m)
  },
  {
    name: "samples-delivery___nl-BE",
    path: "/nl-BE/samples/delivery",
    component: () => import("/vercel/path0/pages/samples/delivery.vue").then(m => m.default || m)
  },
  {
    name: "samples-details___nl-NL___default",
    path: "/samples/details",
    component: () => import("/vercel/path0/pages/samples/details.vue").then(m => m.default || m)
  },
  {
    name: "samples-details___nl-NL",
    path: "/nl-NL/samples/details",
    component: () => import("/vercel/path0/pages/samples/details.vue").then(m => m.default || m)
  },
  {
    name: "samples-details___en-US",
    path: "/en-US/samples/details",
    component: () => import("/vercel/path0/pages/samples/details.vue").then(m => m.default || m)
  },
  {
    name: "samples-details___fr-FR",
    path: "/fr-FR/samples/details",
    component: () => import("/vercel/path0/pages/samples/details.vue").then(m => m.default || m)
  },
  {
    name: "samples-details___nl-BE",
    path: "/nl-BE/samples/details",
    component: () => import("/vercel/path0/pages/samples/details.vue").then(m => m.default || m)
  },
  {
    name: "samples-event-detail___nl-NL___default",
    path: "/samples/event-detail",
    component: () => import("/vercel/path0/pages/samples/event-detail.vue").then(m => m.default || m)
  },
  {
    name: "samples-event-detail___nl-NL",
    path: "/nl-NL/samples/event-detail",
    component: () => import("/vercel/path0/pages/samples/event-detail.vue").then(m => m.default || m)
  },
  {
    name: "samples-event-detail___en-US",
    path: "/en-US/samples/event-detail",
    component: () => import("/vercel/path0/pages/samples/event-detail.vue").then(m => m.default || m)
  },
  {
    name: "samples-event-detail___fr-FR",
    path: "/fr-FR/samples/event-detail",
    component: () => import("/vercel/path0/pages/samples/event-detail.vue").then(m => m.default || m)
  },
  {
    name: "samples-event-detail___nl-BE",
    path: "/nl-BE/samples/event-detail",
    component: () => import("/vercel/path0/pages/samples/event-detail.vue").then(m => m.default || m)
  },
  {
    name: "samples-event___nl-NL___default",
    path: "/samples/event",
    component: () => import("/vercel/path0/pages/samples/event.vue").then(m => m.default || m)
  },
  {
    name: "samples-event___nl-NL",
    path: "/nl-NL/samples/event",
    component: () => import("/vercel/path0/pages/samples/event.vue").then(m => m.default || m)
  },
  {
    name: "samples-event___en-US",
    path: "/en-US/samples/event",
    component: () => import("/vercel/path0/pages/samples/event.vue").then(m => m.default || m)
  },
  {
    name: "samples-event___fr-FR",
    path: "/fr-FR/samples/event",
    component: () => import("/vercel/path0/pages/samples/event.vue").then(m => m.default || m)
  },
  {
    name: "samples-event___nl-BE",
    path: "/nl-BE/samples/event",
    component: () => import("/vercel/path0/pages/samples/event.vue").then(m => m.default || m)
  },
  {
    name: "samples-faq___nl-NL___default",
    path: "/samples/faq",
    component: () => import("/vercel/path0/pages/samples/faq.vue").then(m => m.default || m)
  },
  {
    name: "samples-faq___nl-NL",
    path: "/nl-NL/samples/faq",
    component: () => import("/vercel/path0/pages/samples/faq.vue").then(m => m.default || m)
  },
  {
    name: "samples-faq___en-US",
    path: "/en-US/samples/faq",
    component: () => import("/vercel/path0/pages/samples/faq.vue").then(m => m.default || m)
  },
  {
    name: "samples-faq___fr-FR",
    path: "/fr-FR/samples/faq",
    component: () => import("/vercel/path0/pages/samples/faq.vue").then(m => m.default || m)
  },
  {
    name: "samples-faq___nl-BE",
    path: "/nl-BE/samples/faq",
    component: () => import("/vercel/path0/pages/samples/faq.vue").then(m => m.default || m)
  },
  {
    name: "samples-general-terms___nl-NL___default",
    path: "/samples/general-terms",
    component: () => import("/vercel/path0/pages/samples/general-terms.vue").then(m => m.default || m)
  },
  {
    name: "samples-general-terms___nl-NL",
    path: "/nl-NL/samples/general-terms",
    component: () => import("/vercel/path0/pages/samples/general-terms.vue").then(m => m.default || m)
  },
  {
    name: "samples-general-terms___en-US",
    path: "/en-US/samples/general-terms",
    component: () => import("/vercel/path0/pages/samples/general-terms.vue").then(m => m.default || m)
  },
  {
    name: "samples-general-terms___fr-FR",
    path: "/fr-FR/samples/general-terms",
    component: () => import("/vercel/path0/pages/samples/general-terms.vue").then(m => m.default || m)
  },
  {
    name: "samples-general-terms___nl-BE",
    path: "/nl-BE/samples/general-terms",
    component: () => import("/vercel/path0/pages/samples/general-terms.vue").then(m => m.default || m)
  },
  {
    name: "samples-guarantee___nl-NL___default",
    path: "/samples/guarantee",
    component: () => import("/vercel/path0/pages/samples/guarantee.vue").then(m => m.default || m)
  },
  {
    name: "samples-guarantee___nl-NL",
    path: "/nl-NL/samples/guarantee",
    component: () => import("/vercel/path0/pages/samples/guarantee.vue").then(m => m.default || m)
  },
  {
    name: "samples-guarantee___en-US",
    path: "/en-US/samples/guarantee",
    component: () => import("/vercel/path0/pages/samples/guarantee.vue").then(m => m.default || m)
  },
  {
    name: "samples-guarantee___fr-FR",
    path: "/fr-FR/samples/guarantee",
    component: () => import("/vercel/path0/pages/samples/guarantee.vue").then(m => m.default || m)
  },
  {
    name: "samples-guarantee___nl-BE",
    path: "/nl-BE/samples/guarantee",
    component: () => import("/vercel/path0/pages/samples/guarantee.vue").then(m => m.default || m)
  },
  {
    name: "samples-help___nl-NL___default",
    path: "/samples/help",
    component: () => import("/vercel/path0/pages/samples/help.vue").then(m => m.default || m)
  },
  {
    name: "samples-help___nl-NL",
    path: "/nl-NL/samples/help",
    component: () => import("/vercel/path0/pages/samples/help.vue").then(m => m.default || m)
  },
  {
    name: "samples-help___en-US",
    path: "/en-US/samples/help",
    component: () => import("/vercel/path0/pages/samples/help.vue").then(m => m.default || m)
  },
  {
    name: "samples-help___fr-FR",
    path: "/fr-FR/samples/help",
    component: () => import("/vercel/path0/pages/samples/help.vue").then(m => m.default || m)
  },
  {
    name: "samples-help___nl-BE",
    path: "/nl-BE/samples/help",
    component: () => import("/vercel/path0/pages/samples/help.vue").then(m => m.default || m)
  },
  {
    name: "samples-home___nl-NL___default",
    path: "/samples/home",
    meta: homep28teoC8reMeta || {},
    component: () => import("/vercel/path0/pages/samples/home.vue").then(m => m.default || m)
  },
  {
    name: "samples-home___nl-NL",
    path: "/nl-NL/samples/home",
    meta: homep28teoC8reMeta || {},
    component: () => import("/vercel/path0/pages/samples/home.vue").then(m => m.default || m)
  },
  {
    name: "samples-home___en-US",
    path: "/en-US/samples/home",
    meta: homep28teoC8reMeta || {},
    component: () => import("/vercel/path0/pages/samples/home.vue").then(m => m.default || m)
  },
  {
    name: "samples-home___fr-FR",
    path: "/fr-FR/samples/home",
    meta: homep28teoC8reMeta || {},
    component: () => import("/vercel/path0/pages/samples/home.vue").then(m => m.default || m)
  },
  {
    name: "samples-home___nl-BE",
    path: "/nl-BE/samples/home",
    meta: homep28teoC8reMeta || {},
    component: () => import("/vercel/path0/pages/samples/home.vue").then(m => m.default || m)
  },
  {
    name: "samples___nl-NL___default",
    path: "/samples",
    component: () => import("/vercel/path0/pages/samples/index.vue").then(m => m.default || m)
  },
  {
    name: "samples___nl-NL",
    path: "/nl-NL/samples",
    component: () => import("/vercel/path0/pages/samples/index.vue").then(m => m.default || m)
  },
  {
    name: "samples___en-US",
    path: "/en-US/samples",
    component: () => import("/vercel/path0/pages/samples/index.vue").then(m => m.default || m)
  },
  {
    name: "samples___fr-FR",
    path: "/fr-FR/samples",
    component: () => import("/vercel/path0/pages/samples/index.vue").then(m => m.default || m)
  },
  {
    name: "samples___nl-BE",
    path: "/nl-BE/samples",
    component: () => import("/vercel/path0/pages/samples/index.vue").then(m => m.default || m)
  },
  {
    name: "samples-legal-notice___nl-NL___default",
    path: "/samples/legal-notice",
    component: () => import("/vercel/path0/pages/samples/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "samples-legal-notice___nl-NL",
    path: "/nl-NL/samples/legal-notice",
    component: () => import("/vercel/path0/pages/samples/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "samples-legal-notice___en-US",
    path: "/en-US/samples/legal-notice",
    component: () => import("/vercel/path0/pages/samples/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "samples-legal-notice___fr-FR",
    path: "/fr-FR/samples/legal-notice",
    component: () => import("/vercel/path0/pages/samples/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "samples-legal-notice___nl-BE",
    path: "/nl-BE/samples/legal-notice",
    component: () => import("/vercel/path0/pages/samples/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "samples-message-center___nl-NL___default",
    path: "/samples/message-center",
    meta: message_45centerrIr7mYGvrNMeta || {},
    component: () => import("/vercel/path0/pages/samples/message-center.vue").then(m => m.default || m)
  },
  {
    name: "samples-message-center___nl-NL",
    path: "/nl-NL/samples/message-center",
    meta: message_45centerrIr7mYGvrNMeta || {},
    component: () => import("/vercel/path0/pages/samples/message-center.vue").then(m => m.default || m)
  },
  {
    name: "samples-message-center___en-US",
    path: "/en-US/samples/message-center",
    meta: message_45centerrIr7mYGvrNMeta || {},
    component: () => import("/vercel/path0/pages/samples/message-center.vue").then(m => m.default || m)
  },
  {
    name: "samples-message-center___fr-FR",
    path: "/fr-FR/samples/message-center",
    meta: message_45centerrIr7mYGvrNMeta || {},
    component: () => import("/vercel/path0/pages/samples/message-center.vue").then(m => m.default || m)
  },
  {
    name: "samples-message-center___nl-BE",
    path: "/nl-BE/samples/message-center",
    meta: message_45centerrIr7mYGvrNMeta || {},
    component: () => import("/vercel/path0/pages/samples/message-center.vue").then(m => m.default || m)
  },
  {
    name: "samples-modern-slavery-statement___nl-NL___default",
    path: "/samples/modern-slavery-statement",
    component: () => import("/vercel/path0/pages/samples/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: "samples-modern-slavery-statement___nl-NL",
    path: "/nl-NL/samples/modern-slavery-statement",
    component: () => import("/vercel/path0/pages/samples/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: "samples-modern-slavery-statement___en-US",
    path: "/en-US/samples/modern-slavery-statement",
    component: () => import("/vercel/path0/pages/samples/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: "samples-modern-slavery-statement___fr-FR",
    path: "/fr-FR/samples/modern-slavery-statement",
    component: () => import("/vercel/path0/pages/samples/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: "samples-modern-slavery-statement___nl-BE",
    path: "/nl-BE/samples/modern-slavery-statement",
    component: () => import("/vercel/path0/pages/samples/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: "samples-monetate___nl-NL___default",
    path: "/samples/monetate",
    component: () => import("/vercel/path0/pages/samples/monetate.vue").then(m => m.default || m)
  },
  {
    name: "samples-monetate___nl-NL",
    path: "/nl-NL/samples/monetate",
    component: () => import("/vercel/path0/pages/samples/monetate.vue").then(m => m.default || m)
  },
  {
    name: "samples-monetate___en-US",
    path: "/en-US/samples/monetate",
    component: () => import("/vercel/path0/pages/samples/monetate.vue").then(m => m.default || m)
  },
  {
    name: "samples-monetate___fr-FR",
    path: "/fr-FR/samples/monetate",
    component: () => import("/vercel/path0/pages/samples/monetate.vue").then(m => m.default || m)
  },
  {
    name: "samples-monetate___nl-BE",
    path: "/nl-BE/samples/monetate",
    component: () => import("/vercel/path0/pages/samples/monetate.vue").then(m => m.default || m)
  },
  {
    name: "samples-new-launch___nl-NL___default",
    path: "/samples/new-launch",
    component: () => import("/vercel/path0/pages/samples/new-launch.vue").then(m => m.default || m)
  },
  {
    name: "samples-new-launch___nl-NL",
    path: "/nl-NL/samples/new-launch",
    component: () => import("/vercel/path0/pages/samples/new-launch.vue").then(m => m.default || m)
  },
  {
    name: "samples-new-launch___en-US",
    path: "/en-US/samples/new-launch",
    component: () => import("/vercel/path0/pages/samples/new-launch.vue").then(m => m.default || m)
  },
  {
    name: "samples-new-launch___fr-FR",
    path: "/fr-FR/samples/new-launch",
    component: () => import("/vercel/path0/pages/samples/new-launch.vue").then(m => m.default || m)
  },
  {
    name: "samples-new-launch___nl-BE",
    path: "/nl-BE/samples/new-launch",
    component: () => import("/vercel/path0/pages/samples/new-launch.vue").then(m => m.default || m)
  },
  {
    name: "samples-newsletter___nl-NL___default",
    path: "/samples/newsletter",
    component: () => import("/vercel/path0/pages/samples/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "samples-newsletter___nl-NL",
    path: "/nl-NL/samples/newsletter",
    component: () => import("/vercel/path0/pages/samples/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "samples-newsletter___en-US",
    path: "/en-US/samples/newsletter",
    component: () => import("/vercel/path0/pages/samples/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "samples-newsletter___fr-FR",
    path: "/fr-FR/samples/newsletter",
    component: () => import("/vercel/path0/pages/samples/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "samples-newsletter___nl-BE",
    path: "/nl-BE/samples/newsletter",
    component: () => import("/vercel/path0/pages/samples/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "samples-no-search-result___nl-NL___default",
    path: "/samples/no-search-result",
    component: () => import("/vercel/path0/pages/samples/no-search-result.vue").then(m => m.default || m)
  },
  {
    name: "samples-no-search-result___nl-NL",
    path: "/nl-NL/samples/no-search-result",
    component: () => import("/vercel/path0/pages/samples/no-search-result.vue").then(m => m.default || m)
  },
  {
    name: "samples-no-search-result___en-US",
    path: "/en-US/samples/no-search-result",
    component: () => import("/vercel/path0/pages/samples/no-search-result.vue").then(m => m.default || m)
  },
  {
    name: "samples-no-search-result___fr-FR",
    path: "/fr-FR/samples/no-search-result",
    component: () => import("/vercel/path0/pages/samples/no-search-result.vue").then(m => m.default || m)
  },
  {
    name: "samples-no-search-result___nl-BE",
    path: "/nl-BE/samples/no-search-result",
    component: () => import("/vercel/path0/pages/samples/no-search-result.vue").then(m => m.default || m)
  },
  {
    name: "samples-offers___nl-NL___default",
    path: "/samples/offers",
    meta: offersHqKxRwG5lvMeta || {},
    component: () => import("/vercel/path0/pages/samples/offers.vue").then(m => m.default || m)
  },
  {
    name: "samples-offers___nl-NL",
    path: "/nl-NL/samples/offers",
    meta: offersHqKxRwG5lvMeta || {},
    component: () => import("/vercel/path0/pages/samples/offers.vue").then(m => m.default || m)
  },
  {
    name: "samples-offers___en-US",
    path: "/en-US/samples/offers",
    meta: offersHqKxRwG5lvMeta || {},
    component: () => import("/vercel/path0/pages/samples/offers.vue").then(m => m.default || m)
  },
  {
    name: "samples-offers___fr-FR",
    path: "/fr-FR/samples/offers",
    meta: offersHqKxRwG5lvMeta || {},
    component: () => import("/vercel/path0/pages/samples/offers.vue").then(m => m.default || m)
  },
  {
    name: "samples-offers___nl-BE",
    path: "/nl-BE/samples/offers",
    meta: offersHqKxRwG5lvMeta || {},
    component: () => import("/vercel/path0/pages/samples/offers.vue").then(m => m.default || m)
  },
  {
    name: "samples-orders___nl-NL___default",
    path: "/samples/orders",
    meta: ordersGxO6enFDEVMeta || {},
    component: () => import("/vercel/path0/pages/samples/orders.vue").then(m => m.default || m)
  },
  {
    name: "samples-orders___nl-NL",
    path: "/nl-NL/samples/orders",
    meta: ordersGxO6enFDEVMeta || {},
    component: () => import("/vercel/path0/pages/samples/orders.vue").then(m => m.default || m)
  },
  {
    name: "samples-orders___en-US",
    path: "/en-US/samples/orders",
    meta: ordersGxO6enFDEVMeta || {},
    component: () => import("/vercel/path0/pages/samples/orders.vue").then(m => m.default || m)
  },
  {
    name: "samples-orders___fr-FR",
    path: "/fr-FR/samples/orders",
    meta: ordersGxO6enFDEVMeta || {},
    component: () => import("/vercel/path0/pages/samples/orders.vue").then(m => m.default || m)
  },
  {
    name: "samples-orders___nl-BE",
    path: "/nl-BE/samples/orders",
    meta: ordersGxO6enFDEVMeta || {},
    component: () => import("/vercel/path0/pages/samples/orders.vue").then(m => m.default || m)
  },
  {
    name: "samples-payment-methods___nl-NL___default",
    path: "/samples/payment-methods",
    component: () => import("/vercel/path0/pages/samples/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "samples-payment-methods___nl-NL",
    path: "/nl-NL/samples/payment-methods",
    component: () => import("/vercel/path0/pages/samples/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "samples-payment-methods___en-US",
    path: "/en-US/samples/payment-methods",
    component: () => import("/vercel/path0/pages/samples/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "samples-payment-methods___fr-FR",
    path: "/fr-FR/samples/payment-methods",
    component: () => import("/vercel/path0/pages/samples/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "samples-payment-methods___nl-BE",
    path: "/nl-BE/samples/payment-methods",
    component: () => import("/vercel/path0/pages/samples/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "samples-plp-new-flow___nl-NL___default",
    path: "/samples/plp-new-flow",
    meta: plp_45new_45flownVZQyclwEPMeta || {},
    component: () => import("/vercel/path0/pages/samples/plp-new-flow.vue").then(m => m.default || m)
  },
  {
    name: "samples-plp-new-flow___nl-NL",
    path: "/nl-NL/samples/plp-new-flow",
    meta: plp_45new_45flownVZQyclwEPMeta || {},
    component: () => import("/vercel/path0/pages/samples/plp-new-flow.vue").then(m => m.default || m)
  },
  {
    name: "samples-plp-new-flow___en-US",
    path: "/en-US/samples/plp-new-flow",
    meta: plp_45new_45flownVZQyclwEPMeta || {},
    component: () => import("/vercel/path0/pages/samples/plp-new-flow.vue").then(m => m.default || m)
  },
  {
    name: "samples-plp-new-flow___fr-FR",
    path: "/fr-FR/samples/plp-new-flow",
    meta: plp_45new_45flownVZQyclwEPMeta || {},
    component: () => import("/vercel/path0/pages/samples/plp-new-flow.vue").then(m => m.default || m)
  },
  {
    name: "samples-plp-new-flow___nl-BE",
    path: "/nl-BE/samples/plp-new-flow",
    meta: plp_45new_45flownVZQyclwEPMeta || {},
    component: () => import("/vercel/path0/pages/samples/plp-new-flow.vue").then(m => m.default || m)
  },
  {
    name: "samples-postnl___nl-NL___default",
    path: "/samples/postnl",
    component: () => import("/vercel/path0/pages/samples/postnl.vue").then(m => m.default || m)
  },
  {
    name: "samples-postnl___nl-NL",
    path: "/nl-NL/samples/postnl",
    component: () => import("/vercel/path0/pages/samples/postnl.vue").then(m => m.default || m)
  },
  {
    name: "samples-postnl___en-US",
    path: "/en-US/samples/postnl",
    component: () => import("/vercel/path0/pages/samples/postnl.vue").then(m => m.default || m)
  },
  {
    name: "samples-postnl___fr-FR",
    path: "/fr-FR/samples/postnl",
    component: () => import("/vercel/path0/pages/samples/postnl.vue").then(m => m.default || m)
  },
  {
    name: "samples-postnl___nl-BE",
    path: "/nl-BE/samples/postnl",
    component: () => import("/vercel/path0/pages/samples/postnl.vue").then(m => m.default || m)
  },
  {
    name: "samples-preferences___nl-NL___default",
    path: "/samples/preferences",
    meta: preferencesANfeyJwFlyMeta || {},
    component: () => import("/vercel/path0/pages/samples/preferences.vue").then(m => m.default || m)
  },
  {
    name: "samples-preferences___nl-NL",
    path: "/nl-NL/samples/preferences",
    meta: preferencesANfeyJwFlyMeta || {},
    component: () => import("/vercel/path0/pages/samples/preferences.vue").then(m => m.default || m)
  },
  {
    name: "samples-preferences___en-US",
    path: "/en-US/samples/preferences",
    meta: preferencesANfeyJwFlyMeta || {},
    component: () => import("/vercel/path0/pages/samples/preferences.vue").then(m => m.default || m)
  },
  {
    name: "samples-preferences___fr-FR",
    path: "/fr-FR/samples/preferences",
    meta: preferencesANfeyJwFlyMeta || {},
    component: () => import("/vercel/path0/pages/samples/preferences.vue").then(m => m.default || m)
  },
  {
    name: "samples-preferences___nl-BE",
    path: "/nl-BE/samples/preferences",
    meta: preferencesANfeyJwFlyMeta || {},
    component: () => import("/vercel/path0/pages/samples/preferences.vue").then(m => m.default || m)
  },
  {
    name: "samples-privacy-declaration___nl-NL___default",
    path: "/samples/privacy-declaration",
    component: () => import("/vercel/path0/pages/samples/privacy-declaration.vue").then(m => m.default || m)
  },
  {
    name: "samples-privacy-declaration___nl-NL",
    path: "/nl-NL/samples/privacy-declaration",
    component: () => import("/vercel/path0/pages/samples/privacy-declaration.vue").then(m => m.default || m)
  },
  {
    name: "samples-privacy-declaration___en-US",
    path: "/en-US/samples/privacy-declaration",
    component: () => import("/vercel/path0/pages/samples/privacy-declaration.vue").then(m => m.default || m)
  },
  {
    name: "samples-privacy-declaration___fr-FR",
    path: "/fr-FR/samples/privacy-declaration",
    component: () => import("/vercel/path0/pages/samples/privacy-declaration.vue").then(m => m.default || m)
  },
  {
    name: "samples-privacy-declaration___nl-BE",
    path: "/nl-BE/samples/privacy-declaration",
    component: () => import("/vercel/path0/pages/samples/privacy-declaration.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-apply___nl-NL___default",
    path: "/samples/pro-card-apply",
    component: () => import("/vercel/path0/pages/samples/pro-card-apply.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-apply___nl-NL",
    path: "/nl-NL/samples/pro-card-apply",
    component: () => import("/vercel/path0/pages/samples/pro-card-apply.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-apply___en-US",
    path: "/en-US/samples/pro-card-apply",
    component: () => import("/vercel/path0/pages/samples/pro-card-apply.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-apply___fr-FR",
    path: "/fr-FR/samples/pro-card-apply",
    component: () => import("/vercel/path0/pages/samples/pro-card-apply.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-apply___nl-BE",
    path: "/nl-BE/samples/pro-card-apply",
    component: () => import("/vercel/path0/pages/samples/pro-card-apply.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-demo___nl-NL___default",
    path: "/samples/pro-card-demo",
    component: () => import("/vercel/path0/pages/samples/pro-card-demo.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-demo___nl-NL",
    path: "/nl-NL/samples/pro-card-demo",
    component: () => import("/vercel/path0/pages/samples/pro-card-demo.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-demo___en-US",
    path: "/en-US/samples/pro-card-demo",
    component: () => import("/vercel/path0/pages/samples/pro-card-demo.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-demo___fr-FR",
    path: "/fr-FR/samples/pro-card-demo",
    component: () => import("/vercel/path0/pages/samples/pro-card-demo.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-demo___nl-BE",
    path: "/nl-BE/samples/pro-card-demo",
    component: () => import("/vercel/path0/pages/samples/pro-card-demo.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-withqr___nl-NL___default",
    path: "/samples/pro-card-withqr",
    meta: pro_45card_45withqrSJWLdOsK82Meta || {},
    component: () => import("/vercel/path0/pages/samples/pro-card-withqr.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-withqr___nl-NL",
    path: "/nl-NL/samples/pro-card-withqr",
    meta: pro_45card_45withqrSJWLdOsK82Meta || {},
    component: () => import("/vercel/path0/pages/samples/pro-card-withqr.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-withqr___en-US",
    path: "/en-US/samples/pro-card-withqr",
    meta: pro_45card_45withqrSJWLdOsK82Meta || {},
    component: () => import("/vercel/path0/pages/samples/pro-card-withqr.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-withqr___fr-FR",
    path: "/fr-FR/samples/pro-card-withqr",
    meta: pro_45card_45withqrSJWLdOsK82Meta || {},
    component: () => import("/vercel/path0/pages/samples/pro-card-withqr.vue").then(m => m.default || m)
  },
  {
    name: "samples-pro-card-withqr___nl-BE",
    path: "/nl-BE/samples/pro-card-withqr",
    meta: pro_45card_45withqrSJWLdOsK82Meta || {},
    component: () => import("/vercel/path0/pages/samples/pro-card-withqr.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-detail___nl-NL___default",
    path: "/samples/product-detail",
    component: () => import("/vercel/path0/pages/samples/product-detail.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-detail___nl-NL",
    path: "/nl-NL/samples/product-detail",
    component: () => import("/vercel/path0/pages/samples/product-detail.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-detail___en-US",
    path: "/en-US/samples/product-detail",
    component: () => import("/vercel/path0/pages/samples/product-detail.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-detail___fr-FR",
    path: "/fr-FR/samples/product-detail",
    component: () => import("/vercel/path0/pages/samples/product-detail.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-detail___nl-BE",
    path: "/nl-BE/samples/product-detail",
    component: () => import("/vercel/path0/pages/samples/product-detail.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-listing___nl-NL___default",
    path: "/samples/product-listing",
    component: () => import("/vercel/path0/pages/samples/product-listing.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-listing___nl-NL",
    path: "/nl-NL/samples/product-listing",
    component: () => import("/vercel/path0/pages/samples/product-listing.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-listing___en-US",
    path: "/en-US/samples/product-listing",
    component: () => import("/vercel/path0/pages/samples/product-listing.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-listing___fr-FR",
    path: "/fr-FR/samples/product-listing",
    component: () => import("/vercel/path0/pages/samples/product-listing.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-listing___nl-BE",
    path: "/nl-BE/samples/product-listing",
    component: () => import("/vercel/path0/pages/samples/product-listing.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-review___nl-NL___default",
    path: "/samples/product-review",
    component: () => import("/vercel/path0/pages/samples/product-review.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-review___nl-NL",
    path: "/nl-NL/samples/product-review",
    component: () => import("/vercel/path0/pages/samples/product-review.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-review___en-US",
    path: "/en-US/samples/product-review",
    component: () => import("/vercel/path0/pages/samples/product-review.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-review___fr-FR",
    path: "/fr-FR/samples/product-review",
    component: () => import("/vercel/path0/pages/samples/product-review.vue").then(m => m.default || m)
  },
  {
    name: "samples-product-review___nl-BE",
    path: "/nl-BE/samples/product-review",
    component: () => import("/vercel/path0/pages/samples/product-review.vue").then(m => m.default || m)
  },
  {
    name: "samples-profile___nl-NL___default",
    path: "/samples/profile",
    meta: profilexRJCcBfPLlMeta || {},
    component: () => import("/vercel/path0/pages/samples/profile.vue").then(m => m.default || m)
  },
  {
    name: "samples-profile___nl-NL",
    path: "/nl-NL/samples/profile",
    meta: profilexRJCcBfPLlMeta || {},
    component: () => import("/vercel/path0/pages/samples/profile.vue").then(m => m.default || m)
  },
  {
    name: "samples-profile___en-US",
    path: "/en-US/samples/profile",
    meta: profilexRJCcBfPLlMeta || {},
    component: () => import("/vercel/path0/pages/samples/profile.vue").then(m => m.default || m)
  },
  {
    name: "samples-profile___fr-FR",
    path: "/fr-FR/samples/profile",
    meta: profilexRJCcBfPLlMeta || {},
    component: () => import("/vercel/path0/pages/samples/profile.vue").then(m => m.default || m)
  },
  {
    name: "samples-profile___nl-BE",
    path: "/nl-BE/samples/profile",
    meta: profilexRJCcBfPLlMeta || {},
    component: () => import("/vercel/path0/pages/samples/profile.vue").then(m => m.default || m)
  },
  {
    name: "samples-promotion___nl-NL___default",
    path: "/samples/promotion",
    component: () => import("/vercel/path0/pages/samples/promotion.vue").then(m => m.default || m)
  },
  {
    name: "samples-promotion___nl-NL",
    path: "/nl-NL/samples/promotion",
    component: () => import("/vercel/path0/pages/samples/promotion.vue").then(m => m.default || m)
  },
  {
    name: "samples-promotion___en-US",
    path: "/en-US/samples/promotion",
    component: () => import("/vercel/path0/pages/samples/promotion.vue").then(m => m.default || m)
  },
  {
    name: "samples-promotion___fr-FR",
    path: "/fr-FR/samples/promotion",
    component: () => import("/vercel/path0/pages/samples/promotion.vue").then(m => m.default || m)
  },
  {
    name: "samples-promotion___nl-BE",
    path: "/nl-BE/samples/promotion",
    component: () => import("/vercel/path0/pages/samples/promotion.vue").then(m => m.default || m)
  },
  {
    name: "samples-returns-exchange___nl-NL___default",
    path: "/samples/returns-exchange",
    component: () => import("/vercel/path0/pages/samples/returns-exchange.vue").then(m => m.default || m)
  },
  {
    name: "samples-returns-exchange___nl-NL",
    path: "/nl-NL/samples/returns-exchange",
    component: () => import("/vercel/path0/pages/samples/returns-exchange.vue").then(m => m.default || m)
  },
  {
    name: "samples-returns-exchange___en-US",
    path: "/en-US/samples/returns-exchange",
    component: () => import("/vercel/path0/pages/samples/returns-exchange.vue").then(m => m.default || m)
  },
  {
    name: "samples-returns-exchange___fr-FR",
    path: "/fr-FR/samples/returns-exchange",
    component: () => import("/vercel/path0/pages/samples/returns-exchange.vue").then(m => m.default || m)
  },
  {
    name: "samples-returns-exchange___nl-BE",
    path: "/nl-BE/samples/returns-exchange",
    component: () => import("/vercel/path0/pages/samples/returns-exchange.vue").then(m => m.default || m)
  },
  {
    name: "samples-reviews___nl-NL___default",
    path: "/samples/reviews",
    component: () => import("/vercel/path0/pages/samples/reviews.vue").then(m => m.default || m)
  },
  {
    name: "samples-reviews___nl-NL",
    path: "/nl-NL/samples/reviews",
    component: () => import("/vercel/path0/pages/samples/reviews.vue").then(m => m.default || m)
  },
  {
    name: "samples-reviews___en-US",
    path: "/en-US/samples/reviews",
    component: () => import("/vercel/path0/pages/samples/reviews.vue").then(m => m.default || m)
  },
  {
    name: "samples-reviews___fr-FR",
    path: "/fr-FR/samples/reviews",
    component: () => import("/vercel/path0/pages/samples/reviews.vue").then(m => m.default || m)
  },
  {
    name: "samples-reviews___nl-BE",
    path: "/nl-BE/samples/reviews",
    component: () => import("/vercel/path0/pages/samples/reviews.vue").then(m => m.default || m)
  },
  {
    name: "samples-sale___nl-NL___default",
    path: "/samples/sale",
    component: () => import("/vercel/path0/pages/samples/sale.vue").then(m => m.default || m)
  },
  {
    name: "samples-sale___nl-NL",
    path: "/nl-NL/samples/sale",
    component: () => import("/vercel/path0/pages/samples/sale.vue").then(m => m.default || m)
  },
  {
    name: "samples-sale___en-US",
    path: "/en-US/samples/sale",
    component: () => import("/vercel/path0/pages/samples/sale.vue").then(m => m.default || m)
  },
  {
    name: "samples-sale___fr-FR",
    path: "/fr-FR/samples/sale",
    component: () => import("/vercel/path0/pages/samples/sale.vue").then(m => m.default || m)
  },
  {
    name: "samples-sale___nl-BE",
    path: "/nl-BE/samples/sale",
    component: () => import("/vercel/path0/pages/samples/sale.vue").then(m => m.default || m)
  },
  {
    name: "samples-save-list___nl-NL___default",
    path: "/samples/save-list",
    meta: save_45listTztPT1MuMTMeta || {},
    component: () => import("/vercel/path0/pages/samples/save-list.vue").then(m => m.default || m)
  },
  {
    name: "samples-save-list___nl-NL",
    path: "/nl-NL/samples/save-list",
    meta: save_45listTztPT1MuMTMeta || {},
    component: () => import("/vercel/path0/pages/samples/save-list.vue").then(m => m.default || m)
  },
  {
    name: "samples-save-list___en-US",
    path: "/en-US/samples/save-list",
    meta: save_45listTztPT1MuMTMeta || {},
    component: () => import("/vercel/path0/pages/samples/save-list.vue").then(m => m.default || m)
  },
  {
    name: "samples-save-list___fr-FR",
    path: "/fr-FR/samples/save-list",
    meta: save_45listTztPT1MuMTMeta || {},
    component: () => import("/vercel/path0/pages/samples/save-list.vue").then(m => m.default || m)
  },
  {
    name: "samples-save-list___nl-BE",
    path: "/nl-BE/samples/save-list",
    meta: save_45listTztPT1MuMTMeta || {},
    component: () => import("/vercel/path0/pages/samples/save-list.vue").then(m => m.default || m)
  },
  {
    name: "samples-search-result___nl-NL___default",
    path: "/samples/search-result",
    component: () => import("/vercel/path0/pages/samples/search-result.vue").then(m => m.default || m)
  },
  {
    name: "samples-search-result___nl-NL",
    path: "/nl-NL/samples/search-result",
    component: () => import("/vercel/path0/pages/samples/search-result.vue").then(m => m.default || m)
  },
  {
    name: "samples-search-result___en-US",
    path: "/en-US/samples/search-result",
    component: () => import("/vercel/path0/pages/samples/search-result.vue").then(m => m.default || m)
  },
  {
    name: "samples-search-result___fr-FR",
    path: "/fr-FR/samples/search-result",
    component: () => import("/vercel/path0/pages/samples/search-result.vue").then(m => m.default || m)
  },
  {
    name: "samples-search-result___nl-BE",
    path: "/nl-BE/samples/search-result",
    component: () => import("/vercel/path0/pages/samples/search-result.vue").then(m => m.default || m)
  },
  {
    name: "samples-site-map___nl-NL___default",
    path: "/samples/site-map",
    component: () => import("/vercel/path0/pages/samples/site-map.vue").then(m => m.default || m)
  },
  {
    name: "samples-site-map___nl-NL",
    path: "/nl-NL/samples/site-map",
    component: () => import("/vercel/path0/pages/samples/site-map.vue").then(m => m.default || m)
  },
  {
    name: "samples-site-map___en-US",
    path: "/en-US/samples/site-map",
    component: () => import("/vercel/path0/pages/samples/site-map.vue").then(m => m.default || m)
  },
  {
    name: "samples-site-map___fr-FR",
    path: "/fr-FR/samples/site-map",
    component: () => import("/vercel/path0/pages/samples/site-map.vue").then(m => m.default || m)
  },
  {
    name: "samples-site-map___nl-BE",
    path: "/nl-BE/samples/site-map",
    component: () => import("/vercel/path0/pages/samples/site-map.vue").then(m => m.default || m)
  },
  {
    name: "samples-stock-notification___nl-NL___default",
    path: "/samples/stock-notification",
    meta: stock_45notificationuhNNGgr7m5Meta || {},
    component: () => import("/vercel/path0/pages/samples/stock-notification.vue").then(m => m.default || m)
  },
  {
    name: "samples-stock-notification___nl-NL",
    path: "/nl-NL/samples/stock-notification",
    meta: stock_45notificationuhNNGgr7m5Meta || {},
    component: () => import("/vercel/path0/pages/samples/stock-notification.vue").then(m => m.default || m)
  },
  {
    name: "samples-stock-notification___en-US",
    path: "/en-US/samples/stock-notification",
    meta: stock_45notificationuhNNGgr7m5Meta || {},
    component: () => import("/vercel/path0/pages/samples/stock-notification.vue").then(m => m.default || m)
  },
  {
    name: "samples-stock-notification___fr-FR",
    path: "/fr-FR/samples/stock-notification",
    meta: stock_45notificationuhNNGgr7m5Meta || {},
    component: () => import("/vercel/path0/pages/samples/stock-notification.vue").then(m => m.default || m)
  },
  {
    name: "samples-stock-notification___nl-BE",
    path: "/nl-BE/samples/stock-notification",
    meta: stock_45notificationuhNNGgr7m5Meta || {},
    component: () => import("/vercel/path0/pages/samples/stock-notification.vue").then(m => m.default || m)
  },
  {
    name: "samples-terms-conditions___nl-NL___default",
    path: "/samples/terms-conditions",
    component: () => import("/vercel/path0/pages/samples/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "samples-terms-conditions___nl-NL",
    path: "/nl-NL/samples/terms-conditions",
    component: () => import("/vercel/path0/pages/samples/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "samples-terms-conditions___en-US",
    path: "/en-US/samples/terms-conditions",
    component: () => import("/vercel/path0/pages/samples/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "samples-terms-conditions___fr-FR",
    path: "/fr-FR/samples/terms-conditions",
    component: () => import("/vercel/path0/pages/samples/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "samples-terms-conditions___nl-BE",
    path: "/nl-BE/samples/terms-conditions",
    component: () => import("/vercel/path0/pages/samples/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "samples-test-error-logging___nl-NL___default",
    path: "/samples/test-error-logging",
    meta: test_45error_45logging7sHix76jfbMeta || {},
    component: () => import("/vercel/path0/pages/samples/test-error-logging.vue").then(m => m.default || m)
  },
  {
    name: "samples-test-error-logging___nl-NL",
    path: "/nl-NL/samples/test-error-logging",
    meta: test_45error_45logging7sHix76jfbMeta || {},
    component: () => import("/vercel/path0/pages/samples/test-error-logging.vue").then(m => m.default || m)
  },
  {
    name: "samples-test-error-logging___en-US",
    path: "/en-US/samples/test-error-logging",
    meta: test_45error_45logging7sHix76jfbMeta || {},
    component: () => import("/vercel/path0/pages/samples/test-error-logging.vue").then(m => m.default || m)
  },
  {
    name: "samples-test-error-logging___fr-FR",
    path: "/fr-FR/samples/test-error-logging",
    meta: test_45error_45logging7sHix76jfbMeta || {},
    component: () => import("/vercel/path0/pages/samples/test-error-logging.vue").then(m => m.default || m)
  },
  {
    name: "samples-test-error-logging___nl-BE",
    path: "/nl-BE/samples/test-error-logging",
    meta: test_45error_45logging7sHix76jfbMeta || {},
    component: () => import("/vercel/path0/pages/samples/test-error-logging.vue").then(m => m.default || m)
  },
  {
    name: "samples-thank-you___nl-NL___default",
    path: "/samples/thank-you",
    component: () => import("/vercel/path0/pages/samples/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "samples-thank-you___nl-NL",
    path: "/nl-NL/samples/thank-you",
    component: () => import("/vercel/path0/pages/samples/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "samples-thank-you___en-US",
    path: "/en-US/samples/thank-you",
    component: () => import("/vercel/path0/pages/samples/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "samples-thank-you___fr-FR",
    path: "/fr-FR/samples/thank-you",
    component: () => import("/vercel/path0/pages/samples/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "samples-thank-you___nl-BE",
    path: "/nl-BE/samples/thank-you",
    component: () => import("/vercel/path0/pages/samples/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "samples-toolstation-app___nl-NL___default",
    path: "/samples/toolstation-app",
    component: () => import("/vercel/path0/pages/samples/toolstation-app.vue").then(m => m.default || m)
  },
  {
    name: "samples-toolstation-app___nl-NL",
    path: "/nl-NL/samples/toolstation-app",
    component: () => import("/vercel/path0/pages/samples/toolstation-app.vue").then(m => m.default || m)
  },
  {
    name: "samples-toolstation-app___en-US",
    path: "/en-US/samples/toolstation-app",
    component: () => import("/vercel/path0/pages/samples/toolstation-app.vue").then(m => m.default || m)
  },
  {
    name: "samples-toolstation-app___fr-FR",
    path: "/fr-FR/samples/toolstation-app",
    component: () => import("/vercel/path0/pages/samples/toolstation-app.vue").then(m => m.default || m)
  },
  {
    name: "samples-toolstation-app___nl-BE",
    path: "/nl-BE/samples/toolstation-app",
    component: () => import("/vercel/path0/pages/samples/toolstation-app.vue").then(m => m.default || m)
  },
  {
    name: "samples-trolley-code___nl-NL___default",
    path: "/samples/trolley-code",
    component: () => import("/vercel/path0/pages/samples/trolley-code.vue").then(m => m.default || m)
  },
  {
    name: "samples-trolley-code___nl-NL",
    path: "/nl-NL/samples/trolley-code",
    component: () => import("/vercel/path0/pages/samples/trolley-code.vue").then(m => m.default || m)
  },
  {
    name: "samples-trolley-code___en-US",
    path: "/en-US/samples/trolley-code",
    component: () => import("/vercel/path0/pages/samples/trolley-code.vue").then(m => m.default || m)
  },
  {
    name: "samples-trolley-code___fr-FR",
    path: "/fr-FR/samples/trolley-code",
    component: () => import("/vercel/path0/pages/samples/trolley-code.vue").then(m => m.default || m)
  },
  {
    name: "samples-trolley-code___nl-BE",
    path: "/nl-BE/samples/trolley-code",
    component: () => import("/vercel/path0/pages/samples/trolley-code.vue").then(m => m.default || m)
  },
  {
    name: "samples-trolley___nl-NL___default",
    path: "/samples/trolley",
    component: () => import("/vercel/path0/pages/samples/trolley.vue").then(m => m.default || m)
  },
  {
    name: "samples-trolley___nl-NL",
    path: "/nl-NL/samples/trolley",
    component: () => import("/vercel/path0/pages/samples/trolley.vue").then(m => m.default || m)
  },
  {
    name: "samples-trolley___en-US",
    path: "/en-US/samples/trolley",
    component: () => import("/vercel/path0/pages/samples/trolley.vue").then(m => m.default || m)
  },
  {
    name: "samples-trolley___fr-FR",
    path: "/fr-FR/samples/trolley",
    component: () => import("/vercel/path0/pages/samples/trolley.vue").then(m => m.default || m)
  },
  {
    name: "samples-trolley___nl-BE",
    path: "/nl-BE/samples/trolley",
    component: () => import("/vercel/path0/pages/samples/trolley.vue").then(m => m.default || m)
  },
  {
    name: "samples-trust-pilot___nl-NL___default",
    path: "/samples/trust-pilot",
    component: () => import("/vercel/path0/pages/samples/trust-pilot.vue").then(m => m.default || m)
  },
  {
    name: "samples-trust-pilot___nl-NL",
    path: "/nl-NL/samples/trust-pilot",
    component: () => import("/vercel/path0/pages/samples/trust-pilot.vue").then(m => m.default || m)
  },
  {
    name: "samples-trust-pilot___en-US",
    path: "/en-US/samples/trust-pilot",
    component: () => import("/vercel/path0/pages/samples/trust-pilot.vue").then(m => m.default || m)
  },
  {
    name: "samples-trust-pilot___fr-FR",
    path: "/fr-FR/samples/trust-pilot",
    component: () => import("/vercel/path0/pages/samples/trust-pilot.vue").then(m => m.default || m)
  },
  {
    name: "samples-trust-pilot___nl-BE",
    path: "/nl-BE/samples/trust-pilot",
    component: () => import("/vercel/path0/pages/samples/trust-pilot.vue").then(m => m.default || m)
  },
  {
    name: "samples-under-maintenance___nl-NL___default",
    path: "/samples/under-maintenance",
    meta: under_45maintenancehV20OeD3VgMeta || {},
    component: () => import("/vercel/path0/pages/samples/under-maintenance.vue").then(m => m.default || m)
  },
  {
    name: "samples-under-maintenance___nl-NL",
    path: "/nl-NL/samples/under-maintenance",
    meta: under_45maintenancehV20OeD3VgMeta || {},
    component: () => import("/vercel/path0/pages/samples/under-maintenance.vue").then(m => m.default || m)
  },
  {
    name: "samples-under-maintenance___en-US",
    path: "/en-US/samples/under-maintenance",
    meta: under_45maintenancehV20OeD3VgMeta || {},
    component: () => import("/vercel/path0/pages/samples/under-maintenance.vue").then(m => m.default || m)
  },
  {
    name: "samples-under-maintenance___fr-FR",
    path: "/fr-FR/samples/under-maintenance",
    meta: under_45maintenancehV20OeD3VgMeta || {},
    component: () => import("/vercel/path0/pages/samples/under-maintenance.vue").then(m => m.default || m)
  },
  {
    name: "samples-under-maintenance___nl-BE",
    path: "/nl-BE/samples/under-maintenance",
    meta: under_45maintenancehV20OeD3VgMeta || {},
    component: () => import("/vercel/path0/pages/samples/under-maintenance.vue").then(m => m.default || m)
  },
  {
    name: "samples-vacancies___nl-NL___default",
    path: "/samples/vacancies",
    component: () => import("/vercel/path0/pages/samples/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "samples-vacancies___nl-NL",
    path: "/nl-NL/samples/vacancies",
    component: () => import("/vercel/path0/pages/samples/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "samples-vacancies___en-US",
    path: "/en-US/samples/vacancies",
    component: () => import("/vercel/path0/pages/samples/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "samples-vacancies___fr-FR",
    path: "/fr-FR/samples/vacancies",
    component: () => import("/vercel/path0/pages/samples/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "samples-vacancies___nl-BE",
    path: "/nl-BE/samples/vacancies",
    component: () => import("/vercel/path0/pages/samples/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "samples-wee___nl-NL___default",
    path: "/samples/wee",
    component: () => import("/vercel/path0/pages/samples/wee.vue").then(m => m.default || m)
  },
  {
    name: "samples-wee___nl-NL",
    path: "/nl-NL/samples/wee",
    component: () => import("/vercel/path0/pages/samples/wee.vue").then(m => m.default || m)
  },
  {
    name: "samples-wee___en-US",
    path: "/en-US/samples/wee",
    component: () => import("/vercel/path0/pages/samples/wee.vue").then(m => m.default || m)
  },
  {
    name: "samples-wee___fr-FR",
    path: "/fr-FR/samples/wee",
    component: () => import("/vercel/path0/pages/samples/wee.vue").then(m => m.default || m)
  },
  {
    name: "samples-wee___nl-BE",
    path: "/nl-BE/samples/wee",
    component: () => import("/vercel/path0/pages/samples/wee.vue").then(m => m.default || m)
  },
  {
    name: "save-list-code___nl-NL___default",
    path: "/save-list-code",
    component: () => import("/vercel/path0/pages/save-list-code.vue").then(m => m.default || m)
  },
  {
    name: "save-list-code___nl-NL",
    path: "/nl-NL/save-list-code",
    component: () => import("/vercel/path0/pages/save-list-code.vue").then(m => m.default || m)
  },
  {
    name: "save-list-code___en-US",
    path: "/en-US/save-list-code",
    component: () => import("/vercel/path0/pages/save-list-code.vue").then(m => m.default || m)
  },
  {
    name: "save-list-code___fr-FR",
    path: "/fr-FR/save-list-code",
    component: () => import("/vercel/path0/pages/save-list-code.vue").then(m => m.default || m)
  },
  {
    name: "save-list-code___nl-BE",
    path: "/nl-BE/save-list-code",
    component: () => import("/vercel/path0/pages/save-list-code.vue").then(m => m.default || m)
  },
  {
    name: "search___nl-NL___default",
    path: "/search",
    component: () => import("/vercel/path0/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___nl-NL",
    path: "/nl-NL/search",
    component: () => import("/vercel/path0/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___en-US",
    path: "/en-US/search",
    component: () => import("/vercel/path0/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___fr-FR",
    path: "/fr-FR/search",
    component: () => import("/vercel/path0/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___nl-BE",
    path: "/nl-BE/search",
    component: () => import("/vercel/path0/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "test___nl-NL___default",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___nl-NL",
    path: "/nl-NL/test",
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___en-US",
    path: "/en-US/test",
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___fr-FR",
    path: "/fr-FR/test",
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___nl-BE",
    path: "/nl-BE/test",
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "trolley-code___nl-NL___default",
    path: "/trolley-code",
    component: () => import("/vercel/path0/pages/trolley-code.vue").then(m => m.default || m)
  },
  {
    name: "trolley-code___nl-NL",
    path: "/nl-NL/trolley-code",
    component: () => import("/vercel/path0/pages/trolley-code.vue").then(m => m.default || m)
  },
  {
    name: "trolley-code___en-US",
    path: "/en-US/trolley-code",
    component: () => import("/vercel/path0/pages/trolley-code.vue").then(m => m.default || m)
  },
  {
    name: "trolley-code___fr-FR",
    path: "/fr-FR/trolley-code",
    component: () => import("/vercel/path0/pages/trolley-code.vue").then(m => m.default || m)
  },
  {
    name: "trolley-code___nl-BE",
    path: "/nl-BE/trolley-code",
    component: () => import("/vercel/path0/pages/trolley-code.vue").then(m => m.default || m)
  },
  {
    name: "trolley___nl-NL___default",
    path: "/trolley",
    component: () => import("/vercel/path0/pages/trolley.vue").then(m => m.default || m)
  },
  {
    name: "trolley___nl-NL",
    path: "/nl-NL/trolley",
    component: () => import("/vercel/path0/pages/trolley.vue").then(m => m.default || m)
  },
  {
    name: "trolley___en-US",
    path: "/en-US/trolley",
    component: () => import("/vercel/path0/pages/trolley.vue").then(m => m.default || m)
  },
  {
    name: "trolley___fr-FR",
    path: "/fr-FR/trolley",
    component: () => import("/vercel/path0/pages/trolley.vue").then(m => m.default || m)
  },
  {
    name: "trolley___nl-BE",
    path: "/nl-BE/trolley",
    component: () => import("/vercel/path0/pages/trolley.vue").then(m => m.default || m)
  },
  {
    name: "ui-slug___nl-NL___default",
    path: "/ui/:slug(.*)*",
    meta: _91_46_46_46slug_93xRdUlEOi0UMeta || {},
    component: () => import("/vercel/path0/pages/ui/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ui-slug___nl-NL",
    path: "/nl-NL/ui/:slug(.*)*",
    meta: _91_46_46_46slug_93xRdUlEOi0UMeta || {},
    component: () => import("/vercel/path0/pages/ui/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ui-slug___en-US",
    path: "/en-US/ui/:slug(.*)*",
    meta: _91_46_46_46slug_93xRdUlEOi0UMeta || {},
    component: () => import("/vercel/path0/pages/ui/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ui-slug___fr-FR",
    path: "/fr-FR/ui/:slug(.*)*",
    meta: _91_46_46_46slug_93xRdUlEOi0UMeta || {},
    component: () => import("/vercel/path0/pages/ui/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ui-slug___nl-BE",
    path: "/nl-BE/ui/:slug(.*)*",
    meta: _91_46_46_46slug_93xRdUlEOi0UMeta || {},
    component: () => import("/vercel/path0/pages/ui/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "ui___nl-NL___default",
    path: "/ui",
    meta: indexSDV5zexPEqMeta || {},
    component: () => import("/vercel/path0/pages/ui/index.vue").then(m => m.default || m)
  },
  {
    name: "ui___nl-NL",
    path: "/nl-NL/ui",
    meta: indexSDV5zexPEqMeta || {},
    component: () => import("/vercel/path0/pages/ui/index.vue").then(m => m.default || m)
  },
  {
    name: "ui___en-US",
    path: "/en-US/ui",
    meta: indexSDV5zexPEqMeta || {},
    component: () => import("/vercel/path0/pages/ui/index.vue").then(m => m.default || m)
  },
  {
    name: "ui___fr-FR",
    path: "/fr-FR/ui",
    meta: indexSDV5zexPEqMeta || {},
    component: () => import("/vercel/path0/pages/ui/index.vue").then(m => m.default || m)
  },
  {
    name: "ui___nl-BE",
    path: "/nl-BE/ui",
    meta: indexSDV5zexPEqMeta || {},
    component: () => import("/vercel/path0/pages/ui/index.vue").then(m => m.default || m)
  },
  {
    name: "under-maintenance___nl-NL___default",
    path: "/under-maintenance",
    meta: under_45maintenanceP9qmbuqlofMeta || {},
    component: () => import("/vercel/path0/pages/under-maintenance.vue").then(m => m.default || m)
  },
  {
    name: "under-maintenance___nl-NL",
    path: "/nl-NL/under-maintenance",
    meta: under_45maintenanceP9qmbuqlofMeta || {},
    component: () => import("/vercel/path0/pages/under-maintenance.vue").then(m => m.default || m)
  },
  {
    name: "under-maintenance___en-US",
    path: "/en-US/under-maintenance",
    meta: under_45maintenanceP9qmbuqlofMeta || {},
    component: () => import("/vercel/path0/pages/under-maintenance.vue").then(m => m.default || m)
  },
  {
    name: "under-maintenance___fr-FR",
    path: "/fr-FR/under-maintenance",
    meta: under_45maintenanceP9qmbuqlofMeta || {},
    component: () => import("/vercel/path0/pages/under-maintenance.vue").then(m => m.default || m)
  },
  {
    name: "under-maintenance___nl-BE",
    path: "/nl-BE/under-maintenance",
    meta: under_45maintenanceP9qmbuqlofMeta || {},
    component: () => import("/vercel/path0/pages/under-maintenance.vue").then(m => m.default || m)
  },
  {
    name: "with-blank___nl-NL___default",
    path: "/with-blank",
    meta: with_45blankD5ybUwbyoUMeta || {},
    component: () => import("/vercel/path0/pages/with-blank.vue").then(m => m.default || m)
  },
  {
    name: "with-blank___nl-NL",
    path: "/nl-NL/with-blank",
    meta: with_45blankD5ybUwbyoUMeta || {},
    component: () => import("/vercel/path0/pages/with-blank.vue").then(m => m.default || m)
  },
  {
    name: "with-blank___en-US",
    path: "/en-US/with-blank",
    meta: with_45blankD5ybUwbyoUMeta || {},
    component: () => import("/vercel/path0/pages/with-blank.vue").then(m => m.default || m)
  },
  {
    name: "with-blank___fr-FR",
    path: "/fr-FR/with-blank",
    meta: with_45blankD5ybUwbyoUMeta || {},
    component: () => import("/vercel/path0/pages/with-blank.vue").then(m => m.default || m)
  },
  {
    name: "with-blank___nl-BE",
    path: "/nl-BE/with-blank",
    meta: with_45blankD5ybUwbyoUMeta || {},
    component: () => import("/vercel/path0/pages/with-blank.vue").then(m => m.default || m)
  },
  {
    name: "with-footer___nl-NL___default",
    path: "/with-footer",
    meta: with_45footer2XhL01PTTkMeta || {},
    component: () => import("/vercel/path0/pages/with-footer.vue").then(m => m.default || m)
  },
  {
    name: "with-footer___nl-NL",
    path: "/nl-NL/with-footer",
    meta: with_45footer2XhL01PTTkMeta || {},
    component: () => import("/vercel/path0/pages/with-footer.vue").then(m => m.default || m)
  },
  {
    name: "with-footer___en-US",
    path: "/en-US/with-footer",
    meta: with_45footer2XhL01PTTkMeta || {},
    component: () => import("/vercel/path0/pages/with-footer.vue").then(m => m.default || m)
  },
  {
    name: "with-footer___fr-FR",
    path: "/fr-FR/with-footer",
    meta: with_45footer2XhL01PTTkMeta || {},
    component: () => import("/vercel/path0/pages/with-footer.vue").then(m => m.default || m)
  },
  {
    name: "with-footer___nl-BE",
    path: "/nl-BE/with-footer",
    meta: with_45footer2XhL01PTTkMeta || {},
    component: () => import("/vercel/path0/pages/with-footer.vue").then(m => m.default || m)
  },
  {
    name: "with-header-footer___nl-NL___default",
    path: "/with-header-footer",
    meta: with_45header_45footerasjQUEGbfyMeta || {},
    component: () => import("/vercel/path0/pages/with-header-footer.vue").then(m => m.default || m)
  },
  {
    name: "with-header-footer___nl-NL",
    path: "/nl-NL/with-header-footer",
    meta: with_45header_45footerasjQUEGbfyMeta || {},
    component: () => import("/vercel/path0/pages/with-header-footer.vue").then(m => m.default || m)
  },
  {
    name: "with-header-footer___en-US",
    path: "/en-US/with-header-footer",
    meta: with_45header_45footerasjQUEGbfyMeta || {},
    component: () => import("/vercel/path0/pages/with-header-footer.vue").then(m => m.default || m)
  },
  {
    name: "with-header-footer___fr-FR",
    path: "/fr-FR/with-header-footer",
    meta: with_45header_45footerasjQUEGbfyMeta || {},
    component: () => import("/vercel/path0/pages/with-header-footer.vue").then(m => m.default || m)
  },
  {
    name: "with-header-footer___nl-BE",
    path: "/nl-BE/with-header-footer",
    meta: with_45header_45footerasjQUEGbfyMeta || {},
    component: () => import("/vercel/path0/pages/with-header-footer.vue").then(m => m.default || m)
  },
  {
    name: "with-header___nl-NL___default",
    path: "/with-header",
    meta: with_45header09ivHq3APOMeta || {},
    component: () => import("/vercel/path0/pages/with-header.vue").then(m => m.default || m)
  },
  {
    name: "with-header___nl-NL",
    path: "/nl-NL/with-header",
    meta: with_45header09ivHq3APOMeta || {},
    component: () => import("/vercel/path0/pages/with-header.vue").then(m => m.default || m)
  },
  {
    name: "with-header___en-US",
    path: "/en-US/with-header",
    meta: with_45header09ivHq3APOMeta || {},
    component: () => import("/vercel/path0/pages/with-header.vue").then(m => m.default || m)
  },
  {
    name: "with-header___fr-FR",
    path: "/fr-FR/with-header",
    meta: with_45header09ivHq3APOMeta || {},
    component: () => import("/vercel/path0/pages/with-header.vue").then(m => m.default || m)
  },
  {
    name: "with-header___nl-BE",
    path: "/nl-BE/with-header",
    meta: with_45header09ivHq3APOMeta || {},
    component: () => import("/vercel/path0/pages/with-header.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/auth",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/ui/atoms",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/ui/molecules",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/ui/organisms",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/ui/guides",
    component: component_45stub5X4Ei38PMg
  }
]