<template>
  <div :class="twMerge('ms-auto shrink-0', props.appendClass)">
    <slot />
  </div>
</template>

<script setup lang="ts">
import {twMerge} from "tailwind-merge";

type Props = {
  appendClass?: string;
}

const props = defineProps<Props>();
</script>