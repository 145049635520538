<template>
  <div role="status" class="max-w-full animate-pulse mt-8 px-1">
    <span class="sr-only">Loading</span>
    <div
      class="h-3 bg-natural-light-grey rounded-full w-56 mb-4"
    ></div>
    <div
      class="h-2 bg-natural-light-grey rounded-full max-w-lg mb-2.5"
    ></div>
    <div
      class="h-2 bg-natural-light-grey rounded-full max-w-md mb-2.5"
    ></div>
    <div
      class="h-2 bg-natural-light-grey rounded-full max-w-[330px] mb-2.5"
    ></div>
    <div
      class="h-2 bg-natural-light-grey rounded-full max-w-[200px]"
    ></div>
  </div>
</template>
