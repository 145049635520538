export interface ApiResponse {
  meta: {
    code: number;
    monetateId: string;
  };
  data: {
    responses: Array<{
      requestId: string;
      actions: Array<{
        actionType: string;
        actionId: number;
        items: Array<Item>;
        actionEvents: Array<string>;
        component: string;
      }>;
    }>;
  };
}

interface Item {
  recSetId: number;
  _affinity: number;
  description: string;
  slotIndex: number;
  title: string;
  price: number | null;
  itemGroupId: string;
  recToken: string;
  imageLink: string;
  _rawAffinity: number;
  link: string;
  salePrice: number | null;
  productType: string;
  id: string;
}
interface MonetateEvent {
  eventType: string;
  [key: string]: any;
}

// Enum for action types
enum ActionType {
  PDP = "pdp",
  HOME = "home",
  CART = "cart",
  TROLLEY = "trolley",
  ORDER_CONFIRMATION = "order-confirmation",
  PLP = "plp",
  ACCOUNT = "my-account",
  CATEGORIES = "categories"
}

/**
 * Service class for interacting with the Monetate API.
 */
export default class MonetateService {
  /**
   * Calls the Monetate Engine API with the provided events.
   * @param event - Array of Monetate events to send to the API
   * @returns Promise resolving to the API response
   */

  async callEngineApi(
    event: MonetateEvent[],
    isDescisionRequest = true
  ): Promise<ApiResponse> {
    const uuid = useRandomUUID();
    const monetateId = useCookie("mt.v").value;

    let param = {
      channel: "a-a77f9577/p/toolstation.nl",
      monetateId: monetateId,
      events: [...event],
    };

    if (isDescisionRequest) {
      param.events.push({
        eventType: "monetate:decision:DecisionRequest",
        requestId: uuid,
        includeReporting: false,
      });
    }

    const response = await useAjax<ApiResponse>(
      "https://engine.monetate.net/api/engine/v1/decide/toolstation",
      {
        method: "post",
        params: param,
      }
    );

    return response as ApiResponse;
  }

  /**
   * Tracks a page view event.
   * @param url - The URL of the page being viewed
   * @returns Promise resolving to the API response or undefined
   */
  async trackPageView(url: string): Promise<ApiResponse | undefined> {
    const event_object = {
      eventType: "monetate:context:PageView",
      url: url,
      pageType: url,
    };
    return this.callEngineApi([event_object]);
  }

  /**
   * Tracks a product view event.
   * @param slug - The slug of the product being viewed
   */
  async trackProductView(slug: string): Promise<void> {
    const parts = slug.split("-");
    const product_id = parts.pop() || "";
    const MonetateStore = useMonetateStore();

    if (!product_id) return;

    const event_object = [
      {
        eventType: "monetate:context:PageView",
        url: "https://www.toolstation.nl/",
        pageType: "pdp",
      },
      {
        eventType: "monetate:context:ProductDetailView",
        products: [{ productId: product_id }],
      },
    ];

    try {
      const response = await this.callEngineApi(event_object);

      if (response.data) {
        MonetateStore.pdp_actions = this.filterActions(response, {
          actionType: "monetate:action:OmnichannelRecommendation",
        });
        MonetateStore.processActionsByType(ActionType.PDP);
      }
    } catch (err) {
      useErrorHandler(err, "high");
    }
  }

  /*
   * Tracks a home page view event.
   */
  async trackHomePageView(): Promise<void> {
    const event_object: MonetateEvent[] = [
      {
        eventType: "monetate:context:PageView",
        url: "https://www.toolstation.nl/",
        pageType: "home-page",
      },
    ];

    try {
      await this.callEngineApi(event_object);
    } catch (error) {
      useErrorHandler(error, "high");
    }
  }

  /**
   * Tracks a cart view event.
   * @param cartRows - Array of cart items
   */
  async trackCartView(cartRows: Array<{ [key: string]: any }>): Promise<void> {
    const event_object: MonetateEvent[] = [
      {
        eventType: "monetate:context:CartView",
        cartRows: cartRows,
      },
    ];
    await this.callEngineApi(event_object);
  }

  /**
   * Handles an impression event.
   */
  async handleImpression(): Promise<void> {
    const event_object: MonetateEvent[] = [
      // Define your impression event object structure here
    ];
    await this.callEngineApi(event_object);
  }

  async trackTrolleyPageView(): Promise<void> {
    const monetateStore = useMonetateStore();
    const event_object: MonetateEvent[] = [
      {
        eventType: "monetate:context:PageView",
        url: "https://www.toolstation.nl/",
        pageType: "trolley",
      },
    ];
    try {
      const response = await this.callEngineApi(event_object);

      monetateStore.trolley_page_actions = this.filterActions(response, {
        actionType: "monetate:action:OmnichannelRecommendation",
      });
      monetateStore.processActionsByType(ActionType.TROLLEY);
    } catch (err) {
      useErrorHandler(err, "high");
    }
  }
  async trackCategoriesView(): Promise<void> {
    const monetateStore = useMonetateStore();
    const event_object: MonetateEvent[] = [
      {
        eventType: "monetate:context:PageView",
        url: "https://www.toolstation.nl/",
        pageType: "categories",
      },
    ];
    try {
      const response = await this.callEngineApi(event_object);

      monetateStore.categories_page_actions = this.filterActions(response, {
        actionType: "monetate:action:OmnichannelRecommendation",
      });
      monetateStore.processActionsByType(ActionType.CATEGORIES);
    } catch (err) {
      useErrorHandler(err, "high");
    }
  }
  async trackMyAccountView(): Promise<void> {
    const monetateStore = useMonetateStore();
    const event_object: MonetateEvent[] = [
      {
        eventType: "monetate:context:PageView",
        url: "https://www.toolstation.nl/",
        pageType: "my-account",
      },
    ];
    try {
      const response = await this.callEngineApi(event_object);

      monetateStore.my_account_page_actions = this.filterActions(response, {
        actionType: "monetate:action:OmnichannelRecommendation",
      });
      monetateStore.processActionsByType(ActionType.ACCOUNT);
    } catch (err) {
      useErrorHandler(err, "high");
    }
  }

  async trackOrderConfirmationPageView(): Promise<void> {
    const monetateStore = useMonetateStore();
    const event_object: MonetateEvent[] = [
      {
        eventType: "monetate:context:PageView",
        url: "https://www.toolstation.nl/",
        pageType: "order-confirmation",
      },
    ];
    try {
      const response = await this.callEngineApi(event_object);

      monetateStore.order_confirmation_page_actions = this.filterActions(
        response,
        {
          actionType: "monetate:action:OmnichannelRecommendation",
        }
      );
      monetateStore.processActionsByType(ActionType.ORDER_CONFIRMATION);
    } catch (err) {
      useErrorHandler(err, "high");
    }
  }

  async trackProductThumbnailView(recTokens: string[]) {
    const monetateStore = useMonetateStore();
    const event_object: MonetateEvent[] = [
      {
        eventType: "monetate:record:recImpressions",
        recImpressions: [...recTokens],
      },
    ];

    const response = await this.callEngineApi(event_object, false);
  }

  async trackProductCardClick(recToken: string) {
    const event_object: MonetateEvent[] = [
      {
        eventType: "monetate:record:RecClick",
        recClicks: [{ productId: "99532" }],
      },
    ];
    await this.callEngineApi(event_object, false);
  }

  // For Builder
  async trackBuilderPageView(): Promise<void> {
    const monetateStore = useMonetateStore();
    const event_object: MonetateEvent[] = [
      {
        eventType: "monetate:context:PageView",
        url: "https://www.toolstation.nl/",
        pageType: monetateStore.builder_page_type,
      },
    ];
    try {
      const response = await this.callEngineApi(event_object);
      let actions = this.filterActions(response,{
        actionType: "monetate:action:OmnichannelRecommendation",
      });
      monetateStore.builder_recommendations_id = monetateStore.extractItemsByComponent(actions,monetateStore.builder_component);
    } catch (err) {
      useErrorHandler(err, "high");
    }
  }
  /**
   * Filters actions based on provided criteria.
   * @param response - The API response to filter
   * @param filterCriteria - Criteria to filter the actions
   * @returns Filtered array of actions
   */

  private filterActions(
    response: ApiResponse,
    filterCriteria: { actionType?: string; component?: string }
  ): Array<any> {
    return response.data.responses.flatMap((responseItem) =>
      responseItem.actions.filter(
        (action) =>
          action.actionType === filterCriteria.actionType ||
          action.component === filterCriteria.component
      )
    );
  }
}

// Top selling products
// You may also like
// Others also looked at
// Similar products - for compare PDP
// Related products
// Often bought together
