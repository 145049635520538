<template>
  <TsRow class="flex justify-between border items-center animate-pulse bg-gray-50">
    <div class="image border w-16 h-16 bg-gray-100 ml-5"></div>
    <TsColumn class="flex flex-col items-start justify-evenly">
      <div class="bg-gray-200 h-5 w-72 my-2 rounded"></div>
      <div class="bg-gray-200 h-4 w-64 rounded mt-1"></div>
    </TsColumn>
  </TsRow>
  <TsRow class="flex justify-between border items-center animate-pulse bg-gray-50">
    <div class="image border w-16 h-16 bg-gray-100 ml-5"></div>
    <TsColumn class="flex flex-col items-start justify-evenly">
      <div class="bg-gray-200 h-5 w-72 my-2 rounded"></div>
      <div class="bg-gray-200 h-4 w-64 rounded mt-1"></div>
    </TsColumn>
  </TsRow>
  <TsRow class="flex justify-between border items-center animate-pulse bg-gray-50">
    <div class="image border w-16 h-16 bg-gray-100 ml-5"></div>
    <TsColumn class="flex flex-col items-start justify-evenly">
      <div class="bg-gray-200 h-5 w-72 my-2 rounded"></div>
      <div class="bg-gray-200 h-4 w-64 rounded mt-1"></div>
    </TsColumn>
  </TsRow>
</template>
