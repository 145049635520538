import { ProductAttributeMap } from "~/types/ecom/product/attribute-map";
import type { ProductAttribute } from "~/types/ecom/product/product.type";

// --------------------- Get product ratings from a scale of 1 to 5 ------------------------ //
export function getProductRating(attributes: ProductAttribute[]): string | null {
  const attribute = attributes.find(
    (attr) => attr.id === ProductAttributeMap.ReviewRating
  );
  return attribute ? attribute.value : null;
}

// --------------------- Get Product Additional Images ------------------------------------//
export function getProductImages(attributes: ProductAttribute[]): string[] {
    const images = attributes.filter((attr) => attr.id === ProductAttributeMap.AdditionalPhotos).map((attr) => attr.value)
    return images

}

// --------------------- Get customer review numbers for the product ------------------------ //
export function getNumberOfReviews(attributes: ProductAttribute[]): string | null {
  const attribute = attributes.find(
    (attr) => attr.id === ProductAttributeMap.NumberOfReviews
  );
  return attribute ? attribute.value : null;
}

// --------------------- Get minimum allowable quantity information ------------------------ //
export function getQuantityMinimum(attributes: ProductAttribute[]): string | null {
  const attribute = attributes.find(
    (attr) => attr.id === ProductAttributeMap.QuantityMinimum
  );
  return attribute ? attribute.value : null;
}

// --------------------- Get delivery timeline for dropship delivery ------------------------ //
export function getDropshipDeliveryTime(attributes: ProductAttribute[]): string | null {
  const attribute = attributes.find(
    (attr) => attr.id === ProductAttributeMap.DropshipDeliveryTime
  );
  return attribute ? attribute.value : null;
}

// --------------------- Get offer value to be displayed on top right of the card ------------------------ //
export function getTopRightTagValues(attributes: ProductAttribute[]): string[] | null {
  // @multiple web image top right overlay for same product are found
  const topRightTagValues: string[] = attributes
    .filter((attr) => attr.id === ProductAttributeMap.TagOnTopRight)
    .map((attr) => attr.value);

  return topRightTagValues.length ? topRightTagValues : null;
}

// --------------------- Get detailed description HTML ------------------------ //
export function getDetailedDescriptionHTML(attributes: ProductAttribute[]): string {
  // @multiple detailed descriptions for same product are found
  const attributeValues = attributes
    .filter((attr) => attr.id === ProductAttributeMap.DetailedDescription)
    .map((attr) => attr.value);

  return attributeValues.length ? attributeValues.join("").replace(/\n/g, "<br>") : "";
}



export function getPromotionalMessages(attributes: ProductAttribute[]): string[] | null {
  // @multiple web image top right overlay for same product are found
  if(!attributes){
    return null;
  }
  const promoMessages: string[] = attributes
    .filter((attr) => attr.id === ProductAttributeMap.PromotionMessage)
    .map((attr) => attr.value);

  return promoMessages.length ? promoMessages : null;
}