<template>
  <div
      :dataTestid="props.dataTestid"
      :class="twMerge('flex items-center', props.appendClass)" >
      
    <input
        type="checkbox"
        :id="props.id || `checkbox-${random_id}`"
        :data-testid="`${props.dataTestid}-input`"
        v-bind="$attrs"
        v-model="model"
        :class="twMerge(
            'rounded-[2px] cursor-pointer mr-2 border-natural-light-grey bg-natural-soft-white size-5 focus:ring-0 focus:ring-offset-0',
            props.size === 'sm' && 'size-4',
            props.size === 'lg' && 'size-6',
            props.inputClass
        )"
    />
    <label
        :for="props.id || `checkbox-${random_id}`"
        :data-testid="`${props.dataTestid}-label`"
        :class="twMerge(
            'text-idle-black text-base font-medium cursor-pointer',
            props.labelClass
        )"
    >
      <slot name="label">
        <span v-if="props.isRichText" v-html="props.label">
        </span>
        <span v-else>
          {{ props.label }}
        </span>

        <span v-if="props.quantity || props.forceShow" class="ml-1 font-normal text-natural-grey">({{props.quantity}})</span>
      </slot>
    </label>
  </div>
</template>

<script setup lang="ts">
import {ref, onMounted} from "vue"
import {twMerge} from "tailwind-merge";
import {useRandomUUID} from "../../composables/useRandomUUID";

type Props = {
  dataTestid?: string;
  id?: string;
  label?: string;
  appendClass?: string;
  inputClass?: string;
  labelClass?: string;
  forceShow?: boolean;
  isRichText?: boolean;
  quantity?: string | number;
  size?: string;
}

const props = withDefaults(defineProps<Props>(),{
  forceShow:false,
  isRichText:false
});

defineOptions({
  inheritAttrs: false
})

const model = defineModel();

const random_id = ref();

onMounted(() => {
  random_id.value = useRandomUUID();
})
</script>