<!--Accordion Container-->

<script setup lang="ts">
import {useRandomUUID} from "../../composables/useRandomUUID";
import {onMounted, ref} from "vue";
import {twMerge} from "tailwind-merge";
import { initAccordions } from "flowbite";
const count=ref(0)
type Props = {
  open?:boolean,
  dataTestid?:string,
  dataActiveClasses?: string,
  appendClass?: string
};
const props = withDefaults(defineProps<Props>(), {
  open:false
});
const random_id = ref<string>("");

onMounted(() => {
  initAccordions();
  random_id.value = useRandomUUID();
});
</script>


<template>
  <div :id="'accordion-' + random_id"
       :data-accordion="props.open===true?'open':'collapse'"
       :data-testid="props.dataTestid"
       :class="twMerge('mb-5', props.appendClass)"
       :data-active-classes="twMerge('border-b-0 !font-bold', props.dataActiveClasses)">
    <slot></slot>
  </div>
</template>


