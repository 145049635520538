import { datadogRum } from "@datadog/browser-rum";
import { useAjaxServer } from "../composables/useAjaxServer";

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  // if(config.public.env !== 'local' || !config.public.ddApplicationId
  //     || !config.public.ddApplicationToken || !config.public.ddApiKey
  //     || !config.public.ddApplicationKey){
  //    return false;
  // }

  let res = await useAjaxServer('api/datadog/validate',{
    headers:{
      'accept':'application/json'
    }
  });

  if(res && !res.success){
    return false;
  }

  function beforeSend(event, context) {
      // turn a "resource" error into an "ongoing issue" if backend returned a 400 or 500
      // because it means that frontend failed at guiding a user
      if (event.type === "resource" && (event.resource.status_code === 500
          || event.resource.status_code === 400)) {
          datadogRum.addError(new Error(`Bad request: ${event.resource.method} ${event.resource.url} ${event.resource.status_code}`), context);
      }
  }

  try {

      const url = useRequestURL()

      let app_env = 'local';

      if(url.hostname.includes('feature')){
          app_env = 'feature';
      }else if(url.hostname.includes('develop') || url.hostname.includes('dev')){
          app_env = 'develop';
      }else if(url.hostname.includes('staging')){
          app_env = 'staging';
      }

      if(app_env === 'local'){
          return false;
      }

      datadogRum.init({
          beforeSend,
          applicationId: config.public.ddApplicationId,
          clientToken: config.public.ddApplicationToken,
          site: 'datadoghq.eu',
          service: 'eu24web-nuxt',
          env: app_env,
          // Specify a version number to identify the deployed version of your application in Datadog
          version: config.public.version,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 20,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          trackViewsManually: true,
      });

      datadogRum.setGlobalContext({
          host: url.host
      });

      const authStore = useAuthStore();

      if (authStore.is_authenticated && authStore.user && authStore.user.id) {
          datadogRum.setUser({
              id:authStore.user.id,
              email:authStore.user.email,
              name:authStore.user.first_name +' '+authStore.user.last_name
          });
      }


      return {
          provide: {
              ddRum: datadogRum,
          },
      };

  } catch (error) {
      console.error('Datadog client token is invalid:', error.message);
      // inject('datadogRum', null);
  }
});
