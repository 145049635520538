export default defineNuxtRouteMiddleware((to, from) => {

    if(to.path === '/protected') return;
    const config = useRuntimeConfig();
    const rootStore = useRootStore()
    const authStore = useAuthStore()
    const release_version = useCookie('release_version');

    if(rootStore.isBuilderPreview(to)){
        return ;
    }
    
    if (!release_version.value || config.public.version !== release_version.value) {
        rootStore.isReleaseModalVisible = true;
    }

    if(to.meta.layout != 'checkout'){
        authStore.is_guest_checkout = false;
        authStore.guest = null;
    }

})
