<template>
  <div :class="twMerge('leading-relaxed basis-auto shrink grow', props.appendClass)">
    <slot />
  </div>
</template>
<script setup lang="ts">
import {twMerge} from 'tailwind-merge';

type Props = {
  appendClass?: string
}

const props = defineProps<Props>()
</script>