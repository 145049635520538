<template>
  <div
    :id="'autocomplete-' + randomId"
    :class="$attrs.class || twMerge('flex flex-col w-full', props.appendClass)"
  >
    <div class="flex items-center space-x-2">
      <TsInput
        :data-testid="props.dataTestid"
        v-model="searchQuery"
        :variant="props.inputVariant"
        @input="completeSearch"
        @focus="handleFocus"
        @blur="handleBlur"
        @keydown.enter.exact.prevent="handleEnterKey"
        @keydown.arrow-up.prevent="navigateOptions('up')"
        @keydown.arrow-down.prevent="navigateOptions('down')"
        v-bind="$attrs"
        :appendClass="twMerge('rounded-full bg-white', props.inputClass)"
        :icon="searchIcon"
        icon-position="left"
        with-background
        :with-clear="
          props.withSearchButton || props.variant === 'voiceSearch'
            ? false
            : true
        "
        :disabled="props.disabled"
      >
        <template
          v-if="props.variant === 'voiceSearch' && props.withFilter"
          #left
        >
          <Icon
            name="lets-icons:filter-alt"
            class="w-6 h-6 text-natural-silver-grey me-1 cursor-pointer"
            :data-testid="props.dataTestid + '-icon'"
            @click.prevent="emit('filter', dropdownVisible)"
          />
        </template>
        <template v-if="props.withSearchButton" #right>
          <TsButton
            size="xs"
            label="Search"
            :append-class="
              twJoin(
                'px-7 rounded',
                props.disabled && 'dark:bg-danger-border/60 dark:text-white'
              )
            "
            @click.prevent="completeSearch"
            :disabled="props.disabled"
          />
        </template>
        <template v-if="props.variant === 'voiceSearch'" #right>
          <Icon
            :data-testid="props.dataTestid + '-icon'"
            name="icon-park-outline:voice"
            class="w-6 h-6 text-natural-silver-grey cursor-pointer"
            @click.prevent="emit('talk', dropdownVisible)"
          />
        </template>
      </TsInput>
      <span v-if="props.variant === 'voiceSearch'" @click="completeSearch">
        <slot name="icon-btn">
          <TsButton
            size="xs"
            append-class="p-2.5"
            :disabled="props.disabled"
            :data-testid="props.dataTestid + '-button'"
          >
            <Icon
              name="bx:search"
              size="26"
              :data-testid="props.dataTestid + '-icon'"
            />
          </TsButton>
        </slot>
      </span>
    </div>

    <div
      v-if="props.noResults"
      class="relative select-none text-sm py-1 text-natural-dark-grey"
    >
      {{ noResultMessage }}
    </div>
    <Transition
      enter-from-class="opacity-0"
      leave-to-class="opacity-0"
      enter-active-class="duration-150"
      leave-active-class="duration-150"
    >
      <div
        :data-testid="props.dataTestid"
        @click.stop="dropdownVisible = false"
        v-if="dropdownVisible && !props.noResults"
        class="relative z-[1000] before:bg-black before:inset-0 before:bg-black/50 before:content-[''] before:fixed before:top-[145px] lg:before:top-[76px] before:w-full before:h-full before:z-[100]"
      >
        <ul
          :class="
            twMerge(
              'text-natural-dark-grey absolute z-[110] mt-2 w-full max-w-full max-h-96 shadow-md' +
                ' overflow-y-auto dark:text-natural-light-grey rounded-xl bg-idle-white dark:bg-natural-dark-grey px-4',
              props.dropdownClass
            )
          "
          ref="options_list"
          tabindex="0"
        >
          <!-- /check length -->
          <div v-if="props.options && props.options.length > 0">
            <slot name="dropdown-parent" :section="props.options">
              <div v-for="(section, s_key) in props.options">
                <hr
                  v-if="
                    s_key > 0 && props.options[s_key - 1].options.length > 0
                  "
                />
                <TsTypography v-if="section.options.length > 0">
                  {{ section?.name }}
                </TsTypography>
                <slot name="dropdown">
                  <li
                    v-for="option in section.options"
                    :key="JSON.stringify(option)"
                    :data-testid="props.dataTestid + '-li'"
                    @click.stop="handleSelection(option)"
                    class="cursor-pointer py-1 hover:bg-green-200 dark:hover:bg-natural-black transition-colors"
                  >
                    <div class="select-none">
                      <slot name="list-option" :option="option">
                        <TsRow class="flex justify-between px-2">
                          <span>
                            {{
                              props.optionLabel
                                ? option[props.optionLabel]
                                : option
                            }}
                          </span>
                          <Icon
                            v-if="section.canDelete"
                            name="material-symbols:delete"
                            size="12"
                            @click="
                              emit(
                                'delete',
                                props.optionLabel
                                  ? option[props.optionLabel]
                                  : option
                              )
                            "
                            class="w-6 h-6 me-1 cursor-pointer hover:bg-gray-200 rounded-full p-1"
                            :data-testid="props.dataTestid + '-icon'"
                          />
                        </TsRow>
                      </slot>
                    </div>
                  </li>
                </slot>
              </div>
            </slot>
          </div>
        </ul>
      </div>
    </Transition>
    <div
      :data-testid="props.dataTestid"
      class="text-xs text-natural-silver-grey"
      v-if="$slots['helper-text']"
    >
      <slot name="helper-text"> display your message here</slot>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T">
import { ref, computed, onMounted, onBeforeMount } from "vue";
import { twMerge, twJoin } from "tailwind-merge";
import { useRandomUUID } from "../../composables/useRandomUUID";

const route = useRoute();
// options
defineOptions({
  inheritAttrs: false,
});

const VARIANTS = ["voiceSearch"] as const;

const INPUTVARIANTS = ["default", "primary", "danger", "success"] as const;

type Props = {
  options?: T[];
  optionLabel?: keyof T;
  searchLabel?: keyof T;
  loading?: boolean;
  noResults?: boolean;
  noResultMessage?: string;
  iconPos?: "left" | "right";
  withSearchButton?: boolean;
  variant?: (typeof VARIANTS)[number];
  inputVariant?: (typeof INPUTVARIANTS)[number];
  withFilter?: boolean;
  inputClass?: string;
  appendClass?: string;
  dropdownClass?: string;
  disabled?: boolean;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  iconPos: "right",
  inputVariant: "default",
  inputClass: "",
  appendClass: "",
  dropdownClass: "",
  noResultMessage: "No results found. Please try again",
});

// emits
const emit = defineEmits<{
  input: [query: string | undefined, dropdownVisible: boolean];
  focus: [query: string | undefined, dropdownVisible: boolean];
  navigate: [activeOption: T | null];
  select: [option: T];
  listFocus: [option: T];
  listUnFocus: [option: T];
  filter: [dropdownVisible: boolean];
  talk: [dropdownVisible: boolean];
  enter: [query: string | undefined, dropdownVisible: boolean];
}>();

// model binding
const selectedOption = defineModel<T | null>("selected");
const searchQuery = defineModel<string>("input");

// random UUID for each vue instance
const randomId = ref("");
const isFocused = ref(false);

const options_list = ref<HTMLElement | null>(null);

const activeOpt = ref<T | null>(null);

const dropdownVisible = defineModel<boolean>("visible", {
  default: false,
});

const algoliaStore = useAlgoliaStore();

const searchIcon = computed<string>(() =>
  props.loading
    ? "eos-icons:loading"
    : props.variant === "voiceSearch" ||
      props.withFilter ||
      props.iconPos !== "left"
    ? "bx:search"
    : "bx:search"
);

// watch multiple sources to toggle dropdownVisible
watch(
  () => props.options,
  (updatedOptions): void => {
    const suggestionsLength = updatedOptions.length;

    // Anirudh please check this code. Fixed Dropdown visibility issue

    // dropdownVisible.value = suggestionsLength > 0;
  }
);

watch(
  searchQuery,
  (newSearchQuery) => {
    if (!newSearchQuery) {
      algoliaStore.lastTypedKeyword = "";
    }
    if (isFocused.value && !dropdownVisible.value) {
      dropdownVisible.value = true;
    }
  },
  {
    immediate: true,
  }
);

onUnmounted(() => {
  dropdownVisible.value = false; // Close the dropdown when the component is destroyed
});

// methods
const completeSearch = () => {
  emit("input", searchQuery.value, dropdownVisible.value);
  dropdownVisible.value = true;
};

const handleFocus = (e: Event) => {
  isFocused.value = true;
  dropdownVisible.value = true;
  document.body.classList.add("overflow-y-hidden");
  emit("focus", searchQuery.value, dropdownVisible.value);
};

const handleBlur = () => {
  // dropdownVisible.value = false;
  isFocused.value = false;
  document.body.classList.remove("overflow-y-hidden");
};

const handleListOptionHover = (option: T) => {
  emit("listFocus", option);
};

const handleSelection = (option: T) => {
  selectedOption.value = option;
  emit("select", option);

  let labelValue: string = JSON.stringify(option);

  if (props.searchLabel && option[props.searchLabel as keyof T]) {
    labelValue = option[props.searchLabel as keyof T] as string;
  } else if (props.optionLabel && option[props.optionLabel as keyof T]) {
    labelValue = option[props.optionLabel as keyof T] as string;
  }

  searchQuery.value = labelValue;
  dropdownVisible.value = false;
  activeOpt.value = null;
};

const navigateOptions = (direction: "up" | "down") => {
  if (!options_list.value) return;
  let newIndex: any;

  const options = options_list.value.querySelectorAll("li");
  const currentIndex = Array.from(options).findIndex((option) =>
    option.classList.contains("selected")
  );

  if (direction === "up") {
    newIndex = currentIndex > 0 ? currentIndex - 1 : options.length - 1;
  } else if (direction === "down") {
    newIndex = currentIndex < options.length - 1 ? currentIndex + 1 : 0;
  }
  activeOpt.value = props.options[newIndex];
  emit("navigate", activeOpt.value as T | null);
  options[currentIndex]?.classList.remove("selected");
  options[newIndex]?.classList.add("selected");
  options[newIndex]?.scrollIntoView({ block: "nearest" });
};

const handleEnterKey = () => {
  if (activeOpt.value) {
    handleSelection(activeOpt.value as T); // Ensure selection is called with activeOpt
  } else {
    emit("enter", searchQuery.value, dropdownVisible.value);
    if (searchQuery.value) {
      dropdownVisible.value = false;
    }
  }
  // dropdownVisible.value = false;
};

watch(
  () => route.fullPath,
  () => {
    if (
      !(
        route.fullPath.includes("brands") ||
        route.fullPath.includes("categories") ||
        route.fullPath.includes("search") ||
        route.fullPath.includes("product")
      )
    ) {
      searchQuery.value = '';
      algoliaStore.searchItem = '';
    }
    dropdownVisible.value = false;
  }
);

onBeforeMount(() => {
  searchQuery.value = "";
  activeOpt.value = null;
});

onMounted(() => {
  randomId.value = useRandomUUID();

  /* close the suggestions dropdown on clicking outside */
  window.document.addEventListener(
    "click",
    (event: Event) => {
      const autocompleteId = "autocomplete-" + randomId.value;

      if (
        (event.target as HTMLInputElement).closest(`#${autocompleteId}`) ===
        null
      )
        dropdownVisible.value = false;
      isFocused.value = false;
    },
    { passive: true }
  );
});
</script>

<style scoped>
.selected {
  @apply bg-green-200 dark:bg-natural-black;
}
</style>
