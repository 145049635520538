import type { CustomerAddressItem } from "~/types/ecom/address.type";

export default function isCustomerAddressPresent(
  addresses: CustomerAddressItem[],
  targetAddress: Omit<CustomerAddressItem, "id" | "formatted">
) {
  const { type, county, ...target } = targetAddress;

  return addresses.some((address) => {
    const addressRaw = (({ id, formatted, type, county, ...rest }) => rest)(address);
    return isEqualAddress(addressRaw, target);
  });
}

export type ComparableNLAddress = Omit<CustomerAddressItem, "id" | "formatted" | "type" | "county">

export function isEqualAddress(
  source: ComparableNLAddress,
  target: ComparableNLAddress
) {
  const normalize = (value: string | undefined): string =>
    (value || "").trim().toLowerCase(); 

  return (
    normalize(source.line_1) === normalize(target.line_1) &&
    normalize(source.line_2) === normalize(target.line_2) &&
    normalize(source.line_3) === normalize(target.line_3) &&
    normalize(source.town) === normalize(target.town) &&
    normalize(source.postcode) === normalize(target.postcode) &&
    source.country_id === target.country_id 
  );
}

export function isEqualCustomerAddress(
  source: CustomerAddressItem,
  target: CustomerAddressItem
) {
  const { id: sourceId, formatted: sourceFormatted, type: sourceType, county: sourceCounty, ...comparableSource } = source;
  const { id: targetId, formatted: targetFormatted, type: targetType, county: targetCounty, ...comparableTarget } = target;

  return isEqualAddress(comparableSource, comparableTarget);
}
