import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import { EventConstants } from "~/assets/constants/ecomApi";
import { ProductService } from "~/services/product.service";
import type { APIState } from "~/types/api-state.type";
import type { Product } from "~/types/ecom/product/product.type";

const myList = "My List";
const saveForLater = "Save For Later";
export const useSavelistStore = defineStore({
  id: "savelist",
  state: () => ({
    // accountStore: useAccountStore(),

    /**
     * This is the core state handler. This will sit on the parent level.
     * It depends on the ping status of savelist endpoint group.
     * saved-lists/_ping
     */
    savelist_page_state: <APIState>{
      status: "loading",
      message: "Page is idle",
    },

    savelist_api_healthy: <boolean>false,

    /**
     * This is the state handler for the user savelist section
     */
    user_savelists_state: <APIState>{
      status: "success",
      message: "Page is idle",
    },

    user_savelists_modal_state: <APIState>{
      status: "success",
      message: "Page is idle",
    },

    user_savelists: <any>[],

    first_fetch: <boolean>true,

    selected_savelist_state: <APIState>{
      status: "idle",
      message: "Component is idle",
    },

    /**
     * This is the state handler for the selected savelist's item.
     */
    selected_savelist_item_state: <APIState>{
      status: "loading",
      message: "Page is idle",
    },

    current_active_savelist: {
      name: <string>"Loading...",
      id: <string>"",
      items: <object[] | []>[
        {
          product_id: "",
          quantity: "",
        },
      ],
      internal: <boolean>false,
      index: <number>0,
      is_default: false, // New key to track if this is the default list
    },

    current_active_product: <Product | null>null,
    products_in_current_savelist: <Product[]>[],

    lastUpdated: <number | null>null,
    /**
     * Probably an unique id idk what I'll use for this, and what is something that stays on the front end only
     */
    default_list: "",

    add_new_list_modal: <boolean>false,
    edit_list_modal: <boolean>false,
    delete_list_modal: <boolean>false,
    remove_product_modal: <boolean>false,

    modal_savelist_from_visible: <boolean>false,

    savelist_item_to_delete: {
      name: <string>"",
      product_code: <string>"",
    },

    consolidated_product_code_list: <any>[],

    selectedSavelist: <string[]>[],

    my_list_exists: false,
    save_for_later_exists: false,

    user_save_for_later_id: <string>"",
    my_list_id: <string>"",

    fetching_in_progress: <boolean>false,
    tab_clicked_array: <String[]>[],
    savelist_share_modal_visible: false,
    encoded_savelist_url: "",
    modal_savelist_multiple_items_visible: <boolean>false,
  }),
  actions: {
    /**
     * Saved lists endpoint health check method
     */
    async verifySaveListHealth() {
      if (!this.savelist_api_healthy) {
        useStateModifier(this.savelist_page_state, "loading");
        try {
          const response = await useAjaxEcom("/saved-lists/_ping", {
            method: "get",
          });

          if (response.data.msg === "OK") {
            this.savelist_api_healthy = true;
            useStateModifier(this.savelist_page_state, "success");
          }
          if (response.success === false) {
            this.savelist_api_healthy = false;
            useStateModifier(this.savelist_page_state, "failed");
            throw response.errors[0];
          }
        } catch (err) {
          await useErrorHandler(err);
        }
      }
    },

    async getUserSaveLists(forceFetch?: boolean, newIndex?: number) {
      this.fetching_in_progress = true;
      useStateModifier(this.user_savelists_state, "loading");
      this.resetAllModalStates();
      const authStore = useAuthStore();

      const customerId = ref("");
      if (authStore.user) {
        customerId.value = authStore.user.id;
      }
      if (authStore.is_authenticated) {
        try {
          const response = await useAjaxEcom(
            `/customers/${customerId.value}/saved-lists`
          );

          if (response.data.length > 0) {
            const dateTimeRegex = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
            const formattedResponse = response.data
              .filter((item: any) => !dateTimeRegex.test(item.name))
              .map((item: any) => ({
                ...item,
                label: item.name,
              }));

            this.user_savelists = formattedResponse;

            if (!this.my_list_exists) {
              await this.validateAndCreateDefaultLists(myList);
            }
            if (!this.save_for_later_exists) {
              await this.validateAndCreateDefaultLists(saveForLater);
            }

            this.markSavelistAsInternal();
            let index =
              newIndex !== undefined
                ? newIndex
                : this.current_active_savelist.index;
            if (this.first_fetch) {
              this.updateCurrentSavelist(
                this.user_savelists[0].name,
                this.user_savelists[0].id,
                this.user_savelists[0].items,
                this.user_savelists[0].internal,
                0,
                this.user_savelists[0].is_default
              );
              this.first_fetch = false;
            } else {
              this.updateCurrentSavelist(
                this.user_savelists[index].name,
                this.user_savelists[index].id,
                this.user_savelists[index].items,
                this.user_savelists[index].internal,
                index,
                this.user_savelists[index].is_default
              );
              this.first_fetch = false;
            }
            this.bakeConsolidatedItemList(formattedResponse);
            if (
              this.user_savelists &&
              this.user_savelists[index].items.length > 0
            ) {
              this.getSavelistItemDetails(this.user_savelists[index].items);
            } else {
              useStateModifier(this.selected_savelist_item_state, "failed");
            }

            useStateModifier(this.user_savelists_state, "success");
            useStateModifier(this.selected_savelist_state, "success");
          } else {
            this.my_list_exists = false;
            this.save_for_later_exists = false;
            await this.validateAndCreateDefaultLists(myList);
            await this.validateAndCreateDefaultLists(saveForLater);

            this.markSavelistAsInternal();
            if (this.user_savelists.length > 0) {
              useStateModifier(this.user_savelists_state, "success");
              useStateModifier(this.selected_savelist_state, "success");
            } else {
              useStateModifier(this.selected_savelist_state, "failed");
              useStateModifier(
                this.user_savelists_state,
                "failed",
                "No saved lists found. Create new to begin!"
              );
            }
          }
        } catch (err) {
          await useErrorHandler(err);
        }
      }

      this.fetching_in_progress = false;
    },

    async makeDefault(savelistId: string): Promise<void> {
      try {
        // 1. Make an API request to set the savelist as the default
        const response = await useAjaxEcom(
          `/saved-lists/${savelistId}/default`,
          {
            method: "post",
          }
        );
        if (response && response.error == false) {
          throw new Error(
            "Failed to make this savelist the default. Invalid response."
          );
        }
      } catch (error) {
        // Improved error handling with more context
        const errorMessage =
          error instanceof Error ? error.message : "Unknown error occurred.";
        toast(`Error making savelist default: ${errorMessage}`, {
          autoClose: true,
          type: "error",
        });
        await useErrorHandler(error);
      }
    },

    async validateAndCreateDefaultLists(listName: string) {
      // check if both default lists are present
      // const isMyListPresent = this.checkIfDefaultSavelistExists(myList);
      // const isSaveForLaterPresent =
      //   this.checkIfDefaultSavelistExists(saveForLater);
      const listExists = this.checkIfDefaultSavelistExists(listName);

      if (listExists) {
        return; // Both lists already exist, no need to create
      }

      // Create "My List" if it doesn't exist

      await this.createNewList(listName);

      // Re-fetch save lists to update state after creating "Save For Later"
      // await this.getUserSaveLists();

      // Mark default lists as internal
      this.markSavelistAsInternal();
    },

    checkIfDefaultSavelistExists(checkSavelistName: string) {
      return this.user_savelists.some(
        (savelist) => savelist.name === checkSavelistName
      );
    },

    extractSaveForLaterId() {
      const saveForLaterList = this.user_savelists.find(
        (savelist) => savelist.name === saveForLater
      );
      if (saveForLaterList) {
        this.user_save_for_later_id = saveForLaterList.id;
      } else {
        this.user_save_for_later_id = "";
      }
    },

    extractMyListId() {
      const myListinternal = this.user_savelists.find(
        (list) => list.name === myList
      );
      if (myListinternal) {
        this.my_list_id = myListinternal.id;
      } else {
        this.my_list_id = "";
      }
    },

    async createDefaultSavelists(listName: string) {
      await this.createNewList(listName);
    },

    async addItemToSaveForLater(
      product: {
        product_code: string;
        quantity: string | number;
      },
      showNotification: boolean = true,
      bulkAdditon: boolean = false
    ) {
      const authStore = useAuthStore();

      const customerId = ref("");
      if (authStore.user) {
        customerId.value = authStore.user.id;
      }

      if (this.user_save_for_later_id.length <= 0) {
        this.extractSaveForLaterId();
      }

      try {
        const response = await useAjaxEcom(
          `/saved-lists/${this.user_save_for_later_id}/items`,
          {
            headers: {
              "X-Toolstation-Customer-Id": customerId.value,
            },
            params: product,
            method: "post",
          }
        );
        this.add_new_list_modal = false;

        if (!response.data) {
          throw "Couldn't add product to savelist.";
        }
        if (showNotification) {
          toast("Successfully added to list!", {
            autoClose: true,
            type: "success",
          });
        }
        const searchResultStore = useSearchResultStore();
        searchResultStore.updateIsSavedStatus(product.product_code, true);
        if (!bulkAdditon) {
          await this.getUserSaveLists(true);
        }
      } catch (err) {
        if (showNotification) {
          toast(`${err}`, {
            autoClose: true,
            type: "error",
          });
        }
        this.add_new_list_modal = false;
        await useErrorHandler(err);
      }
    },

    async addItemToMyList(
      product: {
        product_code: string;
        quantity: string | number;
      },
      confirmationLineId?: number
    ) {
      const authStore = useAuthStore();

      const customerId = ref("");
      if (authStore.user) {
        customerId.value = authStore.user.id;
      }

      if (this.my_list_id.length <= 0) {
        this.extractMyListId();
      }

      try {
        const response = await useAjaxEcom(
          `/saved-lists/${this.my_list_id}/items`,
          {
            headers: {
              "X-Toolstation-Customer-Id": customerId.value,
            },
            params: product,
            method: "post",
          }
        );
        this.add_new_list_modal = false;

        if (!response.data) {
          throw "Couldn't add product to savelist.";
        }
        const trolleyStore = useTrolleyStore();
        trolleyStore.removeFromTrolley(confirmationLineId!);
        toast("Successfully added to My List!", {
          autoClose: true,
          type: "success",
        });
        const searchResultStore = useSearchResultStore();
        searchResultStore.updateIsSavedStatus(product.product_code, true);

        await this.getUserSaveLists(true);
      } catch (err) {
        toast(`${err}`, {
          autoClose: true,
          type: "error",
        });
        this.add_new_list_modal = false;
        await useErrorHandler(err);
      }
    },

    markSavelistAsInternal() {
      this.user_savelists = this.user_savelists.map((item: any) => ({
        ...item,
        internal: item.name === myList || item.name === saveForLater,
      }));
      // this.getUserSaveLists();
    },

    async createNewList(newListName: string, makeDefault: boolean) {
      useStateModifier(this.user_savelists_modal_state, "loading");
      const authStore = useAuthStore();
      const customerId = ref("");
      if (authStore.user) {
        customerId.value = authStore.user.id;
      }

      if (authStore.is_authenticated) {
        try {
          const response = await useAjaxEcom(
            `/customers/${customerId.value}/saved-lists`,
            {
              headers: {
                "X-Toolstation-Customer-Id": customerId.value,
              },
              params: {
                name: newListName,
              },
              method: "post",
            }
          );

          if (!response.data) {
            useStateModifier(this.savelist_page_state, "failed");
            useStateModifier(this.user_savelists_modal_state, "failed");
            throw `${useTranslation(
              "couldNotCreate",
              "Kan niet maken"
            )} ${newListName}`;
          }

          // Check if the toggle is on to set as default
          const saveListId = response.data.id;
          if (makeDefault) {
            const defaultResponse = await useAjaxEcom(
              `/saved-lists/${saveListId}/default`,
              {
                method: "post",
              }
            );
            if (defaultResponse && defaultResponse.error == false) {
              throw new Error(
                `${useTranslation(
                  "failedToMake",
                  "Kan de nieuwe bewaarlijst niet standaard instellen"
                )}`
              );
            }
          }

          if (newListName === saveForLater) {
            this.save_for_later_exists = true;
          }
          this.current_active_savelist.index = this.user_savelists.length;
          await this.getUserSaveLists(true, this.current_active_savelist.index);

          if (newListName !== myList && newListName !== saveForLater) {
            toast(
              `${useTranslation(
                "newSaveList",
                "Nieuwe lijst"
              )} ${newListName} ${useTranslation("created", "gemaakt!")}`,
              {
                autoClose: true,
                type: "success",
              }
            );
          }

        } catch (e) {
          useErrorHandler(e);
          useStateModifier(this.user_savelists_modal_state, "failed");
        }
      }
    },

    async deleteSavedlist() {
      const authStore = useAuthStore();

      const customerId = ref("");
      if (authStore.user) {
        customerId.value = authStore.user.id;
      }

      try {
        const response = await useAjaxEcom(
          `/saved-lists/${this.current_active_savelist.id}`,
          {
            headers: {
              "X-Toolstation-Customer-Id": customerId.value,
            },
            method: "delete",
          }
        );

        if (
          response &&
          response.data == undefined &&
          response.success == false
        ) {
          throw `Couldn't delete savelist.`;
        }

        toast("List deleted successfully!", {
          autoClose: true,
          type: "success",
        });
        this.current_active_savelist.index--;
        await this.getUserSaveLists(true);
      } catch (err) {
        toast(`${err}`, {
          autoClose: true,
          type: "error",
        });
        this.resetAllModalStates();

        await useErrorHandler(err);
      }
    },
    async editSavedlist(updateListName: string, makeDefault: boolean) {
      const authStore = useAuthStore();

      const customerId = ref("");
      if (authStore.user) {
        customerId.value = authStore.user.id;
      }

      try {
        const response = await useAjaxEcom(
          `/saved-lists/${this.current_active_savelist.id}`,
          {
            headers: {
              "X-Toolstation-Customer-Id": customerId.value,
            },
            params: {
              name: updateListName,
            },
            method: "patch",
          }
        );

        this.edit_list_modal = false;

        if (response && response.data.error && response.success == false) {
          throw "Failed to edit savelist.";
        }
        toast("List edited successfully!", {
          autoClose: true,
          type: "success",
        });

        const saveListId = response.data.id;
        if (makeDefault) {
          await this.makeDefault(saveListId);
        }
        await this.getUserSaveLists(true);
      } catch (err) {
        toast(`${err}`, {
          autoClose: true,
          type: "error",
        });
        this.resetAllModalStates();

        await useErrorHandler(err);
      }
    },

    async addItemToSavelist(
      savelistId: string,
      productCode: string,
      quantity?: string,
      showToast: boolean = true
    ) {
      logEvent({
        options: {
          eventAction: "click",
          eventProperties: {
            item: {
              savelistId: savelistId,
              productCode: productCode,
              quantity: quantity,
            },
            currency: EventConstants.currency
          },
          eventInteraction: true,
          eventLabel: `User added Product: ${productCode} to Savelist: ${savelistId}`,
          eventName: "add_to_wish_list",
        },
      });
      const authStore = useAuthStore();

      const customerId = ref("");
      if (authStore.user) {
        customerId.value = authStore.user.id;
      }

      try {
        const response = await useAjaxEcom(`/saved-lists/${savelistId}/items`, {
          headers: {
            "X-Toolstation-Customer-Id": customerId.value,
          },
          params: {
            product_code: productCode,
            quantity: quantity ?? "1",
          },
          method: "post",
        });
        this.add_new_list_modal = false;

        if (!response.data) {
          throw "Couldn't add product to savelist.";
        }
        if (showToast) {
          toast("Successfully added to list!", {
            autoClose: true,
            type: "success",
          });
        }
        await this.getUserSaveLists(true);
        const searchResultStore = useSearchResultStore();
        searchResultStore.updateIsSavedStatus(productCode, true);
      } catch (err) {
        if (showToast) {
          toast(`${err}`, {
            autoClose: true,
            type: "error",
          });
        }
        this.add_new_list_modal = false;
        await useErrorHandler(err);
      }
    },
    async deleteItemFromSavelist(
      savelistId: string,
      productCode: string,
      showToast: boolean = true
    ) {
      const authStore = useAuthStore();

      const customerId = ref("");
      if (authStore.user) {
        customerId.value = authStore.user.id;
      }

      try {
        const response = await useAjaxEcom(
          `/saved-lists/${savelistId}/items/${productCode}`,
          {
            headers: {
              "X-Toolstation-Customer-Id": customerId.value,
            },

            method: "delete",
          }
        );
        this.add_new_list_modal = false;

        if (!response.data) {
          this.resetAllModalStates();
          throw "Couldn't delete product";
        }
        const savelist = this.user_savelists.find(
          (list) => list.id === savelistId
        );

        if (savelist) {
          savelist.items = savelist.items.filter(
            (item) => item.product_code !== productCode
          );
        }
        if (showToast) {
          toast("Item removed successfully!", {
            autoClose: true,
            type: "success",
          });
        }
        await this.getUserSaveLists(true);
        const searchResultStore = useSearchResultStore();
        searchResultStore.updateIsSavedStatus(productCode, false);
      } catch (err) {
        if (showToast) {
          toast(`Couldn't remove product from savelist.`, {
            autoClose: true,
            type: "error",
          });
        }
        this.add_new_list_modal = false;
        this.resetAllModalStates();
        await useErrorHandler(err);
      }
    },

    updateCurrentSavelist(
      newLabel: string,
      newId: string,
      newItems: [],
      newInternal: boolean,
      newIndex: number,
      newIsDefault: boolean
    ) {
      useStateModifier(this.selected_savelist_item_state, "loading");
      this.current_active_savelist.id = newId;
      this.current_active_savelist.name = newLabel;
      this.current_active_savelist.index = newIndex;
      this.current_active_savelist.items = newItems;
      this.tab_clicked_array.push(newId);
      if (newItems.length > 0) {
        if (this.current_active_savelist.id !== newId) return;
        this.getSavelistItemDetails(this.current_active_savelist.items, newId);
      } else {
        this.current_active_savelist.items = [];
        const index = this.tab_clicked_array.indexOf(newId);
        if (index !== -1) {
          this.tab_clicked_array.splice(index, 1);
        }
        this.products_in_current_savelist = [];

        useStateModifier(this.selected_savelist_item_state, "failed");
      }
      this.current_active_savelist.internal = newInternal;
      this.current_active_savelist.is_default = newIsDefault;
    },

    async getSavelistItemDetails(items: object[], id?: string) {
      const stockStore = useStockStore();
      const product_codes = this.extractProductCodes(items);
      try {
        this.products_in_current_savelist =
          await stockStore.getStockForProducts(product_codes);
        if (this.products_in_current_savelist.length <= 0) {
          throw "Couldn't fetch details";
        }
        if (id) {
          const index = this.tab_clicked_array.indexOf(id);
          if (index !== -1) {
            this.tab_clicked_array.splice(index, 1);
          }
          if (
            this.tab_clicked_array.length === 0 &&
            this.current_active_savelist.items.length
          ) {
            this.selected_savelist_item_state.status = "success";
          }
        } else {
          this.selected_savelist_item_state.status = "success";
        }
      } catch (error) {
        useStateModifier(this.selected_savelist_item_state, "failed");
        useErrorHandler(error);
      }
    },

    async getSavelistShareCode() {
      this.savelist_share_modal_visible = true;
      this.getEncodeSavelistCodeQueryString(this.current_active_savelist.id);
    },

    getEncodeSavelistCodeQueryString(SavelistCode: string) {
      const activeSavelist = this.current_active_savelist;

      if (
        !activeSavelist ||
        !activeSavelist.items ||
        activeSavelist.items.length === 0
      ) {
        return;
      }

      const productCodes = activeSavelist.items.map(
        (item: any) => item.product_code
      );

      const productString = productCodes.join(",");

      const queryString = `${SavelistCode}#${productString}`;
      const encodedString = window.btoa(queryString);

      this.encoded_savelist_url = encodedString;
    },

    extractProductCodes(items: object[]): string[] {
      return items.map((item) => item.product_code);
    },

    bakeConsolidatedItemList(savelists: []) {
      this.consolidated_product_code_list = savelists.reduce(
        (result, savelist) => {
          const codesWithIds = savelist.items.map((item) => ({
            product_code: item.product_code,
            id: savelist.id,
          }));
          return result.concat(codesWithIds);
        },
        []
      );
    },

    populateSavedList() {
      // Populate the selectedSavelist array based on the active product code
      if (this.current_active_product?.code) {
        this.user_savelists.forEach((savelist) => {
          if (
            savelist.items.some(
              (item) => item.product_code === this.current_active_product?.code
            )
          ) {
            this.selectedSavelist.push(savelist.id);
          }
        });
      }
    },

    resetAllModalStates() {
      this.add_new_list_modal = false;
      this.edit_list_modal = false;
      this.delete_list_modal = false;
      this.remove_product_modal = false;
    },

    async isThisProductSaved(product_code: string): Promise<boolean> {
      if (!product_code) {
        return false;
      }
      if (
        this.consolidated_product_code_list.length <= 0 &&
        this.user_savelists.length <= 0
      ) {
        if (!useSavelistStore().fetching_in_progress) {
          await this.getUserSaveLists();
        }
      }

      return this.consolidated_product_code_list.some(
        (item: any) => item.product_code === product_code
      );
    },

    destroyUserSavelist() {
      this.user_savelists = [];
      this.products_in_current_savelist = [];
      this.consolidated_product_code_list = [];
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSavelistStore, import.meta.hot));
}
