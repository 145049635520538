<template>
  <!-- Triggering Element -->
  <div
    :class="twMerge('inline-flex relative max-w-max cursor-pointer', props.triggerClass)"
    ref="triggerElRef"
    v-bind="$attrs"
    :data-testid="dataTestid"
    @click.prevent="handleTrigger"
  >
    <slot />
  </div>
  <!-- Target Popover Content -->
  <div
    v-if="!hideTooltip"
    ref="targetElRef"
    :id="'tooltip-' + randomId"
    role="tooltip"
    :class="
      twMerge('tooltip absolute !z-40 invisible opacity-0', tooltipClass, props.rootClass)
    "
  >
    <div
      v-bind="$attrs"
      :class="
        $attrs.class ||
        twMerge('inline-flex px-4 space-x-4 items-start', props.appendClass)
      "
    >
      <div
        class="font-medium text-secondary-black dark:text-idle-white text-sm max-w-max"
      >
        <slot
          name="tooltip-content"
          :triggerElRef="triggerElRef"
          :targetElRef="targetElRef"
          :popover="popover"
        >
          {{ text }}
        </slot>
      </div>

      <!-- Close icon to toggle the visibility of the popover -->
      <slot
        name="close-icon"
        :triggerElRef="triggerElRef"
        :targetElRef="targetElRef"
        :popover="popover"
      >
        <Icon
          v-if="mode === 'popover'"
          :name="closeIcon"
          :id="'close-icon-' + randomId"
          @click.stop="handleClose"
          :aria-hidden="true"
          size="16"
          :class="closeIconClass"
        />
      </slot>
    </div>
    <div v-show="!noArrow" data-popper-arrow :class="explicitClassMerger"></div>
  </div>
</template>

<script setup lang="ts">
import { twMerge, type ClassNameValue } from "tailwind-merge";
import { ref, onMounted, computed, onBeforeUnmount, watch} from "vue";
import { Popover } from "flowbite";
import { useRandomUUID } from "../../composables/useRandomUUID";

import type {
  PopoverInterface,
  PopoverOptions,
  InstanceOptions,
} from "flowbite";

const emit = defineEmits<{
  show: [
    triggerElRef: HTMLElement | null,
    targetElRef: HTMLElement | null,
    tooltip: PopoverInterface | null
  ];
  hide: [
    triggerElRef: HTMLElement | null,
    targetElRef: HTMLElement | null,
    tooltip: PopoverInterface | null
  ];
}>();

// template refs
const triggerElRef: Ref<HTMLElement | null> = ref(null);
const targetElRef: Ref<HTMLElement | null> = ref(null);
const popover: Ref<PopoverInterface | null> = ref(null);

// types
type PlacementType = PopoverOptions["placement"];

type Props = {
  mode?: "popover" | "tooltip";
  text?: string;
  offset?: number;
  position?: PlacementType;
  noArrow?: boolean;
  hideTooltip?: boolean;
  closeIcon?: string;
  rootClass?: ClassNameValue;
  appendClass?: ClassNameValue;
  triggerClass?: ClassNameValue;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  mode: "tooltip",
  text: "Tooltip Content",
  offset: 0,
  position: "top",
  noArrow: false,
  hideTooltip: false,
  closeIcon: "mdi:close",
  rootClass: "",
  appendClass: "",
  triggerClass: "",
  dataTestid: "atom-tooltip",
});

defineOptions({
  inheritAttrs: false,
});

const visible = defineModel<boolean>("visible");

// WATCH V-MODEL
watch(visible, (vis) => {
  if (vis) {
    popover.value?.show();
    emit("show", triggerElRef.value, targetElRef.value, popover.value);
  } else {
    popover.value?.hide();
    emit("hide", triggerElRef.value, targetElRef.value, popover.value);
  }
});

// CLASSES
const tooltipClass =
  "inline-block shadow-secondary-black/25 shadow-[0px_2px_8px_0px,0px_1px_4px_0px] dark:shadow max-w-80 transition-opacity duration-300 bg-natural-soft-white rounded-md dark:bg-natural-dark-grey !mt-3";

const closeIconClass =
  "shrink-0 cursor-pointer text-natural-dark-grey hover:text-secondary-black dark:text-idle-white dark:hover:text-white";

const explicitClassMerger = computed(() => {
  return twMerge(
    "tooltip-arrow",
    [
      "after:!border-0 after:bg-natural-soft-white dark:after:bg-natural-dark-grey after:shadow-secondary-black/25 before:!hidden after:!w-3 after:!h-3",
    ],
    [
      props.position === "top" &&
      "after:!bottom-px after:shadow-[3px_3px_2px_-1px]",
      props.position === "bottom" && "after:shadow-[-3px_-2px_5px_-1px]",
      props.position === "right" && " after:shadow-[-3px_2px_5px_-1px]",
      props.position === "left" &&
      "after:!right-px after:shadow-[3px_-2px_5px_-1px]",
    ]
  );
});

// METHODS & EMITS
const handleClose = (): void => {
  popover.value?.hide();
  emit("hide", triggerElRef.value, targetElRef.value, popover.value);
};
const handleTrigger = (): void => {
  emit("show", triggerElRef.value, targetElRef.value, popover.value);
};

const randomId: Ref<string> = ref("");

onMounted(() => {
  randomId.value = useRandomUUID();

  const options: PopoverOptions = {
    triggerType: props.mode === "popover" ? "click" : "hover",
    placement: props.position,
    offset: props.offset,
    onShow: () => {
      visible.value = true;
    },
    onHide: () => {
      visible.value = false;
    },
  };

  const instanceOptions: InstanceOptions = {
    id: "tooltip-" + randomId.value,
    override: true,
  };

  popover.value = new Popover(
    targetElRef.value,
    triggerElRef.value,
    options,
    instanceOptions
  );

  //checking for visible state
  if (visible.value) popover.value?.toggle();
});

onBeforeUnmount(() => {
  popover.value?.destroy();
});
</script>
