import { defineStore } from "pinia";
import type { OrderResponse, OrderItem } from "~/types/ecom/order.type";
import { DeliveryMethodCodes } from "~/assets/constants/ecomApi";
import type { Branch } from "~/types/ecom/branch.type";
import type { CustomerAddressItem } from "~/types/ecom/address.type";
import { EcomService } from "~/services/ecom.service";

interface DeliveryMethod {
  code: string;
  name: string;
}

export const useMyOrderStore = defineStore({
  id: "myOrderStore",
  state: () => ({
    delivery_channels: <DeliveryMethod[]>[],
    products: <any>[],
    order: <OrderResponse | null>null,
    order_id: <string | null>null,
    order_total: <string | null>null,
    order_summary: <string | null>null,
    delivery_address: <CustomerAddressItem | null>null,
    collection_address: <Branch | null>null,
    is_directship: <boolean>false,
    is_collection: <boolean>false,
    is_next_day_collection: <boolean>false,
    is_order_loading: <boolean>false,
    is_delivery: <boolean>false,
  }),
  actions: {
    async getOrderDetails(order_id: string) {
      try {
        const response = await useAjaxEcom(`/orders/${order_id}`);

        if (response && response.data) {
          await this.processOrder(response);
        } else if (response && response.error) {
          useErrorHandler(response.error, "high");
        }
      } catch (error) {
        useErrorHandler(error, "high");
      } finally {
        this.is_order_loading = false;
      }
    },

    async processOrder(order: OrderResponse) {
      this.is_order_loading = true;
      this.order = order;
      this.order_id = order.data.id;
      this.order_total = order.data.order_totals.formatted;
      this.FilterDeliveryChannels(order.data.items);
      this.FilterProducts(order.data.items);
      await this.getAddresses();
    },

    FilterDeliveryChannels(items: OrderItem[]) {
      this.delivery_channels = items
        .filter((item) => !item.product.sellable)
        .map((method) => ({
          code: method.product.code,
          name: method.product.name,
        }));

      // Use a Set to track codes for better performance
      const deliveryCodes = new Set(
        this.delivery_channels.map((item) => item.code)
      );

      this.is_collection = deliveryCodes.has(DeliveryMethodCodes.Collection);
      this.is_next_day_collection = deliveryCodes.has(
        DeliveryMethodCodes.NextDayCollection
      );
      this.is_delivery =
        deliveryCodes.has(DeliveryMethodCodes.Delivery) ||
        deliveryCodes.has(DeliveryMethodCodes.DeliveryUnder);
      this.is_directship = deliveryCodes.has(DeliveryMethodCodes.Directship);
    },

    FilterProducts(items: OrderItem[]) {
      this.products = items.filter((item) => item.product.sellable);
    },
    async getAddresses() {
      const accountStore = useAccountStore();
      const branchStore = useBranchStore();

      await accountStore.getSavedAddresses(this.order?.data.customer_id);

         let collection_address_id: string | null = null;

      if(this.is_collection || this.is_next_day_collection){
          if(this.order.data.collection_address_id){
            collection_address_id = this.order.data.collection_address_id
            
          }else{
            if(!this.order.data.delivery_address_id) return
            collection_address_id = this.order?.data.delivery_address_id;
          }
          await branchStore
            .getAllBranches()
            .then(
              () =>
                (this.collection_address = branchStore.allBranches.find(
                  (address) => address.address_id === collection_address_id
                ))
            );
      }
    
     
      if (this.is_delivery || this.is_directship) {
        let address_id = this.order?.data.delivery_address_id;
        this.delivery_address = accountStore.addresses.find(
          (address) => address.id === address_id
        );
      }
      this.is_order_loading = false;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMyOrderStore, import.meta.hot));
}
