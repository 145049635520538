<script setup lang="ts">
import {twMerge} from "tailwind-merge";

type Props = {
  rows?: number,
  cols?: number,
  mobileRows?: number,
  mobileCols?: number,
  tabletRows?: number,
  tabletCols?: number,
  space?: number,
  spaceX?: number,
  spaceY?: number,
  isMobile?: boolean,
  appendClass?: string,
  dataTestid?: string
}

const props = withDefaults(defineProps<Props>(), {
  isMobile: false,
  dataTestid: 'atom-grid',
})
</script>


<template>
  <div
      :data-testid="props.dataTestid"
      :class="
      twMerge('grid grid-space',
      props.mobileCols && `grid-cols-${props.mobileCols}`,
      props.mobileRows && `grid-row-${props.mobileRows}`,
      props.tabletCols && `md:grid-cols-${props.tabletCols}`,
      props.tabletRows && `md:grid-row-${props.tabletRows}`,
      props.cols && `lg:grid-cols-${props.cols}`,
      props.rows && `lg:grid-rows-${props.rows}`,
      props.space && `gap-${props.space}`,
      props.isMobile && `grid-cols-${props.cols}`,
      props.spaceX && `gap-${props.spaceX}`,
      props.spaceY && `gap-${props.spaceY}`,
      props.appendClass
      )"
      v-bind="$attrs"
  >
    <slot/>
  </div>
</template>


<!--  GRID COLUMNS-->
<!--  grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8 grid-cols-9 -->
<!--  grid-cols-10 grid-cols-11 grid-cols-12 -->

<!--  lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4 lg:grid-cols-5 lg:grid-cols-6 lg:grid-cols-7 lg:grid-cols-8 lg:grid-cols-9 -->
<!--  lg:grid-cols-10 lg:grid-cols-11 lg:grid-cols-12 -->

<!--  md:grid-cols-1 md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 md:grid-cols-5 md:grid-cols-6 md:grid-cols-7 md:grid-cols-8 md:grid-cols-9 -->
<!--  md:grid-cols-10 md:grid-cols-11 md:grid-cols-12 -->

<!--  GRID ROWS-->
<!--  grid-rows-1 grid-rows-2 grid-rows-3 grid-rows-4 grid-rows-5 grid-rows-6 grid-rows-7 grid-rows-8 grid-rows-9 -->
<!--  grid-rows-10 grid-rows-11 grid-rows-12-->

<!--  md:grid-rows-1 md:grid-rows-2 md:grid-rows-3 md:grid-rows-4 md:grid-rows-5 md:grid-rows-6 md:grid-rows-7 md:grid-rows-8 md:grid-rows-9 -->
<!--  md:grid-rows-10 md:grid-rows-11 md:grid-rows-12-->

<!--  lg:grid-rows-1 lg:grid-rows-2 lg:grid-rows-3 lg:grid-rows-4 lg:grid-rows-5 lg:grid-rows-6 lg:grid-rows-7 lg:grid-rows-8 lg:grid-rows-9 -->
<!--  lg:grid-rows-10 lg:grid-rows-11 lg:grid-rows-12-->

<!--  GRID GAPS-->
<!--  gap-1 gap-2 gap-3 gap-4 gap-5 gap-6 gap-7 gap-8 gap-9 gap-10 gap-11 gap-12 gap-14 gap-16 gap-20 gap-24 gap-28 gap-32 -->
<!--  gap-36 gap-40 gap-44 gap-48 gap-52 gap-56 gap-60 gap-64 gap-72 gap-80 gap-96-->

<!--  GRID X GAPS-->
<!--  gap-x-1 gap-x-2 gap-x-3 gap-x-4 gap-x-5 gap-x-6 gap-x-7 gap-x-8 gap-x-9 gap-x-10 gap-x-11 gap-x-12 gap-x-14 gap-x-16 gap-x-20 gap-x-24 gap-x-28 gap-x-32 -->
<!--  gap-x-36 gap-x-40 gap-x-44 gap-x-48 gap-x-52 gap-x-56 gap-x-60 gap-x-64 gap-x-72 gap-x-80 gap-x-96-->

<!--  GRID Y GAPS-->
<!--  gap-y-1 gap-y-2 gap-y-3 gap-y-4 gap-y-5 gap-y-6 gap-y-7 gap-y-8 gap-y-9 gap-y-10 gap-y-11 gap-y-12 gap-y-14 gap-y-16 gap-y-20 gap-y-24 gap-y-28 gap-y-32 -->
<!--  gap-y-36 gap-y-40 gap-y-44 gap-y-48 gap-y-52 gap-y-56 gap-y-60 gap-y-64 gap-y-72 gap-y-80 gap-y-96-->
