<script setup lang="ts">
import { twMerge } from "tailwind-merge";

type Props = {
  heightFull?: boolean;
  isMobile?: boolean;
  isCentered?: boolean;
  isRight?: boolean;
  isLeft?: boolean;
  isMultiline?: boolean;
  gutter?: string;
  noGutter?: boolean;
  appendClass?: string;
};

const props = withDefaults(defineProps<Props>(), {
  heightFull: false,
  // isCentered: false,
});
</script>

<template>
  <div
      :class="
      twMerge('row',
        props.gutter && `gutter-${gutter}`,
        props.noGutter && 'no-gutter',
        props.heightFull && 'h-full',
        props.isCentered && 'is-centered',
        props.isRight && 'is-right',
        props.isLeft && 'is-left',
        props.isMobile && 'is-mobile',
        props.isMultiline && 'flex-wrap',
        appendClass
      )
    "
  >
    <slot></slot>
  </div>
</template>


<!--flex-col flex-row sm:flex-col sm:flex-row md:flex-col md:flex-row lg:flex-col lg:flex-row-->

<!--lg:space-x-0 lg:space-x-1 lg:space-x-2 lg:space-x-3 lg:space-x-4 lg:space-x-5 lg:space-x-6 lg:space-x-7 lg:space-x-8
lg:space-x-9 lg:space-x-10 lg:space-x-11 lg:space-x-12 lg:space-x-14 lg:space-x-16 lg:space-x-20-->

<!--sm:space-x-0 sm:space-x-1 sm:space-x-2 sm:space-x-3 sm:space-x-4 sm:space-x-5 sm:space-x-6 sm:space-x-7 sm:space-x-8
sm:space-x-9 sm:space-x-10 sm:space-x-11 sm:space-x-12 sm:space-x-14 sm:space-x-16 sm:space-x-20-->

<!--space-x-0 space-x-1 space-x-2 space-x-3 space-x-4 space-x-5 spacex-6 space-x-7 space-x-8
space-x-9 space-x-10 spacex-11 space-y-12 space-x-14 space-x-16 space-x-20-->

<!--Y space-->
<!--lg:space-y-0 lg:space-y-1 lg:space-y-2 lg:space-y-3 lg:space-y-4 lg:space-y-5 lg:space-y-6 lg:space-y-7 lg:space-y-8
lg:space-y-9 lg:space-y-10 lg:space-y-11 lg:space-y-12 lg:space-y-14 lg:space-y-16 lg:space-y-20-->

<!--sm:space-y-0 sm:space-y-1 sm:space-y-2 sm:space-y-3 sm:space-y-4 sm:space-y-5 sm:space-y-6 sm:space-y-7 sm:space-y-8
sm:space-y-9 sm:space-y-10 sm:space-y-11 sm:space-y-12 sm:space-y-14 sm:space-y-16 sm:space-y-20-->

<!--space-y-0 space-y-1 space-y-2 space-y-3 space-y-4 space-y-5 space-y-6 space-y-7 space-y-8
space-y-9 space-y-10 space-y-11 space-y-12 space-y-14 space-y-16 space-y-20-->

