import braintree from "braintree-web";

import { PaypalStrategy } from "./braintree/paypal";
import { CreditCardStrategy } from "./braintree/hostedfields";
import { GooglePayStrategy } from "./braintree/googlepay";
import { LocalpayStrategy } from "./braintree/ideal";
import { ApplePayStrategy } from "./braintree/applePay";

export interface PaymentStrategy {
  initializePayment(
    client: braintree.Client,
    data_collector: braintree.DataCollector
  ): Promise<any>;
  processPayment(): Promise<any>;
  // createTransiction(): any;
  verify3Ds?(): any;
  teardown(): any;
}

class PaymentContext {
  private strategy: PaymentStrategy;
  private data_collector: braintree.DataCollector | null = null;
  private client: braintree.Client | null = null;
  private strategies: any;

  constructor(strategy: PaymentStrategy) {
    this.strategy = strategy;
  }

  setStrategy(strategy: PaymentStrategy) {
    this.strategy = strategy;
  }

  async initializePayment(clientToken: string): Promise<void> {
    //---------------- Initializing braintree client---------------------------//

    if (!this.client) {
      this.client = await braintree.client.create({
        authorization: clientToken,
      });
    }

    //---------------- Initialiing data collector-------------------------------//

    this.data_collector = await braintree.dataCollector.create({
      client: this.client,
      paypal: true,
    });

    
    useCheckoutStore().device_data = this.data_collector.deviceData;

    //---------------iniitializing Actual Payment class----------------//
    await this.strategy.initializePayment(this.client, this.data_collector);
  }

  async processPayment() {
    return await this.strategy.processPayment();
  }

  async verify3Ds() {
    return await this.strategy.verify3Ds();
  }

  teardown() {
    this.strategy.teardown();
  }
}

export class PaymentService {
  private client_token: any;
  private paymentContext: PaymentContext;

  constructor() {
    this.braintreeClient = null;
    this.paymentContext = new PaymentContext(); //default
  }

  async initializeBraintree(clientToken: string) {
    this.client_token = clientToken;
  }

  setPaymentMethod(method: string): void {
    switch (method) {
      case "credit_card":
        this.paymentContext.setStrategy(new CreditCardStrategy());
        break;
      case "paypal":
        this.paymentContext.setStrategy(new PaypalStrategy());
        break;
      case "google_pay":
        this.paymentContext.setStrategy(new GooglePayStrategy());
        break;
      case "local_pay":
        this.paymentContext.setStrategy(new LocalpayStrategy());
        break;
      case "apple_pay":
        this.paymentContext.setStrategy(new ApplePayStrategy());
        break;
    }
  }

  async initializePayment() {
    await this.paymentContext.initializePayment(this.client_token);
  }

  async processPayment() {
    await this.paymentContext.processPayment();
  }

  teardown() {
    this.paymentContext.teardown();
  }
}
