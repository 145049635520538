<template>
  <div class="bg-white max-sm:px-4 border w-full">
    <TsRow class="sm:px-7 sm:py-3 max-sm:pt-6 max-sm:pb-4">
      <TsRow class="gap-2 m-0">
        <TsIcon
          @click="hideMenuDrawer"
          name="iconamoon:arrow-left-2-duotone"
          color="#666666"
          size="2rem"
          class="cursor-pointer text-secondary-black"
        />

        <TsTypography
          appendClass="mb-0 block text-base md:text-2xl font-bold
        text-black text-secondary-black sm:hidden"
          :text="
            useTranslation(
              'topBrandMenuText',
              'Upgrade uw gereedschapskist: koop premium topmerken!'
            )
          "
        />
        <TsTitle as="base"
          appendClass="mb-0 text-base md:text-2xl font-bold text-black text-secondary-black hidden sm:block"
          data-testid="top-brand-menu-header"
          :title="
            useTranslation(
              'topBrandMenuText',
              'Upgrade uw gereedschapskist: koop premium topmerken!'
            )
          "
        />
      </TsRow>
    </TsRow>

    <TsRow
      appendClass="flex justify-center align-center mt-2 sm:mt-3 w-full"
    >
      <TsColumn class="max-sm:is-12 sm:min-w-max justify-center">
        <div v-if="builderStore.brand_list_state.status === 'loading'">
          <TsBrandTransition />
        </div>
        <div v-if="builderStore.brand_list_state.status === 'failed'">
          Failed to fetch brands
        </div>
        <div v-if="builderStore.brand_list_state.status === 'success'">
        <TsGrid
          :cols="4"
          :rows="3"
          :space="3"
          class="px-1 sm:mx-2 m-0"
          :mobileCols="2"
          :mobileRows="4"
          v-if="builderStore.builderBrands"
        >
          <div
            class="h-full"
            v-for="brand in builderStore.builderBrands.slice(0, 10)"
            :key="brand.brand.id"
          >
            <NuxtLink
              :to="localepath(`/brands/${brand.brand.title.toLowerCase()}`)"
              @click="emit('onClose')"
              :data-testid="`brand-menu-link-to-brands-${brand.brand.title.toLowerCase()}`"
              class="rounded-md block h-full"
              v-if="brand.brand.isTop"
            >
              <NuxtImg
                class="cursor-pointer w-full"
                width="140"
                height="58"
                :data-testid="`brand-menu-image-of-${brand.brand.title.toLowerCase()}`"
                placeholder=""
                v-if="brand.brand.image"
                :src="brand.brand.image"
                :alt="brand.brand.title"
              />
            </NuxtLink>
          </div>
        </TsGrid>
      </div>
      </TsColumn>
      <TsColumn class="max-sm:is-5 max-w-max hidden sm:flex">
        <NuxtImg
          class="rounded-md"
          src="/images/brand-banner.png"
          alt="brandBannerImg"
          width="428"
          height="217"
          loading="lazy"
        />
      </TsColumn>
    </TsRow>
    <TsRow isCentered class="sm:w-[50rem] py-3">
      <NuxtLink
        class="text-primary text-base font-semibold underline"
        data-testid="link-to-brand-listing-page"
        :to="localepath('/brands')"
        >{{ useTranslation("viewAll", "Bekijk alles") }}</NuxtLink
      >
    </TsRow>
  </div>
</template>

<script setup lang="ts">
const builderStore = useBuilderStore();

const localepath = useLocalePath();

const emit = defineEmits(["onClose"]);

function hideMenuDrawer() {
  emit("onClose");
}
</script>
