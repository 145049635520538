export const getRadialDistanceAtoB = (
  latitude1: number,
  longitude1: number,
  latitude2: number,
  longitude2: number,
  distanceUnit: string
): number => {
  const earthRadius = distanceUnit === "km" ? 6371 : 3959; // Radius of the Earth in kilometers or miles
  const deltaLatitude = toRadians(latitude2 - latitude1);
  const deltaLongitude = toRadians(longitude2 - longitude1);
  const a =
    Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
    Math.cos(toRadians(latitude1)) * Math.cos(toRadians(latitude2)) *
    Math.sin(deltaLongitude / 2) * Math.sin(deltaLongitude / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadius * c;
};

const toRadians = (degrees: number) => degrees * (Math.PI / 180);