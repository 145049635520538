/**
 * Represents the status of a trolley.
 * @property Active [1] - The trolley is currently active
 * @property Ordered [2] - Order has been placed from this trolley
 * @property Abandoned [3] - The trolley has been abandoned by the user
 */
export enum TrolleyStatus {
  Active = 1,
  Ordered,
  Abandoned,
}

/**
 * Represents the different channels for a trolley.
 * @property Delivery [1] - Delivery channel.
 * @property Collection [2] - Collection channel.
 * @property Directship [3] - Direct shipment channel.
 * @property NextDayCollection [4] - Next day collection channel.
 * @property SameDayDelivery [5] - Same day delivery channel.
 */
export enum TrolleyChannel {
  Delivery = 1,
  Collection,
  Directship,
  NextDayCollection,
  SameDayDelivery,
}

/**
 * Represents the various attributes associated with a trolley.
 * @property COLLECTION_ADDRESS [1] - Address for collection.
 * @property PAYMENT_ADDRESS [2] - Address used for payment.
 * @property DELIVERY_ADDRESS [3] - Address for delivery.
 * @property PURCHASE_ORDER_NO [4] - Purchase order number.
 * @property PAYMENT_PROVIDER [5] - The provider used for processing payment.
 * @property LAPOSTE_PICKUP [6] - Pickup location for LaPoste.
 * @property POSTNL_PICKUP [7] - Pickup location for PostNL.
 * @property BRANCH [8] - Branch associated with the trolley.
 * @property NOTES [9] - Any additional notes related to the trolley.
 */
export enum TrolleyAttributeId {
  COLLECTION_ADDRESS = 1,
  PAYMENT_ADDRESS,
  DELIVERY_ADDRESS,
  PURCHASE_ORDER_NO,
  PAYMENT_PROVIDER,
  LAPOSTE_PICKUP,
  POSTNL_PICKUP,
  BRANCH,
  NOTES,
}
