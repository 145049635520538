import qs from 'qs'

export const useAjax = async <T>(url: string, options?: {
    params?: object,
    method?: 'get' | 'post' | 'put' | 'patch' | 'delete',
    query?: object,
    headers?: object,
    show_notifications?: boolean,
    show_loading_indicator?: boolean,
    severity?: string,
    debug?: boolean
}, callback = null) => {

    let default_option = {
        params: null,
        method: 'get',
        query: null,
        headers: null,
        show_notifications: true,
        show_loading_indicator: true,
        severity: null,
        debug: false
    }

    if (options) {
        for (let key in options) {
            default_option[key] = options[key]
        }
    }

    let params = default_option.params
    let method = default_option.method.toLowerCase()
    let query = default_option.query
    let headers = default_option.headers
    let show_notifications = default_option.show_notifications
    let show_loading_indicator = default_option.show_loading_indicator
    let severity = default_option.severity
    let debug = default_option.debug

    let req = {};

    req.method = method.toLowerCase()

    if (method === "get") {
        let query_string = qs.stringify(query)

        if (query_string) {
            url = url + '?' + query_string
        }

    } else {
        req.body = params
    }

    if (headers) {
        req.headers = headers

        if (!req.headers.accept) {
            req.headers.accept = 'application/json';
        }

    }

    try {

        if (debug) {


        }

        if (show_loading_indicator) {
            useLoadingIndicator().start()
        }

        let response = await $fetch<T>(url, req)

        if (show_notifications) {
            await useRootStore().processResponse(response);
        }

        if (callback) {
            if (response.data) {
                callback(response.data, response);
            } else {
                callback(false, response);
            }
        }

        if (show_loading_indicator) {
            useLoadingIndicator().finish();
        }

        return response;
    } catch (error) {

        if (severity) {
            error.name = severity + error.name;
        }

        if (!process.server) {
            await useErrorHandler(error);
        }

        if (debug) {

        }

        let error_message = ['Something went wrong.'];
        if (debug) {
            error_message = [error.message];
        }

        let response = {
            success: false,
            errors: error_message,
            data: error.data && error.data.data ? error.data.data : error.data
        }
        if (callback) {
            callback(false, response);
        }
        if (show_notifications) {
            await useRootStore().processResponse(response);
        }

        if (show_loading_indicator) {
            useLoadingIndicator().finish();
        }

        return response;
    }
};