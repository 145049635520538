
export default defineNuxtPlugin((nuxtApp) => {
    const rootStore = useRootStore()
    // Set up a timer to reset the variable every 5 minutes

    if(!rootStore.cache_expired_at){
        rootStore.cache_expired_at = Date.now();
    }

    setInterval(() => {
        rootStore.checkCacheExpiration()
    }, 600000)
})