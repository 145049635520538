import monetateService from "~/services/monetate.service";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    const trolleyRegex =/trolley/
    const orderConfirmationRegex =/order-confirmation/
    const myAccountRegex =/account/
    const categoriesRegex =/categories/
    const searchRegex =/search/
;

    if (to.meta.layout === "product") {
      await new monetateService().trackProductView(to.params.slug);
    }
    if (to.path === "/") {
      // @TODO: implement and complete `trackHomePageView`
      await new monetateService().trackCategoriesView();
    }
    if (trolleyRegex.test(to.path)) {
    
       await new monetateService().trackTrolleyPageView();
    }
    if(orderConfirmationRegex.test(to.path)){
      await new monetateService().trackOrderConfirmationPageView();
    }
    if(myAccountRegex.test(to.path)){
      await new monetateService().trackMyAccountView();
    }
    if(categoriesRegex.test(to.path)){
      await new monetateService().trackCategoriesView();
    }
    if(searchRegex.test(to.path)){
      await new monetateService().trackCategoriesView();
    }
  }
});
