import { EcomService } from "~/services/ecom.service";
import { PostNLService } from "~/services/postnl.service";
import type { APIState } from "~/types/api-state.type";
import type { PostNLPointResponse } from "~/types/postnl.type";

export const usePostNLStore = defineStore({
  id: "PostNLStore",
  state: () => ({
    locality_search_input: "",
    search_timer: <ReturnType<typeof setTimeout> | null>null,
    selected_locality: <any>null,
    auto_complete_suggestions: [],
    is_processing_locality_search: false,
    no_suggestions_available: false,
    post_nl_address_save_loading: false,

    post_nl_nearest_search_state: <APIState>{
      status: "idle",
    },
    post_nl_collection_points: <PostNLPointResponse[]>[],
    post_nl_modal_visible: false,
  }),
  getters: {
    post_nl_markers: (state) => {
      return state.post_nl_collection_points.map((point) => ({
        lat: point.Latitude,
        lng: point.Longitude,
        content: `
        <p class="text-lg">
        <strong>${point.Name}</strong>
        <span style="float:right; font-size:0.8em; margin:3px 0px 0px 20px;">${
          point.LocationCode
        }</span>
        <br>
        ${point.Address.Street} ${point.Address.HouseNr}${
          point.Address.HouseNrExt || ""
        }<br>${point.Address.Zipcode} ${point.Address.City}
        </p>
        `,
      }));
    },
    formatted_search_query: (state) =>
      state.locality_search_input.replace('"', '\\"').replace(/^\s+|\s+$/g, ""),
  },
  actions: {
    resetStatuses() {
      useStateModifier(this.post_nl_nearest_search_state, "idle");
      this.no_suggestions_available = false;
    },

    async initPostNLCollectionPoints() {
      this.post_nl_modal_visible = true;

      try {
        const res = await PostNLService.fetchPostNLCollectionPoints();
        this.post_nl_collection_points =
          res.GetLocationsResult.ResponseLocation;
      } catch (err) {
        useErrorHandler(err, "medium");
        return err;
      }
    },

    async getNearestPostNLPoints() {
      useStateModifier(this.post_nl_nearest_search_state, "loading");
      try {
        //------------------- Send the latitude and longitude of the selected locality ---------------------------//

        const res = await PostNLService.fetchPostNLCollectionPoints(
          this.selected_locality?.location.lat,
          this.selected_locality?.location.lng
        );

        if (!res.GetLocationsResult.ResponseLocation.length)
          throw new Error("no nearest post nl point available");

        this.post_nl_collection_points =
          res.GetLocationsResult.ResponseLocation;

        if (this.post_nl_collection_points.length)
          useStateModifier(this.post_nl_nearest_search_state, "success");
      } catch (err) {
        useErrorHandler(err, "medium");
        useStateModifier(this.post_nl_nearest_search_state, "failed");
        return err;
      }
    },

    // --------------------- TODO - useAjax debouncer ------------------------ //
    async delayedAutoCompleteSearch(searchDelayDuration: number = 500) {
      this.resetStatuses();

      if (!this.formatted_search_query.length) {
        this.no_suggestions_available = false;
        this.auto_complete_suggestions = [];
        return;
      }

      if (
        this.formatted_search_query.length !== this.locality_search_input.length
      )
        return;

      // Clear previous timeout if any
      if (this.search_timer) {
        clearTimeout(this.search_timer);
        this.search_timer = null;
      }

      // Start a new timeout
      this.search_timer = setTimeout(async () => {
        await this.getAutocompleteResults(this.formatted_search_query);
      }, searchDelayDuration);
    },

    /* ECOM locality service - place suggestions with their lat, lang details */
    async getAutocompleteResults(searchInput: string) {
      this.resetStatuses();
      this.is_processing_locality_search = true;
      try {
        const ecomLocalitySearchResponse =
          await EcomService.fetchEcomLocalityDetails(searchInput);

        if (!ecomLocalitySearchResponse) {
          throw new Error(
            "service not initialized correctly or check private key"
          );
        }

        if (!ecomLocalitySearchResponse.localities.length) {
          if (searchInput.length > 0) this.no_suggestions_available = true;
          this.auto_complete_suggestions = [];
          return;
        }
        /* success: assign localities suggestions and pass in autocomplete options */
        this.auto_complete_suggestions = ecomLocalitySearchResponse.localities;
      } catch (err) {
        useErrorHandler(err, "medium");
        return err;
      } finally {
        this.is_processing_locality_search = false;
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePostNLStore, import.meta.hot));
}
