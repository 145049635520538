export class PostNLService {
  static async fetchPostNLCollectionPoints(
    Latitude: number = 52.161744,
    Longitude: number = 4.528659,
    CountryCode: string = "NL"
  ) {
    // const endPoint = `/api/postnl/shipment/v2_1/locations/nearest/geocode`;
    const res = await useAjaxServer('/api/postnl', {
      query: {
        Latitude,
        Longitude,
        CountryCode,
      },
    });
    if (res.Error) {
      throw new Error("No Post-NL collection point found");
    }
    return res;
  }
}
