<template>
  <div
      :id="'autocomplete-' + randomId"
      :class="
      $attrs.class ||
      twMerge('flex flex-col w-full', props.appendClass)
    "
  >
    <div :data-testid="props.dataTestid" class="flex items-center space-x-2">
      <TsInput
          data-testid="autocomplete"
          v-model="searchQuery"
          :variant="props.inputVariant"
          @input="completeSearch"
          @focus="handleFocus"
          @keydown.enter.exact.prevent="handleEnterKey"
          @keydown.arrow-up.prevent="navigateOptions('up')"
          @keydown.arrow-down.prevent="navigateOptions('down')"
          v-bind="$attrs"
          :appendClass="twMerge('rounded', props.inputClass)"
          :icon="searchIcon"
          :icon-position="props.iconPos === 'left' ? 'left' : undefined"
          with-background
          :with-clear="
          props.withSearchButton || props.variant === 'voiceSearch'
            ? false
            : true
        "
          :disabled="props.disabled"
      >
        <template
            v-if="props.variant === 'voiceSearch' && props.withFilter"
            #left
        >
          <Icon
              name="lets-icons:filter-alt"
              class="w-6 h-6 text-natural-silver-grey me-1 cursor-pointer"
              :data-testid="props.dataTestid + '-icon'"
              @click.prevent="emit('filter', dropdownVisible)"
          />
        </template>
        <template v-if="props.withSearchButton" #right>
          <TsButton
              size="xs"
              label="Search"
              :append-class="
              twJoin(
                'px-7 rounded',
                props.disabled && 'dark:bg-danger-border/60 dark:text-white'
              )
            "
              @click.prevent="completeSearch"
              :disabled="props.disabled"
          />
        </template>
        <template v-if="props.variant === 'voiceSearch'" #right>
          <Icon
              :data-testid="props.dataTestid + '-icon'"
              name="icon-park-outline:voice"
              class="w-6 h-6 text-natural-silver-grey cursor-pointer"
              @click.prevent="emit('talk', dropdownVisible)"
          />
        </template>
      </TsInput>
      <span v-if="props.variant === 'voiceSearch'" @click="completeSearch">
        <slot name="icon-btn">
          <TsButton
              size="xs"
              append-class="p-2.5"
              :disabled="props.disabled"
              :data-testid="props.dataTestid + '-button'"
          >
            <Icon
                name="bx:search"
                size="26"
                :data-testid="props.dataTestid + '-icon'"
            />
          </TsButton>
        </slot>
      </span>
    </div>

    <div
        v-if="props.noResults"
        class="relative select-none text-sm py-1 text-natural-dark-grey"
    >
      {{ noResultMessage }}
    </div>
    <Transition
        enter-from-class="opacity-0"
        leave-to-class="opacity-0"
        enter-active-class="duration-150"
        leave-active-class="duration-150"
    >
      <div
          :data-testid="props.dataTestid"
          v-if="dropdownVisible && !props.noResults && props.options.length"
          class="relative"
      >
        <ul
            :class="
            twMerge(
              'text-natural-dark-grey mt-1.5 absolute z-10 border border-natural-light-grey w-full max-w-full max-h-64' +
               ' shadow-md overflow-y-auto dark:text-natural-light-grey rounded bg-idle-white dark:bg-natural-dark-grey !ml-0',
              props.dropdownClass
            )
          "
            ref="options_list"
            tabindex="0"
        >
          <slot name="dropdown">
            <li
                v-for="option in props.options"
                :key="JSON.stringify(option)"
                :data-testid="props.dataTestid + '-li'"
                @click.stop="handleSelection(option)"
                @mouseover="handleListOptionHover(option)"
                @mouseout="emit('listUnFocus', option)"
                class="cursor-pointer px-2 py-2 hover:bg-info/10 transition-colors"
            >
              <div class="select-none">
                <slot name="list-option" :option="option">
                  {{ props.optionLabel ? option[props.optionLabel] : option }}
                </slot>
              </div>
            </li>
          </slot>
        </ul>
      </div>
    </Transition>
    <div
        :data-testid="props.dataTestid"
        class="text-xs text-natural-silver-grey"
        v-if="$slots['helper-text']"
    >
      <slot name="helper-text"> display your message here</slot>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T">
import {ref, computed, onMounted, onBeforeMount} from "vue";
import {twMerge, twJoin} from "tailwind-merge";
import {useRandomUUID} from "../../composables/useRandomUUID";
// options
defineOptions({
  inheritAttrs: false,
});

const VARIANTS = ["voiceSearch"] as const;

const INPUTVARIANTS = ["default", "primary", "danger", "success"] as const;

type Props = {
  options?: T[];
  optionLabel?: keyof T;
  searchLabel?: keyof T;
  loading?: boolean;
  noResults?: boolean;
  noResultMessage?: string;
  iconPos?: "left" | "right";
  withSearchButton?: boolean;
  variant?: (typeof VARIANTS)[number];
  inputVariant?: (typeof INPUTVARIANTS)[number];
  withFilter?: boolean;
  inputClass?: string;
  appendClass?: string;
  dropdownClass?: string;
  disabled?: boolean;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  iconPos: "right",
  inputVariant: "default",
  inputClass: "",
  appendClass: "",
  dropdownClass: "",
  noResultMessage: "No results found. Please try again",
});

// emits
const emit = defineEmits<{
  input: [query: string | undefined, dropdownVisible: boolean];
  focus: [query: string | undefined, dropdownVisible: boolean];
  navigate: [activeOption: T | null];
  select: [option: T];
  listFocus: [option: T];
  listUnFocus: [option: T];
  filter: [dropdownVisible: boolean];
  talk: [dropdownVisible: boolean];
  enter: any;
}>();

// model binding
const selectedOption = defineModel<T | null>("selected");
const searchQuery = defineModel<string>("input");

// random UUID for each vue instance
const randomId = ref("");

const options_list = ref<HTMLElement | null>(null);

const activeOpt = ref<T | null>(null);

const dropdownVisible = defineModel<boolean>("visible", {
  default: false
});

const searchIcon = computed<string>(() =>
    props.loading
        ? "eos-icons:loading"
        : props.variant === "voiceSearch" ||
        props.withFilter ||
        (searchQuery.value?.length && props.iconPos !== "left")
            ? ""
            : "bx:search"
);

// watch multiple sources to toggle dropdownVisible
watch(
    () => props.options,
    (updatedOptions): void => {
      const suggestionsLength = updatedOptions.length;

      dropdownVisible.value = suggestionsLength > 0;
    }
);

// methods
const completeSearch = () => {
  emit("input", searchQuery.value, dropdownVisible.value);
};

const handleFocus = (e: Event) => {
  dropdownVisible.value = props.options.length > 0;

  emit("focus", searchQuery.value, dropdownVisible.value);
};

const handleListOptionHover = (option: T) => {
  emit("listFocus", option);
};

const handleSelection = (option: T) => {
  selectedOption.value = option;
  emit("select", option);

  let labelValue: string = JSON.stringify(option);

  if (props.searchLabel && option[props.searchLabel as keyof T]) {
    labelValue = option[props.searchLabel as keyof T] as string;
  } else if (props.optionLabel && option[props.optionLabel as keyof T]) {
    labelValue = option[props.optionLabel as keyof T] as string;
  }

  searchQuery.value = labelValue;
  dropdownVisible.value = false;
  activeOpt.value = null;
};

const navigateOptions = (direction: "up" | "down") => {
  if (!options_list.value) return;
  let newIndex: any;

  const options = options_list.value.querySelectorAll("li");
  const currentIndex = Array.from(options).findIndex((option) =>
      option.classList.contains("selected")
  );

  if (direction === "up") {
    newIndex = currentIndex > 0 ? currentIndex - 1 : options.length - 1;
  } else if (direction === "down") {
    newIndex = currentIndex < options.length - 1 ? currentIndex + 1 : 0;
  }
  activeOpt.value = props.options[newIndex];
  emit("navigate", activeOpt.value as T | null);
  options[currentIndex]?.classList.remove("selected");
  options[newIndex]?.classList.add("selected");
  options[newIndex]?.scrollIntoView({block: "nearest"});
};

const handleEnterKey = () => {
  if (activeOpt.value) {
    handleSelection(activeOpt.value as T); // Ensure selection is called with activeOpt
  } else {
    emit("enter");
  }
  dropdownVisible.value = false;
};

onBeforeMount(() => {
  activeOpt.value = null;
});

onMounted(() => {
  randomId.value = useRandomUUID();

  /* close the suggestions dropdown on clicking outside */
  window.document.addEventListener(
      "click",
      (event: Event) => {
        const autocompleteId = "autocomplete-" + randomId.value;
        if (
            (event.target as HTMLInputElement).closest(`#${autocompleteId}`) ===
            null
        )
          dropdownVisible.value = false;
      },
      {passive: true}
  );
});
</script>

<style scoped>
.selected {
  @apply bg-green-200 dark:bg-natural-black;
}
</style>
