<script lang="ts" setup>
import { twMerge } from "tailwind-merge";
import { computed } from "vue";

const model = defineModel();
const emit = defineEmits(["click", "change"]);

enum Sizes {
  Md = "md",
  Lg = "lg",
}

type Props = {
  size?: `${Sizes}`;
  disabled?: boolean,
  true_value?: string | number | boolean,
  false_value?: string | number | boolean,
  readonly?: boolean,
  label?: string,
  labelClass?: string,
  dataTestid?: string
  variant?: string
  noModelValue?: boolean;
  bgPrimary?: boolean;
  appendClass?: string;
};

const props = withDefaults(defineProps<Props>(), {
  size: Sizes.Md,
  disabled: false,
  true_value: true,
  false_value: false,
  readonly: false,
  dataTestid: "atom-toggle",
});

const toggle_on_bg = computed(() => {
  if (props.disabled) {
    return model.value ? 'peer-checked:bg-primary-border' : 'peer-checked:bg-natural-light-grey';
  }

  // If bg-primary is passed as a prop, use it
  if (props.bgPrimary) {
    return 'peer-checked:bg-primary hover:peer-checked:bg-primary-hover';
  }
  
  // Default to bg-success
  return 'peer-checked:bg-success hover:peer-checked:bg-success-hover';
});


// reset toggle if v-model is not ensured
function resetToggle(event: Event) {
  if (!props.noModelValue) return;
  setTimeout(() => {
    (event.target as HTMLInputElement).checked = false;
  }, 2000);
}

function handleToggleChange(event: Event) {
  emit("change", event);
  resetToggle(event);
}

function handleToggleClick(event: Event) {
  emit("click", event);
  resetToggle(event);
}

defineOptions({
  inheritAttrs: false,
});

const toggle_off_bg = computed(() =>
  props.disabled
    ? "bg-natural-light-grey cursor-not-allowed"
    : "bg-natural-silver-grey hover:bg-natural-grey"
);

const thumb = computed(() =>
  props.readonly
    ? "after:bg-white"
    : `after:bg-idle-white ${model.value === props.false_value && ""}`
);

const toggle_style = computed(() =>
  props.readonly
    ? ["border border-success-border bg-success-border"]
    : [toggle_on_bg.value, toggle_off_bg.value]
);

const toggle_common =
  "rounded-full peer dark:bg-natural-black " +
  'peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[""] after:absolute after:top-[2px] after:left-[2px] ' +
  "after:border after:rounded-full after:h-5 after:w-5 after:transition-all " +
  "w-11 h-6 after:top-[4px] after:left-[4px] after:h-5 after:w-5";

const vat_toggle =
  "peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-primary rounded-full peer " +
  "  peer-checked:after:border-primary bg-transparent after:bg-primary border-primary border";

const toggle_sizes: Record<string, string> = {
  md: "w-11 h-6 after:top-[3px] after:left-[4px] after:h-[18px] after:w-[18px]",
  lg: "w-[54px] h-7 after:top-[4px] after:left-[4px] after:h-[20px] after:w-[20px] peer-checked:after:translate-x-[127%]",
};
</script>

<template>
  <label :data-testid="props.dataTestid ? props.dataTestid + '_toggle-label': '_toggle-label'" :class="twMerge('w-fit relative inline-flex items-center cursor-pointer', props.appendClass)">
    <input
      v-bind="$attrs"
      class="sr-only peer"
      type="checkbox"
      :data-testid="props.dataTestid"
      :disabled="props.disabled || props.readonly"
      v-model="model"
      :true-value="props.true_value"
      :false-value="props.false_value"
      @change="handleToggleChange($event)"
      @click="handleToggleClick($event)"
    />
    <span
      :class="
        twMerge(
          toggle_common,
          thumb,
          props.variant === 'switchVat' ? vat_toggle : [...toggle_style],
          toggle_sizes[size.toLowerCase()],

          [
            props.size === Sizes.Md && toggle_sizes.md,
            props.size === Sizes.Lg && toggle_sizes.lg,
          ]
        )
      "
      :data-testid="props.dataTestid ? props.dataTestid + '_toggle-thumb': '_toggle-thumb'"
    ></span>
    <span v-if="props.label" :class="twMerge('ml-2.5', props.labelClass)"
    :data-testid="props.dataTestid ? props.dataTestid + '_toggle-label-text': '_toggle-label-text'"
    >{{
      props.label
    }}</span>
  </label>
</template>
