import type {
  CreateTrolleyRequest,
  OrderTotalResponse,
  OrderTotalsRequest,
  TrolleyDeliveryMethodDetail,
  TrolleyDiscountResponse,
  TrolleyItemUpdateRequest,
  TrolleyLineRequest,
  TrolleyResponse,
  TrolleyShareCodeCreateResponse,
} from "~/types/ecom/trolley/trolley.type";
import { CUSTOMER_ENDPOINTS, EcomService } from "./ecom.service";
import type { TrolleyStatus } from "~/types/ecom/trolley/trolley-map";
import type { EcomPostNLAddressType } from "~/types/ecom/address.type";

// TODO: move to product service
export const PRODUCT_ENDPOINTS = {
  DELIVERY_BY_CODE: (productCode: string) =>
    `/products/${productCode}/delivery`,
};

/**
 * ECOM API trolleys end points
 * @class alias_end_point: callback () => actual_ecom_end_point
 */
export const TROLLEY_ENDPOINTS = {
  TROLLEYS: (trolleyId: string) => `/trolleys/${trolleyId}`,
  TROLLEYS_LINES: (trolleyId: string) => `/trolleys/${trolleyId}/lines`,

  TROLLEYS_DELIVERY_METHODS: (trolleyId: string) =>
    `/trolleys/${trolleyId}/delivery-methods`,

  TROLLEYS_ORDER_TOTALS: (trolleyId: string) =>
    `/trolleys/${trolleyId}/order-totals`,

  TROLLEYS_LINE_BY_ID_QUANTITY: (trolleyLineId: number) =>
    `/trolleys/lines/${trolleyLineId}/quantity`,

  TROLLEYS_LINE_BY_ID_CHANNEL: (trolleyLineId: number) =>
    `/trolleys/lines/${trolleyLineId}/channel`,

  TROLLEYS_LINE_BY_ID_DELIVERY_METHOD: (trolleyLineId: number) =>
    `/trolleys/lines/${trolleyLineId}/delivery-method`,

  TROLLEYS_LINE_BY_ID: (trolleyLineId: number) =>
    `/trolleys/lines/${trolleyLineId}`,

  // discounts or promotions
  TROLLEYS_CHECK_DISCOUNTS: (trolleyId: string) =>
    `/trolleys/${trolleyId}/check-discounts`,
  TROLLEYS_PROMO_CODES: (trolleyId: string) =>
    `/trolleys/${trolleyId}/promo-codes`,
  TROLLEYS_PROMO_CODE_BY_ID: (appliedPromoCodeId: number) =>
    `/trolleys/promo-codes/${appliedPromoCodeId}`,

  TROLLEYS_RECALCULATE_DELIVERY_METHODS: (trolleyId: string) =>
    `/trolleys/${trolleyId}/recalculate-delivery-methods`,

  TROLLEYS_CONVERT_TO_CUSTOMER: (trolleyId: string) =>
    `/trolleys/${trolleyId}/convert-to-customer`,

  TROLLEYS_ATTRIBUTES: (trolleyId: string) =>
    `/trolleys/${trolleyId}/attributes`,

  TROLLEYS_ATTRIBUTE_BY_ID: (trolleyId: string, attributeId: string) =>
    `/trolleys/${trolleyId}/attributes/${attributeId}`,

  SHARE_TROLLEY_CODE: (trolleyId: string) =>
    `/saved-lists/create-from-trolley/${trolleyId}`,

  TROLLEYS_SHARE_BY_EMAIL: (trolleyId: string) =>
    `/trolleys/${trolleyId}/share-by-email`,

  TROLLEYS_CHECKOUT_POST_NL: (trolleyId: string) =>
    `/trolleys/${trolleyId}/checkout/postnl`,
};

export default class EcomTrolleyService {
  /* Create a trolley - returns new trolley_id and session_token */
  static async createTrolley(
    trolleyRequest?: CreateTrolleyRequest
  ): Promise<{ data: TrolleyResponse }> {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();

    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }

    const requestOptions: any = {
      method: "post",
      headers: trolleyHeaders,
      show_loading_indicator: false,
    };

    /* Add to body if request body is provided */
    if (trolleyRequest) requestOptions.params = trolleyRequest;

    const createTrolleyEndpoint = authStore.user
      ? CUSTOMER_ENDPOINTS.CUSTOMER_TROLLEYS((authStore.user as any).id)
      : CUSTOMER_ENDPOINTS.GUEST_TROLLEYS;
    // @ts-ignore
    return await useAjaxEcom(createTrolleyEndpoint, requestOptions);
  }

  /* Convert guest trolley to customer */
  static async convertToCustomer(
    trolleyId: string,
    trolleySessionToken?: string | null
  ): Promise<{ data: TrolleyResponse }> {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    let id = '';
    if(authStore.user){
      id = authStore.user.id;
    }else if(authStore.guest){
      id = authStore.guest.id;
    }

    /* Required ecom headers for guest trolley to customer trolley conversion */
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    const requestOptions: any = {
      method: "patch",
      params: {
        // @ts_mobile_app reference
        customer_id: id,
      },
      headers: trolleyHeaders,
      show_loading_indicator: false,
    };

    // @ts-ignore
    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS_CONVERT_TO_CUSTOMER(trolleyId),
      requestOptions
    );
  }

  /* Add a line to an existing trolley */
  static async addToTrolley(
    trolleyId: string,
    addToTrolleyReq: TrolleyLineRequest,
    trolleySessionToken?: string | null
  ): Promise<{ data: TrolleyResponse }> {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    // @ts-ignore
    return await useAjaxEcom(TROLLEY_ENDPOINTS.TROLLEYS_LINES(trolleyId), {
      method: "post",
      params: addToTrolleyReq,
      headers: trolleyHeaders,
      show_loading_indicator: false,
    });
  }

  /**
   *
   * @param {string} trolleyId
   * @param {string} [trolleySessionToken]
   * @param {OrderTotalsRequest} [trolleyRequest]
   * @returns {Promise<OrderTotalResponse>} order-totals response
   */
  static async fetchOrderTotals(
    trolleyId: string,
    trolleySessionToken?: string | null,
    trolleyRequest?: OrderTotalsRequest
  ): Promise<{ data: OrderTotalResponse }> {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    const requestOptions: any = {
      method: "post",
      headers: trolleyHeaders,
      show_loading_indicator: false,
    };

    /* Add to body if request body is provided */
    if (trolleyRequest) requestOptions.params = trolleyRequest;
    // @ts-ignore
    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS_ORDER_TOTALS(trolleyId),
      requestOptions
    );
  }

  /* Delete a line from the trolley */
  static async deleteTrolleyLine(
    lineId: number,
    trolleySessionToken?: string | null
  ): Promise<{ data: TrolleyResponse }> {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Mandatory headers for authenticatedcustomer */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    const requestOptions: any = {
      method: "delete",
      headers: trolleyHeaders,
      show_loading_indicator: false,
    };
    // @ts-ignore
    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS_LINE_BY_ID(lineId),
      requestOptions
    );
  }

  /**
   * Update items of trolley - quantity / channel / delivery-method
   * @param {number} lineId - The ID of the trolley line to update.
   * @param { Record<"quantity" | "channel" | "delivery_method_code", number | string>} requestBody - The update request body.
   * @param {string} [trolleySessionToken] - The guest session token, if guest.
   * @throws {Error} Throws an error if the request body has more than one key.
   */
  static async updateTrolleyItem(
    lineId: number,
    requestBody: TrolleyItemUpdateRequest,
    trolleySessionToken?: string | null
  ): Promise<{ data: TrolleyResponse }> {
    if (Object.keys(requestBody).length > 1)
      throw new Error(
        "invalid request body! provide single attribute to update"
      );

    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    const requestOptions = {
      method: "put",
      headers: trolleyHeaders,
      params: requestBody,
      show_loading_indicator: false,
    };

    const property = Object.keys(requestBody)[0];

    const lineEndPoint =
      property === "quantity"
        ? TROLLEY_ENDPOINTS.TROLLEYS_LINE_BY_ID_QUANTITY(lineId)
        : property === "channel"
        ? TROLLEY_ENDPOINTS.TROLLEYS_LINE_BY_ID_CHANNEL(lineId)
        : TROLLEY_ENDPOINTS.TROLLEYS_LINE_BY_ID_DELIVERY_METHOD(lineId);
    // @ts-ignore
    return await useAjaxEcom(lineEndPoint, requestOptions);
  }

  /**
   * Update an existing item present in trolley - quantity / channel / delivery-method
   * @param {number} lineId - The ID of the trolley line to update.
   * @param { Record<"quantity" | "channel" | "delivery_method_code", number | string>} requestBody - The update request body.
   * @param {string} [trolleySessionToken] - The guest session token, if guest.
   * @throws {Error} Throws an error if the request body has more than one key.
   */
  static async updateExistingTrolleyItem(
    lineId: number,
    requestBody: TrolleyItemUpdateRequest,
    trolleySessionToken?: string | null
  ): Promise<{ data: TrolleyResponse }> {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    const requestOptions: any = {
      method: "patch",
      headers: trolleyHeaders,
      params: requestBody,
      show_loading_indicator: false,
    };

    // @ts-ignore
    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS_LINE_BY_ID(lineId),
      requestOptions
    );
  }

  static async getTrolleyDetails(
    trolleyId: string,
    trolleySessionToken?: string | null
  ): Promise<{ data: TrolleyResponse }> {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;
    // @ts-ignore
    return await useAjaxEcom(TROLLEY_ENDPOINTS.TROLLEYS(trolleyId), {
      headers: trolleyHeaders,
    });
  }

  /**
   * Check discount details before and after applying
   * @returns
   */
  static async getTrolleyDiscounts(
    trolleyId: string,
    trolleySessionToken?: string | null
  ): Promise<
    | { data: TrolleyDiscountResponse }
    | undefined
    | { success: boolean; errors: string[]; data: any }
  > {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    // @ts-ignore
    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS_CHECK_DISCOUNTS(trolleyId),
      {
        method: "post",
        headers: trolleyHeaders,
      }
    );
  }

  static async removeAppliedPromoCode(
    promoCodeId: number,
    trolleySessionToken?: string | null
  ): Promise<{ data: TrolleyResponse }> {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;
    // @ts-ignore
    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS_PROMO_CODE_BY_ID(promoCodeId),
      {
        method: "delete",
        headers: trolleyHeaders,
      }
    );
  }

  static async applyPromoCode(
    trolleyId: string,
    code: string,
    trolleySessionToken?: string | null
  ): Promise<{ data: TrolleyResponse }> {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;
    // @ts-ignore
    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS_PROMO_CODES(trolleyId),
      {
        method: "post",
        headers: trolleyHeaders,
        params: {
          code,
        },
      }
    );
  }

  static async updateTrolleyStatus(
    trolleyId: string,
    requestBody: {
      status: TrolleyStatus;
    },
    trolleySessionToken?: string | null
  ) {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    const requestOptions: any = {
      method: "patch",
      headers: trolleyHeaders,
      params: requestBody,
      show_loading_indicator: false,
      show_notifications: false,
    };

    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS(trolleyId),
      requestOptions
    );
  }

  static async getTrolleyShareCode(
    trolleyId: string,
    trolleySessionToken?: string | null
  ): Promise<{ data: TrolleyShareCodeCreateResponse }> {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;
    // @ts-ignore
    return await useAjaxEcom(TROLLEY_ENDPOINTS.SHARE_TROLLEY_CODE(trolleyId), {
      method: "post",
      headers: trolleyHeaders,
    });
  }

  static async sendTrolleyShareCodeByEmail(
    trolleyId: string,
    requestBody: {
      recipient_email: string;
    },
    trolleySessionToken?: string | null
  ) {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    const requestOptions: any = {
      method: "post",
      headers: trolleyHeaders,
      params: requestBody,
      show_loading_indicator: false,
      show_notifications: false,
    };

    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS_SHARE_BY_EMAIL(trolleyId),
      requestOptions
    );
  }

  static async getProductDeliveryDetails(
    productCode: string,
    branchId?: string
  ): Promise<{ data: any }> {
    let deliveryQuery = {};

    if (branchId)
      deliveryQuery = {
        site: branchId,
      };

    // @ts-ignore
    return await useAjaxEcom(PRODUCT_ENDPOINTS.DELIVERY_BY_CODE(productCode), {
      query: deliveryQuery,
    });
  }

  static async getCheckoutDeliveryMethods(
    trolleyId: string,
    shippingAddressId: string,
    collectionAddressId?: string,
    trolleySessionToken?: string | null
  ): Promise<{ data: TrolleyDeliveryMethodDetail[] }> {
    const trolleyHeaders: any = {};
    /* Different ecom headers for customer and guest */

    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    const deliveryMethodQuery: Record<string, string> = {
      delivery_address_id: shippingAddressId,
    };

    if (collectionAddressId)
      deliveryMethodQuery["collection_address_id"] = collectionAddressId;

    const requestOptions: any = {
      headers: trolleyHeaders,
      query: deliveryMethodQuery,
      show_loading_indicator: false,
      show_notifications: false,
    };

    // @ts-ignore
    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS_DELIVERY_METHODS(trolleyId),
      requestOptions
    );
  }

  static async updateTrolleyLineDeliveryMethod(
    lineId: number,
    requestBody: {
      delivery_method_code: string;
    },
    trolleySessionToken?: string | null
  ) {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }

    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    const requestOptions: any = {
      method: "put",
      headers: trolleyHeaders,
      params: requestBody,
      show_loading_indicator: false,
      show_notifications: false,
    };

    // @ts-ignore
    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS_LINE_BY_ID_DELIVERY_METHOD(lineId),
      requestOptions
    );
  }

  static async checkoutPostNL(
    trolleyId: string,
    requestBody: EcomPostNLAddressType,
    trolleySessionToken?: string | null
  ): Promise<{ data: { address_id: string } }> {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }

    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    const requestOptions: any = {
      method: "post",
      headers: trolleyHeaders,
      params: requestBody,
      show_loading_indicator: false,
      show_notifications: false,
    };

    // @ts-ignore
    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS_CHECKOUT_POST_NL(trolleyId),
      requestOptions
    );
  }

  static async deleteTrolley(trolleyId: string, trolleySessionToken?: string) {
    const trolleyHeaders: any = {};
    const authStore = useAuthStore();
    /* Different ecom headers for customer and guest */
    if (authStore.user) {
      trolleyHeaders["X-Toolstation-Customer-Id"] = (
        authStore.user as any
      ).token;
    }
    if (trolleySessionToken)
      trolleyHeaders["X-Toolstation-Session-Id"] = trolleySessionToken;

    const requestOptions: any = {
      method: "delete",
      headers: trolleyHeaders,
      show_loading_indicator: false,
    };
    // @ts-ignore
    return await useAjaxEcom(
      TROLLEY_ENDPOINTS.TROLLEYS(trolleyId),
      requestOptions
    );
  }
}
