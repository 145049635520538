import { DeliveryMethodCodes, EventConstants } from "~/assets/constants/ecomApi";
import type { ProductAvailability } from "../components/atoms/TsRadioTrolleyCollection.vue";

import { useErrorHandler } from "~/composables/useErrorHandler";
import EcomTrolleyService from "~/services/ecom.trolley.service";
import type { APIState } from "~/types/api-state.type";
import type { Product } from "~/types/ecom/product/product.type";
import type { ProductStockResponse } from "~/types/ecom/product/stock.type";
import {
  TrolleyChannel,
  TrolleyStatus,
} from "~/types/ecom/trolley/trolley-map";
import type {
  AppliedPromoCodeResource,
  TrolleyDetails,
  TrolleyItemUpdateRequest,
  TrolleyLineItem,
  TrolleyLineItemCore,
  TrolleyLineRequest,
  TrolleyResponse,
} from "~/types/ecom/trolley/trolley.type";
import EcomCustomerService from "~/services/ecom/customer.service";
import { EcomService } from "~/services/ecom.service";
import { ProductService } from "~/services/product.service";

export type RefinedStockResponse = Record<
  "delivery" | "collection",
  Array<Omit<ProductStockResponse, "site_id">> | null
>;

/**
 * @example
 */
export type RefinedTrolleyLineItems = Record<
  Exclude<keyof typeof TrolleyChannel, "SameDayDelivery">,
  TrolleyLineItem[]
>;

export type TrolleyItemVariant = Product & {
  quantity: number;
  channel: TrolleyChannel;
};

export const useTrolleyStore = defineStore("trolleyStore", {
  state: () => ({
    // monetate trolley item confirmation
    current_add_on_item: <Product | null>null,
    trolley_modal_primary_tag: '',
    // states defined below are for the new PLP collection delivery flow
    previewed_product_v2: <Product | null>null,
    preview_overlay_collection_visible: false,

    preview_overlay_delivery_visible: false,
    already_subscribed_modal_visible: false,

    // states for variant overlays
    product_variants_modal_visible: false,
    delivery_variants_modal_visible: false,
    collection_variants_modal_visible: false,
    preview_collection_delivery_modal_visible: false,
    multiple_items_confirmation_modal_visible: false,

    active_trolley_cta_v1: <"AddToTrolley" | "BuyNow" | "BothCTA" | "">"",
    activeTrolleyCTAInModal: "",
    currentTrolleyAction: <
      "Delivery" | "Collection" | "BothCTA" | "NextDayCollection" | ""
      >"",

    delivery_product_variants: <TrolleyItemVariant[]>[],
    collection_product_variants: <TrolleyItemVariant[]>[],
    variant_items_added_to_trolley: <TrolleyItemVariant[]>[],

    trolley: <TrolleyDetails>{},
    total_products: 0,
    final_order_total_amount: "",
    trolley_id: <string | null>null,
    last_active_trolley_id: <string | null>null,
    trolley_session_id: <string | null>null,
    trolley_session_token: <string | null>null,
    promo_code_text: <string>"",
    applied_promo_codes: <AppliedPromoCodeResource[]>[],
    trolley_discounts: <string[]>[],
    applicable_promo_codes: <unknown[]>[],
    is_promo_code_apply_button_loading: false,
    total_discount_amount: 0,

    trolley_code_product: <TrolleyItemVariant[]>[],
    trolley_code: "",
    // shared states between two flows
    previewed_product: <Product | null>null,
    previewed_product_selected_quantity: 1,
    previewed_product_selected_channel: <TrolleyChannel>1,
    previewed_product_variations: <Product[]>[],
    collection_availability: <ProductAvailability | undefined>undefined,
    collection_stock_loading: false,
    free_ship_buffer: 40,
    share_by_email_state: <APIState>{
      status: "idle",
    },

    // To display channel wise line items in the trolley page
    trolley_line_items: <RefinedTrolleyLineItems | null>null,

    loading: false,
    is_decoding_trolley_code: true,
    add_to_trolley_cta_loading: false,
    buy_now_cta_loading: false,
    is_updating_item_details_in_add_to_trolley: false,
    order_summary_loading: false,
    is_added: false,
    // PLP page modals
    preview_overlay_visible: false,
    confirmation_overlay_visible: false,
    buy_now_overlay_visible: false,
    // Trolley page modals
    remove_confirmation_modal_visible: false,
    code_share_modal_visible: false,
    move_all_to_savelist_modal_visible: false,
  }),
  persist: [
    {
      pick: [
        "trolley_id",
        "last_active_trolley_id",
        "total_products",
        "trolley_session_id",
        "trolley_session_token",
      ],
    },
    {
      pick: ["trolley"],
      storage: import.meta.client ? localStorage : undefined,
    },
  ],
  getters: {
    is_empty(state): boolean {
      return (
        !state.trolley_id ||
        !state.trolley_line_items ||
        state.total_products === 0
      );
    },
    all_trolley_line_items(state): TrolleyLineItem[] {
      if (!state.trolley_line_items) return [];
      return Object.values(state.trolley_line_items).flat();
    },
  },
  actions: {
    isCheckoutDisabled() {
      if (!this.trolley_line_items) return false;

      const isAnyLineOutOfStock = Object.values(this.trolley_line_items)
        .flatMap((item) => item)
        .some((line) =>
          useStockStore().isTrolleyItemOutOfStock({
            channel: TrolleyChannel[line.channel] as any,
            product: line.product!,
            quantity: line.quantity,
          })
        );
      const isAnyLineProcessing = Object.values(this.trolley_line_items)
        .flatMap((item) => item)
        .some((line) => line.processing);

      const branchStore = useBranchStore();

      const isNextDayItemUnavailable =
        !branchStore.is_branch_set &&
        this.trolley_line_items.NextDayCollection.length > 0;

      return (
        isAnyLineOutOfStock ||
        isAnyLineProcessing ||
        isNextDayItemUnavailable ||
        this.loading ||
        this.order_summary_loading
      );
    },

    setTotalProducts(trolleyData: TrolleyResponse | null) {
      if (!trolleyData || !trolleyData.lines.length) {
        this.total_products = 0;
        return;
      }

      let sum = 0;

      trolleyData.lines.forEach((line) => {
        sum += line.quantity;
      });

      this.total_products = sum;
    },

    async getEstimatedTimesForProducts(
      trolleyLines: TrolleyLineItem[],
      branchId?: string
    ) {
      const promises = trolleyLines.map(async (line) => {
        try {
          // 1. Get the estimated delivery time for each product
          const response = await EcomTrolleyService.getProductDeliveryDetails(
            line.product_code,
            branchId
          );

          const channels = response.data.channels;

          // 2. Handle edge case --> channels: [] e.g 58123 pre-prod
          if (Array.isArray(channels) && !channels.length) {
            line.estimated_time = {
              collection: "",
              delivery: "",
              directship: "",
            };
          }

          // 3. Update the estimated_time property with the respective dates in the line item
          else
            line.estimated_time = {
              collection: channels.collection?.date || "",
              delivery: channels.delivery?.date || "",
              directship: channels.directship?.date || "",
            };
        } catch (err) {
          useErrorHandler(err, "low");
          console.error(
            `Failed to fetch delivery details for product ${line.product_code}:`,
            err
          );
        }
      });

      // Wait for all promises to complete
      await Promise.all(promises);
    },

    /**
     * @param trolleyData 
     */
    async setPersistingLineData(trolleyData: TrolleyResponse){
      if (!trolleyData || !trolleyData.lines.length) {
        this.trolley.lines = [];
        return;
      }
      this.trolley.lines = trolleyData.lines.map(trolleyLine => {
        const {id, product_code, quantity, channel} = trolleyLine;
        return ({id, product_code, quantity, channel});
      });
    },

    /**
     * @param trolleyData - Ecom trolley response
     * @type {RefinedTrolleyLineItems}
     */
    async setTrolleyLineItems(trolleyData: TrolleyResponse) {
      this.setTotalProducts(trolleyData);
      this.setPersistingLineData(trolleyData);

      const initialTrolleyLineItems: RefinedTrolleyLineItems = {
        Collection: [],
        Delivery: [],
        Directship: [],
        NextDayCollection: [],
      };

      // 1. Construct product_codes array from trolley response
      const productsInTrolley: string[] = trolleyData.lines.map(
        (line) => line.product_code
      );

      if (!productsInTrolley.length) {
        this.trolley_line_items = initialTrolleyLineItems;
        return;
      }

      // 2. Fetch all products from ECOM with stock and create a map for easy lookup
      const productsData = await useStockStore().getStockForProducts(
        productsInTrolley
      );

      const productMap = new Map(
        productsData.map((product: Product) => [product.code, product])
      );

      // 3. Fetch and insert estimated times for each line item
      await this.getEstimatedTimesForProducts(
        trolleyData.lines,
        useBranchStore().lastSavedBranch?.id
      );

      // 4. Update trolley line items state using products response and trolley response
      this.trolley_line_items = trolleyData.lines.reduce(
        (acc: any, line: TrolleyLineItem) => {
          const product = productMap.get(line.product_code);
          if (product) {
            const updatedLineItem = {
              ...line,
              product,
              processing: false,
            };

            let channelKey: any = Object.keys(TrolleyChannel).find(
              //@ts-ignore
              (key) => TrolleyChannel[key] === line.channel
            );

            // 5. Send the line item to the appropriate checkout channel
            if (!acc[channelKey]) acc[channelKey] = [];
            acc[channelKey].push(updatedLineItem);
          }
          return acc;
        },
        initialTrolleyLineItems
      );

      // 6. Check if all lines are delivery so that we do not need to fetch collection address details on checkout page
      if (!this.trolley_line_items) return;

      if (
        this.trolley_line_items.Collection.length ||
        this.trolley_line_items.NextDayCollection.length
      ) {
        this.trolley.is_all_lines_delivery = false;

        if (
          !this.trolley_line_items.Delivery.length &&
          !this.trolley_line_items.Directship.length
        )
          this.trolley.is_all_lines_collection = true;
        else this.trolley.is_all_lines_collection = false;
      } else {
        this.trolley.is_all_lines_delivery = true;
        this.trolley.is_all_lines_collection = false;
      }
    },
    /* To set the order total summary with complete breakdown */
    async setOrderTotals() {
      if (!this.trolley_id)
        throw new Error("can not set order total as trolleyId not found");

      this.order_summary_loading = true;

      try {
        const { data } = await EcomTrolleyService.fetchOrderTotals(
          this.trolley_id,
          this.trolley_session_token
        );

        if (!data)
          throw new Error("couldn't fetch order-totals for this trolley");

        this.trolley.order_response = data;

        // order - subtotal details
        this.trolley.order_subtotal = data.subtotals?.inc_vat.formatted;
        this.trolley.total_vat = data.subtotals?.vat.formatted;

        // order - total details
        this.trolley.order_total = data.totals?.inc_vat.formatted;
        this.trolley.total_exc_vat = data.totals?.ex_vat.formatted;

        this.final_order_total_amount =
          data.totals?.inc_vat.raw.toString() ?? "";
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      } finally {
        this.order_summary_loading = false;
      }
    },
    /* To set an individual line to processing state */
    setTrolleyLineProcessing(line_id: number, processing: boolean) {
      if (!this.trolley_line_items) return;

      Object.keys(this.trolley_line_items).forEach((channelKey) => {
        const channel = channelKey as keyof RefinedTrolleyLineItems;
        const lineItem = this.trolley_line_items![channel].find(
          (line) => line.id === line_id
        );
        if (lineItem) {
          lineItem.processing = processing;
        }
      });
    },

    async evaluateCollectionAvailability(
      product: Product,
      enteredQuantity?: number,
      reCalculate = false
    ): Promise<ProductAvailability> {
      const branchStore = useBranchStore();
      // Branch not selected yet
      if (!branchStore.is_branch_set)
        return {
          status: "NoBranchSelected",
          stock: 0,
        };

      let productToBeProcessed = product;

      const stockStore = useStockStore();
      // if stock details are not available
      if (reCalculate) {
        const [productWithStock] = await stockStore.getStockForProducts([
          product.code,
        ]);
        productToBeProcessed = productWithStock
      }

      // update stock of the product
      this.previewed_product = productToBeProcessed;

      const enableNextDayCollectionByQuantity =
        enteredQuantity &&
        enteredQuantity > productToBeProcessed.stockDetails?.collection! &&
        enteredQuantity <= productToBeProcessed.stockDetails?.delivery!;

      // Next day CC
      if (
        stockStore.isEligibleForNextDayCollection(productToBeProcessed) ||
        enableNextDayCollectionByQuantity
      )
        return {
          status: "NextDayCollection",
          stock: productToBeProcessed.stockDetails?.delivery!,
        };
      const isOutOfStockByQuantity =
        enteredQuantity &&
        enteredQuantity > productToBeProcessed.stockDetails?.collection! &&
        enteredQuantity > productToBeProcessed.stockDetails?.delivery!;

      // Out of stock
      if (
        (productToBeProcessed.outOfStockForCollection &&
          productToBeProcessed.outOfStockForDelivery) ||
        isOutOfStockByQuantity
      )
        return {
          status: "OutOfStock",
          stock: 0,
        };
      // Collection stock - success
      return {
        status: "Collection",
        stock: productToBeProcessed.stockDetails?.collection!,
      };
    },

    async createTrolley() {
      /* No need to create a new session if trolley exist in cookies */
      if (this.trolley_id) return;

      try {
        /* Create trolley for authenticated user or guest */
        const { data: response } = await EcomTrolleyService.createTrolley();

        this.trolley_id = response.id;
        this.trolley_session_id = response.session_id;
        if (response.session_token)
          this.trolley_session_token = response.session_token;
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      }
    },

    async createTrolleyV2() {
      this.variant_items_added_to_trolley = [];

      if (this.trolley_id) return;

      try {
        /* Create trolley for authenticated user or guest */
        const { data: response } = await EcomTrolleyService.createTrolley();

        this.trolley_id = response.id;
        this.trolley_session_id = response.session_id;
        if (response.session_token)
          this.trolley_session_token = response.session_token;
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      }
    },

    /* Convert a guest trolley to Customer trolley */
    async convertToCustomer(canSetOtherDetails = true) {
      try {
        // should never come to this as a trolley must already exist in order to convert
        if (!this.trolley_id || !this.trolley_session_token)
          throw new Error("guest trolley doesn't exist for conversion");

        const { data: response } = await EcomTrolleyService.convertToCustomer(
          this.trolley_id,
          this.trolley_session_token
        );

        if (!response) throw new Error("unable to convert guest trolley");

        this.trolley_id = response.id;
        this.trolley_session_id = response.session_id;
        this.trolley_session_token = null;

        if (canSetOtherDetails) {
          await this.setTrolleyLineItems(response);
          await this.setOrderTotals();
        }

      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      } finally {
      }
    },

    async updateExistingTrolleyItem(
      existingTrolleyLineItem: TrolleyLineItemCore,
      requestOptions: TrolleyItemUpdateRequest
    ) {
      let updateRequestBody: TrolleyItemUpdateRequest = {};

      /* construct request based on attribute values provided */
      if (requestOptions.quantity) {
        updateRequestBody.quantity =
          existingTrolleyLineItem.quantity + requestOptions.quantity;
      }

      if (
        requestOptions.channel &&
        existingTrolleyLineItem.channel !== requestOptions.channel
      ) {
        updateRequestBody.channel = requestOptions.channel;
      }

      if (requestOptions.delivery_method_code)
        updateRequestBody.delivery_method_code =
          requestOptions.delivery_method_code;

      const { data: trolleyData } =
        await EcomTrolleyService.updateExistingTrolleyItem(
          existingTrolleyLineItem.id,
          updateRequestBody,
          this.trolley_session_token
        );

      if (!trolleyData)
        throw new Error("unable to update existing product in trolley!!!");

      if (trolleyData.session_token)
        this.trolley_session_token = trolleyData.session_token;

      await this.setTrolleyLineItems(trolleyData);
    },

    /* Primary method responsible for add to trolley action */
    async dispatchItemToTrolley(
      product_code: Product["code"],
      quantity: number = 1,
      channel: TrolleyChannel = 1,
      delivery_method_code?: string
    ) {
      /* create new trolley if adding products to non-existent or ordered trolley */
      if (!this.trolley_id) await this.createTrolley();

      /* If a product already exists in trolley update it */
      const existingTrolleyLineItem =
        this.findTrolleyItemByProductCode(product_code);

      if (existingTrolleyLineItem) {
        await this.updateExistingTrolleyItem(existingTrolleyLineItem, {
          quantity,
          channel,
          delivery_method_code,
        });
        this.loading = false;
        return;
      }

      const item: TrolleyLineRequest = {
        product_code,
        quantity,
        channel,
      };

      if (delivery_method_code)
        item["delivery_method_code"] = delivery_method_code;

      const { data: trolleyData } = await EcomTrolleyService.addToTrolley(
        this.trolley_id as string,
        item,
        this.trolley_session_token
      );

      if (!trolleyData) throw new Error("unable to add to trolley!!!");

      if (trolleyData.session_token)
        this.trolley_session_token = trolleyData.session_token;

      await this.setTrolleyLineItems(trolleyData);
      logEvent({
        options: {
          eventAction: "click",
          eventProperties: {
            currency: EventConstants.currency,
            product: product_code,
            quantity: quantity,
            delivery_method_code: delivery_method_code,

          },
          eventInteraction: true,
          eventLabel: `User add : ${product_code}`,
          eventName: "add_to_cart",
        },
      });

    },

    async addToTrolley(
      product_code: Product["code"],
      quantity: number = 1,
      channel: TrolleyChannel = 1,
      delivery_method_code: DeliveryMethodCodes,
    ) {
      this.loading = true;
      try {
        await this.dispatchItemToTrolley(
          product_code,
          quantity,
          channel,
          delivery_method_code
        );
      } catch (err) {
        this.is_added = false;
        useErrorHandler(err, "critical");
        return err;
      } finally {
        this.is_added = true;
        this.loading = false;
      }
    },

    async addToTrolleyForDelivery(code: Product["code"], quantity: number) {
      await this.addToTrolley(
        code,
        quantity,
        TrolleyChannel.Delivery,
        DeliveryMethodCodes.Delivery
      );
    },
    async addToTrolleyForCollection(code: Product["code"], quantity: number) {
      await this.addToTrolley(
        code,
        quantity,
        TrolleyChannel.Collection,
        DeliveryMethodCodes.Collection
      );
    },
    async addToTrolleyForDirectship(code: Product["code"], quantity: number) {
      await this.addToTrolley(
        code,
        quantity,
        TrolleyChannel.Directship,
        DeliveryMethodCodes.Directship
      );
    },
    async addToTrolleyForNextDayCollection(
      code: Product["code"],
      quantity: number
    ) {
      await this.addToTrolley(
        code,
        quantity,
        TrolleyChannel.NextDayCollection,
        DeliveryMethodCodes.NextDayCollection
      );
    },

    /* Unnecessary bloatware method */
    async addMultipleItemsToTrolley(items: TrolleyLineRequest[]) {
      if (!this.trolley_id) return;

      try {
        /* Dispatch all items to trolley */
        for (const item of items) {
          const {
            product_code,
            quantity,
            channel = 1,
            delivery_method_code,
          } = item;

          await this.dispatchItemToTrolley(
            product_code,
            quantity,
            channel,
            delivery_method_code
          );
        }
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      }
    },
    /**
     *
     * @param {string[]} variations - array of product codes received as the variations response from getProducts
     */
    async getProductVariants(variations: string[]) {
      if (!variations.length) return;

      try {
        const stockStore = useStockStore();
        // Get variation details from products API and inject stock data
        const variationWithStockResponse = await stockStore.getStockForProducts(
          variations
        );

        if (!variationWithStockResponse || !variationWithStockResponse.length)
          throw new Error("could not fetch product variation details");

        this.previewed_product_variations = variationWithStockResponse;
      } catch (err) {
        useErrorHandler(err, "high");
        return err;
      }
    },

    /**
     * Get trolley lines and channel information for a trolley
     * @returns
     */
    async getTrolleyFromCookieId(requestTrolleyId = "") {
      if (!this.trolley_id && !requestTrolleyId) return;

      this.loading = true;

      try {
        const { data: trolleyData } =
          await EcomTrolleyService.getTrolleyDetails(
            this.trolley_id || requestTrolleyId,
            this.trolley_session_token
          );

        if (!trolleyData) throw new Error("unable to fetch trolley details");

        // update status
        this.trolley.status = trolleyData.status;

        // set trolley attributes
        this.trolley_id = trolleyData.id;
        this.trolley_session_id = trolleyData.session_id;
        if (trolleyData.session_token)
          this.trolley_session_token = trolleyData.session_token;

        this.applied_promo_codes = trolleyData.applied_promo_codes;

        await this.setTrolleyLineItems(trolleyData);
        await this.setOrderTotals();
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      } finally {
        this.loading = false;
      }
    },

    getEncodeTrolleyCodeQueryString(trolleyCode: string) {
      const products = this.all_trolley_line_items || [];

      const productString = products
        .map(product => `${product.product_code}:${product.quantity}:${product.channel}`)
        .join(',');

      const queryString = `${trolleyCode}#${productString}`;
      const encodedString = window.btoa(queryString);

      this.trolley.encoded_trolley_url = encodedString;
    },

    async getDecodeTrolleyCodeQueryString(url: string) {
      try {
        this.is_decoding_trolley_code = true;
        const decodedString = window.atob(url);

        const [trolleyCode, productString] = decodedString.split('#');
        if (!productString) throw new Error("Invalid product string format.");

        const productEntries = productString.split(',');

        const productDetails = productEntries.map(entry => {
          const [productCode, quantity, channel] = entry.split(':');
          return { productCode, quantity, channel };
        });

        const productIds = productDetails.map(item => +item.productCode);
        const productService = new ProductService();
        const response: Product[] = await productService.getProducts(productIds);

        this.trolley_code_product = response.map(product => {
          const matchingProductDetail = productDetails.find(item => item.productCode === product.code);
          return {
            ...product,
            quantity: matchingProductDetail ? Number(matchingProductDetail.quantity) : 1,
            channel: matchingProductDetail ? Number(matchingProductDetail.channel) : 0
          };
        });
        this.trolley_code = trolleyCode;
        this.is_decoding_trolley_code = false;
        return { trolleyCode, response }
      } catch (error) {
        useErrorHandler("Error decoding trolley code or fetching products", "high");
        this.is_decoding_trolley_code = false;
        return error;
      }
    },


    /**
     * Get Trolley Discount
     * @returns
     */
    async getTrolleyDiscounts() {
      if (!this.trolley_id) return;

      this.total_discount_amount = 0;

      try {
        const response = await EcomTrolleyService.getTrolleyDiscounts(
          this.trolley_id,
          this.trolley_session_token
        );

        // failed case: response has success flag set to false
        if (
          response &&
          // @ts-ignore
          typeof response.success === "boolean" &&
          // @ts-ignore
          !response.success
        ) {
          // @ts-ignore
          console.error(response.errors);
          throw new Error("unable to apply promo code");
        }
        // success case-1: response is undefined but status code is 204 -> trolley not eligible for promotions / no valid promotion applied
        if (!response) {
          return;
        }

        this.trolley_discounts = response.data.applied_promo_codes;

        if (response.data.discount_total > 0)
          this.total_discount_amount = response.data.discount_total / 100;
        else this.total_discount_amount = 0;
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      }
    },

    /**
     * Apply Promo Code
     * @returns
     */
    async applyPromoCode(code: string = "") {
      if (!this.trolley_id) return;
      const rootStore = useRootStore();

      if (!code) {
        code = this.promo_code_text;
      }

      try {
        this.is_promo_code_apply_button_loading = true;

        const promoCodeResponse: any = await EcomTrolleyService.applyPromoCode(
          this.trolley_id,
          code,
          this.trolley_session_token
        );

        if (
          promoCodeResponse &&
          // @ts-ignore
          promoCodeResponse.success === false
        ) {
          rootStore.toastErrors(
            [`The code <strong>${code}</strong> is already present.`],
            "top-left"
          );

          return false;
        }

        this.applied_promo_codes = promoCodeResponse.data.applied_promo_codes;

        await this.getTrolleyDiscounts();

        if (!this.trolley_discounts || !this.trolley_discounts.includes(code)) {
          rootStore.toastErrors(["This Promo code is invalid."], "top-left");
          let invalid_code = rootStore.findInArrayByKey(
            this.applied_promo_codes,
            "code",
            code
          );
          if (invalid_code) {
            this.removeAppliedPromoCode((invalid_code as any).id, false);
          }
          return false;
        }

        await this.setOrderTotals();

        rootStore.toastSuccess(["Coupon successfully applied"], "top-left");
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      } finally {
        this.promo_code_text = "";
        this.is_promo_code_apply_button_loading = false;
      }
    },

    /**
     * Remove Applied promo code
     * @returns
     */
    async removeAppliedPromoCode(
      promoCodeId: number,
      showNotifications = true
    ) {
      const rootStore = useRootStore();

      if (!this.trolley_id) return;

      try {
        const trolleyData = await EcomTrolleyService.removeAppliedPromoCode(
          promoCodeId,
          this.trolley_session_token
        );

        if (
          trolleyData &&
          // @ts-ignore
          trolleyData.success === false
        ) {
          if (showNotifications) {
            rootStore.toastErrors([`Something went wrong.`], "top-left");
          }

          return false;
        }

        this.applied_promo_codes = trolleyData.data.applied_promo_codes;

        if (showNotifications) {
          rootStore.toastSuccess(["Removed discount coupon."], "top-left");
        }

        await this.getTrolleyDiscounts();
        await this.setOrderTotals();
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      }
    },

    /**
     * Remove items from trolley
     * @param {number} line_id
     */
    async removeFromTrolley(line_id: number) {
      if (!line_id) return;
      this.setTrolleyLineProcessing(line_id, true);

      try {
        const { data: trolleyData } =
          await EcomTrolleyService.deleteTrolleyLine(
            line_id,
            this.trolley_session_token
          );

        if (!trolleyData)
          throw new Error("can not delete product as lineId not found");

        if (trolleyData.session_token)
          this.trolley_session_token = trolleyData.session_token;

        await this.setTrolleyLineItems(trolleyData);
        // GTM Logger For remove item from trolley
        logEvent({
          options: {
            eventAction: "click",
            eventProperties: {
              currency: EventConstants.currency,
              line_id: line_id,
            },
            eventInteraction: true,
            eventLabel: `User Removed : ${line_id} from trolley`,
            eventName: "remove_from_cart",
          },
        });

        await this.setOrderTotals();
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      } finally {
        this.setTrolleyLineProcessing(line_id, false);
        this.previewed_product = null;
      }
    },

    /**
     * 15/10/2024
     * Make trolley_line_items = null on frontend; so that we can show an empty trolley immediately.
     * If we won't do it - we'll see a loader for each item being removed one by one with a loader.
     *
     * Now, add item to selected savelist and then remove item from trolley - in a loop. Async.
     */

    async moveAllTrolleyItemsToSavelist() {
      if ((this.move_all_to_savelist_modal_visible = true)) {
        this.move_all_to_savelist_modal_visible = false;
      }
      const localePath = useLocalePath();
      const authStore = useAuthStore();
      const savelistStore = useSavelistStore();
      const currentSavelistIndex = savelistStore.current_active_savelist.index;

      if (authStore.is_authenticated) {
        // Create a local state for trolley line items
        const trolley_line_items = this.trolley_line_items;

        // Set the store trolley_line_items to null
        this.trolley_line_items = null;

        if (trolley_line_items) {
          const trolleyTypes = Object.keys(TrolleyChannel).filter((key) =>
            isNaN(Number(key))
          ) as Array<keyof typeof TrolleyChannel>;

          // Loop through each trolley type
          for (const type of trolleyTypes) {
            const productsInChannel = trolley_line_items?.[type];

            if (productsInChannel?.length > 0) {
              const totalProducts = productsInChannel.length; // Total products count

              // Loop through each product
              productsInChannel.forEach((product, index) => {
                const temp_product_code = product.product_code;
                const temp_quantity = product.quantity;

                // Check if it's the last iteration
                const isLastIteration = index === totalProducts - 1;

                // Add item to save for later, pass false for the last iteration
                savelistStore.addItemToSaveForLater(
                  {
                    product_code: temp_product_code,
                    quantity: temp_quantity,
                  },
                  false,
                  !isLastIteration
                );

                // Remove item from trolley
                this.removeFromTrolley(product.id);
                if (this.trolley_line_items) {
                  this.trolley_line_items = null;
                }
              });
            }
          }
          savelistStore.current_active_savelist.index = currentSavelistIndex;
        }
      } else {
        // Navigate to sign-in page if not authenticated
        navigateTo({
          path: localePath("/auth/signin"),
        });
      }
    },

    /* Quantity change */
    async updateQuantity(line: TrolleyLineItem) {
      if (!line.id || !this.trolley_line_items) return;

      this.setTrolleyLineProcessing(line.id, true);

      try {
        let trolleyResponse: { data: TrolleyResponse };

        trolleyResponse = await EcomTrolleyService.updateTrolleyItem(
          line.id,
          {
            quantity: line.quantity,
          },
          this.trolley_session_token
        );

        if (!trolleyResponse || !trolleyResponse.data)
          throw new Error(
            "can not update trolley item quantity as lineId not found"
          );
        if (trolleyResponse.data.session_token)
          this.trolley_session_token = trolleyResponse.data.session_token;

        // Switch channels as per availability
        let updatedChannel: TrolleyChannel | null = null;

        if (
          line.channel === TrolleyChannel.NextDayCollection &&
          line.quantity <= line.product?.stockDetails?.collection!
        ) {
          updatedChannel = TrolleyChannel.Collection;
        } else if (
          line.channel === TrolleyChannel.Collection &&
          line.quantity > line.product?.stockDetails?.collection! &&
          line.quantity < line.product?.stockDetails?.delivery!
        ) {
          updatedChannel = TrolleyChannel.NextDayCollection;
        }

        if (updatedChannel) {
          // handle channel switch
          trolleyResponse = await EcomTrolleyService.updateTrolleyItem(
            line.id,
            {
              channel: updatedChannel,
            },
            this.trolley_session_token
          );
        }

        await this.setTrolleyLineItems(trolleyResponse.data);
        await this.setOrderTotals();
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      } finally {
        this.setTrolleyLineProcessing(line.id, false);
      }
    },
    async listenToBranchSet() {
      const branchStore = useBranchStore();
      if (branchStore.is_branch_set) return;
      branchStore.branchSelectorModalVisible = true;
      await new Promise((resolve) => {
        const unwatch = watch(
          () => branchStore.is_branch_set,
          (isSet) => {
            if (isSet) {
              unwatch(); // Stop watching after branch is set
              resolve(true);
            }
          }
        );
      });
    },
    /* Channel change */
    async updateChannel(line_id: number, channel: TrolleyChannel) {
      if (!line_id || !this.trolley_line_items) return;

      let newChannel = channel;

      // Switch to collection only after branch is set
      if (channel === TrolleyChannel.Collection) {
        await this.listenToBranchSet();
        const trolleyLineItem = Object.values(this.trolley_line_items)
          .flat()
          .find((line) => line.id === line_id);
        if (!trolleyLineItem) return;
        // Usecase: Changing to pick up in store (from delivery) should take the item to next day collection (if quantity entered is more than collection stock) otherwise to collection
        const availableCollectionStock =
          trolleyLineItem.product?.stockDetails?.collection ?? 0;
        if (trolleyLineItem.quantity > availableCollectionStock)
          newChannel = TrolleyChannel.NextDayCollection;
      }

      this.setTrolleyLineProcessing(line_id, true);

      try {
        const { data: trolleyData } =
          await EcomTrolleyService.updateTrolleyItem(
            line_id,
            {
              channel: newChannel,
            },
            this.trolley_session_token
          );

        if (!trolleyData)
          throw new Error("can not update channel as lineId not found");

        if (trolleyData.session_token)
          this.trolley_session_token = trolleyData.session_token;

        await this.setTrolleyLineItems(trolleyData);
        await this.setOrderTotals();
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      } finally {
        this.setTrolleyLineProcessing(line_id, false);
      }
    },

    /* Check if channel switch triggers channel switch based on new branch stock */
    async checkForChannelSwitchByBranch(newBranchId: string) {
      if (!this.trolley_line_items) return;
      if (this.trolley.is_all_lines_delivery) return;

      this.loading = true;

      const collectionItems = this.trolley_line_items.Collection;
      const nextDayCollectionItems = this.trolley_line_items.NextDayCollection;

      const collectionProductCodes = collectionItems.map(
        (line) => line.product_code
      );
      const nextDayCollectionProductCodes = nextDayCollectionItems.map(
        (line) => line.product_code
      );

      const allProductCodes: string[] = [
        ...collectionProductCodes,
        ...nextDayCollectionProductCodes,
      ];

      const newBranchStockForCollection: Record<Product["code"], number> =
        allProductCodes.reduce((acc, code) => {
          acc[code] = 0;
          return acc;
        }, {} as Record<Product["code"], number>);

      try {
        const stockResponse = await EcomService.fetchStockProduct({
          products: allProductCodes,
          sites: [newBranchId],
        });

        if (!stockResponse || !stockResponse.data)
          throw new Error("couldn't get stock for new branch");

        stockResponse.data.map((stockData) => {
          newBranchStockForCollection[stockData.product_code] = parseInt(
            stockData.stock_qty,
            10
          );
        });

        // COLLECTION ---- check if channel needs to be changed to next day
        for (const item of collectionItems) {
          let updatedChannel: TrolleyChannel | null = null;

          if (
            item.quantity > newBranchStockForCollection[item.product_code] &&
            item.quantity <= item.product?.stockDetails?.delivery!
          ) {
            updatedChannel = TrolleyChannel.NextDayCollection;
          }

          if (!updatedChannel) continue;

          await EcomTrolleyService.updateTrolleyItem(
            item.id,
            {
              channel: updatedChannel,
            },
            this.trolley_session_token
          );
        }
        // NEXT-DAY-COLLECTION ---- check if channel needs to be changed to collection
        for (const item of nextDayCollectionItems) {
          let updatedChannel: TrolleyChannel | null = null;

          if (item.quantity <= newBranchStockForCollection[item.product_code]) {
            updatedChannel = TrolleyChannel.Collection;
          }

          if (!updatedChannel) continue;

          await EcomTrolleyService.updateTrolleyItem(
            item.id,
            {
              channel: updatedChannel,
            },
            this.trolley_session_token
          );
        }
      } catch (err) {
        useErrorHandler(err, "high");
        return err;
      } finally {
        this.loading = false;
      }
    },

    async getTrolleyShareCode() {

      if (!this.trolley_id) return;

      this.code_share_modal_visible = true;

      // reset stale states
      useStateModifier(this.share_by_email_state, "idle", "");
      useState("trolley_share_receiver_email").value = "";
      this.trolley.shareable_code = "";
      this.trolley.qr_code = "";

      try {
        const { data } = await EcomTrolleyService.getTrolleyShareCode(
          this.trolley_id,
          this.trolley_session_token
        );
        this.trolley.last_share_code_retrieved_at = new Date(data.name);
        this.trolley.shareable_code = data.id;
        this.trolley.qr_code = data.qr_code;
        this.getEncodeTrolleyCodeQueryString(this.trolley.shareable_code)
      } catch (err) {
        useErrorHandler(err, "medium");
        return err;
      }
    },

    async sendTrolleyShareCodeByEmail(email_id: string) {
      if (!this.trolley_id) return;

      useStateModifier(this.share_by_email_state, "loading", "");

      const response = await EcomTrolleyService.sendTrolleyShareCodeByEmail(
        this.trolley_id,
        {
          recipient_email: email_id,
        },
        this.trolley_session_token
      );
      // failed case: response has success flag set to false
      if (response && !response.success) {
        useStateModifier(
          this.share_by_email_state,
          "failed",
          "unable to send email"
        );
        return response.errors;
      }
      // success case: response is undefined but status code is 204
      useStateModifier(
        this.share_by_email_state,
        "success",
        "email sent successfully! please check your inbox"
      );
    },

    async updateStatus(options: {
      newStatus: TrolleyStatus;
      targetTrolleyId?: string;
      trolleySessionToken?: string | null;
    }) {
      let trolleyId = this.trolley_id;
      if (options.targetTrolleyId) trolleyId = options.targetTrolleyId;

      if (!trolleyId) return;

      try {
        const response = await EcomTrolleyService.updateTrolleyStatus(
          trolleyId,
          {
            status: options.newStatus,
          },
          options.trolleySessionToken || this.trolley_session_token
        );

        if (response && !response.success)
          throw new Error("couldn't update current trolley status");
        return response;
      } catch (err) {
        useErrorHandler(err, "low");
        return err;
      }
    },

    async updateActiveTrolleyWithCurrentItems(activeTrolley: TrolleyResponse) {
      let addToTrolleyResponse: any;

      if (!this.trolley_line_items) return;
      // Map the local trolley line items into promises
      const promises = Object.values(this.trolley_line_items)
        .flat()
        .map(async (line) => {
          const { product_code, quantity, channel, delivery_method_code } =
            line;

          const item = {
            product_code,
            quantity,
            channel,
            delivery_method_code,
          };

          /* check if the same product already exists in the active trolley */
          const existingActiveTrolleyLine = activeTrolley.lines.find(
            (activeTrolleyLine) =>
              activeTrolleyLine.product_code === item.product_code
          );

          console.log(
            "existing active trolley line with same product ->",
            existingActiveTrolleyLine
          );

          // active trolley takes priority, skip adding the same product
          if (existingActiveTrolleyLine) {
            return null;
          }

          /* add the local products in the active trolley */
          return await EcomTrolleyService.addToTrolley(
            activeTrolley.id,
            item,
            activeTrolley.session_token
          );
        });

      const addToTrolleyResponses = await Promise.all(promises);

      // Find the last successful response
      addToTrolleyResponse = addToTrolleyResponses.find(
        (response) => response !== null
      );

      console.log(
        "final add to trolley final response to be set --->",
        addToTrolleyResponse
      );

      if (!addToTrolleyResponse || !addToTrolleyResponse.data) {
        // "No new items were added, setting trolley resource from active trolley."
        await this.setTrolleyResource(activeTrolley);
        return;
      }

      await this.setTrolleyResource(addToTrolleyResponse.data);
    },

    async setTrolleyResource(trolleyData: TrolleyResponse) {
      this.loading = true;

      try {
        this.trolley.status = trolleyData.status;

        // set trolley attributes
        this.trolley_id = trolleyData.id;
        this.trolley_session_id = trolleyData.session_id;
        if (trolleyData.session_token)
          this.trolley_session_token = trolleyData.session_token;

        this.applied_promo_codes = trolleyData.applied_promo_codes;

        await this.setTrolleyLineItems(trolleyData);
        await this.setOrderTotals();
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      } finally {
        this.loading = false;
      }
    },

    async getActiveCustomerTrolleys(
      customer_id: string,
      customer_token: string
    ) {
      try {
        /* get active customer trolley for cross-browser env */
        const res = await EcomCustomerService.fetchActiveCustomerTrolleys(
          customer_id,
          customer_token
        );
        if (!res || !res.data)
          throw new Error("couldn't fetch active customer trolleys");
        return res.data;
      } catch (err) {
        useErrorHandler(err, "critical");
        return [];
      }
    },

    async setActiveCustomerTrolley(
      customer_id: string,
      customer_token: string
    ) {
      const customerActiveTrolleys = await this.getActiveCustomerTrolleys(
        customer_id,
        customer_token
      );
      // 1. if no active trolley exists
      if (!customerActiveTrolleys || !customerActiveTrolleys.length) {
        this.last_active_trolley_id = null;
        return false;
      }
      // -----> active trolley set
      const activeTrolley = customerActiveTrolleys[0];
      this.last_active_trolley_id = activeTrolley.id;

      // 2. if both active trolley and local trolley exist
      if (this.trolley_id && this.trolley_id !== activeTrolley.id) {
        const currentTrolleyId = this.trolley_id;
        const currentTrolleySessionToken = this.trolley_session_token;
        // if there is no product in local trolley
        if (this.is_empty) await this.setTrolleyResource(activeTrolley);
        // if there are products in local trolley and active trolley both
        else await this.updateActiveTrolleyWithCurrentItems(activeTrolley);
        // abandon local trolley
        await this.updateStatus({
          newStatus: TrolleyStatus.Abandoned,
          targetTrolleyId: currentTrolleyId,
          trolleySessionToken: currentTrolleySessionToken
        })
        return;
      }
      // 3. if active trolley exists but local trolley doesn't exist
      await this.setTrolleyResource(activeTrolley);
    },

    findTrolleyItemByProductCode(requiredProductCode: Product["code"]): TrolleyLineItemCore | undefined {
      if (!this.trolley.lines || !this.trolley.lines.length) return;
      return this.trolley.lines.find((line) => line.product_code === requiredProductCode);
    },

    destroyTrolley() {
      this.trolley_id = null;
      this.last_active_trolley_id = null;
      this.trolley_session_id = null;
      this.trolley_session_token = null;
      this.trolley = {} as any;
      this.trolley_line_items = null;
      this.setTotalProducts(null);
    },
  },
});

/* Pinia hot reload */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTrolleyStore, import.meta.hot));
}
