import params from "~/env";
import { AlgoliaECOMService } from "./algoliaECOM.service";
import type { Product } from "~/types/ecom/product/product.type";

export class ProductService {
  async getProductByCode(id: number): Promise<{ data: Product } | any> {
    try {
      const response = await useAjaxEcom(`/products/${id}`, {
        show_notifications: false,
        show_loading_indicator: false,
        cache:true
      });
      return response.data;
    } catch (error) {
      useErrorHandler(error, "critical");
      return error;
    }
  }

  async getProducts(id: number[],cache = false): Promise<Product[] | any> {
    try {
      const response = await useAjaxEcom("/products/getProducts", {
        method: "post",
        params: {
          products: id,
        },
        show_notifications: false,
        show_loading_indicator: false,
        cache
      });
      return response.data;
    } catch (error) {
      useErrorHandler(error, "critical");
      return error;
    }
  }

  async getStocks(id: number[], branch_id?: string[]) {
    try {
      const response = await useAjaxEcom("/stock/getStock", {
        method: "post",
        params: {
          products: id,
          sites: ["WW"],
        },
        show_notifications: false,
        show_loading_indicator: false,
      });
      return response.data;
    } catch (error) {
      //
      useErrorHandler(error, "high");
      return error;
    }
  }

  async getDeliveryDetails(id: number, sites?: string) {
    try {
      if (sites) {
        //  default_sites = [ sites, ...default_sites]
      }
      const response = await useAjaxEcom(`/products/${id}/delivery`, {
        query: {
          site: sites,
        },
        show_notifications: false,
        show_loading_indicator: false,
      });
      return response.data;
    } catch (error) {
      useErrorHandler(error, "low");
      return error;
    }
  }

  async subscribeProduct(customer_id: string, productCode: string) {
    try {
      const url = `/customers/${customer_id}/stock-notifications/${productCode}`;

      let response = await useAjaxEcom(url, {
        method: "post",
      });
      return response;
    } catch (err) {
      useErrorHandler(err, "low");
    }
  }
  async unSubscribeProduct(customer_id: string, product_id: string) {
    try {
      const url = `/customers/${customer_id}/stock-notifications/${product_id}`;
      let response = await useAjaxEcom(url, {
        method: "delete",
      });
      return response;

    } catch (err) {
      useErrorHandler(err, "low");
    }
  }

  async getsubscribedProducts(customerId: string) { 
    try {
      if(!customerId){
        customerId = useAuthStore().user?.id;
      }
       
      const url = `/customers/${customerId}/stock-notifications`;

      let products = await useAjaxEcom(url, {
        method: "get",
      });

      return products;
    } catch (err) {
      useErrorHandler(err, "low");
    }
  }
}
