import { EcomError, EcomService } from "~/services/ecom.service";
import { WoosmapError, WoosmapService } from "~/services/woosmap.service";
import { LocationService } from "~/services/location.service";
import type {
  Branch,
  BranchGooglePlaceData,
  Geoposition,
  NearestBranchRequest,
} from "~/types/ecom/branch.type";
import type { WoosmapLocalitySuggestion } from "~/types/woosmap/locality.type";
import { useErrorHandler } from "~/composables/useErrorHandler";
import EcomCustomerService from "~/services/ecom/customer.service";

type BranchStoreState = {
  ecomService: EcomService | null;
  woosmapService: WoosmapService | null;
  localitySearchInput: string;

  selectedLocality: WoosmapLocalitySuggestion | null;
  autoCompleteSuggestions: WoosmapLocalitySuggestion[];
  autoCompleteDropdownVisible: boolean;

  allBranches: Branch[];
  allBranchNames: string[];
  nearestBranches: Branch[];
  categorizedAllBranches: Record<string, Branch[]> | null;
  branchGoogleFeedback: Pick<BranchGooglePlaceData, 'rating' | 'review_count'> | null,
  branchGooglePlaceId: string,

  currentBranchChoice: Branch | null; // for radio button
  defaultBranch: Branch | null;
  lastSavedBranch: Branch | null;
  currentSavedBranch: Branch | null;

  geolocationError: unknown;
  currentLocation: Geoposition | null;
  selectedLetter: string;
  branchNotFoundForLocality: any;
  branchNotFoundForPostcode: string;
  searchTimer: ReturnType<typeof setTimeout> | null;

  isProcessingLocalitySearch: boolean;
  isProcessingNearestBranchSearch: boolean;
  isDefaultBranchSelected: boolean;
  isAutoCompleteSearchEnabled: boolean;
  locationPermissionDenied: boolean;

  noNearestBranchAvailable: boolean;
  noSuggestionsAvailable: boolean;

  branchSelectorModalVisible: boolean;
  debugValues: {
    currentLat: number;
    currentLong: number;
    radius?: number;
  } | null;
};

export const useBranchStore = defineStore("branchStore", {
  state: (): BranchStoreState => ({
    ecomService: null,
    woosmapService: null,

    localitySearchInput: "",
    selectedLocality: null,
    autoCompleteSuggestions: [],
    autoCompleteDropdownVisible: false,
    nearestBranches: [],
    categorizedAllBranches: null,

    branchGoogleFeedback: null,
    branchGooglePlaceId: '',

    currentBranchChoice: null,
    defaultBranch: null,
    lastSavedBranch: null,
    currentSavedBranch: null,
    geolocationError: null,
    currentLocation: null,
    branchNotFoundForLocality: null,
    branchNotFoundForPostcode: "",
    selectedLetter: "All",

    isProcessingLocalitySearch: false,
    isProcessingNearestBranchSearch: false,

    isDefaultBranchSelected: false,
    isAutoCompleteSearchEnabled: true,
    locationPermissionDenied: false,

    noNearestBranchAvailable: false,
    noSuggestionsAvailable: false,
    branchSelectorModalVisible: false,
    searchTimer: null,
    allBranchNames: [],
    allBranches: [],
    debugValues: null,
  }),
  persist: [
    {
      storage: import.meta.client ? localStorage : undefined,
      pick: ["defaultBranch", "lastSavedBranch"],
    },
    {
      pick: ["currentSavedBranch.id", "lastSavedBranch.name"]
    }
  ],
  getters: {
    is_branch_set: (state) => {
      if (!state.defaultBranch && !state.lastSavedBranch) return false;
      return true;
    },
    formattedSearchQuery: (state) =>
      state.localitySearchInput.replace('"', '\\"').replace(/^\s+|\s+$/g, ""),
    mapZoomCenter: (state) => {
      if (state.noNearestBranchAvailable || !state.nearestBranches.length)
        return null;
      return {
        latitude: Number(state.nearestBranches[0].geolocation.latitude),
        longitude: Number(state.nearestBranches[0].geolocation.longitude),
      };
    },
    locationBlockMessage: (state) => {
      if (
        state.locationPermissionDenied &&
        !state.isProcessingNearestBranchSearch
      )
        return useTranslation(
          "currentLocationMessage",
          "Current location access blocked"
        );
    },
    noResultState: (state) => {
      return (
        !state.nearestBranches.length && !state.isProcessingNearestBranchSearch
      );
    },
    openingHoursModified: () => (timeSlots: string[]) => {
      const daysMap: {
        [key: string]: number
      } = {
        'Zo': 0,  // Sunday
        'Ma': 1,  // Monday
        'Di': 2,  // Tuesday
        'Wo': 3,  // Wednesday
        'Do': 4,  // Thursday
        'Vr': 5,  // Friday
        'Za': 6   // Saturday
      };

      const weekDaysFullName = new Map([['Zo', 'Zondag'], ['Ma', 'Maandag'], ['Di', 'Dinsdag'], ['Wo', 'Woensdag'], ['Do', 'Donderdag'], ['Vr', 'Vrijdag'], ['Za', 'Zaterdag']]);

      function getDaysInRange(startDay: string, endDay: string) {
        const startIndex = daysMap[startDay];
        const endIndex = daysMap[endDay];

        const daysList = [];
        let currentDay = startIndex;

        while (currentDay <= endIndex) {
          daysList.push(Object.keys(daysMap).find(key => daysMap[key] === currentDay));
          currentDay++;
        }

        return daysList;
      }

      const formattedDates: { days: string | string[], timeRange: string }[] = [];

      for (const item of timeSlots) {        
        /**
         * @param daysRange
         * @type string[]
         * Ex: ["Zo", "Vr"] or ["Za"]
         * 
         * @param item
         * @type string
         * Ex: "Zo - Vr: 9:00 - 17:00" or "Za: 8:00 - 17:00"
         */
        const daysRange = item.split(':').at(0).split(' - ')
        // filter out the days that are not valid
        const filtered_days = daysRange.some((day: string) => weekDaysFullName.has(day))
        if (!filtered_days) continue;
        // @params
        // timeRange: string eg: "9:00 - 17:00"
        const timeRange = item.replace(/^(.*?(\d{1,2}:\d{2}\s*-\s*\d{1,2}:\d{2}).*)$/, '$2');
        if (daysRange.length > 1) {
          const [startDay, endDay] = daysRange;
          const days = getDaysInRange(startDay, endDay);
          formattedDates.push({ days, timeRange })
        } else {
          const [days] = daysRange;
          formattedDates.push({ days, timeRange })
        }
      }

      const openingHours = computed(() => {
        const formattedHours = formattedDates.flatMap(item => {
          /**
          * @param item.days
          * @type object
          * Ex: {days: ["Zo", "Vr"], timeRange: "9:00 - 17:00"}
          * or
          * Ex: {days: "Zo", timeRange: "9:00 - 17:00"}
          */
          if (typeof item.days !== 'string') {
            return item.days.map(day => {
              return {
                id: day,
                day: useTranslation(day, weekDaysFullName.get(day)),
                time: item.timeRange
              }
            })
          } else {
            return { id: item.days, day: useTranslation(item.days, weekDaysFullName.get(item.days)), time: item.timeRange }
          }
        })
        const all_days_name = Object.keys(daysMap) // get all days name
        const included_days = formattedHours.map(item => item.id) // all days name that are included in the time slots
        const missing_days = all_days_name.filter(item => !included_days.includes(item)); // all days name that are not included in the time slots

        // mark the missing days as closed
        missing_days.forEach(day => {
          formattedHours.push({ id: day, day: useTranslation(day, weekDaysFullName.get(day)), time: useTranslation('closed', 'Gesloten') })
        })
        return formattedHours
      })
      return openingHours;
    }
  },
  actions: {
    // --------------------- SERVICE INITIALIZER ------------------------ //
    initialize() {
      const runtimeConfig = useRuntimeConfig();

      this.ecomService = new EcomService();

      this.woosmapService = new WoosmapService(
        runtimeConfig.public.woosmapApiKey,
        runtimeConfig.public.woosmapBaseApiUrl
      );
    },
    async delayedAutoCompleteSearch(delayTime: number = 500) {
      this.resetStatuses();

      if (!this.formattedSearchQuery.length) {
        this.noSuggestionsAvailable = false;
        this.autoCompleteSuggestions = [];
        return;
      }

      if (this.formattedSearchQuery.length !== this.localitySearchInput.length)
        return;

      // Clear previous timeout if any
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }

      // Start a new timeout
      this.searchTimer = setTimeout(async () => {
        try {
          await this.getAutoCompleteResults(this.formattedSearchQuery);
        } catch (err) {
          useErrorHandler(err, "low");
        } finally {
          this.searchTimer = null; // Reset timerId after execution
        }
      }, delayTime);
    },

    //----------------- Fetch locality based autocomplete suggestions -----------------------//
    async getAutoCompleteResults(query: string) {
      if (!this.isAutoCompleteSearchEnabled) {
        return;
      }

      this.noSuggestionsAvailable = false;
      this.isProcessingLocalitySearch = true;

      try {
        const response = await this.woosmapService?.fetchLocalitySuggestions(
          query
        );

        if (!response) {
          throw new WoosmapError("woosmap service not initialized correctly");
        }

        if (!response.localities.length) {
          if (query.length > 0) this.noSuggestionsAvailable = true;
          this.autoCompleteSuggestions = [];
          throw new WoosmapError(
            "woosmap locality autocomplete suggestion unavailable"
          );
        }
        /* store localities suggestions and pass in autocomplete options */
        this.autoCompleteSuggestions = response.localities;
      } catch (err) {
        useErrorHandler(err, "medium");
      } finally {
        this.isProcessingLocalitySearch = false;
      }
    },

    //--------------- Common helper function to fetch results from ECOM ../branches/nearest --------------------//
    async getNearestBranches(
      request: NearestBranchRequest,
      options?: {
        isLocalityBased?: boolean;
        isPostcodeBased?: boolean;
      }
    ) {
      try {
        const response = await this.ecomService?.fetchNearestBranches(request);

        // Null check
        if (!response || !response.data || !response.data.length) {
          this.noNearestBranchAvailable = true;
          this.nearestBranches = [];

          if (options?.isLocalityBased)
            this.branchNotFoundForLocality = this.selectedLocality;
          if (options?.isPostcodeBased)
            this.branchNotFoundForPostcode = this.localitySearchInput;

          throw new EcomError("ecom nearest branches fetch error");
        }

        this.nearestBranches = response.data;
        this.noNearestBranchAvailable = false;

        // For visual indication - user can see the last saved branch radio selected
        const matchingBranchAsCurrent = this.nearestBranches.find(branch => branch.id === this.lastSavedBranch?.id)

        if(matchingBranchAsCurrent){
          this.currentBranchChoice = matchingBranchAsCurrent;
        } else{
          this.currentBranchChoice = null;
        }
      } catch (err) {
        useErrorHandler(err, "medium");
      }
    },

    //--------------- Resetter method to reset all error and success states during a request --------------------//
    resetStatuses() {
      this.noNearestBranchAvailable = false;
      this.noSuggestionsAvailable = false;
    },

    //--------------- Set debug values from route query or other source --------------------//
    setDebugValues(
      latitude: string,
      longitude: string,
      query?: string,
      radius?: string
    ) {
      this.debugValues = {
        currentLat: parseFloat(latitude),
        currentLong: parseFloat(longitude),
      };

      if (query) {
        console.log('set query `q` in debug values setter');
        this.localitySearchInput = query;
      }

      if (radius) {
        this.debugValues.radius = Number(radius);
      }
    },
    //--------------- Common helper function to connect to location service --------------------//
    async useCurrentLocation() {
      this.locationPermissionDenied = false;
      try {
        const locationService = new LocationService();
        this.currentLocation = await locationService.getCurrentLocation();

        if (this.localitySearchInput.length) this.localitySearchInput = "";
        this.autoCompleteSuggestions = [];
      } catch (err) {
        this.geolocationError = err;
        useErrorHandler(err, "medium");

        if (err instanceof GeolocationPositionError) {
          //--------- TODO - Log GTM ---------//
          this.locationPermissionDenied = true;
          this.currentLocation = null;
        } else
          console.log(
            `Unknown error occurred while retrieving current location: ${err}`
          );
      }
    },
    //------------------- Get locality details from Woosmap ---------------------------//
    async getLocalityDetails(public_id: string) {
      try {
        const { result } = await this.woosmapService!.fetchLocalityDetails(
          public_id
        );

        if (!result) {
          throw new WoosmapError("woosmap locality details result error");
        }

        /* Geomertry can be undefined */ // TODO: Fallback - send searchQuery as POSTCODE to Ecom
        if (!result.geometry)
          throw new WoosmapError(
            "woosmap localities details received but geometry is not defined"
          );

        return result;
      } catch (err) {
        useErrorHandler(err, "medium");
      }
    },

    //------------------- Selecting one suggestion ---------------------------//
    async onSelectLocality() {
      this.resetStatuses();
      this.isProcessingNearestBranchSearch = true;

      try {
        //------------------- Get latitude and longitude of the selected locality ---------------------------//
        const localityDetailsResult = await this.getLocalityDetails(
          this.selectedLocality!.public_id
        );

        if (!localityDetailsResult || !localityDetailsResult.geometry)
          throw new Error(
            "woosmap locality details fetch error on suggestion selection in branch selector"
          );

        const nearestBranchRequestBody: NearestBranchRequest = {
          latitude: String(localityDetailsResult.geometry.location.lat),
          longitude: String(localityDetailsResult.geometry.location.lng),
        };

        await this.getNearestBranches(nearestBranchRequestBody, {
          isLocalityBased: true,
        });
      } catch (err) {
        useErrorHandler(err, "medium");
        return err;
      } finally {
        this.isProcessingNearestBranchSearch = false;
      }
    },

    //------------------- Fetch branch based on current location------------------//
    async getNearestBranchByCurrentLocation() {
      this.isProcessingNearestBranchSearch = true;

      try {
        await this.useCurrentLocation();
        if (!this.currentLocation) return;

        await this.getNearestBranches(this.currentLocation, {
          isLocalityBased: true,
        });
      } finally {
        this.isProcessingNearestBranchSearch = false;
      }
    },

    //-------------------- Nearest branches based on postcode (on Enter press) -------------------//
    async getPostcodeBasedResults() {
      this.resetStatuses();

      if (!this.formattedSearchQuery.length) return;

      this.isProcessingNearestBranchSearch = true;
      this.isAutoCompleteSearchEnabled = false;

      await this.getNearestBranches(
        {
          postcode: this.formattedSearchQuery,
        },
        { isPostcodeBased: true }
      );

      this.isAutoCompleteSearchEnabled = true;
      this.isProcessingNearestBranchSearch = false;
    },

    /* Get all branches info from ecom */
    async getAllBranches() {
      this.resetStatuses();

      try {
        const response = await this.ecomService!.fetchAllBranches();

        if (!response || !response.data) {
          this.allBranches = [];
          throw new EcomError("ecom all branches fetch error");
        }

        this.allBranches = response.data;

        // Sort branches alphabetically by name
        const sortedBranches = response.data
          .slice()
          .sort((a: Branch, b: Branch) => {
            return a.name.localeCompare(b.name);
          });

        this.allBranchNames = sortedBranches.map(
          (branch: Branch) => branch.name
        );

        // Categorize branches by first letter //
        this.categorizedAllBranches = sortedBranches.reduce(
          (acc: Record<string, Branch[]>, branch: Branch) => {
            const firstLetter = branch.name.charAt(0).toUpperCase();
            if (!acc[firstLetter]) {
              acc[firstLetter] = [];
            }
            acc[firstLetter].push(branch);
            return acc;
          },
          {}
        );
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      }
    },

    /* Get branch details */
    async getBranchById(branchId: string) {
      this.resetStatuses();
      try {
        const response = await this.ecomService?.fetchBranchById(branchId);
        if (!response || !response.data) {
          throw new EcomError("ecom single branch details fetch error");
        }
        return response.data;
      } catch (err) {
        useErrorHandler(err, "critical");
        return null;
      }
    },

    async onSaveBranch(branch: Branch, saveAsDefault = false) {
      this.resetStatuses();
      this.branchSelectorModalVisible = false;
      this.currentBranchChoice = branch;

      if (saveAsDefault) {
        const authStore = useAuthStore();
        this.defaultBranch = branch;
        if (authStore.is_authenticated)
          EcomCustomerService.setDefaultBranch(
            (authStore.user as any)?.id,
            this.defaultBranch.id
          );
      } else {
        this.currentSavedBranch = branch;
      }

      this.lastSavedBranch = branch as Branch;
    },

    //-------------------- Filter all branches based on radius -------------------//
    async filterByRadius(radius: number, unit = "km") {
      this.resetStatuses();

      if (!this.allBranches.length) return;
      this.isProcessingNearestBranchSearch = true;

      try {
        const isRouteDebugValues =
          this.debugValues?.currentLat && this.debugValues?.currentLong;

        if (!isRouteDebugValues) await this.useCurrentLocation();

        if (!this.currentLocation && !isRouteDebugValues) return;

        /* Refine to construct request */
        const currentLatitudeNumber = parseFloat(
          this.currentLocation?.latitude as string
        );
        const currentLongitudeNumber = parseFloat(
          this.currentLocation?.longitude as string
        );

        /* Filtering on all branches */
        this.nearestBranches = this.allBranches.filter((branch) => {
          const branchLatitudeNumber = parseFloat(branch.geolocation.latitude);
          const branchLongitudeNumber = parseFloat(
            branch.geolocation.longitude
          );

          const distance = getRadialDistanceAtoB(
            this.debugValues?.currentLat || currentLatitudeNumber,
            this.debugValues?.currentLong || currentLongitudeNumber,
            branchLatitudeNumber,
            branchLongitudeNumber,
            unit
          );
          return distance <= radius;
        });

        if (!this.nearestBranches.length) this.noNearestBranchAvailable = true;
      } finally {
        this.isProcessingNearestBranchSearch = false;
      }
    },

    //-------------------- Show Route >>> Redirect to google maps -------------------//
    async onShowRoute(branch: Branch) {
      this.resetStatuses();

      await this.useCurrentLocation();

      if (!this.currentLocation) return;

      await navigateTo(
        `https://www.google.com/maps/dir/?api=1&origin=${this.currentLocation.latitude},${this.currentLocation.longitude}&destination=${branch.geolocation.latitude},${branch.geolocation.longitude}&travelmode=driving`,
        {
          external: true,
          open: {
            target: "_blank",
          },
        }
      );
    },

    // ------ Upon signin, set default branch data if found - setter method
    setBranchStates(branch: Branch){
      this.currentBranchChoice = branch;
      this.currentSavedBranch = branch;
      this.lastSavedBranch = branch;
    },

    // ------ Upon signout, reset selected branch data
    resetPersistingBranches() {
      this.currentBranchChoice = null;
      this.currentSavedBranch = null;
      this.lastSavedBranch = null;
      this.defaultBranch = null;
    }
  },
});

/* Pinia hot reload */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBranchStore, import.meta.hot));
}
