import braintree, {
  ApplePay,
  BraintreeError,
  PayPalCheckout,
  Client,
  DataCollector,
  paypal,
} from "braintree-web";

import type { PaymentStrategy } from "../payment.service";

export class GooglePayStrategy implements PaymentStrategy {
  private google_pay_instance: braintree.GooglePayment | null = null;
  private braintree_client_instance: braintree.Client | null = null;
  private three_d_secure_instance: braintree.ThreeDSecure | null = null;
  private data_collector_instance: braintree.DataCollector | null = null;
  private payload: any;

  async initializePayment(
    client: braintree.Client,
    data_collector: braintree.DataCollector
  ): Promise<any> {
    this.braintree_client_instance = client;
    this.data_collector_instance = data_collector;

    await this.initialize();
    await this.initializeThreeDSecure();
  }

  async initialize(
    gpay = typeof google !== "undefined" ? google.payments.api : null,
    environment = "production"
  ): Promise<any> {
    try {
  

      const checkoutStore = useCheckoutStore();
      const trolleyStore = useTrolleyStore();

      this.google_pay_instance = await braintree.googlePayment.create({
        client: this.braintree_client_instance,
        googlePayVersion: 2,
      });

      const payment_client = new (
        gpay ?? window.google.payments.api
      ).PaymentsClient({
        environment: environment === "sandbox" ? "TEST" : "PRODUCTION",
      });

      const button = await payment_client.createButton({
        buttonColor: "black",
        buttonType: "short",
        buttonSizeMode: "fill",
        onClick: async (e) => {
          e.preventDefault();
          try {
            checkoutStore.resetError()
            useCheckoutStore().is_transaction_loading = true;
            const payment_data =
            await this.google_pay_instance.createPaymentDataRequest({
              transactionInfo: {
                currencyCode: "EUR",
                totalPrice: useTrolleyStore().trolley.order_response?.totals?.inc_vat.raw.toString(),
                totalPriceStatus: "FINAL",
              },
            });
    
          let method = JSON.parse(
            JSON.stringify(payment_data?.allowedPaymentMethods)
          );
    
        
    
          const ready_pay = await payment_client.isReadyToPay({
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: method,
          });
    
          if (!ready_pay) {
            throw new Error("Google Pay sheet not available");
          }
            const response = await payment_client.loadPaymentData(payment_data);
            const parsedResponse =
              await this.google_pay_instance?.parseResponse(response);
            const threeDSecureVerification =
              await this.three_d_secure_instance.verifyCard(
                {
                  amount: useTrolleyStore().final_order_total_amount,
                  nonce: parsedResponse.nonce,
                  billingAddress: parsedResponse.details.billingAddress,
                  email: parsedResponse.details.email,
                  challengeRequested: true,
                  bin: parsedResponse?.binData,
                  onLookupComplete: function (data: any, next: any) {
                    next();
                  },
                },
                function (err, payload) {
                  if (err) {
                    useErrorHandler(err, "high");
                    useCheckoutStore().is_transaction_loading = false;
                    useCheckoutStore().payment_error.gpay_error = "3 d secure failed";
                    return;
                  }
                  
                useCheckoutStore().resetError();

                  const { liabilityShifted, liabilityShiftPossible } = payload;
                  if (liabilityShiftPossible && !liabilityShifted)
                 {
                    useCheckoutStore().is_transaction_loading = false;
                    useCheckoutStore().payment_error.gpay_error =
                      "Payment unsuccessful - please try a different payment method";
                      return

                 }
                  if (!liabilityShifted){
                    useCheckoutStore().is_transaction_loading = false
                    useCheckoutStore().payment_error.gpay_error =
                        "Payment unsuccessful - please try a different payment method"
                        return
                  }
                    
                  //-------------create Transiction ------------------//
                  useCheckoutStore().is_transaction_loading = true;
                  useCheckoutStore().payment_success =
                    "Payment success Redirecting...";
                  checkoutStore.createTransaction(
                    payload?.nonce,
                    "braintree-google-pay"
                  );
                }
              );
          } catch (error) {
            if (error.statusCode === "CANCELED") {
              useCheckoutStore().is_transaction_loading = false;
              useCheckoutStore().payment_error.gpay_error = "Google Pay Payment Cancelled";
              useCheckoutStore().is_transaction_loading = false;
              return error;
            } else {
              useCheckoutStore().is_transaction_loading = false;
              useErrorHandler(error, "critical");
              useCheckoutStore().payment_error.gpay_error =
                "Google Pay Error occuoured, please try again";
              return error;
            }
            throw error;
          }
        },
      });

      const el = await document.querySelector(
        "#braintree-google-pay-button-container"
      );

      if (el) {
        el.innerHTML = "";
        el.appendChild(button);
      }

      return button;
    } catch (error) {
      console.error("Error in Google Pay process:", error);
      useCheckoutStore().is_transaction_loading = false;
      throw error;
    }
  }

  async initializeThreeDSecure() {
    this.three_d_secure_instance = await braintree.threeDSecure.create({
      client: this.braintree_client_instance,
      version: 2,
    });
  }

  async teardown() {
    this.google_pay_instance.teardown();
  }
}
