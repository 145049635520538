import { ProductService } from "~/services/product.service";
import type {
  Product,
} from "~/types/ecom/product/product.type";
import type {
  ProductStockResponse,
} from "~/types/ecom/product/stock.type";
import {
  getProductRating,
  getProductImages,
  getTopRightTagValues,
  getDetailedDescriptionHTML,
  getNumberOfReviews,
  getPromotionalMessages
} from "~/utils/productAttributes";
import type { Branch } from "~/types/ecom/branch.type";
import { BuilderService } from "../services/builder.service";
import type { ProductAvailability } from "~/components/atoms/TsRadioTrolleyCollection.vue";
import { toast } from "vue3-toastify";
import { EcomService } from "~/services/ecom.service";
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";
interface VariationDropdown {
  name: string;
  code: number;
}

interface ProductImage {
  src: string;
  thumb: string;
  type: "image" | "video";
}

interface Taxonomy {
  text: string;
  link: string;
}

export const useProductStore = defineStore("product", {
  state: () => ({
    product_id: "" as string,
    product: {} as Product,
    variations_ids: [] as string[],
    product_stock: [] as ProductStockResponse[],
    products_data: [] as Product[] | any,
    compare_products_taxonomies_id: [] as any[],
    filtered_products_data: [] as any[],
    product_images: [] as ProductImage[],
    variation_dropdown: [] as VariationDropdown[] | null,
    builder_product_name: null as string | null,
    builder_product_images: [] as any[],
    is_product_loading: true as boolean,
    // to show a page loader when add to trolley action is dispatched
    is_adding_to_trolley: false as boolean,
    is_product_variation_loading: false as boolean,
    is_product_stock_loading: true as boolean,
    quantity_in_trolley: 0 as number,
    taxonomies: [] as Taxonomy[],
    builder_product: {} as any,
    is_confirmation_modal_visible: false as boolean,
    delivery_method: "" as string,
    notify_product_code: "" as string | undefined,
    notify_product_name: "" as string | undefined,
    notify_user_email: "" as string,
    is_stock_subscribe_loading: false,
    variation_slugs: [] as string[] | null,
    product_rating: null as any,
    promo_tags: [] as string[],
    product_description: null as any,
    product_review_count: null as any,
    is_variation_available: false as boolean,
    quantity_to_add: 1,
    delivery_stock: null as number | null,
    collection_stock: null as number | null,
    show_store_info: true as boolean,
    store_info: useBranchStore().lastSavedBranch as Branch | null,
    is_direct_ship: false as boolean,
    directship_stock: null as number | null,
    selected_trolley_channel: TrolleyChannel.Delivery,
    show_notify_me_cta: false as boolean,
    subscription_successful_modal_visible: false,
    subscribe_notification_modal_visible: false,
    subscribedProductDetails: [],
    promo_messages: null as any,
  }),

  getters: {
    collectionAvailability(): ProductAvailability | undefined {
      if (!this.product) return;

      const branchStore = useBranchStore();
      const isBranchSet = branchStore.is_branch_set;
      // If no branch is selected, return a "NoBranchSelected" status
      if (!isBranchSet) {
        return { status: "NoBranchSelected", stock: 0 };
      }

      const { collection_stock, delivery_stock, quantity_to_add } = this;

      const productCollectionStock = collection_stock || 0;
      const productDeliveryStock = delivery_stock || 0;

      // Case 1: Out of stock when both collection and delivery stocks are 0 or Quantity surpasses Availibility
      // also valid when initially oos, because (1 > 0 && 1 > 0) returns true
      const isOutOfStock = quantity_to_add ? (quantity_to_add > productCollectionStock && quantity_to_add > productDeliveryStock) : false;
      if (isOutOfStock) return { status: "OutOfStock", stock: 0 };
    
      // Case 2: NextDayCollection when no collection stock but delivery stock is available
      const enableNextDayCollection = quantity_to_add ? (quantity_to_add > productCollectionStock && quantity_to_add <= productDeliveryStock) : false;

      if (enableNextDayCollection) {
        return { status: "NextDayCollection", stock: productDeliveryStock };
      }

      // Case 3: Collection available if stock is sufficient for quantity
      return { status: "Collection", stock: productCollectionStock };
    },

    isOutOfStockForDelivery(): boolean {
      return !this.delivery_stock || this.delivery_stock === 0 || this.quantity_to_add > this.delivery_stock
    },

    isOutOfStockForDirectship(): boolean {
      return !this.directship_stock || this.directship_stock === 0 || this.quantity_to_add > this.directship_stock
    },

    isTrolleyCTADisabled(): boolean {
      const { quantity_to_add, selected_trolley_channel, delivery_stock, collection_stock, collectionAvailability } = this;

      const isGreaterThanDeliveryStock = quantity_to_add > (delivery_stock || 0);
      const isGreaterThanCollectionStock = quantity_to_add > (collection_stock || 0);

      return (
        !quantity_to_add ||
        (selected_trolley_channel === TrolleyChannel.Delivery
          ? isGreaterThanDeliveryStock
          : collectionAvailability?.status === "NextDayCollection"
            ? isGreaterThanDeliveryStock
            : isGreaterThanCollectionStock)
      );
    }
  },


  actions: {
    //----------------------------------------------------------------------------------------------
    async initialize(product_id?: string) {
      const promises = [];
      this.quantity_to_add = 1;
      if (product_id) {
        this.product_id = product_id;
        this.is_confirmation_modal_visible = false;
      } else {
        const route = useRoute();
        this.setProductIdFromRoute(route.params.slug as string);
      }
      await Promise.all([
        this.fetchProductDetails(),
       // this.fetchProductStocks(),
      ]);
    },

    //----------------------------------------------------------------------------------------------
    async fetchProductDetails() {
      this.quantity_to_add = 1;
      if (!this.product_id) {
        throw new Error("Product ID is not set");
      }
      const route = useRoute();

      try {
        this.is_product_loading = true;
        await Promise.all([this.fetchProductData()]);

        if (this.product) {
          await this.checkSlug(route.params.slug as string);
        }

      } catch (error) {
        if (!process.server) {
          useErrorHandler(error, "critical");
        }
      } finally {
        this.is_product_loading = false;
      }
    },
    async fetchsubscribedProductsDetails() {
      const searchResultStore = useSearchResultStore();
      const accountStore = useAccountStore();
      let product_Service = new ProductService();
      if (accountStore.stock_notifications && accountStore.stock_notifications.length > 0) {
        const productIds = accountStore.stock_notifications?.map(notification => Number(notification.product_id));
        if (productIds?.length > 0) {
          try {

            const response = await product_Service.getProducts(productIds)
            if (response) {
              this.subscribedProductDetails = accountStore.stock_notifications.map(notification => {
                const productData = response.find(product => product.code === notification.product_id);
                return {
                  product_data: productData,
                  created_at: notification.created_at,
                }
              })
              const productIdsString = accountStore.stock_notifications?.map(notification => (notification.product_id));
              await searchResultStore.validateSavedProducts2(productIdsString, this.subscribedProductDetails);
            }
            else {
              this.subscribedProductDetails = [];
              accountStore.stock_notifications = [];
            }
          }
          catch (err) {
            this.subscribedProductDetails = [];
            accountStore.stock_notifications = [];
            useErrorHandler(err);
          }

        }
      }
    },
    //----------------------------------------------------------------------------------------------
    async getProductById(id?: number) {
      try {
        return await new ProductService().getProductByCode(id);
      } catch (err) {
        useErrorHandler(err);
        return err;
      }
    },

    //----------------------------------------------------------------------------------------------
    async setProductIdFromRoute(slug: string) {
      const parts = slug.split("-");
      this.product_id = parts.pop() || "";
      await this.getBuilderProduct(slug);
    },

    async checkSlug(slug: string) {
      if (this.getPartBeforeLastDash(slug) !== this.product.slug) {
        navigateTo(`/product/${this.product.slug}-${this.product.code}`);
      }
    },

    getPartBeforeLastDash(input) {
      // Find the position of the last dash
      const lastDashIndex = input.lastIndexOf("-");
      return input.substring(0, lastDashIndex);
    },


    //----------------------------------------------------------------------------------------------
    async getBuilderProduct(link: string) {
      const config = useRuntimeConfig();
      const api_key = config.public.builderApiKey;

      this.builder_product = await new BuilderService(api_key).getItem(
        "products",
        {
          urlPath: "/product/" + link,
        }
      );
      if (this.builder_product) {
        useHead({
          meta: [
            {
              name: "description",
              content:
                this.builder_product &&
                  this.builder_product.data &&
                  this.builder_product.data.seoMetaTags &&
                  this.builder_product.data.seoMetaTags.description
                  ? this.builder_product.data.seoMetaTags.description
                  : this.product.name,
            },
          ],
        });

        this.processBuilderDetails();
      }
    },

    //----------------------------------------------------------------------------------------------
    processBuilderDetails() {
      if (this.builder_product.data.name)
        this.builder_product_name = this.builder_product.data.name;

      if (this.builder_product.data.images) {
        this.builder_product_images = this.builder_product.data.images.map(
          (image) => ({
            src: image.originalFile,
            thumb: image.thumbnail,
            type: "image" as const,
          })
        );
      }
      if (this.builder_product.data.videos.length) {
        let product_videos = this.builder_product.data.videos.map((video) => ({
          src: `https://www.youtube.com/embed/${this.extractYouTubeVideoId(
            video.originalFile
          )}`,
          thumb: video.thumbnail,
          type: "video" as const,
        }));

        this.builder_product_images = [
          ...this.builder_product_images,
          ...product_videos,
        ];
      }
    },

    //----------------------------------------------------------------------------------------------
    extractYouTubeVideoId(url: string) {
      // Regular expression to match YouTube video ID
      const regex = /[?&]v=([^&]+)/;
      const match = url.match(regex);
      return match ? match[1] : null;
    },

    //----------------------------------------------------------------------------------------------
    async fetchProductData(id?: number) {
      try {
        if (!id) id = this.product_id;
        this.product = await new ProductService().getProductByCode(id);
        await this.processProductData();
        this.is_product_loading = false;

        return this.product;
      } catch (err) { }
    },

    //----------------------------------------------------------------------------------------------
    async processProductData() {
      if (!this.product) {
        return;
      }

      this.is_variation_available = this.product.variations.length > 0;
      if (this.product.variations.length > 0) {
        this.variations_ids = this.product.variations;
        await this.fetchProductVariations();
      }
      this.setProductImages();
      this.createBreadcrumb();
      this.product_rating = getProductRating(this.product.other_attributes);
      this.promo_tags = getTopRightTagValues(this.product.other_attributes) || [];
      this.product_description = getDetailedDescriptionHTML(
        this.product.other_attributes
      );
      this.product_review_count = getNumberOfReviews(
        this.product.other_attributes
      );
      this.is_variation_available = this.product.variations.length > 0;
      this.setProductStockData(this.product_stock);
      this.is_direct_ship = this.product.direct_ship;
      this.promo_messages = getPromotionalMessages(this.product.other_attributes);
    },

    //----------------------------------------------------------------------------------------------
    setProductImages() {
      const imageUrl = "https://cdn.toolstation.nl/images/140211-NL";
      const biggestImage = {
        src: this.product.images?.biggest,
        thumb: this.product.images?.med,
        type: "image" as const,
      };

      const productImageCodes = getProductImages(this.product.other_attributes);
      const otherImages = productImageCodes.map((code) => ({
        src: `${imageUrl}/800/${code}.jpg`,
        thumb: `${imageUrl}/250/${code}.jpg`,
        type: "image" as const,
      }));

      const productVideos =
        this.product.videos?.map((item) => ({
          src: `https://www.youtube.com/embed/${item.videoId}`,
          thumb: item.thumbnail,
          type: "video" as const,
          title: item.title,
        })) || [];

      this.product_images = [biggestImage, ...productVideos, ...otherImages];
    },

    //----------------------------------------------------------------------------------------------
    async fetchProductVariations() {
      try {
        this.is_product_variation_loading = true;
        let product_Service = new ProductService();
        const response = await product_Service.getProducts(this.variations_ids);
        this.variation_dropdown = null;
        this.variation_slugs = null;

        if (response) {
          this.variation_dropdown = response.map((variation: Product) => ({
            // considering fall back for names for pre-prod env
            name: `${variation.full_name || variation.name || 'lorem ipsum'} (${variation.code})`,
            code: variation.code,
          }));
          this.variation_slugs = response.map((variation: Product) => ({
            slug: variation.slug,
            code: variation.code,
          }));
          this.is_product_variation_loading = false;
        }
      } catch (error) {
        useErrorHandler(error, "high");
        this.is_product_variation_loading = false;
      }
    },

    //----------------------------------------------------------------------------------------------
    setProductStockData(data: ProductStockResponse[]) {
      if (!data.length) {
        this.delivery_stock = 0, this.collection_stock = 0, this.directship_stock = 0;
        return;
      }
      const deliveryStockData = data.find(stockData => stockData.site_id === 'WW');
      this.delivery_stock = parseInt(deliveryStockData?.stock_qty || '0', 10);
      const collectionStockData = data.find(stockData => stockData.site_id !== 'WW');
      this.collection_stock = parseInt(collectionStockData?.stock_qty || '0', 10);
      if (this.is_direct_ship)
        this.directship_stock = parseInt(deliveryStockData?.stock_qty || '0', 10);
    },

    //----------------------------------------------------------------------------------------------
    async fetchProductStocks() {
      this.is_product_stock_loading = true;
      this.show_notify_me_cta = false;
      let sites = ["WW"];
      const branchStore = useBranchStore();
      const branchId = branchStore.lastSavedBranch?.id;
      if (branchId) sites.push(branchId);
      try {
        const stockResponse = await EcomService.fetchStockProduct({
          products: [this.product_id],
          sites
        });
        if (!stockResponse || !stockResponse.data) throw new Error("couldn't fetch product stock on details page");
        this.setProductStockData(stockResponse.data);
        if(branchStore.is_branch_set && this.delivery_stock === 0 && this.collection_stock === 0){
            this.show_notify_me_cta = true;
        } 
        if(this.is_direct_ship && (!this.directship_stock || this.directship_stock === 0))
        {
          this.show_notify_me_cta = true;
        }
        if (this.is_direct_ship && this.directship_stock === 0) {
          this.show_notify_me_cta = true;
        }
        if (!branchStore.is_branch_set && this.delivery_stock === 0) {
          this.show_notify_me_cta = true;
        }
      } catch (error) {
        useErrorHandler(error, "high");
      } finally {
        this.is_product_stock_loading = false;
      }
    },

    //----------------------------------------------------------------------------------------------
    updateQuantityInTrolley() {
      const trolleyStore = useTrolleyStore();
      const product = trolleyStore.trolley_line_items?.Collection.find(
        (item) => item.product_code === this.product.code.toString()
      );
      this.quantity_in_trolley = product?.quantity || 0;
    },

    //----------------------------------------------------------------------------------------------
    createBreadcrumb() {
      this.taxonomies = this.product.taxonomies[0].map((item) => ({
        text: item.name,
        link:
          item.parent_id === 0
            ? `/categories/${item.name.toLowerCase().replaceAll(" ", "-")}-${item.id
            }`
            : `/category/${item.name.toLowerCase().replaceAll(" ", "-")}-${item.parent_id
            }`,
      }));
      this.compare_products_taxonomies_id = this.product.taxonomies[0];
    },

    //----------------------------------------------------------------------------------------------
    async navigateToProductVariation(id: string, name: string) {
      const slug = this.variation_slugs.find(
        (variation) => variation.code === id
      )?.slug;

      await navigateTo(`/product/${slug}-${id}`);
    },

    //----------------------------------------------------------------------------------------------
    async showAdscenceStore(id: string) {
      this.show_store_info = true;
      const branchStore = useBranchStore();
      if (id.length !== 2) {
        this.store_info = branchStore.lastSavedBranch;
        return;
      }
      //if its a valid store code doing further operations else showing last saved branch from branchStore
      let response: Branch | null = await branchStore.getBranchById(id);

      if (response?.address) {
        this.store_info = response;
        branchStore.currentBranchChoice = response;
        branchStore.currentSavedBranch = response;
        branchStore.lastSavedBranch = response;
        localStorage.setItem(
          "branchStore",
          JSON.stringify({ lastSavedBranch: response })
        );
      } else {
        this.store_info = branchStore.lastSavedBranch;
      }
    },

    async unSubscribeProduct(productId: string | undefined, showToast: boolean = true) {
      const authStore = useAuthStore();
      try {
        const customerId = authStore.user?.id;
        let response = await new ProductService().unSubscribeProduct(
          customerId,
          productId
        );
        if (showToast) {
          toast("Successfully unsubscribed", {
            autoClose: true,
            type: "success",
          });
        }

        if (response && response.success == false) {
          throw "Failed to unsubscribe the product.";
        }
        return true;
      } catch (error) {
        await useErrorHandler(error);
        return false;
      }
    },

    async subscribeProduct(productCode?: string) {
      const authStore = useAuthStore();
      const accountStore = useAccountStore();
      this.is_stock_subscribe_loading = true;

      let product_code: string = "";
      if (!productCode && !this.notify_product_code) {
        return;
      }
      product_code = productCode || this.notify_product_code;

      let alreadySubscribed = await this.getAllSubscribedProducts();
      if (
        alreadySubscribed.data.some((item: any) => item.product_id === product_code)
      ) {
        this.subscribe_notification_modal_visible = false;
        return;
      }

      try {
        const customerId = authStore.user?.id;
        let response = await new ProductService().subscribeProduct(
          customerId,
          product_code
        );
        if (response && response?.success === false) {
          throw new Error("Failed to subscribe to product stock");
        }

        return response;
      } catch (error) {
        await useErrorHandler(error);
        return false;
      }
      finally {
        this.is_stock_subscribe_loading = false;
      }
    },

    async getAllSubscribedProducts() {
      const authStore = useAuthStore();
      try {
        const customerId = authStore.user?.id;
        if (!customerId) return
        let response = await new ProductService().getsubscribedProducts(customerId);
        return response;
      } catch (error) {
        await useErrorHandler(error);
        return false;
      }
    },
    setProductCodeforNotification(code: string | undefined, name: string | undefined) {
      this.notify_product_code = code;
      this.notify_product_name = name;
    },
    //-------------------- Find stock near by feature
    async findNearByStock() {
      const branchStore = useBranchStore();
      const currentBranch = branchStore.lastSavedBranch;

      if (!currentBranch) return;
      const stockStore = useStockStore();

      // reset old statuses
      stockStore.nearby_stock_search_branch_choice = null;
      branchStore.localitySearchInput = currentBranch.name;
      branchStore.autoCompleteSuggestions = [];
      branchStore.resetStatuses();

      stockStore.stock_finder_modal_visible = true;
      stockStore.is_nearby_stock_search_loading = true;

      await handleNearByStockSearch({
        latitude: currentBranch.geolocation.latitude,
        longitude: currentBranch.geolocation.longitude,
        productCode: this.product.code,
        requiredQuantity: this.quantity_to_add
      });

      stockStore.is_nearby_stock_search_loading = false;
    }
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProductStore, import.meta.hot));
}
