import type { CustomerAddressItem } from "~/types/ecom/address.type";
import { EcomService } from "../ecom.service";
import { TROLLEY_ENDPOINTS } from "../ecom.trolley.service";
import type { TrolleyResponse } from "~/types/ecom/trolley/trolley.type";

const ENDPOINTS = {
  CUSTOMERS: (customerId: string) => `/customers/${customerId}`,
  CUSTOMER_ORDERS: (customerId: string) => `/customers/${customerId}/orders`,
  CUSTOMER_PURCHASE_HISTORY: (customerId: string) =>
    `/customers/${customerId}/purchase-history`,
  CUSTOMER_PASSWORD: (customerId: string) =>
    `/customers/${customerId}/password`,
  CUSTOMER_ACCOUNTS: (customerId: string) =>
    `/customers/${customerId}/accounts`,
  CUSTOMER_TROLLEYS: (customerId: string) =>
    `/customers/${customerId}/trolleys`,
  CUSTOMER_ACTIVE_TROLLEYS: (customerId: string) =>
    `/customers/${customerId}/trolleys/active`,
  CUSTOMER_CONTACT_PREFERENCES: (customerId: string) =>
    `/customers/${customerId}/contact-preferences`,
  CUSTOMER_CATALOGUE_REQUESTS: (customerId: string) =>
    `/customers/${customerId}/catalogue-requests`,
  CUSTOMERS_LAST_CATALOGUE_REQUEST: (customerId: string) =>
    `/customers/${customerId}/last-catalogue-request`,
  CUSTOMER_SAVED_LISTS: (customerId: string) =>
    `/customers/${customerId}/saved-lists`,
  FROM_TROLLEY_ID: (trolleyId: string) =>
    `/saved-lists/create-from-trolley/${trolleyId}`,
  CUSTOMER_CARD_REQUEST: (customerId: string) =>
    `/customers/${customerId}/card-request`,
  CUSTOMER_FAVOURITE_BRANCHES: (customerId: string) =>
    `/customers/${customerId}/favourite-branches`,
  CUSTOMER_FAVOURITE_BRANCH: (customerId: string, branchId: string) =>
    `/customers/${customerId}/favourite-branches/${branchId}`,
  CUSTOMER_DEFAULT_BRANCH: (customerId: string) =>
    `/customers/${customerId}/default-branch`,
  CUSTOMER_USERNAME_CHECK: () => `/customers/username-check`,
  CUSTOMER_REQUEST_ACCOUNT_DELETION: (customerId: string) =>
    `/customers/${customerId}/request-account-deletion`,

  // Promotions
  CUSTOMER_APPLY_PROMOTION: () => `/customers/promotion`,
  CUSTOMER_PROMOTIONS: (customerId: string) =>
    `/customers/${customerId}/promotions`,

  // Address book
  CUSTOMER_ADDRESS_BOOK: (customerId: string) =>
    `/customers/address-book/${customerId}`,
  CUSTOMER_ADDRESS_BOOK_PRIMARY_ADDRESS: (customerId: string) =>
    `/customers/address-book/${customerId}/primary-address`,
  CUSTOMER_ADDRESS_BOOK_ADDRESS: (addressId: string) =>
    `/customers/address-book/${addressId}`,

  // Auth
  CUSTOMER_AUTH_LOGIN: `/customers/auth/login`,
  CUSTOMER_AUTH_LOGOUT: `/customers/auth/logout`,
  CUSTOMER_AUTH_REGISTER: `/customers/auth/register`,
  CUSTOMER_AUTH_GUEST_REGISTER: `/customers/auth/guest/register`,
  CUSTOMER_AUTH_RESET_PASSWORD: `/customers/auth/password/reset`,
  CUSTOMER_AUTH_RESET_PASSWORD_EMAIL: `/customers/auth/password/email`,
  CUSTOMER_AUTH_CONVERT_GUEST_TO_CUSTOMER: (customerId: string) =>
    `/customers/auth/guest/${customerId}/convert-to-customer`,

  CUSTOMER_LOYALTY_CLUB: (customerId: string) =>
    `/customers/${customerId}/loyalty-club`,

  CUSTOMER_LOYALTY_CLUB_LINK: (customerId: string) =>
    `/customers/${customerId}/loyalty-club/rewards-platform-sso-url`,
};

export default class EcomCustomerService {
  static async getCustomerById(
    customer_id: string,
    show_notification = true,
    show_loading_indicator = true,
    headers = {}
  ) {
    let options = {
      show_notification,
      show_loading_indicator,
      headers
    };
    return await useAjaxEcom(ENDPOINTS.CUSTOMERS(customer_id), options);
  }

  static async fetchActiveCustomerTrolleys(
    customer_id: string,
    customer_token: string,
    show_notification = true,
  ): Promise<{ data: TrolleyResponse[] }> {
    const headers: any = {
      "X-Toolstation-Customer-Id": customer_token,
    };
    let options = {
      headers,
      show_notification,
    };
    //@ts-ignore
    return await useAjaxEcom(
      ENDPOINTS.CUSTOMER_ACTIVE_TROLLEYS(customer_id),
      options
    );
  }

  static async getAddresses(customer_id: string) {
    return await useAjaxEcom(ENDPOINTS.CUSTOMER_ADDRESS_BOOK(customer_id));
  }

  static async redirectToTrackOrder(orderId: string) {
    return await useAjaxEcom(`/orders/${orderId}/tracking-links`);
  }

  static async addAddress(customer_id: string) {
    return await useAjaxEcom(ENDPOINTS.CUSTOMER_ADDRESS_BOOK(customer_id));
  }

  static async updateAddress(
    address_id: string,
    new_address: CustomerAddressItem
  ) {
    const requestOptions: any = {
      method: "put",
      params: new_address,
      show_notifications: false,
    };

    return await useAjaxEcom(
      ENDPOINTS.CUSTOMER_ADDRESS_BOOK_ADDRESS(address_id),
      requestOptions
    );
  }

  static async getProCardOrderHistoryData(data: object) {
    let options = {
      method: "get",
      params: data,
    };

    return await useAjaxEcom(`/customers/${data.customerId}/orders`);
  }


  static async updateProCardOrderHistoryData(payload: object, data: object, token: string) {
    let options = {
      method: "post",
      params: payload,
      headers: {
        'x-toolstation-session-id': token
      }
    };

    return await useAjaxEcom(`/order-attributes/${data.id}`, options);
  }

  static async deleteAddress(address_id: string) {
    let options: any = {
      method: "delete",
    };

    return await useAjaxEcom(
      ENDPOINTS.CUSTOMER_ADDRESS_BOOK(address_id),
      options
    );
  }

  static async setAddressAsPrimary(customer_id: string, address_id: string) {
    let options = {
      method: "put",
      params: {
        address_id: address_id,
      },
    };

    return await useAjaxEcom(
      ENDPOINTS.CUSTOMER_ADDRESS_BOOK_PRIMARY_ADDRESS(customer_id),
      options
    );
  }

  static async getOrders(
    customer_id: string,
    page: number = 1,
    page_limit: number = 50,
    q: string | null = null,
    payment_type: string | null = null
  ) {
    let options = {
      query: {
        q: q,
        page: page,
        pageLimit: page_limit,
        paymentType: payment_type,
      },
    };

    return await useAjaxEcom(ENDPOINTS.CUSTOMER_ORDERS(customer_id), options);
  }

  static async signIn(username: string, password: string) {
    let options = {
      method: "post",
      params: {
        username,
        password,
      },
    };

    return await useAjaxEcom(ENDPOINTS.CUSTOMER_AUTH_LOGIN, options);
  }

  static async usernameCheck(username: string) {
    let options = {
      method: "post",
      params: {
        username,
      },
    };

    return await useAjaxEcom(ENDPOINTS.CUSTOMER_USERNAME_CHECK(), options);
  }

  static async postGuestSignUp(item, token) {
    let options = {
      method: "post",
      params: item,
      headers: {
        'x-toolstation-session-id': token
      }
    };

    return await useAjaxEcom(ENDPOINTS.CUSTOMER_AUTH_GUEST_REGISTER, options);
  }

  static async sendForgotPasswordMail(username: string) {
    let options = {
      method: "post",
      params: {
        username,
      },
    };

    return await useAjaxEcom(
      ENDPOINTS.CUSTOMER_AUTH_RESET_PASSWORD_EMAIL,
      options
    );
  }

  static async resetPassword(item) {
    let options = {
      method: "post",
      params: item,
    };

    return await useAjaxEcom(ENDPOINTS.CUSTOMER_AUTH_RESET_PASSWORD, options);
  }

  static async signUp(sign_up_form: any) {
    try {
      let options = {
        method: "post",
        params: sign_up_form,
      };

      return await useAjaxEcom(ENDPOINTS.CUSTOMER_AUTH_REGISTER, options);
    } catch (err) {
      return err;
    }
  }

  static async setDefaultBranch(customer_id: string, site_id: string) {
    try {
      let options = {
        method: "put",
        params: {
          site_id: site_id,
        },
      };

      return await useAjaxEcom(
        ENDPOINTS.CUSTOMER_DEFAULT_BRANCH(customer_id),
        options as any
      );
    } catch (err) {
      return err;
    }
  }

  static async fetchCustomerDetails(customerId: string) {
    let options = {
      method: "get",
    };

    return await useAjaxEcom(
      `/customers/${customerId}`,
      options
    );
  }

  static async getOneTimeQrCodeUserDetails(customerId: string) {
    let options = {
      method: "post",
    };

    return await useAjaxEcom(
      `/customers/${customerId}/one-time-qrcode`,
      options
    );
  }

}

// address.line_1
// :
// ['The address.line 1 field is required.']
// address.postcode
// :
// ['The address.postcode field is required.']
// address.town
// :
// ['The address.town field is required.']
// email
// :
// ['The email has already been taken.']
// password
// :
// ['The password format is invalid.']
