<script setup lang="ts">
import {twMerge} from "tailwind-merge";

type Props = {
  heightFull ?: boolean,
  dataTestid?: string,
  appendClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  heightFull: false,
  dataTestid : 'atom-column',
})


</script>

<template>
    <div :class="
      twMerge(`column`, props.heightFull && 'h-full', props.appendClass)"
      :data-testid="props.dataTestid">
      <slot />
    </div>
</template>