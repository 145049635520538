import type { PaymentStrategy } from "../payment.service.js";
import braintree, {
  Client,
  DataCollector,
  PayPalCheckout,
  BraintreeError,
} from "braintree-web";

export class PaypalStrategy implements PaymentStrategy {
  private paypal_instance: PayPalCheckout | null = null;
  private data_collector_instance: DataCollector | null = null;

  async initializePayment(
    client: Client,
    data_collector: DataCollector
  ): Promise<void> {
    try {
      this.paypal_instance = await braintree.paypalCheckout.create({
        client: client,
      });

      this.data_collector_instance = data_collector;
     
      await this.setupPaypalButton();
    } catch (err) {
     useErrorHandler(err, 'high')
    }
  }

  private async setupPaypalButton(): Promise<void> {
    if (!this.paypal_instance) {
      return
    }

    try {
      const paypal_sdk = await this.paypal_instance.loadPayPalSDK({
        currency: "EUR",
        components: "buttons,messages",
        "enable-funding": "paylater",
      });

      const fundingSources = [
        String(window.paypal.FUNDING.PAYPAL),
        String(window.paypal.FUNDING.CREDIT),
        "paylater",
      ];  

      const authStore = useAuthStore()
      let user = authStore.user ?? authStore.guest;
      const checkoutStore = useCheckoutStore()
      const trolleyStore = useTrolleyStore()

      fundingSources.forEach((fundingSource) => {
        const settings = {
          createOrder: async (): Promise<string> => {
            try {
              checkoutStore.is_transaction_loading = true
              checkoutStore.resetError()
              return await paypal_sdk.createPayment({
                locale: "en-GB",
                flow: "checkout",
                amount: trolleyStore.trolley.order_response?.totals?.inc_vat.raw.toString(), // Adjust as needed
                currency: "EUR",
                enableShippingAddress: true,
                shippingAddressEditable: false,
                shippingAddressOverride: {
                  recipientName: user.first_name,
                  line1: checkoutStore.billing_address.line_1,
                  line2: checkoutStore.billing_address.line_2,
                  city: checkoutStore.billing_address.town,
                  state: checkoutStore.billing_address.country,
                  postalCode: checkoutStore.billing_address.postcode,
                  countryCode: 'IN',
                },
              });
            } catch (err) {
              
              throw err; // Ensure the promise is rejected
              useCheckoutStore().is_transaction_loading = false
            }
          },

          onApprove: async (data: any, actions: any) => {
            try {
              const payload = await this.paypal_instance!.tokenizePayment(data);
              useCheckoutStore().is_transaction_loading = true
              useCheckoutStore().payment_sucess = 'Payment successful redirecting,,'
              useCheckoutStore().createTransaction(payload.nonce, "braintree-paypal-normal")
            } catch (err) {
              checkoutStore.payment_error.paypal_error = 'Paypal payment failed'
              useCheckoutStore().is_transaction_loading = false
            }
          },

          onCancel: () => {
           
            useCheckoutStore().payment_error.paypal_error = 'Paypal payment cancelled'
            useCheckoutStore().is_transaction_loading = false
          },

          onError: (err: string) => {
            useCheckoutStore().payment_error.paypal_error = 'Paypal payment failed'
            useCheckoutStore().is_transaction_loading = false
          },

          style: {
            height: 40,
            "z-index": 0,
            ...(fundingSource === "paylater" ? { color: "gold" } : {}),
          },
        };

        const button = window.paypal.Buttons({
          fundingSource,
          ...settings,
        });

        if (!button.isEligible()) {
          console.warn(`Funding source ${fundingSource} is not eligible.`);
          return;
        }

        button.render("#paypal-buttons-container");
      });
    } catch (err) {
      useCheckoutStore().payment_error.paypal_error = 'Paypal payment failed'
      useCheckoutStore().is_transaction_loading = false
    }
  }



}
