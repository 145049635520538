<template>
    <span :class="twMerge(
      'relative flex justify-center items-center min-h-full',
      'before:content[\'\'] before:block before:absolute',
      props.layout === 'vertical' && 'mx-4 py-4 before:border-l before:top-0 before:left-1/2 before:h-full',
      props.layout === 'horizontal' && 'my-4 px-4 before:border-t before:top-1/2 before:left-0 before:w-full',
      props.appendClass
    )"
         :id="'divider-' + randomId"
    >
      <slot>
        <span v-if="props.text"
             :class="twMerge(
                 'z-10 p-1 border-2 border-solid border-gray-400 text-xs rounded-full',
                 props.textClass
             )">
          {{ props.text }}
        </span>
    </slot>
  </span>
</template>
<script setup lang="ts">
import {twMerge} from "tailwind-merge";
import {onMounted, ref} from "vue";
import {useRandomUUID} from "../../composables/useRandomUUID";


type Props = {
  layout?: "horizontal" | "vertical",
  appendClass?: string;
  textClass?: string;
  text?: string;
}

const props = withDefaults(defineProps<Props>(), {
  layout: "horizontal",
  appendClass:'',
  textClass:'',
})

// random ID for each Vue Instance
const randomId: Ref<string> = ref("");

onMounted(() => {
  randomId.value = useRandomUUID();
});

</script>
