<template>
  <div class="px-4">
    <TsRow append-class="justify-between pt-4 pb-2">
      <TsColumn class="max-w-max">
        <TsRow append-class="items-center">
          <TsIcon
            @click="handleBackNavigation"
            name="iconamoon:arrow-left-2-duotone"
            size="1.75rem"
            class="cursor-pointer mr-2 text-black"
          />
          <TsTypography
            as="h4"
            appendClass="mb-0"
            :text="selectedData?.taxonomy?.name || 'Explore Categories'"
          />
        </TsRow>
      </TsColumn>
      <TsColumn append-class="max-w-max">
        <TsTypography
            @click="handleCategoryNavigation"
            append-class="text-info text-base underline cursor-pointer hover:no-underline"
        >View All</TsTypography
        >
      </TsColumn>
    </TsRow>
    <TsDivider appendClass="my-0"/>

    <div v-if="menuStore.categories_state.status === 'success'" class="my-2">
    <TsRow
      v-for="tile in selectedData?.children || menuStore?.categories?.data"
      :id="tile?.taxonomy?.id"
      append-class="text-gray-500 cursor-pointer py-1"
      @click="handleSelectedCategory(tile)"
    >
      <TsColumn class="is-11">
        <TsRow append-class="items-center gap-2 m-0">
          <TsIcon
            v-if="Object.keys(icons).includes(tile.taxonomy.name)"
            :name="icons[tile.taxonomy.name]"
            size="20"
          />
          <TsTypography
              v-if="tile.children.length"
            as="p"
            append-class="mb-0 font-semibold text-base text-gray-500"
            :text="tile.taxonomy.name"
            @click.stop="
              handleSelectedCategory(tile)
            "
          />
          <TsTypography
              v-else
              as="p"
              append-class="mb-0 font-semibold text-base text-gray-500"
              :text="tile.taxonomy.name"
              @click.prevent="
              handleSubCategoryClick(tile?.taxonomy?.name, tile?.taxonomy?.id)
            "
          />
        </TsRow>
      </TsColumn>
      <TsColumn class="max-w-max">
        <TsIcon
          v-if="tile?.children?.length"
          name="lucide:chevron-right"
          size="22"
        />
      </TsColumn>
    </TsRow>
    </div>
    <TsCategoriesTransition v-else />
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
const localePath = useLocalePath();

const emit = defineEmits(["back", "redirect"]);

const selectedData = ref();

const menuStore = useMenuStore();

function handleSelectedCategory(texonomyData: any) {
  if (!texonomyData?.children?.length) return;
  selectedData.value = texonomyData;
}

defineProps({});

const handleCategoryNavigation = () => {
  emit("redirect");
  if (!selectedData.value) navigateTo(localePath("/categories"));
  else
    handleCategoryClick(
      selectedData.value.taxonomy?.name,
      selectedData.value.taxonomy?.id
    );
};

function handleBackNavigation() {
  selectedData.value ? (selectedData.value = undefined) : emit("back");
}

const bakeCategoryAsFilter = (subCategoryId: number) => {
  return "categoryPageId:" + subCategoryId;
};

const algoliaStore = useAlgoliaStore();

const handleCategoryClick = (categoryName: string, categoryid: string) => {
  navigateTo(
    localePath(
      `/categories/${categoryName
        .replaceAll(" ", "-")
        .toLowerCase()}-${categoryid}`
    )
  );
};

const handleSubCategoryClick = (
  subCategoryName: string,
  subCategoryId: number
) => {
  subCategoryName = subCategoryName.toLowerCase().replaceAll(" ", "-");
  algoliaStore.currentActiveCategoryFilter =
    bakeCategoryAsFilter(subCategoryId);

  algoliaStore.handleEnterClick(
    "",
    "category-search",
    false,
    [],
    "",
    bakeCategoryAsFilter(subCategoryId),
    subCategoryName,
    subCategoryId
  );
  emit("redirect");
};

const icons = {
  "WD-40": "formkit:tools",
  Deals: "mynaui:percentage-waves",
  "Schroeven & Bevestigingsmiddelen": "mdi:screw-round-top",
  "Elektrisch Gereedschap": "material-symbols:tools-power-drill-outline",
  Elektra: "icons8:electrical",
  "Gereedschap Accessoires": "carbon:tool-kit",
  Handgereedschap: "mingcute:screwdriver-line",
  "Hang- & Sluitwerk": "tabler:lock-filled",
  "Kranen & Douches": "cil:shower",
  IJzerwaren: "material-symbols:hardware-outline-sharp",
  Installatie: "codicon:tools",
  "Lijmen, Kitten & Tapes": "la:tape",
  Tuingereedschap: "tabler:shovel",
  "Verf & Accessoires": "octicon:tools-24",
  Verlichting: "tdesign:lighting-circle",
  "Verwarming & Ventilatie": "iconoir:heating-square",
  "Werkkleding & PBM": "healthicons:ppe-suit-outline",
  Werkplaats: "pajamas:work",
  "Reinigen & Onderhoud": "healthicons:cleaning-outline",
  Uitverkoop: "foundation:burst-sale",
};
</script>
