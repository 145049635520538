<script setup lang="ts">
import type { NuxtError } from '#app'

const config = useRuntimeConfig();

const { isMobile} = useDevice();

const props = defineProps({
  error: Object as () => NuxtError
})

const handleError = () => clearError({ redirect: '/' })

onMounted(() => {
  useErrorHandler(props.error)
})

</script>

<template>
  <div>
    <TsHeader></TsHeader>
    <TsSection appendClass="mb-0 px-0 bg-cover bg-no-repeat pb-7 pt-1.5 bg-[url('/images/404BackImage.png')]">

      <TsTypography align="center"  variant="h1" appendClass="text-[#065CAB] mb-0">Oops!</TsTypography>

      <NuxtImg v-if="error.statusCode === 500" src="/images/500Error.png"
               alt="bannerImage" height="156" width="337" class=" mx-auto object-cover" loading="lazy"/>

      <NuxtImg v-else src="/images/error404.png" alt=""
               height="156" width="273" class="mx-auto object-cover" loading="lazy"/>

      <TsTypography align="center" variant="h4"  appendClass="mb-5">
        {{ config.public.appDebug ? error?.message : 'Something went wrong.' }}
      </TsTypography>

      <TsTypography align="center" :appendClass="isMobile ? 'mb-5 w-full' : 'mb-5 w-1/3 mx-auto'">
        Our apologies for the hiccup. Our team of tech wizards been dispatched to tame the gremlins in the system
      </TsTypography>

      <TsMedia appendClass="justify-center">
        <TsButton @click="handleError" size="md" label="Refresh & try again" appendClass="whitespace-nowrap px-9" />
      </TsMedia>

    </TsSection>
    <TsFooter></TsFooter>
  </div>
</template>