<template>
  <div class="border overflow-hidden rounded-b-xl">
    <TsRow v-if="menuStore.categories_state.status === 'success'"
           class="max-w-screen-xl mx-auto min-h-full bg-white py-0.5 ">
      <TsColumn class="is-3 p-0">
        <div class="flex justify-between items-center p-2">
          <TsTypography as="h4" appendClass="mb-0" text="Explore Categories" data-testid="category-menu-explore-category-text"/>
          <a
            :href="`${localePath('/categories')}`"
            class="text-primary text-base font-semibold underline"
            @click.prevent="handleCategoryNavigation()"
            data-testid="category-megamenu-to-clp"
          >
            {{ useTranslation("viewAll", "Bekijk alles") }}
          </a>
        </div>
        <div
          class="my-3 flex flex-col overflow-y-auto max-h-[calc(100vh-170px)]"
          v-if="data && data.length > 0"
        >
          <template v-for="(tile, categoryIndex) in data">
            <div v-if="tile.taxonomy"
                :id="tile.taxonomy.id"
                class="flex justify-between gap-3 px-2 py-2 text-gray-500 cursor-pointer hover:bg-blue-50"
                :class="{
              'bg-blue-100': activeMenu && activeMenu.taxonomy && (activeMenu.taxonomy.id === tile.taxonomy.id),
              }"
              @click="handleCategoryClickInMenu(tile, categoryIndex)"
            >
              <div class="flex items-center gap-2">
                <TsIcon :name="icons[tile.taxonomy.name]" size="20" />
                <TsTypography
                  :data-testid ="`category-list-option-${categoryIndex +1}`"
                  as="p"
                  append-class="mb-0 font-semibold text-base text-gray-500"
                  :text="tile.taxonomy.name"
                />
              </div>
              <div class="flex justify-between items-center">
                <TsIcon name="lucide:chevron-right" size="22" />
              </div>
            </div>
          </template>

        </div>
      </TsColumn>
      <TsDivider layout="vertical" appendClass="mx-0" />
      <TsColumn class="is-9 overflow-y-auto max-h-[calc(100vh-119px)]">
        <TsRow v-if="activeMenu && activeMenu?.taxonomy" class="flex gap-8 items-center">
          <TsTypography
            as="h4"
            appendClass="pl-4 mb-0"
            :text="activeMenu.taxonomy.name"
            :data-testid="`category-page-header`"
          />
          <a
            :href="`${localePath(
              `/categories/${activeMenu.taxonomy.name
                .replaceAll(' ', '-')
                .toLowerCase()}-${activeMenu.taxonomy.id}`
            )}`"
            class="text-primary text-base font-semibold underline"
            @click.prevent="
              handleCategoryClick(
                activeMenu.taxonomy.name,
                activeMenu.taxonomy.id
              )
            "
            :data-testid="`category-megamenu-to-subcategory-page-link`"
          >
            {{ useTranslation("viewAll", "Bekijk alles") }}
          </a>
        </TsRow>
        <div class="columns-3 gap-3 py-3" v-if="activeMenu && activeMenu.children">
          <TsCard
            v-for="(item, subCategoryindex) in activeMenu.children"
            :key="item.taxonomy.id"
            appendClass="bg-white shadow-none rounded-none border-none p-2 pl-5"
          >
            <TsGrid>
              <TsLabel
                :label="item.taxonomy.name"
                :data-testid="`sub-category-option-${subCategoryindex +1}`"
                labelClass="font-bold text-base mb-2 hover:underline cursor-pointer max-w-max"
                variant="info"
                @click="
                  handleParentCategoryClick(
                    item.taxonomy.name,
                    item.taxonomy.id,
                    subCategoryindex
                  )
                "
              />
              <div v-if="item.children">
                  <TsLabel
                    v-for="(subMenu, index) in item.children"
                    :key="subMenu.taxonomy.id"
                    :label="subMenu.taxonomy.name"
                    :data-testid ="`sub-category-option-${subCategoryindex +1}-list-${index +1}`"
                  @click="
                    handleSubCategoryClick(
                      subMenu.taxonomy.name,
                      subMenu.taxonomy.id
                    )
                  "
                  labelClass="bg-[#065CAB1A] font-normal text-base mb-1 hover:underline cursor-pointer max-w-max"
                />
              </div>
            </TsGrid>
            <TsDivider appendClass="min-h-0 w-72 mx-auto my-3" />
          </TsCard>
        </div>
      </TsColumn>
    </TsRow>

    <TsCategoriesTransition v-else />
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
import { ref } from "vue";

const algoliaStore = useAlgoliaStore();
const menuStore = useMenuStore();
const icons = {
  "WD-40": "formkit:tools",
  Deals: "mynaui:percentage-waves",
  "Schroeven & Bevestigingsmiddelen": "mdi:screw-round-top",
  "Elektrisch Gereedschap": "material-symbols:tools-power-drill-outline",
  Elektra: "icons8:electrical",
  "Gereedschap Accessoires": "carbon:tool-kit",
  Handgereedschap: "mingcute:screwdriver-line",
  "Hang- & Sluitwerk": "tabler:lock-filled",
  "Kranen & Douches": "cil:shower",
  IJzerwaren: "material-symbols:hardware-outline-sharp",
  Installatie: "codicon:tools",
  "Lijmen, Kitten & Tapes": "la:tape",
  "Reinigen & Onderhoud": "healthicons:cleaning-outline",
  Tuingereedschap: "mdi:gardening",
  "Verf & Accessoires": "uil:paint-tool",
  Verlichting: "solar:lightbulb-bolt-outline",
  "Verwarming & Ventilatie": "iconoir:heating-square",
  "Werkkleding & PBM": "icon-park-solid:basketball-clothes",
  Werkplaats: "uil:suitcase",
  Uitverkoop: "flowbite:sale-percent-outline",
};

const handleSubCategoryClick = (
  subCategoryName: string,
  subCategoryId: number
) => {
  subCategoryName = subCategoryName.toLowerCase().replaceAll(" ", "-");

  navigateTo({
    path: localePath(`/category/${subCategoryName}-${subCategoryId}`),
  });
};

const bakeCategoryAsFilter = (subCategoryId: number) => {
  return "categoryPageId:" + subCategoryId;
};
type Props = {
  data: any[];
};

const handleCategoryClick = (categoryName: string, categoryid: string) => {
  navigateTo(
    localePath(
      `/categories/${categoryName
        .replaceAll(" ", "-")
        .toLowerCase()}-${categoryid}`
    )
  );
};

const handleCategoryNavigation = () => {
  navigateTo(localePath("/categories"));
};
const activeMenu = ref();
const activeCategoryIndex = ref();

const props = withDefaults(defineProps<Props>(), {
  data: () => [
    {
      id: 1,
      icon: "lucide:wrench",
      title: "Screws & Fastners",
    },
  ],
});

const handleCategoryClickInMenu = (tile: any, categoryIndex: number) => {
  activeMenu.value = tile;
  activeCategoryIndex.value = categoryIndex;
};

const handleParentCategoryClick = (
  parentCategoryName: string,
  parentCategoryId: number,
  subCategoryIndex: number
) => {
  parentCategoryName = parentCategoryName.toLowerCase().replaceAll(" ", "-");
  algoliaStore.currentActiveCategoryFilter =
    bakeCategoryAsFilter(parentCategoryId);

  menuStore.current_active_sub_category = {
    category_index: activeCategoryIndex.value,
    sub_category_index: subCategoryIndex,
  };

  algoliaStore.handleEnterClick(
    "",
    "category-search",
    false,
    [],
    "",
    bakeCategoryAsFilter(parentCategoryId),
    parentCategoryName,
    parentCategoryId
  );
  // navigateTo(`/categories/${subCategoryName}-${subCategoryId}`);
};

watchEffect(() => {
  activeMenu.value = props.data[0];
});
</script>
