<template>
  <div>
    <div class="flex justify-between items-center gap-4 px-4">
      <TsAutoCompleteAlgolia
          data-testid="autocomplete-algolia"
          @clear="() => algoliaStore.handleClearSearchInput()"
          @input="(keyword : string) => algoliaStore.getAlgoliaSuggestions(keyword)"
          @enter="(keyword: string, dropdownVisible: boolean) => {
          menuStore.show_mobile_menu_drawer = false;
          algoliaStore.handleEnterClick(keyword,'direct-search', dropdownVisible);
        }"
          @delete="(option: options) => algoliaStore.delteSearchHistoryItem(option)"
          :last-searched-keyword="algoliaStore.lastTypedKeyword"
          :placeholder="
          useTranslation('searchPlaceholder', 'What are you looking for?')
        "
          v-model="selectedProduct"
          :suggestions="algoliaStore.suggestions()"
      >
      </TsAutoCompleteAlgolia>
      <TsIcon
          @click="hideMenuDrawer"
          name="radix-icons:cross-2"
          color="#666666"
          size="1.75rem"
          class="cursor-pointer"
      />
    </div>
    <div v-if="menuStore.current_menu === 'main-menu'">
      <div class="pb-5 pt-6">

        <div v-if="useAuthStore().is_authenticated" class="flex items-center px-4">
          <TsIcon
              name="mdi:user"
              size="18"
              class="text-primary me-1"
          />
          <NuxtLink
              to="/account"
              class="font-semibold text-primary hover:underline"
          >{{ useTranslation("myAccount", "My Account") }}
          </NuxtLink
          >
        </div>

        <div v-else class="flex items-center my-2 px-4">
          <TsIcon
              name="bx:arrow-to-right"
              size="18"
              class="text-primary me-1"
          />
          <NuxtLink
              to="/auth/signin"
              class="font-semibold text-primary !hover:underline"

          >{{ useTranslation("signIn", "Sign In") }}
          </NuxtLink
          >
          <span class="text-primary font-semibold mx-px">/</span>
          <NuxtLink
              to="/auth/signup"
              class="font-semibold text-primary !hover:underline"
          >{{ useTranslation("signOut", "Sign Up") }}
          </NuxtLink
          >
        </div>

      </div>

      <TsDivider appendClass="my-0 mx-4"/>
      <div>
        <div class="my-2 flex flex-col">
          <div
              v-for="tile in primaryMenuItems"
              :id="`${tile.id}`"
              :key="`${tile.id}`"
              class="flex justify-between gap-3 py-3 px-4 text-gray-500 cursor-pointer"
          >

            <div v-if="tile.link" class="flex items-center gap-2">
              <TsIcon :name="tile.icon" size="20"/>
              <NuxtLink
                  :to="tile.link"
                  :data-testid="
                  'mobile-primary-menu-item-' + tile.title.toLowerCase()
                "
                  class="mb-0 font-semibold text-base text-gray-500"
              >
                {{ tile.title }}
              </NuxtLink>
            </div>

            <div v-else class="flex justify-between items-center w-full" @click="handleMenuChange(tile.title)">
              <div class="flex items-center gap-2">

                <TsIcon :name="tile.icon" size="20"/>
                <TsTypography
                    as="p"
                    append-class="mb-0 font-semibold text-base text-gray-500"
                    :text="tile.title"
                    :data-testid="
                  'mobile-primary-menu-item-' + tile.title.toLowerCase()
                "
                />
              </div>

              <TsIcon name="lucide:chevron-right" size="22"/>
            </div>
          </div>
          <template v-if="props.headerLinks && Array.isArray(props.headerLinks) && props.headerLinks.length > 0">
            <div
                v-for="tile in props.headerLinks"
                :key="tile.data.label"
                class="flex justify-between gap-3 py-3 px-4 text-gray-500 cursor-pointer"
            >
              <div v-if="tile && tile.data && tile.data.link" class="flex items-center gap-2">
                <TsIcon v-if="tile.data.icon" :name="tile.data.icon" size="20"/>
                <NuxtLink
                    :to="localePath(tile.data.link)"
                    :data-testid="'mobile-primary-menu-item-' + tile.data.label.toLowerCase()"
                    class="mb-0 font-semibold text-base text-gray-500"
                    :target="tile.data?.openLinkInNewTab ? '_blank' : '_self'"
                >
                  {{tile.data.label}}
                </NuxtLink>
              </div>
            </div>
          </template>
        </div>
        <TsDivider append-class="my-0 mx-4"/>
      </div>
      <div class="mt-2">
        <div class="flex gap-2 items-center py-3 px-4">
          <TsVatToggle />
        </div>

        <div>
          <div
              @click="showAllLocales = !showAllLocales"
              class="cursor-pointer flex items-center justify-between py-3 px-4"
          >
            <div class="flex items-center space-x-2">
              <TsIcon size="22" :name="countryFlagCode(locale)"></TsIcon>
              <TsTypography
                  as="span"
                  weight="semibold"
                  append-class="text-gray-500"
              >
                {{ countryCode(locale) }}
              </TsTypography>
            </div>
            <TsIcon name="lucide:chevron-right" size="22" class="text-gray-500 transition-all"
                    :class="twJoin(showAllLocales && 'rotate-90')"/>
          </div>
          <ul
              v-if="showAllLocales"
              class="mx-4 border-y *:text-gray-500 divide-y *:cursor-pointer *:py-2 mb-2 *:font-semibold"
          >
            <li
                v-for="lang in locales.filter((lang) => lang.code !== locale)"
                :key="lang.code"
                @click="selectedLocale = lang"
                class="flex items-center space-x-2"
            >
              <TsIcon size="22" :name="countryFlagCode(lang.code)"></TsIcon>
              <TsTypography
                  as="span"
                  weight="semibold"
                  append-class="text-gray-500"
              >
                {{ countryCode(lang.code) }}
              </TsTypography>
            </li>
          </ul>

          <div
              v-for="tile in secondaryMenuItems"
              :id="`${tile.id}`"
              class="flex justify-between gap-3 py-3 px-4 text-gray-500 cursor-pointer hover:bg-blue-50"
          >
            <div class="flex items-center gap-2">
              <TsIcon :name="tile.icon" size="24"/>
              <NuxtLink
                  v-if="tile.link"
                  :to="tile.link"
                  :data-testid="
                'mobile-secondary-menu-item-' + tile.title.toLowerCase()
              "
                  class="mb-0 font-semibold text-base text-gray-500"
              >
                {{ tile.title }}
              </NuxtLink>
              <TsTypography
                  v-else
                  as="p"
                  append-class="mb-0 font-semibold text-base text-gray-500"
                  :text="tile.title"
                  :data-testid="
                'mobile-secondary-menu-item-' + tile.title.toLowerCase()
              "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 mx-4">
        <TsCardCta
            data-testid="footer-cta"
            content="Download out Mobile App"
            class="md:py-2 text-left"
        />
      </div>
    </div>
    <div v-else-if="menuStore.current_menu === 'Categories'">
      <TsCategoryMenuMobile
          @back="(selectedMenuTitle) => handleMenuChange(selectedMenuTitle)"
          @redirect="hideMenuDrawer"
      />
    </div>
    <div v-else-if="menuStore.current_menu === 'Brands'">
      <TsTopBrandMenu @onClose="handleMenuChange"/>
    </div>
  </div>
</template>
<script setup lang="ts">
import {twJoin, twMerge} from "tailwind-merge";
import {defineProps, ref} from "vue";
import type {options} from "~/types/algolia/algolia.type";

type Props = {
  headerLinks?: object[];
}

const props = defineProps<Props>();

const emit = defineEmits(["close"]);
const localePath = useLocalePath();

function hideMenuDrawer() {
  emit("close");
}

const router = useRouter();

router.beforeEach(() => {
  hideMenuDrawer()
})

const algoliaStore = useAlgoliaStore();
const rootStore = useRootStore();
const menuStore = useMenuStore();

const selectedProduct = ref();

const {locales, locale, setLocale} = useI18n();

const selectedLocale = ref("en");
const showAllLocales = ref(false);

watch(selectedLocale, () => {
  setLocale(selectedLocale.value.code);
});

function handleMenuChange(category = "") {
  if (category === "Brands" || category === "") {
    getTopBrands();
  } else {
    getCategories();
  }

  if (category.length == 0) {
    menuStore.current_menu = "main-menu";
    return;
  }

  if(category === 'Categories'){
    getCategories();
  }
  menuStore.current_menu = category;
}

const countryFlagCode = (countryCode: string, index: number = 1) => {
  const base = "circle-flags:";

  if (countryCode) {
    if (countryCode === "en-US") {
      return base + "uk";
    }
    const countryCodeNew = countryCode.split("-")[index].toLowerCase();
    return base + countryCodeNew;
  }
};

const countryCode = (countryCode: string, index: number = 0) => {
  if (countryCode) {
    let locale_item = rootStore.findInArrayByKey(
        locales.value,
        "code",
        countryCode
    );

    return locale_item.display_name;
  }
};

const primaryMenuItems = ref([
  {
    id: 1,
    icon: "tabler:category",
    title: "Categories",
    hasArrow: true,
  },
  {
    id: 2,
    icon: "fluent:tag-28-regular",
    title: "Brands",
    hasArrow: true,
  }
]);

const secondaryMenuItems = ref([
  {
    id: 1,
    icon: "mdi:store-outline",
    title: "Our Branches",
    hasArrow: true,
    link: "/branches",
  },
  {
    id: 2,
    icon: "lucide:box",
    title: "Orders",
    hasArrow: false,
    link: "/account/orders",
  },
  {
    id: 3,
    icon: "fluent:document-one-page-24-regular",
    title: "Blogs",
    hasArrow: false,
    link: "/blogs",
  },
  {
    id: 4,
    icon: "simple-line-icons:earphones-alt",
    title: "Support",
    hasArrow: false,
    link: "/samples/help",
  },
]);

async function getCategories() {
  if (menuStore.categories && menuStore.categories.data) {
    menuStore.categories_state.status = "success";
    return false;
  }
  if (menuStore.categories_state.status === 'loading') {
    return false;
  }
  await menuStore.getCategories(true);
}

async function getTopBrands() {
  const builderStore = useBuilderStore();
  if (builderStore.builderBrands.length <= 0) {
    await builderStore.fetchBrandList();
  }
}
</script>
