import {useAjax} from "~/composables/useAjax";

export const useAjaxServer= async (url:string,options?: {
    params?: object,
    method?: 'get' | 'post' | 'put' | 'patch' | 'delete',
    query?: object,
    headers?: object,
    show_notifications?: boolean,
    show_loading_indicator?: boolean,
    severity?: string,
    debug?: boolean
},callback = null) => {

    try{
        if(!options){
            options = {};
        }

        options.show_notifications = false;
        options.show_loading_indicator = false;

        return await useAjax(url, options,callback)

    }catch (error){

        let error_message = ['Something went wrong.'];
        if(options.debug){
            error_message = [error.message];
        }

        let response = {
            success: false,
            errors: error_message
        }

        return response;
    }

};
