// plugins/bazaarvoice.client.js
export default defineNuxtPlugin((nuxtApp) => {
  // Only run on client-side
  if (process.server) return;

  const config = {
    clientName: "toolstation-nl",
    siteId: "main_site",
    environment: "production",
    locale: "nl_NL",
  };

  // Load BV script
  const loadBVScript = () => {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://apps.bazaarvoice.com/deployments/${config.clientName}/${config.siteId}/${config.environment}/${config.locale}/bv.js`;
    document.head.appendChild(script);
  };

  // Initialize BV callback
  window.bvCallback = function (BV) {
    window.$BV = BV;
  };


  const env_config = useRuntimeConfig();
  const is_production =  env_config.public.featureFlags.enableBVPixel;
  


  if (is_production) {
  loadBVScript();
  }

  // Provide tracking utilities
  const trackTransaction = (transactionData) => {
    if(!is_production) return
    if (!window.$BV) {
      console.warn("BV niet geïnitialiseerd");
      return;
    }

    window.$BV.pixel.trackTransaction(transactionData);
  };

  // Track page view
  const trackPageView = () => {
    if(!is_production) return
    if (!window.$BV) {
      console.warn("BV niet geïnitialiseerd");
      return;
    }

    window.$BV.pixel.trackPageView();
  };

  // Track other events
  const trackEvent = (eventName, eventData = {}) => {
    if(!is_production) return
    if (!window.$BV) {
      console.warn("BV niet geïnitialiseerd");
      return;
    }

    window.$BV.pixel.trackEvent(eventName, eventData);
  };

  return {
    provide: {
      bv: {
        trackTransaction,
        trackPageView,
        trackEvent,
      },
    },
  };
});
