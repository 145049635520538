
import MonetateService from "~/services/monetate.service";
import { ProductService } from "~/services/product.service";
import type { Product } from "~/types/ecom/product/product.type";

interface GetProductsParams {
  ids: string[];
  type_of_action: string;
}

interface MonetateAction {
  component: string;
  items: Array<{
    id: string;
    recToken: string;
    [key: string]: any;
  }>;
  [key: string]: any;
}

interface RecTokenMap {
  id: string;
  recToken: string;
}

// Enum for action types
enum ActionType {
  PDP = "pdp",
  HOME = "home",
  CART = "cart",
  TROLLEY = "trolley",
  ORDER_CONFIRMATION = "order-confirmation",
  PLP = "plp",
  ACCOUNT = "my-account",
  CATEGORIES = "categories"
}

// Enum for component names
enum ComponentName {
  PDP_RECOMMENDED = "pdp-recommended",
  PDP_RELATED = "pdp-related",
  OFTEN_BOUGHT_TOGETHER = "often-bought-together",
  YOU_MAY_ALSO_LIKE = "you-may-also-like",
  TOP_SELLING_PRODUCTS = "top-selling-products-gke-test",
  OFTEN_BOUGHT_TOGETHER_GKE = "often-bought-together-gke-test",
  YOU_MAY_ALSO_LIKE_GKE = "you-may-also-like-gke-test",
  RELATED_PRODUCTS_GKE = "related-products-gke-test",
  OTHER_ALSO_LOOKED_AT_GKE = "other-also-looked-at-gke-test",
  RECENTLY_VIEWED = "recently-view"
}

export const useMonetateStore = defineStore({
  id: "MonetateStore",
  state: () => ({
    pdp_actions: [] as MonetateAction[],
    home_actions: [] as MonetateAction[],
    cart_actions: [] as MonetateAction[],
    plp_actions: [] as MonetateAction[],
    trolley_page_actions: [] as MonetateAction[],
    order_confirmation_page_actions: [] as MonetateAction[],
    my_account_page_actions: [] as MonetateAction[],
    categories_page_actions: [] as MonetateAction[],
    pdp_recommended_products_ids: [] as string[],
    pdp_often_bought_together_ids: [] as string[],
    pdp_related_product_ids: [] as string[],
    pdp_other_also_like_at_ids: [] as string[],
    pdp_you_may_also_like_ids: [] as string[],
    trolley_you_may_also_like_ids: [] as string[],
    trolley_often_bought_together_ids: [] as string[],
    trolley_best_seller_ids: [] as string[],
    trolley_new_arrival_ids: [] as string[],  
    trolley_related_product_ids: [] as string[],
    trolley_other_also_like_at_ids:[] as string[],
    order_confirmation_related_product_ids: [] as string[],
    order_confirmation_you_may_also_like_ids: [] as string[],
    order_confirmation_often_bought_together_ids: [] as string[],
    order_confirmation_best_seller_ids: [] as string[],
    order_confirmation_new_arrival_ids: [] as string[],  
    order_confirmation_other_also_like_at_ids:[] as string[],
    categories_related_product_ids: [] as string[],
    categories_you_may_also_like_ids: [] as string[],
    categories_often_bought_together_ids: [] as string[],
    categories_best_seller_ids: [] as string[],
    categories_new_arrival_ids: [] as string[],  
    categories_other_also_like_at_ids:[] as string[],
    my_account_recently_viewed_ids:[] as string[],
    my_account_you_may_also_like_ids:[] as string[],
    rec_token_map: [] as RecTokenMap[],

    // builder
    builder_recommendations_id : [] as string[],
    builder_component: 'you-may-also-like' as ComponentName,
    builder_page_type: 'trolley' as String,
    monetate_item_add_ons: [] as Product[]

  }),

 actions: {
    /**
     * Fetches and processes products based on the type of action.
     * @param type_of_action - The type of action (e.g., "pdp", "home", "cart", "plp")
     */
    async processActionsByType(type_of_action: ActionType): Promise<void> {
      switch (type_of_action) {

        // Action Type For PDP
        case ActionType.PDP:
          this.pdp_recommended_products_ids = this.extractItemsByComponent(
            this.pdp_actions,
            ComponentName.PDP_RECOMMENDED
          );
          this.pdp_related_product_ids = this.extractItemsByComponent(
            this.pdp_actions,
            ComponentName.PDP_RELATED
          );
          this.pdp_often_bought_together_ids = this.extractItemsByComponent(
            this.pdp_actions,
            ComponentName.OFTEN_BOUGHT_TOGETHER
          );
          this.pdp_other_also_like_at_ids = this.extractItemsByComponent(
            this.pdp_actions,
            ComponentName.OTHER_ALSO_LOOKED_AT_GKE
          );
          this.pdp_you_may_also_like_ids = this.extractItemsByComponent(
            this.pdp_actions,
            ComponentName.YOU_MAY_ALSO_LIKE_GKE
          );
          this.rec_token_map = this.extractAllRecTokens(this.pdp_actions);
          break;

          case ActionType.HOME:
            // Implementation for home action
            break;

        // Action Type For Trolley
        case ActionType.TROLLEY:

          this.rec_token_map = this.extractAllRecTokens(this.trolley_page_actions);
          this.trolley_you_may_also_like_ids = this.extractItemsByComponent(
            this.trolley_page_actions,
            ComponentName.YOU_MAY_ALSO_LIKE_GKE
          );
          this.trolley_often_bought_together_ids = this.extractItemsByComponent(
            this.trolley_page_actions,
            ComponentName.OFTEN_BOUGHT_TOGETHER
          );
          this.trolley_best_seller_ids = this.extractItemsByComponent(
            this.trolley_page_actions,
            ComponentName.TOP_SELLING_PRODUCTS
          );
          this.trolley_related_product_ids = this.extractItemsByComponent(
            this.trolley_page_actions,
            ComponentName.RELATED_PRODUCTS_GKE
          );
          this.trolley_other_also_like_at_ids = this.extractItemsByComponent(
            this.trolley_page_actions,
            ComponentName.OTHER_ALSO_LOOKED_AT_GKE
          );
          break;

          // Action For Categories
        case ActionType.CATEGORIES:

          this.rec_token_map = this.extractAllRecTokens(this.trolley_page_actions);
          this.categories_you_may_also_like_ids = this.extractItemsByComponent(
            this.categories_page_actions,
            ComponentName.YOU_MAY_ALSO_LIKE
          );
          this.categories_often_bought_together_ids = this.extractItemsByComponent(
            this.categories_page_actions,
            ComponentName.OFTEN_BOUGHT_TOGETHER
          );
          this.categories_best_seller_ids = this.extractItemsByComponent(
            this.categories_page_actions,
            ComponentName.TOP_SELLING_PRODUCTS
          );
          this.categories_related_product_ids = this.extractItemsByComponent(
            this.categories_page_actions,
            ComponentName.RELATED_PRODUCTS_GKE
          );
          this.categories_other_also_like_at_ids = this.extractItemsByComponent(
            this.categories_page_actions,
            ComponentName.OTHER_ALSO_LOOKED_AT_GKE
          );
          break;

        // Action Type For Order Confirmation
        case ActionType.ORDER_CONFIRMATION:

          this.rec_token_map = this.extractAllRecTokens(this.order_confirmation_page_actions);
          this.order_confirmation_best_seller_ids = this.extractItemsByComponent(
            this.order_confirmation_page_actions,
            ComponentName.TOP_SELLING_PRODUCTS
          );
          this.order_confirmation_often_bought_together_ids = this.extractItemsByComponent(
            this.order_confirmation_page_actions,
            ComponentName.OFTEN_BOUGHT_TOGETHER
          );
          this.order_confirmation_you_may_also_like_ids = this.extractItemsByComponent(
            this.order_confirmation_page_actions,
            ComponentName.YOU_MAY_ALSO_LIKE_GKE
          );
          this.order_confirmation_related_product_ids = this.extractItemsByComponent(
            this.order_confirmation_page_actions,
            ComponentName.RELATED_PRODUCTS_GKE
          );
          this.order_confirmation_other_also_like_at_ids = this.extractItemsByComponent(
            this.order_confirmation_page_actions,
            ComponentName.OTHER_ALSO_LOOKED_AT_GKE
          );
          break;
          case ActionType.PLP:
            // Implementation for plp action
          break;

             // Action Type For My Account
          case ActionType.ACCOUNT:

          this.rec_token_map = this.extractAllRecTokens(this.my_account_page_actions);
          this.my_account_recently_viewed_ids = this.extractItemsByComponent(
          this.my_account_page_actions,
          ComponentName.RECENTLY_VIEWED
          );
          this.my_account_you_may_also_like_ids = this.extractItemsByComponent(
          this.my_account_page_actions,
          ComponentName.YOU_MAY_ALSO_LIKE_GKE
          );
          break;
      
        default:
          console.warn(`Unknown action type: ${type_of_action}`);
          break;
      }
    },
    

    /**
     * Extracts item IDs from actions based on the component name.
     * @param data - Array of Monetate actions
     * @param componentName - Name of the component to filter by
     * @returns Array of item IDs
     */
    extractItemsByComponent(data: MonetateAction[], componentName: ComponentName): string[] {
      return data
        .filter((item) => item.component === componentName)
        .flatMap((item) => item.items.map((subItem) => subItem.id));
    },

    /**
     * Extracts all recommendation tokens and their associated item IDs from actions.
     * @param data - Array of Monetate actions
     * @returns Array of objects containing item IDs and their recommendation tokens
     */
    extractAllRecTokens(data: MonetateAction[]): RecTokenMap[] {
      return data.flatMap((item) =>
        item.items.map((subItem) => ({
          id: subItem.id,
          recToken: subItem.recToken,
        }))
      );
    },

    /**
     * Handles the impression event when a product is clicked.
     * @param productId - ID of the clicked product
     */
    async handleProductClickImpression(productId: string): Promise<void> {
      // Implementation for handling product click impression
      let rec_toekn = this.rec_token_map.filter((item) => productId === item.id)
      
      //   await new MonetateService().trackProductCardClick(productId);
    },

    async handleProductViewImpression(productIds: string[]): Promise<void> {
      
      let rec_tokens = this.rec_token_map.filter((item) => productIds.includes(item.id)).map((obj) => obj.recToken)
    
     // await new MonetateService().trackProductThumbnailView(rec_tokens);
    },
    async getProductAddons(){
      const isTrolleyRoute = useRoute().path.includes('trolley');
      const data_ids = isTrolleyRoute 
        ? this.trolley_you_may_also_like_ids 
        : this.categories_you_may_also_like_ids;
    
      if (!data_ids) return; // Exit if data_ids is not defined or empty
    
      this.monetate_item_add_ons = await new ProductService().getProducts(data_ids);
    }

  },
 
});