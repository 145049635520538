
export const useTranslation = (key: string, default_string?: string) => {

    const nuxtApp = useNuxtApp();

    return nuxtApp.$i18n.t(key,default_string, {
        missingWarn: false,
        silentTranslationWarn: true,
        fallbackWarn: false
    });
};
