<template>
  <TsAutocomplete
    v-model:input="inputSearchQuery"
    :data-testid="props.dataTestid"
    :options="props.suggestions ?? []"
    icon-pos="left"
    :input-class="twJoin('rounded-full bg-idle-white', props.inputClass)"
    dropdown-class="bg-white divide-y divide-natural-silver-grey group"
    @list-focus="(opt) => (hoveredOption = opt.description as string)"
    @navigate="(opt) => (keyActiveOption = opt?.description as string)"
    @list-un-focus="hoveredOption = ''"
    @blur="keyActiveOption = ''"
  >
    <template #list-option="{ option }">
      <div class="flex justify-between text-base font-normal">
        <div class="flex text-base font-normal">
          <TsIcon
            :name="suggestionIcon"
            size="20"
            :class="
              twJoin(
                'mt-0.5 me-0.5 shrink-0',
                (hoveredOption === option.description ||
                  keyActiveOption === option.description) &&
                  'text-primary'
              )
            "
          />
          <div
            v-if="option.description"
            v-html="
              boldMatchingText(option.description, inputSearchQuery ?? '')
            "
          ></div>
        </div>

        <TsIcon
          v-if="rightIcon || option.description?.split(' ').includes('Addresses')"
          :name="rightIcon || 'uiw:right'"
          size="16"
          class="text-natural-grey font-semibold shrink-0 mt-0.5"
        />
      </div>
    </template>
  </TsAutocomplete>
</template>

<script setup lang="ts" generic="TLocality extends WoosmapLocalitySuggestion">
import { twJoin } from "tailwind-merge";
import type { WoosmapLocalitySuggestion } from "~/types/woosmap/locality.type";

type Props = {
  suggestions: TLocality[] | null;
  dataTestid?: string;
  inputClass?: string;
  suggestionIcon?: string;
  rightIcon?: string;
};

const props = withDefaults(defineProps<Props>(), {
  inputClass: "",
  suggestionIcon: "carbon:location-filled",
  rightIcon: "",
});

const inputSearchQuery = defineModel<string>("input");

const hoveredOption = ref<string>("");
const keyActiveOption = ref<string>("");

const boldMatchingText = (text: string, keyword: string) => {
  if (!keyword) return;

  // Escape special characters in the keyword for safe use in a RegExp
  const escapedKeyword = keyword
    .replace(/[.,*+?^${}()|[\]\\]/g, "\\$&")
    .replace(/^\s+|\s+$/g, "");

  // Create a RegExp to match the keyword globally and case insensitively
  const regex = new RegExp(`(${escapedKeyword})`, "gi");

  // Replace matched keyword with bold HTML
  return text.replace(regex, `<strong>$1</strong>`);
};
</script>
