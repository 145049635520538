import braintree, {
    ApplePay,
    BraintreeError,
    PayPalCheckout,
    Client,
    DataCollector,
    paypal,
  } from "braintree-web";
  
  import type { PaymentStrategy } from "../payment.service";
  import { LazyTsOfferCard } from "#build/components";
  
  export class CreditCardStrategy implements PaymentStrategy {
    private hosted_field_instance: braintree.HostedFields | null = null;
    private three_d_secure_instance: braintree.ThreeDSecure | null = null;
    private braintree_client_instance: braintree.Client | null = null;
    private payload: any;
    private data_collector_instance: braintree.DataCollector | null = null;
  
    // private braintree_store = useBraintreeStore()
  
    async initializePayment(client, data_collector): Promise<void> {
      this.braintree_client_instance = client;
      this.data_collector_instance = await data_collector;
  
      await this.initializeHostedFields(data_collector.device_data);
      await this.initializeThreeDSecure();
    }
  
    private async initializeHostedFields(device_data) {
      let checkout_store = useCheckoutStore();
  
      this.hosted_field_instance = await braintree.hostedFields.create({
        client: this.braintree_client_instance,
        styles: {
          input: {
            "font-size": "16px",
            "font-family": "gilroy, gilroy fallback, sans-serif",
            "font-weight": "lighter",
            color: "#ccc",
          },
          ":focus": {
            color: "black",
          },
          ".valid": {
            color: "#238826",
          },
          "..invalid": {
            color: "red",
          },
        },
        fields: {
          cardholderName: {
            container: "#cardholder-name-input",
            placeholder: useTranslation('cardholderName','Cardholder name'),
          },
          number: {
            selector: "#card-number-input",
            placeholder: "4111 1111 1111 1111",
          },
          cvv: {
            selector: "#cvv-input           ",
            placeholder: "123",
          },
          postalCode: {
            selector: "#postal-code-input",
            placeholder: "XXXXXX",
          },
          expirationDate: {
            selector: "#card-expiration-input",
            placeholder: "MM/YYYY",
          },
        },
      });
  
      this.hosted_field_instance?.on("validityChange", (event) => {
        checkout_store.is_card_button = Object.values(event.fields).every(
          (field) => field.isValid
        );
      });
  
      const button = document.querySelector("#braintree-card-pay");
  
      button?.addEventListener("click", (e) => {
        e.preventDefault();
        useCheckoutStore().is_transaction_loading = true;
        useCheckoutStore().resetError()
        useCheckoutStore().is_card_button_loading = true;
  
        try{
         
  
        this.processPayment(device_data);
  
        }catch(err){
          useCheckoutStore().is_card_button_loading = false;
          useErrorHandler(err, 'medium')
          useCheckoutStore().is_transaction_loading = false;
        }
        
      });
    }
  
    private async initializeThreeDSecure() {
      if (!this.hosted_field_instance) {
        useErrorHandler("hosted fields not initialized", "high");
        useCheckoutStore().is_transaction_loading = false;
      }
  
      this.three_d_secure_instance = await braintree.threeDSecure.create({
        client: this.braintree_client_instance,
        version: 2,
      });
    }
  
    async processPayment(): Promise<any> {
      try {
       
        this.payload = await this.hosted_field_instance?.tokenize();
        await this.verify3Ds();
        this.clearFileds();
      } catch (err) {
        useErrorHandler(err, "high");
        useCheckoutStore().is_card_button_loading = false
        useCheckoutStore().is_transaction_loading = false;
      }
    }
  
    async verify3Ds() {
      try {
        const checkoutstore = useCheckoutStore();
        const trolleystore = useTrolleyStore();
       
        const authStore = useAuthStore()

        let user = authStore.user ?? authStore.guest;
  
        let amount = trolleystore.trolley.order_response?.totals?.inc_vat.raw.toString();
  
        const response = await this.three_d_secure_instance?.verifyCard(
          {
            amount: amount,
            nonce: this.payload.nonce,
            bin: this.payload.details.bin,
  
            email: user.email,
            billingAddress: {
              givenName: user.first_name,
              surname: user.last_name,
              streetAddress: checkoutstore.billing_address?.line_1,
              locality: checkoutstore.billing_address?.town,
              region: checkoutstore.billing_address?.country,
            },
            onLookupComplete: function (data: any, next: any) {
           
              next();
              
          useCheckoutStore().is_card_button_loading = false
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          },
          function (err, payload) {
            if (err) {
              console.error(err);
              useCheckoutStore().is_transaction_loading = false;
              
          useCheckoutStore().is_card_button_loading = false
              return;
            }
  
            const { liabilityShifted, liabilityShiftPossible } = payload;
            if (liabilityShiftPossible && !liabilityShifted){
              useCheckoutStore().payment_error.hosted_field_error = 'Card Payment failed please try again'
              useCheckoutStore().is_transaction_loading = false;
              
              
          useCheckoutStore().is_card_button_loading = false
                return
            }
  
            if (!liabilityShifted){
  
              
              useCheckoutStore().is_card_button_loading = false
              useCheckoutStore().payment_error.hosted_field_error = 'Card Payment failed please try again'
              useCheckoutStore().is_transaction_loading = false;
              
              return
            }
            useCheckoutStore().is_transaction_loading = true
            //-------------create Transiction -------------//
            useCheckoutStore().payment_success = 'card payment successfull'
            checkoutstore.createTransaction(payload?.nonce, "braintree-card");
        
          }
        );
  
        return response;
      } catch (err) {
        useErrorHandler(err, 'medium')
        useCheckoutStore().is_transaction_loading = false;
        return err
      }
    }
  
    private clearFileds() {
      this.hosted_field_instance?.clear("cardholderName");
      this.hosted_field_instance?.clear("number");
      this.hosted_field_instance?.clear("cvv");
      this.hosted_field_instance?.clear("postalCode");
      this.hosted_field_instance?.clear("expirationDate");
    }
    
    async tearDown() {
      this.hosted_field_instance?.teardown();
    }
  }
  