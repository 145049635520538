export class ExponeaPushNotification {
    constructor() {
        this.checkAvailbility();
    }

    checkAvailbility(): void {
        exponea.notifications.isAvailable((status: boolean) => {
            if (status) {
                this.updateStatus();
            }
        });
    }

    updateStatus(): void {
        exponea.notifications.isSubscribed((subscriptionActive: boolean, notificationsDenied: boolean) => {
            if (subscriptionActive) {
                console.log('active');
            } else {
                if (notificationsDenied) {
                    console.log('denied');
                }
                else {
                    this.subscribe();
                }
            }
        });
    }

    subscribe(): void {
        exponea.notifications.subscribe((status: string) => {
            this.updateStatus();
        });
    }

    unsubscribe(): void {
        exponea.notifications.unsubscribe((status: boolean) => {
            this.updateStatus();
        });
    }
}
