import data from "~/json/pro_card_labels.json";
import labelData from "~/json/pro_card_labels.json";
import { ProCardService } from "~/services/pro-card.service";
import proCardApplicationData from "~/json/pro_card_data.json";
import applicationData from "~/json/pro_card_application_data.json";

export interface ProCardState {
  pro_card_type: string;
  draft_loader: boolean;
  pro_card_credit_form_visible: boolean;
  application_status: string | null;
  selected_company_data: Record<string, any>;
  preview_overlay_terms_modal_with_credit_visible: boolean;
  preview_overlay_terms_modal_without_credit_visible: boolean;
  application_labels_data: typeof labelData;
  pro_application_data: typeof applicationData;
  loading: boolean;
  error: string;
  pro_card_errors: {
    details_error : string;
    name_error : string;
  };
}

export interface ProCardActions {
  getCompanyList(name: string, number: string, code: string, proCardtype: string): Promise<any>;
  putCompanyDetails(company: Object): void;
  saveProCardData(): Promise<any>;
  getDraftData(): Promise<any>;
  postProCardDetails(): Promise<any>;
  validateAccountHolderName(duns: string, name: string, serviceType: ProCardServiceType): Promise<any>;
  getBankDetails(is_consumer: boolean): Promise<any>;
  redirectToThankyou(data: object): Promise<any>;
  handleRedirectToTweeky(data: object): Promise<any>;
  emptyProCardStore(): void;
}

export enum FormStepper {
  STEP_IN_FORM = 'step-in-form',
  COMPANY_DETAILS = 'company-details',
  COMPANY_LIST = 'company-list',
  AUTHROIZED_PERSON = 'authorized-person',
  BANK_DETAILS = 'bank-details',
  SUMMARY = 'summary',
  TWEEKY = 'tweeky',
}

export enum ProCardServiceType {
  WITH_CREDIT = 'With Credit',
  WITHOUT_CREDIT = 'Without Credit'
}

export const useProCardStore = defineStore<"pro-card", ProCardState, {}, ProCardActions>("pro-card", {
  state: () => ({
    pro_card_type: "",
    pro_card_credit_form_visible: false,
    draft_loader: false,
    application_status: null,
    selected_company_data: {},
    preview_overlay_terms_modal_with_credit_visible: false,
    preview_overlay_terms_modal_without_credit_visible: false,
    application_labels_data: { ...labelData },
    pro_application_data: { ...applicationData },
    loading: false,
    error: "",
    pro_card_errors: {
      details_error: "",
      name_error: ""
    }
  }),
  persist: [
    {
      enabled: true,
      key: "pro-card",
      pick: [
        "pro_card_type",
      ],
      storage: import.meta.client ? localStorage : undefined,
    }
  ],
  actions: {
    async getCompanyList(name: string, number: string, code: string, proCardtype: string) {
      try {
        this.loading = true;
        let response;
        if (name && number) {
          response = await ProCardService.getCompaniesData(name, number, code);
        } else if (name) {
          response = await ProCardService.getCompaniesDataByName(name, code);
        } else {
          response = await ProCardService.getCompaniesDataByNumber(number, code);
        }

        if (response.success === false) {
          this.pro_card_errors.details_error = response.data.error.data.error.errorMessage;
        } else {

          if (response.matchCandidates.length === 0) {
            this.pro_card_errors.details_error = "No Match Found for the given input criteria";
            this.loading = false;
            return;
          }

          this.pro_card_errors.details_error = "";
          this.application_labels_data.company_list = response.matchCandidates;

        }

        this.loading = false;
      } catch (err) {
        useErrorHandler(err);
        return err;
      }
    },

    putCompanyDetails(company: Object) {
      if (!company) return;
      const { streetAddress, addressLocality, addressCountry, postalCode } = company?.organization?.primaryAddress;
      this.pro_application_data.form_data.application_data.company_details = {
        duns: company?.organization?.duns,
        company_name: this.pro_application_data.form_data.application_data.company_details.company_name,
        company_address_full: `${streetAddress.line1}, ${postalCode}, ${addressLocality.name} ${addressCountry.name}`,
        company_address_postalcode: postalCode,
        company_address_street: streetAddress.line1,
        company_address_city: addressLocality.name,
        company_info: {
          registration_number: company?.organization?.registrationNumbers?.[0].registrationNumber
        },
        primary_address: {
          address_line1: streetAddress.line1,
          country: {
            name: addressCountry.name
          },
          post_code: postalCode
        },
        account_holder: this.pro_application_data.form_data.application_data.primary_account_holder.name,
      };

      this.pro_application_data.pro_card_data.iban = this.pro_application_data.form_data.application_data.bank_details.iban;
    },

    async saveProCardData() {
      try {
        const response = await ProCardService.saveData(
          this.pro_application_data.form_data
        );

        const { company_name, company_info, company_address_street, company_address_city, primary_address } = this.pro_application_data.form_data.application_data.company_details;
        this.pro_application_data.pro_card_data = {
          applicationId: response.data.id,
          companyName: company_name,
          commerce_number: this.application_labels_data.commerce_number,
          companyRegistrationNumber: company_info.registration_number,
          address: company_address_street,
          city: company_address_city,
          zip: primary_address.post_code
        }

        // return JSON.parse(response.data.application_data);
      } catch (err) {
        useErrorHandler(err);
        return err;
      }
    },

    async getDraftData() {
      try {
        this.draft_loader = true;
        const response = await ProCardService.getDraftData();
        this.pro_application_data.form_data.application_data = JSON.parse(response.data.application_data);
      } catch (err) {
        useErrorHandler(err);
        return err;
      } finally {
        this.draft_loader = false;
      }
    },

    async postProCardDetails() {
      this.loading = true;
      try {
        let payload = {
          company: this.pro_application_data.pro_card_data.companyName,
          commerce_number: this.pro_application_data.pro_card_data.commerce_number,
          account_holder_name: this.pro_application_data.form_data.application_data.primary_account_holder.name,
          account_type: this.pro_application_data.form_data.application_data.bank_details.type,
          iban: this.pro_application_data.form_data.application_data.bank_details.iban,
          position: this.pro_application_data.form_data.application_data.signing_agreement_details.position_within_business,
          name: this.pro_application_data.form_data.application_data.signing_agreement_details.full_name,
          phone: this.pro_application_data.form_data.application_data.signing_agreement_details.mobile,
          email: this.pro_application_data.form_data.application_data.signing_agreement_details.email
        }

        await ProCardService.postProCardInfo(JSON.stringify(payload));
      } catch (err) {
        useErrorHandler(err);
        return err;
      } finally {
        this.loading = false;
      }
    },

    async validateAccountHolderName(duns: string, name: string, serviceType: string) {
      this.loading = true;
      try {
        const response = await ProCardService.validateUserInput(
          { duns, name }
        );

        if (!response.companyPrinciple) {
          this.pro_card_errors.name_error = `Account Holder name must be valid name. Check again`
          this.loading = false;
          return;
        } else {
          this.pro_card_errors.name_error = '';
          this.pro_application_data.form_data.application_data.primary_account_holder.name = name;
          if (serviceType === ProCardServiceType.WITH_CREDIT) navigateTo("/pro-card/application/bank-details");
          else navigateTo("/pro-card/application/summary");
        }

        this.loading = false;

      } catch (err) {
        useErrorHandler(err);
        return err;
      } finally {
        this.loading = false;
      }
    },

    async getBankDetails(is_consumer: boolean) {
      this.loading = true;
      try {
        const response = await ProCardService.getBanksList(
          is_consumer
        );

        response.forEach((curr: object) => {
          this.application_labels_data.bank_list.push(curr.Name);
        })

      } catch (err) {
        useErrorHandler(err);
        return err;
      } finally {
        this.loading = false;
      }
    },

    async redirectToThankyou(data: object) {
      this.loading = true;
      try {
        let proCardPayloadData = { ...this.pro_application_data.pro_card_data, ...this.pro_application_data.form_data };

        let payload = {
          ...data,
          ...proCardPayloadData
        }

        const response = await ProCardService.handleRedirectToThankyou(JSON.stringify(payload));

        if (response.data.status === 1 || response.data.status === 2) {
          navigateTo("/pro-card/application/complete");
        }
      } catch (err) {
        useErrorHandler(err);
        return err;
      } finally {
        this.loading = false;
      }
    },

    async handleRedirectToTweeky(data: object) {
      this.loading = true;
      try {
        let proCardPayloadData = { ...this.pro_application_data.pro_card_data, ...this.pro_application_data.form_data };

        let payload = {
          ...data,
          ...proCardPayloadData
        }

        const response = await ProCardService.redirectToTweeky(JSON.stringify(payload));

        if (response.success === false) {
          this.error = response.errors[0];
        } else {
          navigateTo(`${response.url}`, { external: true });
        }
      } catch (err) {
        useErrorHandler(err);
        return err;
      } finally {
        this.loading = false;
      }
    },
    emptyProCardStore() {
      this.pro_card_type = "";
      this.draft_loader = false;
      this.application_status = null;
      this.selected_company_data = {};
      this.preview_overlay_terms_modal_with_credit_visible = false;
      this.preview_overlay_terms_modal_without_credit_visible = false;
      this.application_labels_data = data;
      this.pro_application_data = proCardApplicationData;
      this.loading = false;
      this.error = "";
    }
  },
});


if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProCardStore, import.meta.hot));
}