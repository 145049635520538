<script setup lang="ts">
import {twMerge} from "tailwind-merge";

// Variants Types::
type Variants = "primary" | "info" | "success" | "warning" | "danger" | "contrast";

type Sizes = "xs" | "sm" | "base" | "lg";

type Props = {
  label?: string,
  icon?: string,
  variant?: Variants,
  appendClass?: string,
  disabled?: boolean,
  dashed?: boolean,
  rounded?: boolean,
  size?: Sizes,
  outlined?: boolean,
  dataTestid?: string
}

const props = withDefaults(defineProps<Props>(), {
  label: 'Tag',
  variant: 'primary',
  size: 'base',
  dataTestid: 'atom-tag'
});
</script>

<template>
  <span
      :class="twMerge(
          'inline-flex gap-1 items-center text-base font-semibold px-2.5 py-[5px] bg-natural-soft-white text-nowrap',
          variant === 'primary' && 'text-idle-black border-natural-silver-grey',
          variant === 'success' && 'bg-success/15 text-success border-none',
          variant === 'warning' && 'bg-warning/15 text-warning border-none',
          variant === 'danger' && 'bg-danger/15 text-danger border-none',
          variant === 'info' && 'bg-info/15 text-info border-none',
          variant === 'contrast' && 'bg-idle-black text-idle-white border-none',
          props.dashed && 'border-dashed',
          props.outlined && 'outline outline-1',
          props.size === 'xs' && 'text-xs px-2 py-0.5 rounded-[2px]',
          props.size === 'sm' && 'text-xs px-2 py-1 rounded-xs',
          props.size === 'base' && 'text-base px-3 py-1.5 rounded-sm',
          props.size === 'lg' && 'text-base px-3 py-1.5 rounded-md',
          props.rounded && 'rounded-full',
          props.appendClass
          )"
      :data-testid="dataTestid"
  >
    <Icon v-if="props.icon" :name="props.icon" />
    <slot>{{ props.label }}</slot>
  </span>
</template>
