<template>
  <section
    :data-testid="
      props.dataTestid ? props.dataTestid + '_map-container' : '_map-container'
    "
    :class="twMerge('relative h-[550px]', props.appendClass)"
  >
    <!-- Actual Map -->
    <div
      id="map"
      :data-testid="props.dataTestid ? props.dataTestid + '_map' : '_map'"
      ref="mapRef"
      class="w-full h-full rounded-lg"
    ></div>

    <!-- Intermediate state between listview and mapview  -->
    <!-- <div
      v-if="!mapRef?.clientHeight"
      class="h-full w-full absolute inset-0 bg-natural-dark-grey/20 grid place-items-center"
    >
      <Icon
        name="eos-icons:bubble-loading"
        size="96"
        class="text-natural-grey"
      />
    </div> -->
  </section>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import type { Branch } from "~/types/ecom/branch.type";

type Props = {
  stores?: Branch[];
  zoomTo?: {
    latitude: number;
    longitude: number;
  } | null;
  isDetailedInfoWindow?: boolean;
  appendClass?: string;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  isDetailedInfoWindow: false,
  appendClass: "",
});

const emit = defineEmits<{
  saveStore: [store: Branch];
}>();

//---- STATES
const mapRef = ref<HTMLElement | null>(null);
let map: woosmap.map.Map;
const currentInfoWindow = ref<woosmap.map.InfoWindow | null>(null);

function initMap() {
  const latLang = {
    lat: 51.50940214,
    lng: -0.133012,
  };

  map = new woosmap.map.Map(mapRef.value as HTMLElement, {
    zoom: 9,
    center: latLang,
  });

  setMarkers();
}

//------ METHODS

function setMarkers() {
  if (!props.stores?.length) return;

  const randomIndex = Math.floor(Math.random() * props.stores.length);

  map.panTo({
    lat: Number(props.stores[randomIndex].geolocation.latitude),
    lng: Number(props.stores[randomIndex].geolocation.longitude),
  });

  props.stores.forEach((store: Branch) => {
    const woosmapMarker: woosmap.map.Marker = putMarker(store);
    woosmapMarker.setMap(map);
    setBranchInfoPopup(woosmapMarker, store);
  });
}

function setBranchInfoPopup(marker: woosmap.map.Marker, store: Branch) {
  const infoBox = new woosmap.map.InfoWindow({});

  let content: string = "";

  content += `<strong class="text-lg mb-4">Toolstation ${store.name}</strong>`;

  /* show hour details of branch in our-branches page */
  if (props.isDetailedInfoWindow) {
    content += `<p class="text-sm">${store.details}</p>`;
  } else {
    content += `<p class="my-2"></p>`;
  }

  content += `
  <button id="make-my-store" class="bg-primary text-white text-[18px] rounded-md px-8 py-2.5 font-semibold hover:bg-primary-hover">Set as my branch</button>
`;

  infoBox.setOffset(new woosmap.map.Point(0, -40));
  infoBox.setContent(content);

  marker.addListener("click", () => {
    /* close active window if another marker is clicked */
    currentInfoWindow.value?.close();

    infoBox.open(map, marker.position);
    currentInfoWindow.value = infoBox;

    /* Add event listener to make this my store button */
    setTimeout(() => {
      const button = document.getElementById("make-my-store");
      if (button) {
        button.addEventListener("click", () => handleSaveFromMap(store));
      }
    }, 0);
  });
}

function handleSaveFromMap(store: Branch) {
  currentInfoWindow.value?.close();
  emit("saveStore", store);
}

/* Render marker on the map */
const putMarker = (store: Branch) => {
  const markerPosition: woosmap.map.LatLngLiteral = {
    lat: Number(store.geolocation.latitude),
    lng: Number(store.geolocation.longitude),
  };

  return new window.woosmap.map.Marker({
    position: markerPosition,
    icon: {
      url: "/images/ts-map-marker.png",
      scaledSize: {
        height: 31,
        width: 26,
      },
    },
  });
};

/* Zoom to first nearest branch on search */
watch(
  () => props.zoomTo,
  (z) => {
    if (!z) {
      initMap();
      return;
    }

    const zoomCenter = {
      lat: z.latitude,
      lng: z.longitude,
    };

    map.flyTo({
      center: zoomCenter,
      zoom: 14,
      animate: true,
      duration: 150,
    });
  }
);

onMounted(() => {
  initMap();
});
</script>
