<template>
     <TsTypography
         :id="props.id || `title-${random_id}`"
         :data-testid="props.dataTestid"
         :as="props.as"
         :variant="props.variant"
         :size="props.size"
         :append-class="twMerge('ts-title relative',props.animate && 'animateTitle', props.appendClass)"
     >
       <slot>
         {{ props.title }}
       </slot>
     </TsTypography>
</template>

<script lang="ts" setup>
import {onMounted, ref} from "vue";
import {useRandomUUID} from "../../composables/useRandomUUID";
import {twMerge} from "tailwind-merge";

const random_id = ref("");

onMounted(() => {
  random_id.value = useRandomUUID();
});
const animateTitle='animateTitle'

// Variant
const Variants = ["h1", "h2", "h3", "h4", "h5", "h6", "ul", "ol", "li", "md", "base", "sm", "xs", "xxs", "NuxtLink"] as const;

type Props = {
  dataTestid?: string;
  as?: string;
  id?: string;
  appendClass?: string;
  title?: string;
  animate?:boolean;
  variant?: (typeof Variants)[number];
  size?: "md" | "base" | "sm" | "xs" | "xxs";
};

const props = withDefaults(defineProps<Props>(), {
  dataTestid: 'atom-title',
  appendClass: '',
  as: 'h1'
});

</script>
<style scoped>

.animateTitle {

  animation: titleAnimation 3000ms ease-in-out backwards;
}

@keyframes titleAnimation {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
}
.animateCircle {
  animation: circleAnimation 3000ms ease-in-out forwards;
}

@keyframes circleAnimation {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(1440px);
  }
}
</style>