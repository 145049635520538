import CardDeal from "./CardDeal.vue";
import Breadcrumb from "./molecules/TsBreadcrumb.vue";
import HeroBanner from "./organisms/TsHeroBanner.vue";
import CardSection from "./organisms/TsSection.vue";
import CardInfo from "./organisms/TsCardInfo.vue";
import CardNews from "./organisms/TsCardNews.vue";
import NewsletterSection from "./organisms/TsNewsletterSection.vue";
import Swiper from "./organisms/TsSwiper.vue";
import CardCollapsable from "./organisms/TsCardCollapsable.vue";
import Accordion from "./atoms/TsAccordion.vue";
import AccordionTab from "./atoms/TsAccordionTab.vue";
import Typography from "./atoms/TsTypography.vue";
import CardInfoV2 from "./organisms/TsCardInfoV2.vue";
import TabSwiper from "./organisms/TsTabSwiper.vue";
import Card from "./organisms/TsCard.vue";
import CardVacancies from "./organisms/TsCardVacancies.vue";
import Button from "./atoms/TsButton";
import Player from "./molecules/TsPlayer.vue";
import CardTestimonial from "./organisms/TsCardTestimonial.vue";
import CardWithAction from "./organisms/TsCardWithActionButtons.vue";
import CardLabel from "./organisms/TsCardLabel.vue";
import CardStatstics from "./organisms/TsCardStatstics.vue";
import Xscrollbar from "./molecules/TsXscrollContainer.vue";
import BannerWithText from "./organisms/TsBannerWithText.vue";
import CardRounded from "./organisms/TsCardRounded.vue";
import CardWithList from "./organisms/TsCardWithList.vue";
import Icon from "./atoms/TsIcon.vue";
import Row from "./organisms/TsRow.vue";
import Column from "./organisms/TsColumn.vue";
import Grid from "./organisms/TsGrid.vue";
import TsAlert from "./molecules/TsAlert.vue";
import TsInput from "./atoms/TsInput.vue";
import TsFaqSection from "./organisms/TsFaqSection.vue";
import TsCardCtaContact from "./organisms/TsCardCtaContact.vue";
import TsSelectBox from "./atoms/TsSelectBox.vue";
import TsTextarea from "./atoms/TsTextarea.vue";
import CardProductPreview from "./organisms/TsCardProductPreview.vue";
import BannerFaq from "./organisms/TsBannerFaq.vue";
import CardTrackOrder from "./organisms/TsCardTrackOrder.vue";
import TsCardMedia from "./organisms/TsCardMedia.vue";
import TsSection from "./organisms/TsSection.vue";
import TsGifPlayer from "./organisms/TsGifPlayer.vue";
import CardHighFive from "./organisms/TsCardHighFive.vue";
import CarouselHighlights from "./organisms/TsCarouselHighlights.vue";
import CarouselDealsAndOffers from "./organisms/TsCarouselDealsAndOffers.vue";
import CarouselSaleAutoPlay from "./organisms/TsCarouselSaleAutoPlay.vue";
import CardCategory from "./organisms/TsCardCategory.vue";
import CarouselCardProduct from "./organisms/TsCarouselCardProduct.vue";
import CarouselBlackFriday from "./organisms/TsCarouselBlackFriday.vue";
import CarouselTopBrands from "./organisms/TsCarouselTopBrands.vue";
import CarouselRecommendProducts from "./organisms/TsCarouselRecommendProducts.vue";
import CarouselIdeasAndInspiration from "./organisms/TsCarouselIdeasAndInspiration.vue";
import SectionBarcelona from "./organisms/TsSectionBarcelona.vue";
import CardPlayer from "./organisms/TsCardPlayer.vue";
import CardExplore from "./organisms/TsCardExplore.vue";
import CarouselJobCompanion from "./organisms/TsCarouselJobCompanion.vue";
import CarouselBannerHome from "./organisms/TsCarouselBannerHome.vue";
import NewLaunch from "./organisms/TsNewLaunch.vue";
import TabsCategorySelection from "./molecules/TsTabsCategorySelection.vue";
import HiFiveSection from "./organisms/TsHiFiveSection.vue";
import ContainerCategories from "./molecules/TsContainerCategories.vue";
import CustomerSupportSection from "./organisms/TsCustomerSupportSection.vue";
import ClearanceSaleSection from "./organisms/TsClearanceSaleSection.vue";
import ReviewCard from "./organisms/TsReviewCard.vue";
import CardReviewTrustpilot from "./organisms/TsCardReviewTrustpilot.vue";
import TsProductBanner from "./organisms/TsProductBanner.vue";
import TsBannerProduct from "./organisms/TsBannerProduct.vue";
import TsCarouselProductBanner from "./organisms/TsCarouselProductBanner.vue";
import BenefitNewsletter from "./organisms/TsBenefitNewsletter.vue";
import Title from "./atoms/TsTitle.vue";
import GifPlayer from "./organisms/TsGifPlayer.vue";
import StoryCard from "./organisms/TsStoryCard.vue";
import TestimonialCard from "./organisms/TsTestimonialCard.vue";
import CardBenifit from "./organisms/TsCardBenifit.vue";
import RoundedCards from "./organisms/TsRoundedCards.vue";
import OurStoriesCarousel from "./organisms/TsOurStoriesCarousel.vue";
import CardGetInTouch from "./organisms/TsCardGetInTouch.vue";
import CatalogueCards from "./organisms/TsCatalogueCards.vue";
import DiscoveryCards from "./organisms/TsDiscoveryCards.vue";
import SearchBrand from "./organisms/TsSearchBrand.vue";
import TsTable from "./molecules/TsTable.vue";
import CardWithRatings from "./organisms/TsCardWithRatings.vue";
import Carousel from "./organisms/TsCarousel.vue";
import SearchByTopic from "./organisms/TsSearchByTopic.vue";
import TimelineSlider from "./organisms/TsTimelineSlider.vue";
import Tag from "./atoms/TsTag.vue";
import CardSiteMapList from "./organisms/TsCardSitemapList.vue";
import TsContactUsForm from "./organisms/TsContactUsForm.vue";
import AccordionV3 from "./atoms/TsAccordionV3.vue";
import AccordionTabV3 from "./atoms/TsAccordionTabV3.vue";
import InfoTable from "./organisms/TsInfoTable.vue";
import CardContent from "./organisms/TsCardContent.vue";
import CardFloatingCta from "./organisms/TsCardFloatingCta.vue";
import CardFloatingCarousel from "./organisms/TsCardFloatingCarousel.vue";
import BranchLocatorForm from "./organisms/TsBranchLocatorForm.vue";
import HistoryTimeline from "./organisms/TsHistoryTimeline.vue";
import CardClickAndCollect from "./organisms/TsCardClickAndCollect.vue";
import BannerHomePrimaryLarge from "./organisms/TsBannerHomePrimaryLarge.vue";
import BannerHomePrimarySmall from "./organisms/TsBannerHomePrimarySmall.vue";
import TsCarouselV2 from "./organisms/TsCarouselBuilder.vue";
import TsBannerHomePrimaryDynamic from "./organisms/TsBannerHomePrimaryDynamic.vue";
import TsBannerHomeRightDynamic from "./organisms/TsBannerHomeRightDynamic.vue";
import PaymentMethodsTable from "./organisms/TsPaymentMethodsTable.vue";
import TableDeliverySchedule from "./organisms/TsTableDeliverySchedule.vue";
import TableBenefitComparison from "./organisms/TsTableBenefitComparison.vue";
import StepperStatic from "./organisms/TsStepperStatic.vue";
import CardProcedureRequirement from "./organisms/TsCardProcedureRequirement.vue";
import TsBannerHomeHeroMobile from "./organisms/TsBannerHomeHeroMobile.vue";
import TsBannerCard from "./organisms/TsBannerCard.vue";
import BannerLayout from "./builder/BannerLayout.vue";
import CardLink from "./organisms/TsCardLink.vue";
import CustomerSupport from "./organisms/TsCustomerSupport.vue";
import TsBannerHomeHighlights from "./organisms/TsBannerHomeHighlights.vue";
import TsBannerHomePrimaryFullDynamic from "./organisms/TsBannerHomePrimaryFullDynamic.vue";
import TsBannerHomeDealsAndOffers from "./organisms/TsBannerHomeDealsAndOffers.vue";
import TsBannerNewLaunch from "./organisms/TsBannerNewLaunch.vue";
import TsCardNewLaunch from "./organisms/TsCardNewLaunch.vue";
import CardCta from "./organisms/TsCardCta.vue";
import TsDivider from "./atoms/TsDivider.vue";
import RequirementCard from "./organisms/TsRequirementCard.vue";
import TsTabsLinksSelection from "./molecules/TsTabsLinksSelection.vue";
import TsCardTestimonialV2 from "./organisms/TsCardTestimonialV2.vue";
import CardGuarantee from "./organisms/TsCardGuarantee.vue";
import CardInfoIcon from "./organisms/TsCardInfoIcon.vue";
import BrowserIconCards from "./organisms/TsBrowserIconCards.vue";
import BrandGuarantee from "./organisms/TsBrandGuarantee.vue";
import TsTableBuilder from "./organisms/TsTableBuilder.vue";
import DealsAnimation from "./organisms/TsDealsAnimation.vue";
import CardCategoryVersionTwo from "./organisms/TsCardCategoryVersionTwo.vue";
import TsCardImageHorizontal from "./organisms/TsCardImageHorizontal.vue";
import BannerCountdown from "./organisms/TsBannerCountdown.vue";
import TsCardDetail from "./organisms/TsCardDetail.vue";
import TsCardCheckOut from "./organisms/TsCardCheckOut.vue";
import TsCardRoundedV1 from "./organisms/TsCardRoundedV1.vue";
import TsCardFeatureBlog from "./organisms/TsCardFeatureBlog.vue";
import TsCardProductWithVideo from "./organisms/TsCardProductWithVideo.vue";
import TsBrandTag from "./organisms/TsBrandTag.vue";
import FaqSearchSection from "./organisms/TsFaqSearchSection.vue";
import CardHelp from "./organisms/TsCardHelp.vue";
import TsTypographyWithIcon from "./atoms/TsTypographyWithIcon.vue";
import TsListWithIcon from "./molecules/TsListWithIcon.vue";
import TextReadMore from "./molecules/TsTextReadMore.vue";
import CardViewMore from "./organisms/TsCardViewMore.vue";
import TsBannerWithThreeSegments from "./organisms/TsBannerWithThreeSegments.vue";
import TsBannerWithTwoSegmentsMobile from "./organisms/TsBannerWithTwoSegmentsMobile.vue";
import NewArrivalCarouselWithBanner from "./organisms/TsNewArrivalCarouselWithBanner.vue";
import ButtonReturnFormTrigger from "./organisms/TsButtonReturnFormTrigger.vue";
import TsMonetateCarousel from "./organisms/TsMonetateCarousel.vue";
import TsTopBrands from "./organisms/TsTopBrands.vue";
export {
  Card,
  Button,
  Player,
  Breadcrumb,
  CardDeal,
  CardInfo,
  CardNews,
  HeroBanner,
  CardSection,
  NewsletterSection,
  Swiper,
  CardCollapsable,
  HistoryTimeline,
  Typography,
  CardInfoV2,
  TabSwiper,
  CardVacancies,
  CardTestimonial,
  CardWithAction,
  CardLabel,
  CardStatstics,
  Accordion,
  AccordionTab,
  Xscrollbar,
  BannerWithText,
  CardRounded,
  CardWithList,
  CardCategory,
  Icon,
  Row,
  Column,
  Grid,
  TsAlert,
  TsInput,
  TsFaqSection,
  CardProductPreview,
  BannerFaq,
  CardTrackOrder,
  TsCardMedia,
  TsSection,
  TsGifPlayer,
  CardHighFive,
  TsProductBanner,
  CardExplore,
  CarouselHighlights,
  CarouselDealsAndOffers,
  CarouselSaleAutoPlay,
  CarouselCardProduct,
  CarouselBlackFriday,
  CarouselTopBrands,
  CarouselRecommendProducts,
  CardPlayer,
  SectionBarcelona,
  CarouselIdeasAndInspiration,
  NewLaunch,
  CarouselBannerHome,
  CarouselJobCompanion,
  ContainerCategories,
  HiFiveSection,
  TabsCategorySelection,
  ClearanceSaleSection,
  CustomerSupportSection,
  ReviewCard,
  CardReviewTrustpilot,
  TsBannerProduct,
  TsCarouselProductBanner,
  TsCardCtaContact,
  BenefitNewsletter,
  Title,
  CardFloatingCta,
  CardFloatingCarousel,
  TsSelectBox,
  TsTextarea,
  GifPlayer,
  StoryCard,
  TestimonialCard,
  CardBenifit,
  RoundedCards,
  OurStoriesCarousel,
  CardGetInTouch,
  CatalogueCards,
  DiscoveryCards,
  SearchBrand,
  SearchByTopic,
  TsTable,
  CardWithRatings,
  Carousel,
  TimelineSlider,
  Tag,
  CardSiteMapList,
  TsContactUsForm,
  AccordionV3,
  AccordionTabV3,
  InfoTable,
  CardContent,
  CardProcedureRequirement,
  CardClickAndCollect,
  TableBenefitComparison,
  StepperStatic,
  BranchLocatorForm,
  BannerHomePrimarySmall,
  BannerHomePrimaryLarge,
  TsCarouselV2,
  TsBannerHomePrimaryDynamic,
  TsBannerHomeRightDynamic,
  PaymentMethodsTable,
  TableDeliverySchedule,
  CardLink,
  CustomerSupport,
  TsBannerHomeHeroMobile,
  BannerLayout,
  TsBannerHomeHighlights,
  TsBannerHomePrimaryFullDynamic,
  TsBannerCard,
  TsBannerHomeDealsAndOffers,
  TsCardNewLaunch,
  TsBannerNewLaunch,
  CardCta,
  TsTabsLinksSelection,
  TsDivider,
  RequirementCard,
  TsCardTestimonialV2,
  CardGuarantee,
  CardInfoIcon,
  BrowserIconCards,
  TsTableBuilder,
  BrandGuarantee,
  DealsAnimation,
  CardCategoryVersionTwo,
  TsCardDetail,
  TsCardCheckOut,
  TsCardRoundedV1,
  TsCardFeatureBlog,
  TsCardProductWithVideo,
  TsBrandTag,
  TsCardImageHorizontal,
  FaqSearchSection,
  CardHelp,
  BannerCountdown,
  TsTypographyWithIcon,
  TextReadMore,
  CardViewMore,
  TsListWithIcon,
  TsBannerWithThreeSegments,
  TsBannerWithTwoSegmentsMobile,
  NewArrivalCarouselWithBanner,
  ButtonReturnFormTrigger,
  TsMonetateCarousel,
  TsTopBrands
};
